import { Injectable } from "@angular/core";
import { ChargeOrganizationFee, ExecuteOrganizationTransition, GiveOrganizationBonus, UpdateOrganization, UpdateOrganizationNotes, UpdatePaymentDetails } from "app/entity/admin";
import { PaymentDetails } from "app/entity/billing";
import { Module, Organization, OrganizationTransition } from "app/entity/infrastructure";
import { ApiService } from "app/shared/services/api";
import { httpAdminOrganizationBonus, httpAdminOrganizationChargeFee, httpAdminOrganizationModuleLocation, httpAdminOrganizationNotes, httpAdminOrganizationPaymentDetails, httpAdminOrganizationTransitions, httpAdminOrganizations, httpModules } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationDataService extends GenericDataService<Organization, null, UpdateOrganization> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrganizations, apiService);
  }

  updateNotes(id: number, data: UpdateOrganizationNotes): Observable<Organization> {
    return this.apiService.patch(httpAdminOrganizationNotes(id), data);
  }

  getTransitions(id: number): Observable<OrganizationTransition[]> {
    return this.apiService.get(httpAdminOrganizationTransitions(id));
  }

  executeTransition(id: number, data: ExecuteOrganizationTransition): Observable<void> {
    return this.apiService.post(httpAdminOrganizationTransitions(id), data);
  }

  giveBonus(id: number, data: GiveOrganizationBonus): Observable<Organization> {
    return this.apiService.post(httpAdminOrganizationBonus(id), data);
  }

  chargeFee(id: number, data: ChargeOrganizationFee): Observable<Organization> {
    return this.apiService.post(httpAdminOrganizationChargeFee(id), data);
  }

  getPaymentDetails(id: number): Observable<PaymentDetails> {
    return this.apiService.get(httpAdminOrganizationPaymentDetails(id));
  }

  updatePaymentDetails(id: number, data: UpdatePaymentDetails): Observable<PaymentDetails> {
    return this.apiService.patch(httpAdminOrganizationPaymentDetails(id), data);
  }

  getModules(): Observable<Module[]> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.apiService.get(httpModules(), { params: { 'no_filter': 1 } });
  }

  enableModuleAccess(id: number, moduleId: number, locationId: number): Observable<void> {
    return this.apiService.post(httpAdminOrganizationModuleLocation(id, moduleId, locationId));
  }

  disableModuleAccess(id: number, moduleId: number, locationId: number): Observable<void> {
    return this.apiService.delete(httpAdminOrganizationModuleLocation(id, moduleId, locationId));
  }
}
