import { Injectable } from '@angular/core';
import { CreateTutorialType, TutorialType, UpdateTutorialType } from 'app/entity/admin';
import { ApiService } from 'app/shared/services/api';
import { httpAdminDashboardTutorialTypes } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class TutorialTypeDataService extends GenericDataService<TutorialType, CreateTutorialType, UpdateTutorialType> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminDashboardTutorialTypes, apiService);
  }
}
