import { Component, Input, OnInit } from '@angular/core';

import { Image } from 'app/entity/compute-instances/image.model';

import { ValueAccessorBase } from '../../utils/value-accessor';
import { CardOption } from '../card-dropdown-select/card-dropdown-select.component';

interface Distribution {
  os: string;
  selected: Image;
  versions: Image[];
}

@Component({
  selector: 'myflow-os-list',
  templateUrl: './os-list.component.html',
  styleUrls: [ './os-list.component.scss' ],
  providers: [
    // eslint-disable-next-line no-use-before-define
    ValueAccessorBase.createProviderFor(OsListComponent),
  ],
})
export class OsListComponent extends ValueAccessorBase<Image> implements OnInit {
  @Input() location: number;
  @Input() images: Image[];

  distributions: Distribution[];

  get distributionOptions(): CardOption<Image>[] {
    // Filter by distribution and version availability
    let distributions = this.distributions.filter((distribution) => this.isDistributionAvailable(distribution));
    distributions = distributions.map((distribution) => {
      distribution.versions = distribution.versions.filter((version) => this.isAvailableAtLocation(version));
      return distribution;
    });

    // Map distributions to card options
    return distributions.map((distribution): CardOption<Image> => ({
      name: distribution.os,
      subOptions: distribution.versions.map((version) => ({
        name: version.version,
        value: version,
      })),
      currentValue: distribution.versions[0],
    }));
  }

  ngOnInit(): void {
    const reduced = this.images.reduce(OsListComponent.transformToDistributions, {} as Record<string, Distribution>);
    this.distributions = Object.keys(reduced).map(key => reduced[key]);
  }

  isDistributionAvailable(distribution: Distribution): boolean {
    return !!distribution.versions.find(image => this.isAvailableAtLocation(image));
  }

  isAvailableAtLocation(image: Image): boolean {
    return image.availableLocations.findIndex(val => val === this.location) !== -1;
  }

  private static transformToDistributions(accumulator: Record<string, Distribution>, current: Image): Record<string, Distribution> {
    let dist = accumulator[current.os];
    if (!dist) {
      dist = {
        os: current.os,
        selected: current,
        versions: [],
      };

      accumulator[current.os] = dist;
    }

    dist.versions.push(current);
    return accumulator;
  }
}
