import { Component, ContentChild, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SideBySideSelectLabelDirective } from '../../directives/side-by-side-select/side-by-side-select-label.directive';
import {
  SideBySideSelectDisabledTooltipDirective,
} from '../../directives/side-by-side-select/side-by-side-select-disabled-tooltip.directive';

@Component({
  selector: 'myflow-side-by-side-select',
  templateUrl: './side-by-side-select.component.html',
  styleUrls: [ './side-by-side-select.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SideBySideSelectComponent),
    },
  ],
})
export class SideBySideSelectComponent<T> implements ControlValueAccessor {
  @Input() items: T[];
  @Input() disabled = false;
  @Input() bindLabel = 'name';

  @ContentChild(SideBySideSelectLabelDirective, { read: TemplateRef }) labelTemplate: TemplateRef<any>;
  @ContentChild(SideBySideSelectDisabledTooltipDirective, { read: TemplateRef }) tooltipTemplate: TemplateRef<any>;

  onChange: (item: T) => void;
  onTouched: (item: T) => void;

  selectedItem: T;

  isSelected(item: T): boolean {
    return this.selectedItem === item;
  }

  isDisabled(item: T): boolean {
    const obj = item as any;
    return (obj?.disabled !== undefined) && obj?.disabled;
  }

  writeValue(obj: T): void {
    if (!this.isDisabled(obj)) {
      this.selectedItem = obj;
      if(this.onChange) {
        this.onChange(this.selectedItem);
      }
    }
  }

  registerOnChange(fn: (item: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (item: T) => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
