import { Network } from './network.model';
import { Router } from './router.model';

export interface VirtualPrivateNetworkPeerEndpoint {
  network: Network;
  router?: Router;
}

export interface CreatePeerEndpoint {
  privateNetworkId: number;
}
