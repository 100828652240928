import { createAction, props, union } from '@ngrx/store';
import { Organization } from 'app/entity/infrastructure/organization.model';
import { User } from 'app/entity/infrastructure/user.model';

export const setUser = createAction('[User] Set User', props<{ user: User }>());
export const setUserProfile = createAction('[User] Set User Profile Fields', props<{ user: User }>());
export const setUserOrg = createAction('[User] Set User Organization', props<{ organization: Organization }>());
export const impersonate = createAction('[User] Impersonating', props<{ user: User }>());
export const removeImpersonate = createAction('[User] Clear Impersonating');

const all = union({ setUser, setUserProfile, setUserOrg, impersonate, removeImpersonate });
export type UserActionsUnion = typeof all;
