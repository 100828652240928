<div class="toast-message-container">
  <div class="">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      <strong>{{ title }}</strong>
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="close-btn">
    <a (click)="remove()">
      <i class="icofont icofont-close-line"></i>
    </a>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
