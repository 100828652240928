import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SidePopupService } from 'app/shared/services/side-popup.service';
import { TicketService } from 'app/tickets/store/tickets';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CreateTicket } from 'app/entity/tickets';
import { Plan, PlanService } from 'app/tickets/store/plans';
import { first, map } from 'rxjs/operators';
import { PlanOption } from '../plan-select/plan-select.component';
@Component({
  selector: 'myflow-support-plan-sidebar',
  templateUrl: './support-plan-sidebar.component.html',
  styleUrls: [ './support-plan-sidebar.component.scss' ],
})
export class SupportPlanSidebarComponent {
  @Output() create = new EventEmitter();

  selectedPlan: Plan;

  constructor (
    private ticketService: TicketService,
    private planService: PlanService,
    private toastrService: ToastrService,
    private sidePopup: SidePopupService,
    private router: Router,
  ) {
  }

  get loading$(): Observable<boolean> {
    return this.sidePopup.loading$;
  }

  get plans$(): Observable<PlanOption<Plan>[]> {
    return this.planService.getPlans().pipe(
      // Map the plan object to the PlanOption used by the plan selector
      map((plans) => plans.map((plan): PlanOption<Plan> => ({
        name: plan.name,
        features: plan.items.map((item) => ({
          title: item.name,
          value: item.description,
        })),
        value: plan,
        price: plan.price,
        active: plan.active,
        hint: plan.description,
      }))),
    );
  }

  getSelectedPlan(): Plan {
    return this.selectedPlan;
  }

  requestSelectedPlan(): void {
    const requestTicket: CreateTicket = {
      subject: `Upgrade to ${this.getSelectedPlan().name} Support`,
      comment: `We are interested in upgrading our support plan to ${this.getSelectedPlan().name}.`,
      categoryId: 3,
      severityId: 1,
      attachments: [],
    };
    this.sidePopup.loading = true;
    this.ticketService.create(requestTicket)
    .pipe(first())
    .subscribe({
      next: () => {
        const text = 'Request for Support Plan upgrade successfully created. You will be contacted soon to discuss the next steps.';
        this.toastrService.success(text);
        this.router.navigate([ '/support/tickets/open' ]);
        this.sidePopup.close();

        this.create.emit(true);
      }, error: () => this.sidePopup.loading = false,
    });
  }

  close(): void {
    this.sidePopup.close();
  }
}
