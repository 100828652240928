import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ImageCropService } from 'app/shared/services/image-crop.service';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  allowUseImgFromService = false;
  private avatarImgBlobSource$ = new BehaviorSubject<Blob>(null);
  private avatarImgBlob$: Observable<Blob> = this.avatarImgBlobSource$.asObservable();

  constructor(
    @Inject(ImageCropService) private imgCropService: ImageCropService,
  ) {}

  fetchAvatar(id: number, imgUrl: string | null = null): Observable<any> {
    return this.imgCropService.fetchAvatar(id, imgUrl);
  }

  deleteAvatar(id: number): Observable<any> {
    return this.imgCropService.deleteAvatar(id);
  }

  addAvatar(file: Blob, id: number, fileName?: string): Observable<any> {
    return this.imgCropService.addAvatar(file, id, fileName);
  }

  setAvatarImgBlob(blob: Blob): void {
    this.avatarImgBlobSource$.next(blob);
  }

  getAvatarImgBlob(): Observable<Blob> {
    return this.avatarImgBlob$;
  }
}
