import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myflow-table-truncated-cell',
  templateUrl: './table-truncated-cell.component.html',
  styleUrls: [ './table-truncated-cell.component.scss' ],
})
export class TableTruncatedCellComponent {
  @Input() list: any[];
  @Input() cellTemplate: TemplateRef<HTMLElement>;
  @Input() truncateTemplate: TemplateRef<HTMLElement>;
  @Input() truncateClass: string;
  @Input() displaySelector: string;
  @Input() card = false;

  @ViewChild('tooltip') tooltipTrigger: NgbTooltip;

  onMouseLeave(): void {
    if (this.tooltipTrigger && this.tooltipTrigger.isOpen) {
      this.tooltipTrigger.close();
    }
  }
}
