<div
class="dynamic-field form-input form-group"
[formGroup]="group"
>
<div class="d-flex justify-content-between align-items-center">
  <label [for]="config.name">
    {{ config.label }} <span *ngIf="config.required">*</span>
  </label>
  <div
    class="toggle"
    [class.toggled]="control.value"
    (click)="actionToggle()"
  >
    <span class="toggle-indicator"></span>
  </div>
</div>

<input
  class="d-none"
  type="checkbox"
  [formControlName]="config.name"
  [required]="config.required"
  [id]="config.name"
>
<app-form-errors [control]="control" [label]="config.label"></app-form-errors>
</div>