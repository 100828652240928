/* eslint-disable @typescript-eslint/no-empty-function */
import { MessageOptions } from './handler/message.handler';
import { VirtualPrivateNetwork } from '../../../entity/compute-networking';
import { ToastrService } from 'ngx-toastr';
import { Status } from './entity-connection';

export class ConnectionMessages implements MessageOptions<VirtualPrivateNetwork> {
  createMessageCallback(_toastr: ToastrService, _entity: VirtualPrivateNetwork): void {}

  deleteMessageCallback(_toastr: ToastrService, _entity: VirtualPrivateNetwork): void {}

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void {}

  updateMessageCallback(_toastr: ToastrService, _entity: VirtualPrivateNetwork): void {}
}
