import { NgModule } from '@angular/core';
import { BytesPipe } from './bytes/bytes.pipe';

import { CreditPipe } from './credit/credit.pipe';
import { DateAgoPipe } from './date-ago/date-ago.pipe';
import { DateLeftPipe } from './date-left/date-left.pipe';
import { DomSanitizerPipe } from './dom-sanitizer/dom-sanitizer.pipe';
import { DurationPipe } from './duration/duration.pipe';
import { HidePasswordPipe } from './hide-password/hide-password.pipe';
import { LinkifyPipe } from './linkify/linkify.pipe';
import { MultiLangStingPipe } from './multi-lang-sting/multi-lang-sting.pipe';
import { NewRecordPipe } from './new-record/new-record.pipe';
import { PerDayPipe } from './price/per-day.pipe';
import { PerHourPipe } from './price/per-hour.pipe';
import { PerWeekPipe } from './price/per-week.pipe';
import { SeperateNumberPipe } from './separate-number/separate-number.pipe';
import { TimeBetweenPipe } from './time-between/time-between.pipe';

const pipes = [
  CreditPipe,
  MultiLangStingPipe,
  PerHourPipe,
  PerDayPipe,
  PerWeekPipe,
  NewRecordPipe,
  DomSanitizerPipe,
  HidePasswordPipe,
  DateAgoPipe,
  LinkifyPipe,
  BytesPipe,
  DateLeftPipe,
  DurationPipe,
  SeperateNumberPipe,
  TimeBetweenPipe,
];

@NgModule({
  providers: [
    DurationPipe,
  ],
  declarations: pipes,
  exports: pipes,
})
export class PipesModule {}
