import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { FieldConfig } from '../../models/field-config.interface';

@Component({
  exportAs: 'dynamicForm',
  selector: 'myflow-dynamic-form',
  styleUrls: [ 'dynamic-form.component.scss' ],
  template: `
    <form
      class="dynamic-form"
      [formGroup]="form"
      (submit)="handleSubmit($event)"
    >
      <ng-container
        *ngFor="let field of config;"
        formDynamicField
        [config]="field"
        [group]="form">
      </ng-container>
    </form>
  `,
})
export class DynamicFormComponent implements OnChanges, OnInit {
  @Input()
  config: FieldConfig[] = [];

  @Output()
  send: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  formChange: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  get controls(): FieldConfig[] {
    if (Array.isArray(this.config)) {
      return this.config.filter(({ type }) => type !== 'button');
    }
    return this.config;
  }

  get changes(): Observable<any> {
    return this.form.valueChanges;
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get invalid(): boolean {
    return this.form.invalid;
  }

  get value(): any {
    return this.form.getRawValue();
  }

  ngOnInit(): void {
    this.form = this.createGroup();
    this.onChanges();
  }

  ngOnChanges(): void {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map((item: FieldConfig) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control: string) => !controls.includes(control))
        .forEach((name: string) => {
          const config = this.config.find((control: FieldConfig) => control.name === name);
          this.form.addControl(name, this.createControl(config));
        });
    }
  }

  addControl(config: FieldConfig, unshift?: boolean): void {
    if (unshift) {
      this.config.unshift(config);
    } else {
      this.config.push(config);
    }
    this.form.addControl(config.name, this.createControl(config));
  }

  createGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    this.controls.forEach((control: FieldConfig) => group.addControl(control.name, this.createControl(control)));
    return group;
  }

  createControl(config: FieldConfig): UntypedFormControl {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  handleSubmit(event: SubmitEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.send.emit(this.value);
  }

  onChanges(): void {
    /** Angular Reactive Forms doesn't send value of 'disabled' fields */
    this.form.valueChanges.subscribe((val: any) => {
      this.formChange.emit(val);
    });
  }

  setDisabled(name: string, disable: boolean): void {
    if (this.form.controls[name] && this.form.controls[name].disabled !== disable) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config = this.config.map((item: FieldConfig) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any): void {
    this.form.controls[name].setValue(value, { emitEvent: true });
  }

  patchValue(value: any): void {
    this.form.patchValue(value, { emitEvent: true });
  }
  /** @todo dynamic change */
  setOptions(name: string, value: FieldConfig['options']): void {
    const control = this.config.find((item: FieldConfig) => item.name === name);
    control.options = { ...control.options, ...value };
  }

  /**
   * Marks all controls in a form group as touched
   *
   * @param formGroup - The form group to touch
   */
  markAsTouched(formGroup?: UntypedFormGroup): void {
    const form = formGroup ? formGroup.controls : this.form.controls;
    Object.values(form).forEach((control: UntypedFormGroup) => {
      control.markAsTouched();

      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }
}
