import { BaseEntity } from 'app/entity/store/base-entity';
import { MessageHandler, Status } from 'app/shared/services/messages/entity-connection';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { first, map, tap } from 'rxjs/operators';
import { Message } from 'app/shared/services/sse/connection';
import { lastValueFrom } from 'rxjs';
import { StoreEntity } from '../../../../store/generic-store-infrastructure/store.entity';

export class NgrxEntityHandler<T extends BaseEntity> implements MessageHandler<T> {
  constructor(
    private service: GenericService<T, any, any>,
    private entityMapper?: (msg: Message<T>) => [ number[], T ],
  ) {
  }

  create(msg: Message<T>): void {
    let context = [];
    let entity = msg.data;

    if (this.entityMapper) {
      const entityMapping = this.entityMapper(msg);
      context = entityMapping[0];
      entity = entityMapping[1];
    }

    this.service.addOneToCache(context, entity);
  }

  async update(msg: Message<T>): Promise<void> {
    let context = [];
    let entity = msg.data;

    if (this.entityMapper) {
      const entityMapping = this.entityMapper(msg);
      context = entityMapping[0];
      entity = entityMapping[1];
    }

    await lastValueFrom(
      this.service.keys$.pipe(
        first(),
        tap((keys: number[]) => {
          if (!keys.includes(msg.id)) {
            return;
          }

          this.service.updateOneInCache(context, entity);
        }),
      ),
    );
  }

  async status(msg: Message<Status>): Promise<void> {
    await lastValueFrom(
      this.service.storeEntities$.pipe(
        first(),
        map(entities => entities.find(entity => entity.id === msg.id)),
        tap(entity => {
          if (!entity) {
            return;
          }

          const updatedEntity: StoreEntity<T> = {
            ...entity,
            entity: { ...entity.entity, status: msg.data },
          };
          this.service.updateOneInCache(updatedEntity.context, updatedEntity.entity);
        }),
      ),
    );
  }

  delete(msg: Message<T>): void {
    this.service.removeOneFromCache(msg.id);
  }
}
