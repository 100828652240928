import { Injectable } from "@angular/core";
import { Account, AccountSSOLink, MigrateAccount } from "app/entity/app-engine";
import { ApiService } from "app/shared/services/api";
import { httpAdminOrganizationAppEngineAccountSSO, httpAdminOrganizationAppEngineAccounts } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationAppEngineAccountDataService extends GenericDataService<Account> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrganizationAppEngineAccounts, apiService);
  }

  migrateAccount(context: number[], data: MigrateAccount): Observable<Account> {
    return this.apiService.post(this.resolveUrl(context), data);
  }

  getSSOLink(context: number[], id: number): Observable<AccountSSOLink> {
    return this.apiService.get(httpAdminOrganizationAppEngineAccountSSO(context[0], id));
  }
}
