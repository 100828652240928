import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'form-multi-input',
  templateUrl: './form-multi-input.component.html',
  styleUrls: [ './form-multi-input.component.scss' ],
})
export class FormMultiInputComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;

  readonly separatorKeysCodes = [ SPACE, TAB, COMMA, ENTER ] as const;

  chips: BehaviorSubject<string[]>;

  get isValid(): boolean {
    return this.group.controls[this.config.name].valid;
  }

  get isDirty(): boolean {
    return this.group.controls[this.config.name].dirty;
  }

  get isTouched(): boolean {
    return this.group.controls[this.config.name].touched;
  }

  get errors(): ValidationErrors {
    return this.group.controls[this.config.name];
  }

  get control(): AbstractControl {
    return this.group.controls[this.config.name];
  }

  get currentChips(): string[] {
    return this.chips.value;
  }

  ngOnInit(): void {
    this.chips = new BehaviorSubject<string[]>(this.config.value ?? []);
    this.chips.subscribe(chips => {
      this.group.controls[this.config.name].setValue(chips);
    });
  }

  addChip(event: MatChipInputEvent): void {
    if (event.value) {
      this.chips.next([
        ...this.chips.value,
        event.value,
      ]);
      event.chipInput!.clear();
    }
  }

  removeChip(chip: string): void {
    const chips = this.chips.value;
    const index = chips.indexOf(chip);
    if (index > -1) {
      chips.splice(index, 1);
    }
    this.chips.next(chips);
  }
}
