import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Organization } from 'app/entity/infrastructure/organization.model';
import { ApiService } from 'app/shared/services/api';

import {
  httpOrganization,
  httpOrganizationBalance,
  httpOrganizationPaymentDetails,
  httpOrganizationPaymentMethod,
  httpOrganizationPaymentMethods,
  httpOrganizationRecharge, httpOrganizationSpecificPaymentMethod,
  httpOrganizationUsage,
  httpOrganizationUsageHistory,
  httpOrganizationUser,
  httpOrganizationUsers,
  httpOrganizationUserSendActivation,
  httpPasswordReset,
  httpUserOrganizations,
} from 'app/shared/utils/http.helper';

import { lastValueFrom, Observable } from 'rxjs';

import { HttpOptions, HttpResponseTypes } from 'app/entity';
import { Success } from '../../../entity/common';

export interface AddUserRequestBody {
  username: string;
  firstname: string;
  lastname: string;
  phoneNumber?: string;
  emailAlternative?: string;
}

export interface EditUserRequestBody {
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  defaultOrganization?: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  fetchUser(id: number): Observable<any> {
    return this.apiService.get(httpOrganizationUser(id));
  }

  fetchOrganizationUsers(options: Partial<HttpOptions> = {}): Observable<any> {
    return this.apiService.get(httpOrganizationUsers(), options);
  }

  fetchUserOrganizations(): Observable<Organization[]> {
    return this.apiService.get(httpUserOrganizations());
  }

  fetchInvoice(url: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.apiService.get(url, {
      headers,
      responseType: HttpResponseTypes.BLOB,
    }, true);
  }

  fetchOrganizationPaymentMethods(): Observable<any> {
    return this.apiService.get(httpOrganizationPaymentMethods());
  }

  createOrganizationPaymentMethod(paymentMethod: string): Observable<any> {
    return this.apiService.post(httpOrganizationSpecificPaymentMethod(paymentMethod));
  }

  deleteOrganizationPaymentMethod(paymentMethod: number): Observable<any> {
    return this.apiService.delete(httpOrganizationPaymentMethod(paymentMethod));
  }

  fetchOrganization(): Observable<any> {
    return this.apiService.get(httpOrganization());
  }

  fetchOrganizationPaymentDetails(): Observable<any> {
    return this.apiService.get(httpOrganizationPaymentDetails());
  }

  updateOrganization(data: any): Observable<any> {
    return this.apiService.patch(httpOrganization(), data);
  }

  updateOrganizationAutoAmount(data: any): Observable<any> {
    return this.apiService.patch(httpOrganizationPaymentDetails(), data);
  }

  rechargeOrganization(data: any): Observable<Success> {
    return this.apiService.post(httpOrganizationRecharge(), data);
  }

  resendActivationMail(id: number): Observable<any> {
    return this.apiService.post(httpOrganizationUserSendActivation(id));
  }

  resetPassword(email: any): Observable<any> {
    return this.apiService.post(httpPasswordReset(), { email });
  }

  deleteUser(id: number): Observable<any> {
    return this.apiService.delete(httpOrganizationUser(id));
  }

  addUser(user: AddUserRequestBody): Observable<any> {
    return this.apiService.post(httpOrganizationUsers(), user);
  }

  updateUser(user: EditUserRequestBody, id: number): Observable<any> {
    return this.apiService.patch(httpOrganizationUser(id), user);
  }

  fetchBalance(options: Partial<HttpOptions> = {}): Observable<any> {
    return this.apiService.get(httpOrganizationBalance(), options);
  }

  /** Organization Billing Usage */
  fetchUsageInfo(): Promise<any> {
    return lastValueFrom(this.apiService.get(httpOrganizationUsage()));
  }

  fetchUsageHistoryInfo(options: { fromDate: string; toDate: string }): Promise<any> {
    return lastValueFrom(this.apiService.get(httpOrganizationUsageHistory(), {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      params: { 'from_date': options.fromDate, 'to_date': options.toDate },
    }));
  }
}
