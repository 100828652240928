import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-price-label',
  templateUrl: './price-label.component.html',
  styleUrls: [ './price-label.component.scss' ],
})
export class PriceLabelComponent {
  @Input() price: number;
  @Input() type: string;
  @Input() place: 'start' | 'center' | 'end';
  @Input() showDropdown: boolean;
  @Input() decSize = 2;
  @Input() showAlways = false;

  @Input() customItems: { label: string; price: number }[];

  constructor() {
    this.type = '';
    this.place = 'start';
    this.showDropdown = false;
  }

  getDropdownClass(): string {
    if (this.showDropdown) {
      return 'dropdown';
    }

    return '';
  }
}
