import { MessageHandler, Status } from 'app/shared/services/messages/entity-connection';
import { ToastrService } from 'ngx-toastr';
import { Message } from 'app/shared/services/sse/connection';

export interface MessageOptions<T> {
  createMessageCallback(toastr: ToastrService, entity: T): void | Promise<void>;

  updateMessageCallback(toastr: ToastrService, entity: T): void | Promise<void>;

  statusMessageCallback(toastr: ToastrService, id: number, status: Status): void | Promise<void>;

  deleteMessageCallback(toastr: ToastrService, entity: T): void | Promise<void>;
}

export class MessageEntityHandler<T> implements MessageHandler<T> {
  constructor(
    private toastrService: ToastrService,
    private child: MessageHandler<T>,
    private options: MessageOptions<T> = null,
  ) {
  }

  async create(msg: Message<T>): Promise<void> {
    if (this.options) {
      await this.options.createMessageCallback(this.toastrService, msg.data);
    }

    await this.child.create(msg);
  }

  async update(msg: Message<T>): Promise<void> {
    if (this.options) {
      await this.options.updateMessageCallback(this.toastrService, msg.data);
    }

    await this.child.update(msg);
  }

  async status(msg: Message<Status>): Promise<void> {
    if (this.options) {
      await this.options.statusMessageCallback(this.toastrService, msg.id, msg.data);
    }

    await this.child.status(msg);
  }

  async delete(msg: Message<T>): Promise<void> {
    if (this.options) {
      await this.options.deleteMessageCallback(this.toastrService, msg.data);
    }

    await this.child.delete(msg);
  }
}
