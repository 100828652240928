<myflow-side-panel
  [title]="editMode ? 'Edit User' : 'Create User'"
  (closeEvent)="close()"
  [loading$]="isLoading"
  (confirmEvent)="onSubmit()"
  [action]="editMode ? 'Save' : 'Create'"
>
  <div content class="form-wrapper centered"  *ngIf="elements">
    <!-- @todo to Shared? -->
    <ng-container *ngIf="editMode && !isAdmin">
      <div 
        class="profile-fallback" 
        myflowDnD 
        (filesChangeEmiter)="open($event)"
        [allowedExtensions]="['jpg', 'jpeg', 'png', 'gif']" 
        (dragover)="draggable = true"
        (drop)="draggable = false"
      >
        <img [src]="avatar | domSanitizer" *ngIf="avatar">
        <div class="actions" *ngIf="!draggable">
          <button class="btn btn-primary btn-sm edit" (click)="file.click()">
            <i class="icofont icofont-ui-edit"></i>
          </button>
          <button *ngIf="avatar" class="btn btn-primary btn-sm delete" (click)="deleteAvatar()">
            <i class="icofont icofont-ui-delete"></i>
          </button>
          <input type="file" #file style="display: none" (change)="open()"/>
        </div>
      </div>
    </ng-container>

    <myflow-dynamic-form
      class="w-100"
      [config]="elements"
      (keydown.enter)="onSubmit()"
      #userForm
    />
  </div>
</myflow-side-panel>

<ng-template #imageCrop>
  <myflow-image-crop 
    *ngIf="imgFile" 
    (closeImgCrop)="closeImgCrop($event)" 
    [file]="imgFile"
    [id]="id" 
  />
</ng-template>
