import { Injectable } from "@angular/core";
import { ActivateSupportPlan, SupportPlan, UpdateSupportPlan } from "app/entity/admin";
import { ProductInstance } from "app/entity/common";
import { ApiService } from "app/shared/services/api";
import { httpAdminOrganizationProductInstances, httpAdminOrganizationSupportPlan } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationSupportPlanDataService extends GenericDataService<ProductInstance> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrganizationProductInstances, apiService);
  }

  activatePlan(context: number[], data: ActivateSupportPlan): Observable<SupportPlan> {
    return this.apiService.post(httpAdminOrganizationSupportPlan(context[0]), data);
  }

  updatePlan(context: number[], id: number, data: UpdateSupportPlan): Observable<SupportPlan> {
    return this.apiService.patch(
      this.appendId(httpAdminOrganizationSupportPlan(context[0]), id),
      data,
    );
  }

  terminatePlan(context: number[], id: number): Observable<void> {
    return this.apiService.delete(this.appendId(httpAdminOrganizationSupportPlan(context[0]), id));
  }
}
