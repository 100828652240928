import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[myflowRandomizeTableLoaderWidth]',
})
export class RandomizeTableLoaderWidthDirective implements OnInit {
  constructor (
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.setRandomWidth();
  }

  setRandomWidth(): void {
    const randomWidth = this.setRandomNubmerInLimits(40, 90) + '%';
    this.renderer.setStyle(this.el.nativeElement, 'width', randomWidth);
  }

  private setRandomNubmerInLimits(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
