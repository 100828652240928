import { Injectable } from '@angular/core';

import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchRequest: string;
  searchSector: string;

  constructor (
    private localStorage: LocalStorageService,
  ) { }

  checkSearchRequest(): string {
    return this.localStorage.get('search');
  }

  setSearchRequest(request: string): void {
    this.searchRequest = request;
    this.localStorage.set('search', request);
  }

  cleanSearchRequest(): void {
    this.localStorage.delete('search');
  }
}
