import { Injectable } from '@angular/core';
import { Image } from 'app/entity/ci-engine';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { ImageDataService } from './image.data';

@Injectable()
export class ImageService extends GenericService<Image> {
  constructor(
    store: Store<EntityCache>,
    private imageDataService: ImageDataService,
  ) {
    super('CiEngineImage', store, imageDataService);
  }
}
