import { Injectable } from '@angular/core';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { OrganizationQuotaDataService } from './organization-quota.data';
import { CreateOrganizationQuota, OrganizationQuota, QuotaRule } from 'app/entity/admin/quota.model';
import { Observable, tap } from 'rxjs';

@Injectable()
export class OrganizationQuotaService extends GenericService<OrganizationQuota, CreateOrganizationQuota> {
  constructor(
    store: Store<EntityCache>,
    private organizationQuotaDataService: OrganizationQuotaDataService,
  ) {
    super('AdminOrganizationQuota', store, organizationQuotaDataService);
  }

  createQuotaRule(
    quota: OrganizationQuota,
    data: CreateOrganizationQuota,
    context?: number[],
  ): Observable<QuotaRule> {
    const ctx = context || this.getContext();

    return this.organizationQuotaDataService.createQuotaRule(ctx, quota.id, data).pipe(
      tap(rule => {
        quota.rules.push(rule);
        quota.orgRule = this.getOrgRule(quota);
        this.updateOneInCache(ctx, quota);
      }),
    );
  }

  updateQuotaRule(
    quota: OrganizationQuota,
    ruleId: number,
    data: CreateOrganizationQuota,
    context?: number[],
  ): Observable<QuotaRule> {
    const ctx = context || this.getContext();

    return this.organizationQuotaDataService.updateQuotaRule(ctx, quota.id, ruleId, data).pipe(
      tap(updatedRule => {
        const existingRuleIndex = quota.rules.findIndex(rule => rule.id === updatedRule.id);
        if (existingRuleIndex !== -1) {
          quota.rules[existingRuleIndex] = updatedRule;
          this.updateOneInCache(ctx, quota);
        }
      }),
    );
  }

  deleteQuotaRule(
    ruleId: number,
    quota: OrganizationQuota,
    context?: number[],
  ): Observable<void> {
    const ctx = context || this.getContext();

    return this.organizationQuotaDataService.deleteQuotaRule(ctx, quota.id, ruleId).pipe(
      tap(() => {
        quota.rules = quota.rules.filter(rule => rule.id !== ruleId);
        quota.orgRule = this.getOrgRule(quota);
        this.updateOneInCache(ctx, quota);
      }),
    );
  }

  updateEntities(entities: OrganizationQuota[]): OrganizationQuota[] {
    for (const entity of entities) {
      entity.orgRule = this.getOrgRule(entity);
    }
    return entities;
  }

  private getOrgRule(entity: OrganizationQuota): number {
    for (let i = 1; i < entity.rules.length; i++) {
      if (entity.rules[i].values.length === 0) {
        return i;
      }
    }
    return 0;
  }
}
