import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[myflowHighlightNew]',
})
export class HighlightNewDirective implements OnInit {
  @Input('myflowHighlightNew') isNew: boolean;

  highlightColor = '#F0F8FF';

  constructor (
    private el: ElementRef,
  ) { }

  @HostListener('document:click', [ '$event' ]) onClick(): void {
    this.highlight(null);
  }

  ngOnInit(): void {
    if (this.isNew) {
      this.highlight(this.highlightColor);
    }
  }

  private highlight(color: string | null): void {
    this.el.nativeElement.style.backgroundColor = color;
  }
}
