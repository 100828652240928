
export enum RouteReferenceType {
  VPN = 'vpn'
}

export interface Route {
  id: number;
  destination: string;
  nexthop: string;
}

export interface CreateRoute {
  destination: string;
  nexthop: string;
}
