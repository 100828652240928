import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiLangString',
})
export class MultiLangStingPipe implements PipeTransform {
  transform(value: Record<string, string>): string {
    switch (window.localStorage.locale) {
      case 'de':
        return value.de;
      case 'fr':
        return value.fr;
      case 'en':
      default:
        return value.en;
    }
  }
}
