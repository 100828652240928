import { ElasticIp } from 'app/entity/compute-networking/elastic-ip.model';
import { SecurityGroup } from 'app/entity/compute-networking/security-group.model';
import { Network } from 'app/entity/compute-networking/network.model';

export interface AttachedNetworkInterface {
  id: number;
  privateIp: string;
  publicIp?: string;
  network: Network;
  instance: {
    id: number;
    name: string;
    type: string;
  };
  macAddress: string;
  security: boolean;
}

export interface NetworkInterface {
  id: number;
  privateIp: string;
  publicIp?: string;
  network: Network;
  securityGroups: SecurityGroup[];
  attachedElasticIp: ElasticIp;
  macAddress: string;
  security: boolean;
}

export interface AttachNetworkInterface {
  networkId: number;
  privateIp?: string;
}
