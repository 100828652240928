import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ValueAccessorBase } from 'app/shared/utils/value-accessor';
import { isEqual } from 'lodash';
export interface Identifiable {
  id: number;
}

export interface CardSubOption<T extends Identifiable> {
  value: T;
  name: string;
  disabledReason?: string;
}

export interface CardOption<T extends Identifiable> {
  name: string;
  subOptions: CardSubOption<T>[];
  disabledReason?: string;
  selectionHint?: string;
  currentValue?: T;
  hideOptions?: boolean;
  comingSoon?: boolean;
}

@Component({
  selector: 'myflow-card-dropdown-select',
  templateUrl: './card-dropdown-select.component.html',
  styleUrls: [ './card-dropdown-select.component.scss' ],
  providers: [
    // eslint-disable-next-line no-use-before-define
    ValueAccessorBase.createProviderFor(CardDropdownSelectComponent),
  ],
})
export class CardDropdownSelectComponent<T extends Identifiable> extends ValueAccessorBase<T> implements OnInit, OnChanges {
  @Input() options: CardOption<T>[];
  @Output() emitChange = new EventEmitter();

  @ContentChild('icon') icon: TemplateRef<any>;
  @ViewChild('tooltip', { static: true }) tooltip: NgbTooltip;

  cardOptions: CardOption<T>[];
  activeTooltip: NgbTooltip;

  get gridColumns(): string {
    const columns = this.options.length >= 3 ? 3 : this.options.length;
    return `repeat(${columns}, 210px)`;
  }

  ngOnInit(): void {
    this.cardOptions = this.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Internal cardOptions needs to be reassigned if cardOptions changes (because options could be set after onInit)
    if (
      changes.options &&
      changes.options.previousValue &&
      !isEqual(changes.options.previousValue, changes.options.currentValue)
    ) {
      this.cardOptions = changes.options.currentValue;
    }
  }

  change(value: T): void {
    this.value = value;
    this.emitChange.emit(value);
  }

   // Ng-for track function (option)
  trackOptionByName(_index: number, option: CardOption<T>): string {
    return option.name;
  }

  isOptionActive(targetOption: CardOption<T>): boolean {
    if (targetOption.subOptions.length === 1) {
      return targetOption.subOptions[0].value === this.value;
    }
    return targetOption.currentValue === this.value;
  }

  selectOption(targetOption: CardOption<T>): void {
    if (targetOption.comingSoon || targetOption.disabledReason || this.disabledOption(targetOption)) {
      return;
    }

    if (targetOption.subOptions.length > 1) {
      this.change(targetOption.currentValue);
    } else {
      this.change(targetOption.subOptions[0].value);
    }
    if (this.activeTooltip)
      this.activeTooltip.close();
  }

  onMouseEnter(tooltip: NgbTooltip): void {
    tooltip.open();
  }

  onMouseLeave(option: CardOption<T>, tooltip: NgbTooltip): void {
    if (!this.isOptionActive(option)) {
      tooltip.close();
    } else {
      tooltip.open();
      this.activeTooltip = tooltip;
    }
  }


  disabledOption(option: CardOption<T>): string {
    if (option.disabledReason) {
      return option.disabledReason;
    }

    if (option.subOptions) {
      const subOption = option.subOptions.find(sub =>
        sub.disabledReason && sub.value.id === option.currentValue.id,
      );

      return subOption ? subOption.disabledReason : null;
    }


    return null;
  }

}
