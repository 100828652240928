import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InstanceDataService } from 'app/compute/store/instance/instance.data';

import { VNC } from 'app/entity';
import { Status } from 'app/entity/common';
import { CreateInstance, Instance, UpdateInstance } from 'app/entity/compute-instances';
import { Order } from 'app/entity/store/order';
import { LocationService } from 'app/core/services/location';
import { OrderMessagesService } from 'app/shared/services/messages/order-messages.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class InstanceService extends GenericService<Instance, CreateInstance, UpdateInstance> {
  constructor(
    store: Store<EntityCache>,
    orderMessagesService: OrderMessagesService,
    private instanceDataService: InstanceDataService,
    private locationService: LocationService,
  ) {
    super('Instance', store, instanceDataService, orderMessagesService);
  }

  upgrade(id: number, product: { productId: number }): Observable<Order> {
    return this.instanceDataService.upgrade(id, product).pipe(
      tap(order => {
        this.getById(id).pipe(
          take(1),
          tap(entity =>
            this.orderMessagesService.subscribeToOrderEvents('Instance', entity.name, order.ref, this.orderMessagesService.resizing,
            )),
        ).subscribe();
      }),
    );
  }

  postAction(id: number, action: string, context?: number[]): Observable<Instance> {
    const ctx = context || this.getContext();
    return this.instanceDataService.postAction(id, action).pipe(
      tap(instance => this.store.update(ctx, instance)),
    );
  }

  getVncToken(id: number): Observable<VNC> {
    return this.instanceDataService.getVncToken(id);
  }

  getStatusActions(): Observable<Status[]> {
    return this.instanceDataService.getStatusActions();
  }

  /**
   * @deprecated The method should not be used
   */
  delete(id: number, context?: number[]): Observable<void> {
    return super.delete(id, context);
  }

  deleteWithElasticIps(id: number, params: { 'delete_elastic_ip': boolean }, context?: number[]): Observable<void> {
    const ctx = context || this.getContext();
    return this.instanceDataService.deleteWithElasticIps(ctx, id, params).pipe(
      tap(() => this.store.delete(id)),
    );
  }

  protected createDummyRecord(create: CreateInstance): Instance {
    const location = this.locationService.moduleLocations(2).find(l => l.id === create.locationId);

    return {
      id: new Date().valueOf(),
      name: create.name,
      status: {
        id: 18,
        name: 'Creating',
        key: 'creating',
        actions: [],
      },
      image: null,
      product: null,
      location: {
        id: location.id,
        key: location.key,
        name: location.name,
      },
      networks: [],
      keyPair: null,
    };
  }
}
