import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'myflow-password-display',
  templateUrl: './password-display.component.html',
  styleUrls: [ './password-display.component.scss' ],
})
export class PasswordDisplayComponent implements OnChanges {
  @Input() secret: string;
  @Input() black?: boolean;
  @Input() maxCensored: number;

  censored: string;
  showSecret = false;

  ngOnChanges(changes: SimpleChanges): void {
    // Every time the secret changes, update the censored version
    const secret = changes.secret.currentValue;
    if(secret !== undefined) {
      const censoredLength = this.maxCensored !== undefined && secret.length > this.maxCensored ? this.maxCensored : secret.length;
      this.censored = '•'.repeat(censoredLength);
    }
  }

  toggle(): void {
    this.showSecret = !this.showSecret;
  }
}
