import { select, Store } from '@ngrx/store';
import { BaseEntity } from 'app/entity/store/base-entity';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericActions } from 'app/store/generic-store-infrastructure/generic.actions';
import { GenericReducer } from 'app/store/generic-store-infrastructure/generic.reducer';
import { StoreEntity } from 'app/store/generic-store-infrastructure/store.entity';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

export class GenericStore<T extends BaseEntity> {
  actions: GenericActions<T>;
  reducer: GenericReducer<T>;

  constructor(
    entityName: string,
    private store: Store<EntityCache>,
  ) {
    this.actions = new GenericActions<T>(entityName);
    this.reducer = new GenericReducer<T>(this.actions, entityName, store);
  }

  loadKeys(): Observable<number[]> {
    return this.store.pipe(
      select<EntityCache, number[]>(this.reducer.loadAllKeys()),
    );
  }

  loadAllRaw(): Observable<StoreEntity<T>[]> {
    return this.store.pipe(
      select<EntityCache, StoreEntity<T>[]>(this.reducer.loadAll()),
    );
  }

  loadAll(): Observable<T[]> {
    return this.loadAllRaw().pipe(
      map(storeEntities => storeEntities.map(storeEntity => storeEntity.entity)),
    );
  }

  loadRaw(id: number): Observable<StoreEntity<T>> {
    return this.loadAllRaw().pipe(
      map(storeEntities => storeEntities.find(storeEntity => storeEntity.entity.id === id)),
    );
  }

  load(id: number): Observable<T> {
    return this.loadAll().pipe(
      map(entities => {
        const list = entities.filter(entity => entity.id === id);
        return list.length > 0 ? list[0] : null;
      }),
    );
  }

  addMany(context: number[], entities: T[]): void {
    this.store.dispatch(this.actions.addMultiple()({ context, entities }));
  }

  addOne(context: number[], entity: T): void {
    this.store.dispatch(this.actions.add()({ context, entity }));
  }

  update(context: number[], entity: T): void {
    this.store.dispatch(this.actions.update()({ context, entity }));
  }

  deleteMultiple(ids: number[]): void {
    this.store.dispatch(this.actions.deleteMultiple()({ ids }));
  }

  delete(id: number): void {
    this.store.dispatch(this.actions.delete()({ id }));
  }

  deleteAll(): void {
    this.store.dispatch(this.actions.deleteAll()());
  }
}
