import configs from 'app/shared/data/configs';

// Cluster
export const httpKubernetesClusters = (): string => `v${configs.version}/kubernetes/clusters`;
export const httpKubernetesClusterAction = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/action`;
export const httpKubernetesClusterUpgrade = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/upgrade`;
export const httpKubernetesClusterFlavor = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/flavor`;
export const httpKubernetesClusterNodes = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/nodes`;
export const httpKubernetesClusterVolumes = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/volumes`;
export const httpKubernetesClusterVolume = (
  clusterId: number,
  volumeId: number,
): string => `v${configs.version}/kubernetes/clusters/${clusterId}/volumes/${volumeId}`;
export const httpKubernetesClusterVolumeNode = (
  clusterId: number,
  volumeId: number,
  nodeId: number,
): string => `v${configs.version}/kubernetes/clusters/${clusterId}/volumes/${volumeId}/nodes/${nodeId}`;
export const httpKubernetesClusterVolumeSnapshot = (
  clusterId: number,
  volumeId: number,
  snapshotId: number,
): string => `v${configs.version}/kubernetes/clusters/${clusterId}/volumes/${volumeId}/snapshots/${snapshotId}`;
export const httpKubernetesClusterConfiguration = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/configuration`;
export const httpKubernetesClusterConfigurationHistory = (
  id: number,
): string => `v${configs.version}/kubernetes/clusters/${id}/configuration/history`;
export const httpKubernetesClusterLoadBalancers = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/load-balancers`;
// Download Kube-config
export const httpKubernetesKubeConfig = (id: number): string => `v${configs.version}/kubernetes/clusters/${id}/kube-config`;

// SSE Topics
export const sseKubernetesClusters = (): string => `/v${configs.version}/kubernetes/clusters/{clusterId}`;
export const sseKubernetesNodes = (): string => `/v${configs.version}/kubernetes/clusters/{clusterId}/nodes/{id}`;
