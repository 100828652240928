// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Stripe: any;

import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  /** @todo create the stripe key for production */
  stripe = Stripe(environment.stripeKey);
}
