<div
  class="dynamic-field form-select form-group"
  [formGroup]="group">

  <form-base-label [config]="config" />

  <ng-select
    *ngIf="(options && config.options.items) && !!config.options.items.length; else loading"
    [formControlName]="config.name"
    [items]="config.options.items"
    [required]="config.required"
    [multiple]="options.multiple"
    [clearable]="options.clearable"
    [searchable]="!options.disableSearch"
    [bindLabel]="options.bindLabel"
    [bindValue]="options.bindValue"
    [placeholder]="config.placeholder"
    [searchFn]="config.options.customLabel ? customSearchFn : null"
  >
    <ng-template ng-label-tmp let-item="item">
      <span class="ng-value-label">
        <ng-container *ngIf="config.options.customLabel">
          {{ item?.firstname }} {{ item?.lastname }} ({{ item?.username }})
        </ng-container>

        <ng-container *ngIf="!config.options.customLabel" >
          <ng-container *ngTemplateOutlet="customValues; context: { item: item }"></ng-container>
        </ng-container>
      </span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <ng-container *ngIf="config.options.customLabel">
        {{ item?.firstname }} {{ item?.lastname }} ({{ item?.username }})
      </ng-container>

      <ng-container *ngIf="!config.options.customLabel" >
        <ng-container *ngTemplateOutlet="customValues; context: { item: item }"></ng-container>
      </ng-container>
    </ng-template>
  </ng-select>

  <app-form-errors [control]="control" [label]="config.label"></app-form-errors>
</div>

<ng-template #loading>
  <ng-container *ngIf="config.options.items && !!config.options.items.length; else nolength">
    <select class="form-control">
      <option>
        <div>Loading ...</div>
      </option>
    </select>
  </ng-container>
</ng-template>

<ng-template #nolength>
  <input class="form-control" disabled value="No available {{ config.options.nolength || config.label }}s" />
</ng-template>

<ng-template #customValues let-item="item">
  <ng-container [ngSwitch]="config.options.custom">
    <ng-container *ngSwitchCase="'vpc'">{{ item?.name }} | {{ item?.location.name }} | {{ item?.cidrBlock }}</ng-container>
    <ng-container *ngSwitchCase="'subnet'">{{ item?.name }} | {{ item?.cidrBlock }}</ng-container>
    <ng-container *ngSwitchCase="'products'">{{ item?.productName }} ({{ item?.price | customCurrency : 'CHF' : true }}/mo)</ng-container>
    <ng-container *ngSwitchCase="'networks'">{{ item?.privateIp }}</ng-container>
    <ng-container *ngSwitchCase="'network-name-cidr'">{{item?.name }} | {{ item?.cidr }}</ng-container>
    <ng-container *ngSwitchCase="'region-name'">{{item?.name }} ({{ item?.city }})</ng-container>
    <ng-container *ngSwitchCase="'hours'">{{ item?.value }} {{ item?.value === 1 ? 'hour' : 'hours'}}</ng-container>
    <ng-container *ngSwitchDefault>{{ item[options.bindLabel] }}</ng-container>
  </ng-container>
</ng-template>
