import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateSecurityGroupRule, SecurityGroupRule, UpdateSecurityGroupRule } from 'app/entity/compute-networking';
import { SecurityGroupRuleDataService } from 'app/networking/store/security-group-rule/security-group-rule.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class SecurityGroupRuleService extends GenericService<SecurityGroupRule, CreateSecurityGroupRule, UpdateSecurityGroupRule> {
  constructor(
    store: Store<EntityCache>,
    dataService: SecurityGroupRuleDataService,
  ) {
    super('SecurityGroupRule', store, dataService);
  }
}
