import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { slideInOutAnimation } from '../../utils/animations';

@Component({
  selector: 'myflow-range-amount',
  templateUrl: './range-amount.component.html',
  styleUrls: [ './range-amount.component.scss' ],
  animations: [ slideInOutAnimation ],
})
export class RangeAmountComponent implements OnInit {
  @Input() amount = 0;
  @Input() vat: any = 0;
  @Input() vatLabel: string;

  @Input() min: string | number = 0;
  @Input() max: string | number = 1000;
  @Input() step: string | number = 10;
  /* Minimum Value to send back */
  @Input() minShow = this.step as number;

  @Output() private rangeUpdated = new EventEmitter();

  ngOnInit(): void {
    // eslint-disable-next-line no-eval
    this.vat = eval(this.vat);
  }

  onChange(val: number): void {
    this.rangeUpdated.emit(val >= this.minShow ? val : 0);
  }
}
