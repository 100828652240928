import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateRouterInterface, RouterInterface } from 'app/entity/compute-networking';
import { RouterInterfaceDataService } from 'app/networking/store/router-interface/router-interface.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class RouterInterfaceService extends GenericService<RouterInterface, CreateRouterInterface> {
  constructor(
    store: Store<EntityCache>,
    dataService: RouterInterfaceDataService,
  ) {
    super('RouterInterface', store, dataService);
  }
}
