<section class="details">
  <div class="details-header">
    <div class="header-left-content">
      <div class="animated" myflowRandomizeTableLoaderWidth></div>
    </div>
    <div class="header-right-content">
      <div class="animated"></div>
    </div>
  </div>
  <div class="details-content instance" [ngClass]="{'instance': type === 'instance'}">
    <div class="details-left-content">
      <h3><div class="animated" myflowRandomizeTableLoaderWidth></div></h3>
      <table class="details-content-table">
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
      </table>
    </div>
    <div class="details-center-content" *ngIf="type === 'instance'">
      <h3><div class="animated" myflowRandomizeTableLoaderWidth></div></h3>
      <table class="details-content-table">
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
      </table>
    </div>
    <div class="details-right-content">
      <h3><div class="animated" myflowRandomizeTableLoaderWidth></div></h3>
      <table class="details-content-table">
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
        <tr>
          <td class="label"><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
          <td><div class="animated" myflowRandomizeTableLoaderWidth></div></td>
        </tr>
      </table>
    </div>
  </div>
</section>
