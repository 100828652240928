import { createAction, props, union } from '@ngrx/store';

import { Module } from 'app/entity/infrastructure/module.model';

export const openSidenav = createAction('[Layout] Open Sidenav');
export const closeSidenav = createAction('[Layout] Close Sidenav');
export const showTable = createAction('[Layout] Show Table');
export const showPlates = createAction('[Layout] Show Plates');
export const setMenu = createAction('[Layout] Set Menu', props<{ menu: Module[] }>());

const all = union({ openSidenav, closeSidenav, showTable, showPlates, setMenu });

export type LayoutActionsUnion = typeof all;
