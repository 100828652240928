import { Injectable } from '@angular/core';
import { AttachedSecurityGroup, NetworkInterface } from 'app/entity/mbm-networking';
import { ApiService } from 'app/shared/services/api';
import { httpBareMetalDeviceNetworkInterface, httpBareMetalDeviceSecurityGroups } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceSecurityGroupDataService extends GenericDataService<AttachedSecurityGroup> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpBareMetalDeviceSecurityGroups, apiService);
  }

  attachSecurityGroup(context: number[], networkInterfaceId: number, id: number): Observable<NetworkInterface> {
    return this.apiService.patch(httpBareMetalDeviceNetworkInterface(context[0], networkInterfaceId), { securityGroupId: id });
  }
}
