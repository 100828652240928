import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeviceElasticIpDataService } from 'app/baremetal/store/device-elastic-ip/device-elastic-ip.data';
import { AttachElasticIp, ElasticIp } from 'app/entity/mbm-networking';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DeviceElasticIpService extends GenericService<ElasticIp> {
  constructor(
    store: Store<EntityCache>,
    private deviceElasticIpDataService: DeviceElasticIpDataService,
  ) {
    super('DeviceElasticIp', store, deviceElasticIpDataService);
  }

  attachElasticIp(data: AttachElasticIp, context?: number[]): Observable<ElasticIp> {
    const ctx = context ?? this.getContext();
    return this.deviceElasticIpDataService.attachElasticIp(ctx, data).pipe(
      tap(elasticIp => this.addOneToCache(ctx, elasticIp)),
    );
  }

  detachElasticIp(id: number, context?: number[]): Observable<void> {
    const ctx = context ?? this.getContext();
    return this.deviceElasticIpDataService.detachElasticIp(ctx, id).pipe(
      tap(() => this.removeOneFromCache(id)),
    );
  }
}
