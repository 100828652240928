import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { AuthActions } from 'app/store/actions';
import * as fromRoot from 'app/store/reducers';

import { Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { ActiveOrganizationService } from '../services/active-organization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private store: Store<fromRoot.State>,
    private localStore: LocalStorageService,
    private activeOrganization: ActiveOrganizationService,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.getAuthed),
      take(1),
      switchMap(authed => {
        const user = this.localStore.getObject('user');
        if (!authed && user) {
          this.store.dispatch(AuthActions.loginSuccess({ user }));
        }
        return authed || user ? this.activeOrganization.checkActiveOrg(next) : of(authed);
      }),
      map(authed => this.checkPermission(authed, state)),
    );
  }

  checkPermission(authed: boolean, state: RouterStateSnapshot): boolean {
    if (!authed) {
      this.store.dispatch(AuthActions.logout({ returnUrl: state.url }));
      return false;
    }

    return true;
  }
}
