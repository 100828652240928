import { InstanceService } from 'app/compute/store/instance';
import { Instance } from 'app/entity/compute-instances';
import { ToastPendingComponent } from 'app/shared/components';
import { Status } from 'app/shared/services/messages/entity-connection';
import { MessageOptions } from 'app/shared/services/messages/handler/message.handler';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

interface PendingMessage {
  entityId: number;
  toastId: number;
}

export class ComputeMessages implements MessageOptions<Instance> {
  private pending: PendingMessage[] = [];

  constructor(private instancesService: InstanceService) {
  }

  createMessageCallback(toastr: ToastrService, entity: Instance): void {
    toastr.success(`<b>Instance:</b> Succeeded to create ${entity.name}`, '', { enableHtml: true });
    this.createStatusMessage(toastr, entity);
  }

  updateMessageCallback(toastr: ToastrService, entity: Instance): Promise<void> {
    return lastValueFrom(
      this.instancesService.getById(entity.id).pipe(
        first(),
        filter(current => current.status.id !== entity.status.id),
        map(() => this.createStatusMessage(toastr, entity)),
      ),
    );
  }

  async statusMessageCallback(toastr: ToastrService, id: number, status: Status): Promise<void> {
    await lastValueFrom(
      this.instancesService.getById(id).pipe(
        first(),
        filter(entity => entity.status.id !== status.id),
        map(entity => ({ ...entity, status } as any)),
        tap(entity => this.createStatusMessage(toastr, entity)),
      ),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteMessageCallback(_toastr: ToastrService, _entity: Instance): void | Promise<void> {}

  private createStatusMessage(toastr: ToastrService, entity: Instance): void {
    switch (entity.status.key) {
      case 'starting':
      case 'stopping': {
        const toast = toastr.warning(`<b>Instance:</b> ${entity.status.name} ${entity.name}`, '', {
          toastComponent: ToastPendingComponent,
          enableHtml: true,
          disableTimeOut: true,
        });
        this.pending.push({ entityId: entity.id, toastId: toast.toastId });
        break;
      }

      case 'running':
      case 'stopped': {
        this.pending
          .filter(({ entityId }) => entityId === entity.id)
          .forEach(({ toastId }) => toastr.clear(toastId));

        const action = entity.status.key === 'running' ? 'started' : entity.status.name.toLowerCase();
        toastr.success(`<b>Instance:</b> Successfully ${action} ${entity.name}`, '', { enableHtml: true });
        break;
      }

      default:
        break;
    }
  }
}
