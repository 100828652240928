import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { PriceDirective } from '../../directives/price/price.directive';
import { CustomCurrencyPipe } from '../../pipes/custom-currency/custom-currency.pipe';
import { FormErrorsComponent } from '../form-errors/form-errors.component';

import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormToggleComponent } from './components/form-toggle/form-toggle.component';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormMultiInputComponent } from './components/form-multi-input/form-multi-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { FormInputNumberComponent } from './components/form-number/form-number.component';
import { FormCollapsableInputComponent } from './components/form-collapsable-input/form-collapsable-input.component';
import { FormPasswordComponent } from './components/form-password/form-password.component';
import { FormBaseLabelComponent } from './components/form-base-label/form-base-label.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    MatChipsModule,
    MatInputModule,
  ],
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormButtonComponent,
    FormInputComponent,
    FormInputNumberComponent,
    FormSelectComponent,
    FormCheckboxComponent,
    FormTextareaComponent,
    FormToggleComponent,
    FormErrorsComponent,
    PriceDirective,
    CustomCurrencyPipe,
    FormMultiInputComponent,
    FormCollapsableInputComponent,
    FormPasswordComponent,
    FormBaseLabelComponent,
  ],
  exports: [
    DynamicFormComponent,
    FormErrorsComponent,
    CustomCurrencyPipe,
    PriceDirective,
    FormBaseLabelComponent,
  ],
})
export class DynamicFormModule {}
