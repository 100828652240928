import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BillingItem } from 'app/entity/billing';
import { PaymentHistoryDataService } from 'app/organization/store/payment-history/payment-history.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class PaymentHistoryService extends GenericService<BillingItem> {
  constructor(
    store: Store<EntityCache>,
    dataService: PaymentHistoryDataService,
  ) {
    super('Invoice', store, dataService);
  }
}
