import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { LayoutActions } from 'app/store/actions';
import * as fromLayout from 'app/store/reducers';
import { environment } from 'environments/environment';
import { map, tap } from 'rxjs/operators';
import { version } from 'environments/version';

export type Link = string | RegExp;

export interface MenuItem {
  moduleId?: number;
  name: string;
  key: string;
  link: string;
  listOfLinks: Link[];
  blackListLinks?: Link[];
}
export interface MenuCategory {
  id: number;
  name: string;
  items: MenuItem[];
}

@Component({
  selector: 'myflow-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent implements OnInit, OnChanges {
  @Input() menuList: MenuCategory[] = [];
  @Input() createBtnIcon: string;
  @Input() createBtnText: string;

  @Output() createBtnClick = new EventEmitter();

  versionName = version.name;

  selectedMenuCategory: number | null;
  selectedMenuItem: string;
  showSidenav: boolean;

  documentationUrl = environment.documentation;
  termsAndConditionsUrl = environment.termsAndConditions;

  admin$ = this.store.select(fromLayout.getAdmin);

  constructor(
    private store: Store<fromLayout.State>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.createBtnText = 'Wizard';
  }

  ngOnInit(): void {
    this.store.select(fromLayout.getShowSidenav)
      .pipe(
        tap(isShow => this.showSidenav = isShow),
      )
      .subscribe();

    this.store.select(fromLayout.getSector)
      .pipe(
        map(sector => this.selectedMenuItem = sector),
        tap(() => this.activateCurrentRoute()),
      ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'menuList')) {
      this.activateCurrentRoute();
    }
  }

  activateCurrentRoute(): void {
    if (!this.menuList) {
      return;
    }

    const results = this.menuList.filter(link => this.isMenuItemSelected(link));
    this.selectedMenuCategory = results.length ? results[0].id : null;
  }

  selectCategory(item: number): void {
    this.selectedMenuCategory = (this.selectedMenuCategory === item ? null : item);
  }

  isCategoryActive(category: MenuCategory): boolean {
    return this.selectedMenuCategory === category.id;
  }

  toggleSidebar(): void {
    if (this.showSidenav) {
      this.store.dispatch(LayoutActions.closeSidenav());
    } else {
      this.store.dispatch(LayoutActions.openSidenav());
    }
  }

  isRouteActive(listOfLinks: Link[], blackListLinks: Link[]): boolean {
    if (listOfLinks.find(regex => !!this.router.url.match(regex))) {
      if (blackListLinks !== undefined) {
        return !blackListLinks.find(regex => this.router.url.match(regex));
      }
      return true;
    }
    return false;
  }

  calculateMaxHeightForSubmenus(category: MenuCategory): string {
    const size = category.items.length * 32;
    return `${size}px`;
  }

  private isMenuItemSelected(category: MenuCategory): boolean {
    return !!(category.items && category.items.length && !!category.items.find(item => item.key === this.selectedMenuItem));
  }
}
