import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-table-jumbotron',
  templateUrl: './table-jumbotron.component.html',
  styleUrls: [ './table-jumbotron.component.scss' ],
})
export class TableJumbotronComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() transparent: boolean;
}
