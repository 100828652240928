import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-overview-grid-card',
  templateUrl: './overview-grid-card.component.html',
  styleUrls: [ './overview-grid-card.component.scss' ],
})
export class OverviewGridCardComponent {
  @Input() header: string = null;
  @Input() status: string = null;
  @Input() value: string = null;
  @Input() color = '';
  @Input() darkAppearance = false;

  @Input() loading = false;
  @Input() smallCard = true;

  get statusColor(): string {
    if (this.status === 'active') {
      return 'green';
    }
    return '';
  }
}
