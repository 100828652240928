<p class="info-text" *ngIf="type !== 'no'">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'manual'">
      Add credit to your account by selecting the amount in the drop-down below. This is a one-time payment and may take
      up to five minutes to process.
    </ng-container>
    <ng-container *ngSwitchCase="'automatic'">
      Select the amount in CHF you want to automatically recharge when your credit goes below 10 CHF. If you want to
      disable the auto-recharge functionality select the “Disable Auto-recharge”
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{ description }}
    </ng-container>
  </ng-container>
</p>

<div class="form-group row row-multiple">
  <div class="form-group col">
    <ng-select class="form-recharge" placeholder="Set amount" [clearable]="false" [searchable]="false"
      [(ngModel)]="dropdownValue">
      <ng-option *ngFor="let val of predefinedAmounts" [value]="val.amount">
        {{ val.amount | customCurrency: 'CHF' }} <span *ngIf="val.cashbackPercent && val.cashbackPercent !== 0">(Cashback: {{val.cashbackPercent}}%)</span>
      </ng-option>
      <ng-option [value]="-1">Custom amount</ng-option>
      <ng-option [value]="0" *ngIf="type === 'automatic'">Disable Auto-recharge</ng-option>
    </ng-select>
  </div>
  <div class="form-group col" [hidden]="dropdownValue !== -1">
    <input appPriceString type="number" placeholder="Set amount" name="amount" class="form-control" [(ngModel)]="value"
      required>
  </div>
</div>
