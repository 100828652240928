import { Injectable } from '@angular/core';
import { HttpObserveTypes, HttpResponseTypes } from 'app/entity';
import { CreateTicket, CreateTicketComment, Ticket, UpdateTicket } from 'app/entity/tickets';
import { ApiService } from 'app/shared/services/api';
import {
  httpSupportTicketComment,
  httpSupportTickets,
  httpSupportTicketsAttachment,
  httpSupportTicketAttachmentContent,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketDataService extends GenericDataService<Ticket, CreateTicket, UpdateTicket> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpSupportTickets, apiService);
  }

  /**
   * Create a new  Comment for Ticket
   *
   * @arg id
   * @arg data
   * @param id
   * @param data
   */
  createComment(id: number, data: CreateTicketComment = {} as CreateTicketComment): Observable<any> {
    return this.apiService.post(httpSupportTicketComment(id), data);
  }

  /**
   * Download ticket attachment
   *
   * @arg url
   */
  fetchCommentAttachment(ticketId: number, attachmentId: number): Observable<any> {
    return this.apiService.get(
      httpSupportTicketAttachmentContent(ticketId, attachmentId),
      { responseType: HttpResponseTypes.BLOB, observe: HttpObserveTypes.RESPONSE },
    );
  }

  upload(files: Set<File>): { [ key: string ]: { progress: Observable<number>; token: Observable<string> } } {
    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number>; token: Observable<string> } } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('attachment', file, file.name);

      // create a new progress/token-subjects for every file
      const progress = new Subject<number>();
      const token = new Subject<string>();

      // send the http-request and subscribe for progress-updates
      this.apiService.uploadFile(httpSupportTicketsAttachment(), formData)
        .subscribe(response => {
          if (response.percent) {
            progress.next(response.percent);
          } else if (response.token) {
            progress.complete();

            token.next(response.token);
            token.complete();
          }
        });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable(),
        token: token.asObservable(),
      };
    });

    // return the map of progress.observables, token.observables
    return status;
  }
}
