import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'perHour',
})
export class PerHourPipe implements PipeTransform {
  transform(value: number): string {
    return (value / 730)
      .toFixed(4)
      .toString();
  }
}
