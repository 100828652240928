import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Plan, PlanService } from 'app/tickets/store/plans';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'myflow-support-plan-toggle',
  templateUrl: './support-plan-toggle.component.html',
  styleUrls: [ './support-plan-toggle.component.scss' ],
})
export class SupportPlanToggleComponent implements OnInit, OnDestroy {
  @Output() toggleClicked = new EventEmitter();

  private destroyed$ = new Subject();

  constructor(
    private planService: PlanService,
  ) { }

  get plans$(): Observable<Plan[]> {
    return this.planService.getPlans();
  }

  ngOnInit(): void {
    this.planService.getAll().pipe(
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  planClicked(plan: Plan): void {
    if(!plan.active) {
      this.toggleClicked.emit();
    }
  }
}
