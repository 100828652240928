import { Injectable } from '@angular/core';
import { CreateLoadBalancer, LoadBalancer, LoadBalancerStatistics, UpdateLoadBalancer } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import {
  httpLoadBalancers,
  httpLoadBalancerStatistics,
  httpLoadBalancerElasticIP,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class LoadBalancerDataService extends GenericDataService<LoadBalancer, CreateLoadBalancer, UpdateLoadBalancer> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpLoadBalancers, apiService);
  }

  getStatistics(id: number): Observable<LoadBalancerStatistics> {
    return this.apiService.get(httpLoadBalancerStatistics(id));
  }

  attach(id: number, elasticIpId: number): Observable<LoadBalancer> {
    return this.apiService.post(httpLoadBalancerElasticIP(id), { elasticIpId });
  }

  detach(id: number): Observable<void> {
    return this.apiService.delete(httpLoadBalancerElasticIP(id));
  }
}
