<div class="side-popup" [class.danger]="danger">
  <div class="side-popup-header">
    <h1>{{ title }}</h1>
    <div class="side-popup-close" (click)="onClose()">
      <i class="icon-cancel"></i>
    </div>
  </div>

  <div class="side-popup-content no-padding no-center">
    <ng-content select="[info-panel]"></ng-content> <!-- Info panel -->

    <ng-content select="[content]"></ng-content> <!-- Form content -->

    <ng-content select="[hint-card]"></ng-content> <!-- Hint cards -->
    <ng-content select="[hint-text]"></ng-content> <!-- Hint text -->
  </div>

  <div class="side-popup-footer" *ngIf="showFooter">
    <div class="footer justify-content-between w-100">
      <button type="button" [disabled]="loading$" class="btn btn-link link" (click)="onClose()">Cancel</button>
      <div class="d-flex">
        <ng-content select="[footer-content]"></ng-content> <!-- Footer content -->
        <app-submit-btn
          *ngIf="confirmAction"
          [loading]="loading$"
          [disabled]="disabled$"
          (btnClick)="onConfirm()"
        >
          {{ action || 'Save' }}
        </app-submit-btn>
      </div>
    </div>
  </div>
</div>
