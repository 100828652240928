import configs from 'app/shared/data/configs';

/**
 * Entities
 * ----------------------------------------------
 */

// General
export const httpCountries = (): string => `v${configs.version}/entities/countries`;
export const httpLocations = (): string => `v${configs.version}/entities/locations`;
export const httpModules = (): string => `v${configs.version}/entities/modules`;

// Compute
export const httpInstanceStatus = (): string => `v${configs.version}/entities/compute/instance-status`;
export const httpImagesList = (): string => `v${configs.version}/entities/compute/images`;
export const httpLoadBalancerProtocols = (): string => `v${configs.version}/entities/compute/load-balancer-protocols`;
export const httpLoadBalancerBalancingAlgorithms = (): string => `v${configs.version}/entities/compute/load-balancer-algorithms`;
export const httpLoadBalancerBalancingHealthTypes = (): string => `v${configs.version}/entities/compute/load-balancer-health-check-types`;
export const httpVirtualPrivateNetworkEntities = (): string => `v${configs.version}/entities/compute/connections`;

// Kubernetes
export const httpListKubernetesVersions = (): string => `v${configs.version}/entities/kubernetes/versions`;

// CI Engine
export const httpCiEngineIntegrationProviders = (): string => `v${configs.version}/entities/ci-engine/integration-providers`;
export const httpCiEngineOs = (): string => `v${configs.version}/entities/ci-engine/os`;
export const httpCiEngineSubscriptionFlavors = (): string => `v${configs.version}/entities/ci-engine/flavors`;
export const httpCiEngineGlobalImages = (): string => `v${configs.version}/admin/ci-engine/images`;

