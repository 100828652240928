import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrivateNetworkDeviceDataService } from 'app/baremetal/store/private-network-device/private-network-device.data';
import { CreateDevice, Device, UpdateDevice } from 'app/entity/mbm-devices';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class PrivateNetworkDeviceService extends GenericService<Device, CreateDevice, UpdateDevice> {
  constructor(
    store: Store<EntityCache>,
    dataService: PrivateNetworkDeviceDataService,
  ) {
    super('PrivateNetworkDevice', store, dataService);
  }
}
