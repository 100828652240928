import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {
  async downloadFile(filename: string, content: string): Promise<void> {
    await this.downloadBlobFile(filename, new Blob([ content ], { type: 'text/plain' }));
  }

  async downloadBase64File(filename: string, content: string): Promise<void> {
    const fileUrl = await DownloadService.base64ToBlob(content);
    DownloadService.downloadFile(filename, fileUrl);
  }

  async downloadBlobFile(filename: string, content: Blob): Promise<void> {
    const reader = new FileReader();
    reader.onload = (): void => {
      DownloadService.downloadFile(filename, reader.result as string);
    };
    reader.readAsDataURL(content);
  }

  private static base64ToBlob(b64Data: string): Promise<string> {
    const byteCharacters = window.atob(b64Data);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (): void => resolve(reader.result.toString());
      reader.onerror = reject;
      reader.readAsDataURL(new Blob([ byteCharacters ], { type: 'text' }));
    });
  }

  private static downloadFile(filename: string, file: string): void {
    const link = document.createElement('a');
    link.href = file;
    link.download = filename;
    link.click();
  }
}
