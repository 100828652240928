/* eslint-disable @typescript-eslint/naming-convention */

import { Location } from 'app/entity/common';
import { LoadBalancer } from 'app/entity/compute-networking/load-balancer.model';

export interface Certificate {
  subject: {
    CN: string;
    O: string;
    C: string;
  };
  issuer: {
    O: string;
    C: string;
  };
  validFrom: string;
  validTo: string;
  serial: string;
}

export interface CertificateUsedBy {
  id: number;
  name: string;
  loadBalancer?: LoadBalancer;
}

export interface CertificateSecret {
  id: number;
  name: string;
  type: string;
  location: Location;
  usedBy: CertificateUsedBy[];
  certificate: Certificate;
}

export interface CreateCertificate {
  name?: string;
  locationId: number;
  certificate: string;
  privateKey: string;
}
