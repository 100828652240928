import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivationEnd, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { filter, repeat, take, takeUntil, tap } from 'rxjs/operators';
import { TableService } from 'app/shared/services/table';
import { HttpObserveTypes } from 'app/entity';

/**
 * @deprecated Deprecated component, use myflow-ngrx-table instead
 */
@Component({
  selector: 'myflow-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ],
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() title = 'Items';

  limits: number[] = [ 5, 10, 15, 25, 50, 100 ];
  size = 0;
  page = 1;
  limit = 10;
  query = '';
  orderStatus = '';
  rows: any[] = [];
  dataSource = new MatTableDataSource([]);
  isLoading = false;
  orderColumn = '';
  orderDir = 'asc';
  newItemId: number;
  customFilter: any = null;

  loadingItems: number[] = []; // for 'table loading indicator'
  url = '';

  protected destroyed$ = new Subject();

  constructor(
    public router: Router,
    public tableService?: TableService,
  ) { }

  ngOnInit(): void {
    this.getLoadingItems();
    this.load();

    if (this.router) {
      this.router.events.pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof ActivationEnd),
        filter(() => history.state.reloadData),
        tap(() => {
          this.load();
        }),
      ).subscribe();

      if (this.tableService) {
        this.url = this.router.url;
        const state = this.tableService.getTableState(this.url);

        if (state) {
          this.limit = state.limit || this.limit;
          this.page = state.page || this.page;
        }
      }
    }
  }

  getLoadingItems(): number[] {
    const loadingItem: number[] = [];
    for (let i = 0; i < this.limit; i++) { // for table loading indicator TODO
      loadingItem.push(i);
    }
    this.loadingItems = [ ...loadingItem ];
    return this.loadingItems;
  }

  setTableState(event: any): void {
    this.tableService.setTableState(this.url, event);
  }

  setLimit(limit: number): void {
    this.limit = limit;
    this.getLoadingItems();

    this.load();
  }

  load(event?: any): void {
    this.isLoading = true;
    if (event) {
      this.page = event;
    }

    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('per_page', `${this.limit}`);

    if (this.query && this.query.trim()) {
      params = params.append('q', this.query.trim());
    }
    if (this.orderColumn || this.orderDir) {
      params = params.append('order_by', this.orderColumn);
      params = params.append('order_dir', this.orderDir);
    }

    if (this.orderStatus) {
      params = params.append('status', this.orderStatus);
    }

    if (this.customFilter) {
      params = params.append(this.customFilter.param, this.customFilter.value);
    }

    this.getRows({ params, observe: HttpObserveTypes.RESPONSE }).pipe(
      take(1),
      repeat(1),
      takeUntil(this.destroyed$),
      tap((response) => {
        if (response.items) {
          this.rows = response.items;
          this.size = response.size;
          this.limit = response.limit;
          this.query = response.query;
        } else if (this.queryMatches(response.headers)) {
          this.rows = response.body;
          this.dataSource.data = response.body;
          this.readPaginationParams(response.headers);
        }
        this.isLoading = false;
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  protected readPaginationParams(headers: HttpHeaders): void {
    this.size = +headers.get('x-pagination-total-count');
  }

  protected getRowIndexById(id: number): number | undefined {
    for (const idx in this.rows) {
      if (this.rows[idx].id === id) {
        return +idx;
      }
    }

    return -1;
  }

  protected replaceRow(row: any): void {
    for (const idx in this.rows) {
      if (this.rows[idx].id === row.id) {
        this.rows[idx] = row;
      }
    }
  }

  protected getRows(_params: { params: HttpParams; observe: HttpObserveTypes }): Observable<any> {
    return new Observable<any>();
  }

  protected queryMatches(headers: any): boolean {
    if (headers && headers.has('x-search-query')) {
      return this.query.trim() === headers.get('x-search-query')[0];
    }
    return this.query === '' || this.query.length < 2;
  }
}
