import { KeyValue } from '../common';

export interface VirtualPrivateNetworkIpsecPolicy {
  authenticationAlgorithm: KeyValue;
  encryptionAlgorithm: KeyValue;
  diffieHellmanGroup: KeyValue;
  encapsulationMode: KeyValue;
  transformProtocol: KeyValue;
  lifetime: number;
}

export interface CreateIpsecPolicy {
  authenticationAlgorithmId: number;
  encryptionAlgorithmId: number;
  diffieHellmanGroupId: number;
  encapsulationModeId: number;
  transformProtocolId: number;
  lifetime: number;
}
