
<ng-template #monthPickerHeader>
  <div class="selection-header">
    <ng-container *ngIf="currentState === 0 else monthSelectionHeader">
      <i class="icon-angel-left" (click)="changePage(-1)"></i>
      <i class="icon-angel-right" (click)="changePage(1)"></i>
    </ng-container>

    <ng-template #monthSelectionHeader>
      <i class="icon-angel-left" (click)="changeYear(-1)"></i>
      <span (click)="showYearView()">{{selectedYear}}</span>
      <i class="icon-angel-right" (click)="changeYear(1)"></i>
    </ng-template>
  </div>
</ng-template>

<ng-template #monthPicker>
  <div class="selection-grid">
    <ng-container *ngIf="currentState === 0; else monthSelection">
      <div
        *ngFor="let year of displayedYears"
        (click)="selectYear(year)"
        (mouseenter)="onHoverYear(year)"
        (mouseleave)="offHoverYear()"
      >
        <span
          [ngClass]="computeYearStyling(year)"
        >{{year}}</span>
      </div>
    </ng-container>

    <ng-template #monthSelection>
      <div
        *ngFor="let month of displayedMonths; let i = index"
        (click)="selectMonth(i)"
        (mouseenter)="onHoverMonth(i)"
        (mouseleave)="offHoverMonth()"
      >
        <span
          [ngClass]="computeMonthStyling(i)"
        >{{month}}</span>
      </div>
    </ng-template>
  </div>
</ng-template>

<button
  class="btn btn-primary"
  [ngbPopover]="monthPicker"
  [popoverTitle]="monthPickerHeader"
  [autoClose]="'outside'"
  triggers="manual"
  (click)="open()"
>
  <mat-icon aria-hidden="false" aria-label="Example home icon">today</mat-icon>
</button>
