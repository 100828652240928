import { AfterViewChecked, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[myflowColorizedRange]',
})
export class ColorizedRangeDirective implements OnInit, AfterViewChecked {
  @Input() min: number | string;
  @Input() max: number | string;
  @Input() value: number | string;
  @Input() ngModel: number;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('ngModelChange', [ '$event' ])
  onChange(): void {
    this.doColorize(this.ngModel);
  }

  ngOnInit(): void {
    this.doColorize(this.ngModel);
  }

  ngAfterViewChecked(): void {
    this.doColorize(this.ngModel);
  }

  doColorize(input: number): void {
    const val = (input - +this.min) / (+this.max - +this.min);
    const backgroundImage = `
      -webkit-gradient(linear, left top, right top,
        color-stop(${val}, transparent),
        color-stop(${val}, #9E9E9E)
      )
    `;
    this.renderer.setStyle(this.el.nativeElement, 'background-image', backgroundImage);
  }
}
