import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationService } from 'app/organization/services/organization';
import { map, tap } from 'rxjs/operators';

import { UserActions } from '../actions';
import * as fromRoot from '../reducers';
import { User } from 'app/entity/infrastructure';
import { AvatarService } from 'app/shared/services/avatar.service';
import { LocationService } from 'app/core/services/location';

@Injectable()
export class UserEffects {
  user$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.setUser.type),
    map((action: any) => action.user),
    map((user: User) => {
      this.organizationService.fetchOrganization().pipe(
        tap(organization => {
          this.locationService.setModules(organization.registeredModules);
          this.locationService.getAll().subscribe();
        }),
      ).subscribe();
      this.avatarService.fetchAvatar(user.id).pipe(
        tap((response: any) => {
          const blob = new Blob([ response.body ], { type: `${response.body.type}` });
          this.avatarService.setAvatarImgBlob(blob);
        }),
      ).subscribe();
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private organizationService: OrganizationService,
    private locationService: LocationService,
    private avatarService: AvatarService,
  ) {
  }
}
