import { InvokeAction } from 'app/entity/common';
import { Order } from 'app/entity/store/order';
import { ApiService } from 'app/shared/services/api';
import { DataServiceTemplate } from 'app/store/generic-store-infrastructure/service.template';
import { Observable } from 'rxjs/internal/Observable';

export class GenericDataService<T, Create = T, Update = T> implements DataServiceTemplate<T, Create, Update> {
  protected readonly optionsPaginationDeactivated = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    params: { 'no_filter': 1 },
  };

  constructor(
    private context: (...id: number[]) => string,
    protected apiService: ApiService,
  ) {
  }

  getAll(context: number[]): Observable<T[]> {
    return this.apiService.get(this.resolveUrl(context), this.optionsPaginationDeactivated);
  }

  getById(context: number[], id: number): Observable<T> {
    return this.apiService.get(this.appendId(this.resolveUrl(context), id));
  }

  create(context: number[], create: Create): Observable<T|Order> {
    return this.apiService.post(this.resolveUrl(context), create);
  }

  update(context: number[], id: number, update: Update): Observable<T> {
    return this.apiService.patch(this.appendId(this.resolveUrl(context), id), update);
  }

  invokeAction(context: number[], id: number, action: InvokeAction): Observable<T> {
    return this.apiService.post(this.appendAction(this.appendId(this.resolveUrl(context), id)), action);
  }

  delete(context: number[], id: number): Observable<void> {
    return this.apiService.delete(this.appendId(this.resolveUrl(context), id));
  }

  protected resolveUrl(ids: number[]): string {
    return this.context.bind(this, ...ids)();
  }

  protected appendId(url: string, id: number): string {
    return `${url}/${id}`;
  }

  protected appendAction(url: string): string {
    return `${url}/action`;
  }
}
