import { Injectable } from '@angular/core';
import { ClusterLoadBalancer } from 'app/entity';
import { LoadBalancer } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import { httpKubernetesClusterLoadBalancers } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClusterLoadBalancerDataService extends GenericDataService<ClusterLoadBalancer> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpKubernetesClusterLoadBalancers, apiService);
  }

  getAll(context: number[]): Observable<ClusterLoadBalancer[]> {
    return super.getAll(context).pipe(
      map(loadBalancers => loadBalancers as any[]),
      map((loadBalancers: LoadBalancer[]) => {
        const clusterLoadBalancer: ClusterLoadBalancer[] = [];

        loadBalancers.forEach(loadBalancer => {
          loadBalancer.balancingPools.forEach(balancingPool => {
            clusterLoadBalancer.push({
              id: balancingPool.id,
              balancingPool,
              loadBalancerName: loadBalancer.name,
              networkInterface: loadBalancer.networks[0].networkInterfaces[0],
            });
          });
        });

        return clusterLoadBalancer;
      }),
    );
  }
}
