import configs from 'app/shared/data/configs';

/**
 * Load Balancer
 * ----------------------------------------------
 */
export const httpLoadBalancers = (): string => `v${configs.version}/compute/load-balancers`;
export const httpLoadBalancer = (id: number): string => `v${configs.version}/compute/load-balancers/${id}`;
export const httpLoadBalancerStatistics = (id: number): string => `v${configs.version}/compute/load-balancers/${id}/statistics`;
export const httpLoadBalancerElasticIP = (id: number): string => `v${configs.version}/compute/load-balancers/${id}/elastic-ip`;
export const httpLoadBalancerAction = (id: number): string => `v${configs.version}/compute/load-balancers/${id}/action`;

/**
 * Load Balancer Pools
 * ----------------------------------------------
 */
export const httpLoadBalancerPools = (id: number): string => `v${configs.version}/compute/load-balancers/${id}/balancing-pools`;
export const httpLoadBalancerPool = (
  id: number,
  poolId: number,
): string => `v${configs.version}/compute/load-balancers/${id}/balancing-pools/${poolId}`;

/**
 * Load Balancer Pools Members
 * ----------------------------------------------
 */
export const httpLoadBalancerPoolMembers = (
  loadBalancerId: number,
  poolId: number,
): string => `v${configs.version}/compute/load-balancers/${loadBalancerId}/balancing-pools/${poolId}/members`;
export const httpLoadBalancerPoolMember = (
  loadBalancerId: number,
  memberId: number,
  poolId: number,
): string => `v${configs.version}/compute/load-balancers/${loadBalancerId}/balancing-pools/${poolId}/members/${memberId}`;
export const httpLoadBalancerPoolMemberAction = (
  loadBalancerId: number,
  memberId: number,
  poolId: number,
): string => `v${configs.version}/compute/load-balancers/${loadBalancerId}/balancing-pools/${poolId}/members/${memberId}/action`;

// SSE Topics
export const sseLoadBalancer = (): string => `/v${configs.version}/compute/load-balancers/{id}`;
export const sseLoadBalancerPool = (): string => `/v${configs.version}/compute/load-balancers/{id}/balancing-pools/{id}`;
export const sseLoadBalancerPoolMember = (): string => `/v${configs.version}/compute/load-balancers/{id}/balancing-pools/{id}/members/{id}`;
