/* eslint-disable @typescript-eslint/no-empty-function */
import { Snapshot } from 'app/entity/compute-instances';
import { Status } from 'app/shared/services/messages/entity-connection';
import { MessageOptions } from 'app/shared/services/messages/handler/message.handler';
import { ToastrService } from 'ngx-toastr';

export class SnapshotMessages implements MessageOptions<Snapshot> {
  createMessageCallback(_toastr: ToastrService, _entity: Snapshot): void {}

  updateMessageCallback(_toastr: ToastrService, _entity: Snapshot): void {}

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void {}

  deleteMessageCallback(_toastr: ToastrService, _entity: Snapshot): void {}
}
