import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ProductInstance } from "app/entity/common";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { OrganizationSupportPlanDataService } from "./organization-support-plan.data";
import { Observable, map, tap } from "rxjs";
import { ActivateSupportPlan, SupportPlan, UpdateSupportPlan } from "app/entity/admin";


@Injectable()
export class OrganizationSupportPlanService extends GenericService<ProductInstance> {
  constructor(
    store: Store<EntityCache>,
    private supportPlanDataService: OrganizationSupportPlanDataService,
  ) {
    super('AdminOrganizationSupportPlan', store, supportPlanDataService);
  }

  private get supportPlanInstanceKeys(): string[] {
    return [ 'support-ticketing', 'support-dev-ops' ];
  }

  get filteredEntities$(): Observable<ProductInstance[]> {
      return super.filteredEntities$.pipe(
        map(entities => entities.filter((instance) =>
          // Filter product instances by support plan types
          typeof instance.product.type !== 'string' && this.supportPlanInstanceKeys.includes(instance.product.type.key),
        )),
      );
  };

  activatePlan(data: ActivateSupportPlan, context?: number[]): Observable<SupportPlan> {
    const ctx = context || this.getContext();

    return this.supportPlanDataService.activatePlan(ctx, data).pipe(
      tap(() => {
        // Reload support product instances
        this.getAll(ctx, true).subscribe();
      }),
    );
  }

  updatePlan(id: number, data: UpdateSupportPlan, context?: number[]): Observable<SupportPlan> {
    const ctx = context || this.getContext();

    return this.supportPlanDataService.updatePlan(ctx, id, data).pipe(
      tap(() => {
        // Reload support product instances
        this.getAll(ctx, true).subscribe();
      }),
    );
  }

  terminatePlan(id: number, context?: number[]): Observable<void> {
    const ctx = context || this.getContext();

    return this.supportPlanDataService.terminatePlan(ctx, id).pipe(
      tap(() => {
        // Reload support product instances
        this.getAll(ctx, true).subscribe();
      }),
    );
  }
}
