import { Injectable } from "@angular/core";
import { ChargeOrganizationFee, ExecuteOrganizationTransition, GiveOrganizationBonus, UpdateOrganization, UpdateOrganizationNotes, UpdatePaymentDetails } from "app/entity/admin";
import { Module, Organization, OrganizationTransition } from "app/entity/infrastructure";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { OrganizationDataService } from "./organization.data";
import { Store } from "@ngrx/store";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { Observable, tap } from "rxjs";
import { PaymentDetails } from "app/entity/billing";

@Injectable()
export class OrganizationService extends GenericService<Organization, null, UpdateOrganization> {
  constructor(
    store: Store<EntityCache>,
    private organizationDataService: OrganizationDataService,
  ) {
    super('AdminOrganization', store, organizationDataService);
  }

  updateNotes(id: number, data: UpdateOrganizationNotes, context?: number[]): Observable<Organization> {
    const ctx = context ?? this.getContext();

    return this.organizationDataService.updateNotes(id, data).pipe(
      tap(organization => this.updateOneInCache(ctx, organization)),
    );
  }

  getTransitions(id: number): Observable<OrganizationTransition[]> {
    return this.organizationDataService.getTransitions(id);
  }

  executeTransition(id: number, data: ExecuteOrganizationTransition): Observable<void> {
    return this.organizationDataService.executeTransition(id, data);
  }

  giveBonus(id: number, data: GiveOrganizationBonus, context?: number[]): Observable<Organization> {
    const ctx = context ?? this.getContext();

    return this.organizationDataService.giveBonus(id, data).pipe(
      tap(organization => this.updateOneInCache(ctx, organization)),
    );
  }

  chargeFee(id: number, data: ChargeOrganizationFee, context?: number[]): Observable<Organization> {
    const ctx = context ?? this.getContext();

    return this.organizationDataService.chargeFee(id, data).pipe(
      tap(organization => this.updateOneInCache(ctx, organization)),
    );
  }

  getPaymentDetails(id: number): Observable<PaymentDetails> {
    return this.organizationDataService.getPaymentDetails(id);
  }

  updatePaymentDetails(id: number, data: UpdatePaymentDetails): Observable<PaymentDetails> {
    return this.organizationDataService.updatePaymentDetails(id, data);
  }

  getModules(): Observable<Module[]> {
    return this.organizationDataService.getModules();
  }

  enableModuleAccess(id: number, moduleId: number, locationId: number): Observable<void> {
    return this.organizationDataService.enableModuleAccess(id, moduleId, locationId);
  }

  disableModuleAccess(id: number, moduleId: number, locationId: number): Observable<void> {
    return this.organizationDataService.disableModuleAccess(id, moduleId, locationId);
  }
}
