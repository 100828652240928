<label [for]="config.name">
  {{ config.label }}
  <span *ngIf="config.required">*</span>
  <i
    *ngIf="config.tooltip"
    [ngbTooltip]="config.tooltip.text"
    tooltipClass="tooltip-info"
    placement="left"
    class="icon-question-circle-filled"
  ></i>

  <span
    class="notification-dot"
    *ngIf="config.notification"
    [ngbTooltip]="'Upgrade available'"
    [placement]="'left'"
  >{{config.notification.count}}</span>
</label>
