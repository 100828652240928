import { Component, EventEmitter, Input, Output } from '@angular/core';

import { zoomInOutAnimation } from '../../utils/animations';

@Component({
  selector: 'app-submit-btn',
  templateUrl: './submit-btn.component.html',
  styleUrls: [ './submit-btn.component.scss' ],
  animations: [ zoomInOutAnimation ],
})
export class SubmitBtnComponent {
  @Input() loading = false;
  @Input() disabled = false;
  @Input() type = 'primary';
  @Input() text = 'Save';

  @Output() btnClick = new EventEmitter();

  onClick(event: any): void {
    if (!this.loading && !this.disabled) {
      this.btnClick.emit(event);
    }
  }
}
