import { Component } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { FieldConfig, OptionsSelectConfig } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@Component({
  selector: 'form-textarea',
  styleUrls: [ 'form-textarea.component.scss' ],
  templateUrl: 'form-textarea.component.html',
})
export class FormTextareaComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;

  options: OptionsSelectConfig;

  get isValid(): boolean {
    return this.group.controls[this.config.name].valid;
  }

  get isDirty(): boolean {
    return this.group.controls[this.config.name].dirty;
  }

  get isTouched(): boolean {
    return this.group.controls[this.config.name].touched;
  }

  get errors(): ValidationErrors {
    return this.group.controls[this.config.name].errors;
  }

  get control(): AbstractControl {
    return this.group.controls[this.config.name];
  }
}
