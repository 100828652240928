import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'myflow-dialog-full',
  templateUrl: './dialog-full.component.html',
  styleUrls: [ './dialog-full.component.scss' ],
})
export class DialogFullComponent {
  @Input() data: any;
  @Input() title = 'Add';
  @Input() loading$ = new BehaviorSubject(false);

  @Input() customContent: TemplateRef<any>;
  @Input() customAction: TemplateRef<any>;

  @Output() out = new EventEmitter();
  @Output() closing = new EventEmitter();

  constructor (
    public activeModal: NgbActiveModal,
  ) { }

  close(): void {
    this.activeModal.close(false);
  }
}
