<div class="jumbotron-box" [ngClass]="{'transparent': transparent}">
  <div class="not-found-icon">
    <i class="icon-binocular"></i>
  </div>
  <h5>
    <ng-container *ngIf="title">
      <span class="app-color">0</span> {{ title }}
    </ng-container>
    <ng-container *ngIf="description">
      {{ description }}
    </ng-container>
  </h5>
  <div>
    <ng-content></ng-content>
  </div>
</div>
