import { Location } from 'app/entity/common/location.model';
import { NetworkInterface } from './network-interface.model';

export interface SecurityGroup {
  id: number;
  name: string;
  description?: string;
  location: Location;
  default: boolean;
  immutable: boolean;
  networkInterfaces: NetworkInterface[];
}

export interface CreateSecurityGroup {
  name: string;
  description: string;
  locationId: number;
}

export interface UpdateSecurityGroup {
  name: string;
  description: string;
}

export interface AttachSecurityGroups {
  securityGroupIds: number[];
}
