import { Injectable } from '@angular/core';
import { CreateSubscription, Subscription, UpdateSubscription } from 'app/entity/ci-engine';
import { ApiService } from 'app/shared/services/api';
import {
  httpCiEngineSubscriptionUpgrade,
  httpCiEngineSubscriptions,
} from 'app/shared/utils/endpoints/ci-engine.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionDataService extends GenericDataService<Subscription, CreateSubscription, UpdateSubscription> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpCiEngineSubscriptions, apiService);
  }

  upgrade(id: number, flavor: { flavorId: number }): Observable<Subscription> {
    return this.apiService.post(httpCiEngineSubscriptionUpgrade(id), flavor);
  }
}
