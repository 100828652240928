import { Injectable } from "@angular/core";
import { AssignOrganizationUser } from "app/entity/admin";
import { CreateUser, UpdateUser } from "app/entity/admin/user.model";
import { User } from "app/entity/infrastructure";
import { ApiService } from "app/shared/services/api";
import { httpAdminOrganizationAssignUser, httpAdminOrganizationUsers } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationUserDataService extends GenericDataService<User, CreateUser, UpdateUser> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrganizationUsers, apiService);
  }

  assignUser(context: number[], data: AssignOrganizationUser): Observable<void> {
    return this.apiService.post(httpAdminOrganizationAssignUser(context[0]), data);
  }

  unassignUser(context: number[], id: number): Observable<void> {
    return this.apiService.delete(this.appendId(this.resolveUrl(context), id));
  }
}
