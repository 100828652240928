import { Injectable } from '@angular/core';
import { BillingItem, Transaction } from 'app/entity/billing';
import { ApiService } from 'app/shared/services/api';
import { httpOrganizationBillingHistory } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentHistoryDataService extends GenericDataService<BillingItem> {
  private initialStartingId = 100_000_000_000;
  private transactionId = this.initialStartingId;

  constructor(
    apiService: ApiService,
  ) {
    super(() => '', apiService);
  }

  getAll(): Observable<BillingItem[]> {
    return this.getAllTransactions().pipe(
      tap(() => this.transactionId = this.initialStartingId),
      map((transactions) =>
        this.mapTransactions(transactions),
      ),
    );
  }

  private mapTransactions(transactions: Transaction[]): BillingItem[] {
    return transactions.map(transaction => {
      this.transactionId++;

      return {
        id: this.transactionId,
        type: transaction.type,
        description: transaction.description,
        status: transaction.status,
        pdf: transaction.pdf,
        vat: transaction.vat,
        amount: transaction.amount,
        recharge: transaction.recharge,
        invoicedAt: transaction.invoicedAt,
        dueAt: transaction.dueAt,
        createdAt: transaction.createdAt,
      };
    });
  }

  private getAllTransactions(): Observable<Transaction[]> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.apiService.get(httpOrganizationBillingHistory(), { params: { 'no_filter': 1 } });
  }
}
