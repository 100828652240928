import { Location } from 'app/entity/common/location.model';
import { Product } from 'app/entity/common/product.model';

export interface ElasticIp {
  id: number;
  product: Product;
  location: Location;
  price?: number;
  publicIp: string;
  privateIp?: string;
  attachedInstance?: {
    id: number;
    name: string;
    type: string;
  };
}

export interface CreateElasticIp {
  locationId: number;
}

export interface AttachElasticIp {
  elasticIpId: number;
  networkInterfaceId: number;
}
