import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InstanceNetworkInterfaceDataService } from 'app/compute/store/instance-network-interface/instance-network-interface.data';
import { AttachNetworkInterface, NetworkInterface } from 'app/entity/compute-networking';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class InstanceNetworkInterfaceService extends GenericService<NetworkInterface, AttachNetworkInterface> {
  constructor(
    store: Store<EntityCache>,
    private interfaceDataService: InstanceNetworkInterfaceDataService,
  ) {
    super('InstanceNetworkInterface', store, interfaceDataService);
  }

  updateSecurity(id: number, data: { security: boolean }, context?: number[]): Observable<NetworkInterface> {
    const ctx = context ?? this.getContext();
    return this.interfaceDataService.updateSecurity(ctx, id, data).pipe(
      tap(networkInterface => this.updateOneInCache(ctx, networkInterface)),
    );
  }
}
