import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService {
  constructor(
    private router: Router,
    private location: Location,
    private store: Store<fromRoot.State>,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkPermission(state).pipe(
      switchMap((result) => {
        if (!result) {
          if (this.location.getState()) {
            this.location.back();
          }
          return of(false);
        }
        return of(true);
      }),
      catchError(() => of(false)),
    );
  }

  checkPermission(_state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.getAdmin),
      take(1),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
