import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderDataService } from 'app/admin/store/order/order.data';
import { Order } from 'app/entity/admin';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { BehaviorSubject, combineLatest, EMPTY, iif, merge, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class OrderService extends GenericService<Order> {
  private failedOrderFilter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    store: Store<EntityCache>,
    private orderDataService: OrderDataService,
  ) {
    super('AdminOrder', store, orderDataService);
  }

  get filteredEntities$(): Observable<Order[]> {
    return combineLatest([
      super.filteredEntities$,
      this.failedOrderFilterEnabled$,
    ]).pipe(
      map(([ orders, failedOrderFilterEnabled ]) => {
        if (!failedOrderFilterEnabled) {
          return orders;
        }
        return orders.filter(
          order =>
            order.status.name !== 'failed' ||
            (new Date(order.createdAt)).getTime() > (new Date()).getTime() - 1.21e+9,
        );
      }),
    );
  }

  get failedOrderFilterEnabled$(): Observable<boolean> {
    return this.failedOrderFilter$.asObservable();
  }

  getAllSuccessively(context?: number[]): Observable<Order[]> {
    this.loading$.next(true);

    const ctx = context || this.getContext();

    const itemCount = 100; // 10 pages of 10 items each

    const iterator = new BehaviorSubject<number>(2);

    const dataGetAll = this.orderDataService.getPaginated(ctx, 1, itemCount).pipe(
      tap(response => this.store.addMany(ctx, response.body)),
      tap(() => this.loading$.next(false)),
      map(response => +response.headers.get('X-Pagination-Total-Pages')),
      switchMap(totalPages => iif(() => totalPages > 1, iterator.pipe(
        switchMap(currentPage => iif(() => currentPage <= totalPages,
          this.orderDataService.getPaginated(ctx, currentPage, itemCount).pipe(
            map(response => response.body),
            tap(entities => this.store.addMany(ctx, entities)),
            tap(() => iterator.next(currentPage + 1)),
          ),
          EMPTY,
        )),
      ), EMPTY)),
    );

    return merge(
      this.store.loadAll(),
      dataGetAll,
    );
  }

  toggleFailedOrderFilter(): void {
    this.failedOrderFilter$.next(!this.failedOrderFilter$.value);
  }
}
