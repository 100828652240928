import { createAction, props, union } from '@ngrx/store';
import { User } from 'app/entity/infrastructure/user.model';

export const login = createAction('[Auth] Login', props<{ user: User }>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ user: User }>());
export const loginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());

export const logout = createAction('[Auth] Logout', props<{ returnUrl: string } | null>());

const all = union({ login, logout, loginSuccess, loginFailure });
export type AuthActionsUnion = typeof all;
