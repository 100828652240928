import { Component, Input } from '@angular/core';
import { zoomInOutAnimation } from 'app/shared/utils';

@Component({
  selector: 'myflow-buttons-menu',
  templateUrl: './buttons-menu.component.html',
  styleUrls: [ './buttons-menu.component.scss' ],
  animations: [ zoomInOutAnimation ],
})
export class ButtonsMenuComponent {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() open = false;
  @Input() notifications?: [
    {
      message: string;
      type?: string;
    }
  ];

  get hasNotifications(): boolean {
    return this.notifications?.length > 0;
  }

  get notificationCount(): number {
    return this.notifications?.length;
  }

  get notificationType(): string {
    const notificationTypes = this.notifications?.map(notification => notification.type);

    if (notificationTypes.includes('danger')) {
      return 'danger';
    }

    return 'primary';
  }

  get notificationTooltip(): string {
    return this.notifications?.map(notification => notification.message).join(' & ');
  }
}
