import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupportCategory } from 'app/entity/support';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { devopsPlanId, PlanService } from '../plans';
import { CategoryDataService } from './category.data';

@Injectable()
export class CategoryService extends GenericService<SupportCategory> {
  constructor(
    store: Store<EntityCache>,
    private categoryDataService: CategoryDataService,
    private planService: PlanService,
  ) {
    super('Category', store, categoryDataService);
  }

  /**
   * Returns an array of support category (as an observable)
   */
  getCategories(): Observable<SupportCategory[]> {
    return combineLatest([
      this.entities$,
      this.planService.isPlanActive(devopsPlanId), //Check if devops plan is active
    ]).pipe(
      map(( [ entities, devopsActive ] ) => {
          entities.forEach((entity, index) => {
            // eslint for small switch disabled due to the fact that this switch might grow with more complex categories
            // eslint-disable-next-line sonarjs/no-small-switch
            switch(entity.id) {
              //Only show category 2 (devops), if the devops plan is active
              case 2:
                entities[index].disabled = !devopsActive;
                break;
              default:
                entities[index].disabled = false;
                break;
            }
          });
          return entities;
        },
      ),
    );
  }
}
