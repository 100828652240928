import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { ObjectStorageCreate, ObjectStorageInstance } from 'app/entity';
import { map, tap } from 'rxjs/operators';

import { EntityCache } from 'app/store/entity-cache/entity-cache';

import { ObjectStorageDataService } from './object-storage.data';
import { LocationService } from 'app/core/services/location';

@Injectable({
  providedIn: 'root',
})
export class ObjectStorageService extends GenericService<ObjectStorageInstance, ObjectStorageCreate> {
  private locations = [];

  constructor(
    store: Store<EntityCache>,
    private objectStorageDataService: ObjectStorageDataService,
    private locationService: LocationService,
  ) {
    super('ObjectStorageInstance', store, objectStorageDataService);
  }

  create(create: ObjectStorageCreate, context?: number[]): Observable<ObjectStorageInstance> {
    const ctx = context || this.getContext();
    return this.dataService.create(ctx, create).pipe(
      map((entity: ObjectStorageInstance) => this.prepareLocationToInstance(entity)),
      tap(entity => this.store.addOne(ctx, entity)),
    );
  }

  getAll(context?: number[], _forceReload?: boolean): Observable<ObjectStorageInstance[]> {
    this.loading$.next(true);

    const ctx = context || this.getContext();


    return this.objectStorageDataService.getAll(ctx).pipe(
      map(regions => regions.map(region => this.prepareLocationToInstance(region))),
      tap((regions) => this.addMultipleToCache(ctx, regions)),
      tap(() => this.loading$.next(false)),
    );
  }

  /**
   * Rotate Object Storage Instances Credentials
   */
  rotateCredentials(id: number, context?: number[]): Observable<ObjectStorageInstance> {
    const ctx = context ?? this.getContext();

    return this.objectStorageDataService.rotateCredentials(id)
    .pipe(
      tap(instance => this.updateOneInCache(ctx, instance)),
    );
  }

  private prepareLocationToInstance(region: ObjectStorageInstance): ObjectStorageInstance {
    this.locations = this.locationService.moduleLocations(4);

    return {
      ...region,
      location: {
        ...this.locations.find(loc => loc.id === region.location.id),
      },
    };
  }
}
