import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'; // hammer for animations and touch api

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

import { getTranslationProviders } from 'app/i18n-provider.service';

if (environment.production) {
  enableProdMode();
}

getTranslationProviders().then(providers => {
  const options = { providers };
  platformBrowserDynamic().bootstrapModule(AppModule, options);
});
