import { Injectable } from '@angular/core';
import { CreateElasticIp, ElasticIp } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import { httpComputedInstanceElasticIP, httpElasticIPs, httpLoadBalancerElasticIP } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ElasticIpDataService extends GenericDataService<ElasticIp, CreateElasticIp> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpElasticIPs, apiService);
  }

  detachElasticIpFromInstance(context: number[], id: number): Observable<void> {
    return this.apiService.delete(httpComputedInstanceElasticIP(context[0], id));
  }

  detachElasticIpFromLoadBalancer(context: number[]): Observable<void> {
    return this.apiService.delete(httpLoadBalancerElasticIP(context[0]));
  }
}
