<div
  class="toggle"
  [class.toggled]="value"
  [class.disabled]="disabled"
  [class.always-colored]="alwaysColored"
  [class.small]="small"
  (click)="actionToggle()"
>
  <span class="toggle-indicator"></span>
</div>
