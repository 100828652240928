import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards';
import { MaintenanceComponent } from './maintenance/maintenance.component';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('app/core/core.module').then((m) => m.CoreModule),
  },
  {
    path: '',
    loadChildren: () => import('app/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  // { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
