import { GenericDataService } from '../../../store/generic-store-infrastructure/generic.data';
import {
  CreatePeeringVirtualPrivateNetwork,
  CreateVirtualPrivateNetwork,
  UpdateVirtualPrivateNetwork,
  VirtualPrivateNetwork,
} from '../../../entity/compute-networking';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api';
import { httpVirtualPrivateNetworkEntities, httpVirtualPrivateNetworks } from '../../../shared/utils';
import { Observable } from 'rxjs';
import { Order } from '../../../entity/store';
import { VirtualPrivateNetworkEntities } from '../../../entity/compute-networking/virtual-private-network-entities.model';

@Injectable({
  providedIn: 'root',
})
export class VirtualPrivateNetworkDataService
  extends GenericDataService<VirtualPrivateNetwork, CreateVirtualPrivateNetwork, UpdateVirtualPrivateNetwork> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpVirtualPrivateNetworks, apiService);
  }

  create(context: number[], create: CreateVirtualPrivateNetwork): Observable<Order> {
    return this.apiService.post(this.appendType(this.resolveUrl(context), 'vpn'), create);
  }

  createPeering(create: CreatePeeringVirtualPrivateNetwork, context: number[]): Observable<Order[]> {
    return this.apiService.post(this.appendType(this.resolveUrl(context), 'peering'), create);
  }

  getEntities(): Observable<VirtualPrivateNetworkEntities> {
    return this.apiService.get(httpVirtualPrivateNetworkEntities());
  }

  private appendType(url: string, type: string): string {
    return `${url}/${type}`;
  }
}
