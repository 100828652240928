import { ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormButtonComponent } from '../form-button/form-button.component';
import { FormCheckboxComponent } from '../form-checkbox/form-checkbox.component';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';
import { FormTextareaComponent } from '../form-textarea/form-textarea.component';
import { FormToggleComponent } from '../form-toggle/form-toggle.component';
import { FormTextComponent } from '../form-text/form-text.component';

import { FieldConfig } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';
import { FormMultiInputComponent } from '../form-multi-input/form-multi-input.component';
import { FormInputNumberComponent } from '../form-number/form-number.component';
import { FormCollapsableInputComponent } from '../form-collapsable-input/form-collapsable-input.component';
import { FormPasswordComponent } from '../form-password/form-password.component';

const components: {[type: string]: Type<Field>} = {
  button: FormButtonComponent,
  input: FormInputComponent,
  numeric: FormInputNumberComponent,
  multiInput: FormMultiInputComponent,
  collapsableInput: FormCollapsableInputComponent,
  select: FormSelectComponent,
  checkbox: FormCheckboxComponent,
  textarea: FormTextareaComponent,
  toggle: FormToggleComponent,
  text: FormTextComponent,
  password: FormPasswordComponent,
};

@Directive({
  selector: '[formDynamicField]',
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input()
  config: FieldConfig;

  @Input()
  group: UntypedFormGroup;

  component: ComponentRef<Field>;

  constructor(
    private container: ViewContainerRef,
  ) {}

  ngOnChanges(): void {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit(): void {
    this.config.type = this.config.type ? this.config.type : 'input';
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`,
      );
    }
    this.component = this.container.createComponent(components[this.config.type]);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
