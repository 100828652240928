import { CdkStepper } from '@angular/cdk/stepper';
import { AfterContentChecked, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'myflow-dialog-wizard',
  templateUrl: './dialog-wizard.component.html',
  styleUrls: [ './dialog-wizard.component.scss' ],
  providers: [ {
    provide: CdkStepper,
    // eslint-disable-next-line no-use-before-define
    useExisting: DialogWizardComponent,
  } ],
})
export class DialogWizardComponent extends CdkStepper implements AfterContentChecked {
  @Input() disabled: boolean;
  @Input() previousDisabled: boolean;
  @Input() loading: boolean;
  @Input() noCenter: boolean; // TODO refactor side popup
  @Input() title = 'Create new Instance';
  @Input() btnSubmitText: string;

  @Output() clickSave = new EventEmitter();
  @Output() clickClose = new EventEmitter();

  stepsLength: number[] = [];

  ngAfterContentChecked(): void {
    this.stepsLength = Array(this.steps.length).fill(false).map((_, i) => ++i);
  }

  save(): void {
    this.clickSave.emit(true);
  }

  close(): void {
    this.clickClose.emit(true);
  }
}
