import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Account, AccountSSOLink, MigrateAccount } from "app/entity/app-engine";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { OrganizationAppEngineAccountDataService } from "./organization-app-engine-account.data";
import { Observable, tap } from "rxjs";

@Injectable()
export class OrganizationAppEngineAccountService extends GenericService<Account> {
  constructor(
    store: Store<EntityCache>,
    private accountDataService: OrganizationAppEngineAccountDataService,
  ) {
    super('AdminOrganizationAppEngineAccount', store, accountDataService);
  }

  migrateAccount(data: MigrateAccount, context?: number[]): Observable<Account> {
    const ctx = context || this.getContext();

    return this.accountDataService.migrateAccount(ctx, data).pipe(
      tap(account => this.addOneToCache(ctx, account)),
    );
  }

  getSSOLink(id: number, context?: number[]): Observable<AccountSSOLink> {
    const ctx = context || this.getContext();

    return this.accountDataService.getSSOLink(ctx, id);
  }
}
