import { Injectable } from '@angular/core';
import { Image } from 'app/entity/ci-engine';
import { ApiService } from 'app/shared/services/api';
import {
  httpCiEngineImages,
} from 'app/shared/utils/endpoints/ci-engine.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class ImageDataService extends GenericDataService<Image> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpCiEngineImages, apiService);
  }
}
