<p class="info-text">
  Set the amount in CHF you want to automatically recharge when your credit goes below 10 CHF in the slider below. Put it to zero if you want to disable the auto recharge functionality.
  <span *ngIf="!value" class="disabled">(Disabled)</span>
</p>

<div class="form-group">
  <input
    type="range"
    min="0"
    [step]="step"
    [max]="maxValue"
    [(ngModel)]="value"
    [style.background-image]="getProgressStyle()"
  >
</div>
