<div class="wrapper">
  <div class="click-catcher" (click)="close()"></div>

  <ng-container *ngTemplateOutlet="popup.custom"></ng-container>

  <myflow-side-panel
    *ngIf="!popup.custom"
    [title]="popup.title"
    [loading$]="(popup.loading | async)"
    (closeEvent)="close()"
    (confirmEvent)="onSubmit()"
    [confirmAction]="popup.fields?.length"
    [action]="popup.action || 'Save'"
    >
    <div content class="form-wrapper centered">
      <div *ngIf="!popup.fields?.length" class="flex jumbotron justify-content-center w-100">
        Loading...
      </div>
      <myflow-dynamic-form
        [config]="popup.fields"
        (keydown.enter)="onSubmit()"
        (formChange)="onChange()"
        class="w-100"
        #form
      />
    </div>
  </myflow-side-panel>
</div>
