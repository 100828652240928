/** @todo placed to Shared */

import { Injectable } from '@angular/core';
import { CaseConverterService } from 'app/shared/services/case-converter.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  set(key: string, value: string): void {
    window.localStorage[key] = value;
  }

  setObject(key: string, value: unknown): void {
    window.localStorage[key] = JSON.stringify(value);
  }

  get(key: string, fallback?: string): string {
    return window.localStorage[key] || fallback;
  }

  getObject(key: string): any {
    if (window.localStorage[key] !== undefined) {
      let obj = JSON.parse(window.localStorage[key] || null);

      const isCamel = CaseConverterService.checkCase(obj, CaseConverterService.isCamel);
      if (!isCamel) {
        obj = CaseConverterService.convertKeys(obj, CaseConverterService.snakeToCamel);
        this.setObject(key, obj);
      }

      return obj;
    }

    return null;
  }

  delete(key: string): void {
    window.localStorage.removeItem(key);
  }

  setSession(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  setSessionObject(key: string, value: unknown): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  clearSessionObject(key: string): void {
    sessionStorage.removeItem(key);
  }

  clearSessionStorage(): void {
    sessionStorage.clear();
    const version = this.get('x-version');
    localStorage.clear();
    this.set('x-version', version);
  }

  getSessionObject(key: string): any {
    if (sessionStorage.getItem(key) !== undefined) {
      return JSON.parse(sessionStorage.getItem(key) || null);
    }
    return null;
  }
}
