import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Organization } from 'app/entity/infrastructure/organization.model';
import { ActiveOrganizationService } from 'app/core/services/active-organization.service';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'myflow-organization-switcher',
  templateUrl: './oranization-switcher.component.html',
  styleUrls: [ './oranization-switcher.component.scss' ],
})
export class OranizationSwitcherComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter();
  loading = false;

  assignedOrganizations: Organization[];
  active: number;
  switchedOrg: Organization;

  private destroyed$ = new Subject();

  constructor (
    private activeOrganizationService: ActiveOrganizationService,
  ) { }

  ngOnInit(): void {
    this.assignedOrganizations = this.activeOrganizationService.assignOrganizations;

    this.activeOrganizationService.active.pipe(
      takeUntil(this.destroyed$),
      map((org: Organization) => {
        this.active = org.id;
      }),
    ).subscribe();
  }

  switch(organization: Organization): void {
    this.switchedOrg = organization;
    this.active = organization.id;
  }

  onSwitch(): void {
    this.activeOrganizationService.switchOrg(this.switchedOrg, true);
    this.close();
  }

  close(): void {
    this.closed.emit(true);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
