import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: [ './info-panel.component.scss' ],
})
export class InfoPanelComponent {
  @Input() noIcon: boolean;
  @Input() type: 'primary' | 'danger' = 'primary';
  @Input() tooltip: string | undefined;
}
