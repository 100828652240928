import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createFeatureSelector, createReducer, on, Store } from '@ngrx/store';
import { BaseEntity } from 'app/entity/store/base-entity';
import { AuthActions } from 'app/store/actions';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericActions } from 'app/store/generic-store-infrastructure/generic.actions';
import { StoreEntity } from 'app/store/generic-store-infrastructure/store.entity';

type State<T> = EntityState<StoreEntity<T>>;

export class GenericReducer<T extends BaseEntity> {
  private actions: GenericActions<T>;

  private reducer;
  private getAllKeys: (state: object) => number[];
  private getAll: (state: object) => StoreEntity<T>[];

  constructor(
    actions: GenericActions<T>,
    entityName: string,
    private entityCacheStore: Store<EntityCache>,
  ) {
    this.actions = actions;

    this.generateReducer(entityName);
  }

  generateReducer(entityName: string): void {
    const adapter: EntityAdapter<StoreEntity<T>> = createEntityAdapter<StoreEntity<T>>();
    const initialState: State<T> = adapter.getInitialState();

    this.reducer = createReducer(
      initialState,
      on(
        this.actions.addMultiple(),
        (state: State<T>, { context, entities }) => adapter.upsertMany(this.mapStoreEntities(context, entities), state),
      ),
      on(
        this.actions.add(),
        (state: State<T>, { context, entity }) => adapter.upsertOne(this.mapStoreEntity(context, entity), state),
      ),
      on(
        this.actions.update(),
        (state: State<T>, { context, entity }) => adapter.updateOne(this.mapUpdate(this.mapStoreEntity(context, entity)), state),
      ),
      on(
        this.actions.deleteMultiple(),
        (state: State<T>, { ids }) => adapter.removeMany(ids, state),
      ),
      on(
        this.actions.delete(),
        (state: State<T>, { id }) => adapter.removeOne(id, state),
      ),
      on(
        this.actions.deleteAll(),
        (state: State<T>) => adapter.removeAll(state),
      ),
      on(
        AuthActions.logout,
        (state: State<T>) => adapter.removeAll(state),
      ),
    );

    this.entityCacheStore.addReducer(entityName, this.reducer);

    const featureSelector = createFeatureSelector<State<T>>(entityName);
    const { selectIds, selectAll } = adapter.getSelectors(featureSelector);
    this.getAllKeys = selectIds as (state: object) => number[];
    this.getAll = selectAll;
  }

  loadAllKeys(): (state: object) => number[] {
    return this.getAllKeys;
  }

  loadAll(): (state: object) => StoreEntity<T>[] {
    return this.getAll;
  }

  private mapUpdate(storeEntity: StoreEntity<T>): Update<StoreEntity<T>> {
    return {
      id: storeEntity.id,
      changes: storeEntity,
    };
  }

  private mapStoreEntity(context: number[], entity: T): StoreEntity<T> {
    return {
      id: +entity.id,
      context,
      entity,
    };
  }

  private mapStoreEntities(context: number[], entities: T[]): StoreEntity<T>[] {
    return entities.map(entity => this.mapStoreEntity(context, entity));
  }
}
