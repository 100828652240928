<div class="jumbotron-container">
  <div class="video-wrapper" (click)="this.actionClick.emit()">
    <video autoplay loop muted src="/assets/dashboard/product-banner/myflow/{{ product }}.mp4" preload="metadata" #video>
    
    </video>
    <div class="icon-wrapper">
      <div class="create-icon">
        <img src="assets/icons/plus-thin.svg" alt="Create icon">
      </div>
    </div>

    <p class="create-text">
      {{ action }}
    </p>
  </div>
  <p class="bottom-text">
    <ng-container *ngIf="entity">
      <span class="app-color">0</span> {{ entity }} found
    </ng-container>

    <ng-container *ngIf="description">
      {{ description }}
    </ng-container>
  </p>
</div>