import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: [ './expansion-panel.component.scss' ],
})
export class ExpansionPanelComponent<T> {
  @Input() items: T[];
  @Input() headerRef: TemplateRef<any>;
  @Input() descriptionRef: TemplateRef<any>;
  @Input() contentRef: TemplateRef<any>;
}
