/**
 * One item (or feature) of the plan, which is displayed in the wizard
 */
export interface PlanItem {
  name: string;
  description: string;
}

/**
 * Model class specifying a support plan (independent of the model class SupportPlan, which is used during the API interaction)
 * and primarily used by the UI. Only used for "Economy", "Business" and "First" (not DevOps, which is independent)
 */
export interface Plan {
  id: number;
  name: string; //Name of the plan (without "Support" or "Support Package", only the name: e.g. "Business")
  price: number; //The price of the plan (in CHF)
  active?: boolean; //Is the plan active or not
  description: string; //The description of the plan (only used in the Support Plan Wizard Tooltip)
  items: PlanItem[]; //The features of the plan (only asused in the Support Plan Wizard)
}
