<div class="toast-message-container" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div>
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" class="inner-text" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" class="inner-text" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="close-btn">
    <a (click)="remove()">
      <i class="icofont icofont-close-line"></i>
    </a>
  </div>
</div>
<div>
  <div class="toast-progress"></div>
</div>
