import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: [ './notification-icon.component.scss' ],
})
export class NotificationIconComponent {
  @Input() notificationCount = 1;
  @Input() size = 20;
  @Input() type = 'primary';
}
