import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmService } from '../../services/confirm.service';

@Component({
  selector: 'myflow-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: [ './dialog-confirm.component.scss' ],
})
export class DialogConfirmComponent implements OnInit, OnDestroy {
  @Input() data = true;
  @Input() name: string;
  @Input() type = 'Name';
  @Input() title = 'Confirm action';
  @Input() action: string;
  @Input() describe: string;
  @Input() cancelLabel = 'Cancel';
  @Input() confirmLabel = 'Confirm';

  msg: string;
  isLoading = false;

  constructor (
    public activeModal: NgbActiveModal,
    private confirmService: ConfirmService,
  ) {}

  ngOnInit(): void {
    switch (this.action) {
      case 'close':
        this.title = 'Confirmation';
        this.msg = 'Are you sure you want to cancel?';
        this.describe = 'All entered data so far will be lost.';
        this.cancelLabel = 'Continue';
        this.confirmLabel = 'Yes';
        break;
      case 'instanceWizardClose':
        this.title = 'Confirmation';
        this.msg = 'Are you sure you want to cancel the process?';
        this.describe = `The progress of «${this.data}» is not saved!`;
        this.cancelLabel = 'Continue';
        this.confirmLabel = 'Cancel process';
        break;
      case 'impersonate':
        this.msg = this.describe;
        this.describe = '';
        break;
      case 'disableAutoRecharge':
        this.msg = `Are you sure you want to disable the auto recharge?`;
        break;
      case 'disableAutoRechargeAdmin':
        this.msg = 'Are you sure you want to disable the auto recharge for this organization?';
        break;
      case 'setInstanceToNoInvoice':
        this.msg = `Are you sure you want to set the product instance ${this.data || ''} to no-invoice?`;
        break;
      case 'setInstanceToInvoice':
        this.msg = `Are you sure you want to set the product instance ${this.data || ''} to invoice?`;
        break;
      default:
        this.msg = this.data ? `Are you sure you want to ${this.action || 'delete'} this ${this.data}?` : '';
        break;
    }
  }

  close(): boolean {
    if (this.isLoading) {
      return false;
    }

    this.activeModal.close(false);
    return true;
  }

  confirm(): boolean {
    if (this.isLoading) {
      return false;
    }

    this.isLoading = true;
    this.confirmService.updateModalSpinner(true);

    return true;
  }

  ngOnDestroy(): void {
    this.confirmService.updateModalSpinner(false);
  }
}
