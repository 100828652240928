import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-status-dot',
  templateUrl: './status-dot.component.html',
  styleUrls: [ './status-dot.component.scss' ],
})
export class StatusDotComponent {
  @Input() color: string;
}
