import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromLayout from 'app/store/reducers';

import { ModuleHeaderService } from '../../services/module-header.service';
import { Router } from '@angular/router';
import { Link, MenuItem } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: [ './module-header.component.scss' ],
})
export class ModuleHeaderComponent implements OnInit, OnDestroy {
  @Input() menulist: MenuItem[];

  showSidebar$ = this.store.select(fromLayout.getShowSidenav);

  windowScrolled = false;
  backLink: Observable<string>;
  customMenu: TemplateRef<any>;

  private destroyed$ = new Subject();

  constructor(
    private service: ModuleHeaderService,
    private store: Store<fromLayout.State>,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  @HostListener('document:scroll', [])
  onWindowScroll(): void {
    if (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop > 0) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.scrollY || document.documentElement.scrollTop || document.body.scrollTop === 0) {
      this.windowScrolled = false;
    }
  }

  ngOnInit(): void {
    this.backLink = this.service.backLinkUrl$;
    this.service.customMenu$.subscribe(menu => this.customMenu = menu);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  isRouteActive(listOfLinks: Link[], blackListLinks: Link[]): boolean {
    if (listOfLinks.find(regex => !!this.router.url.match(regex))) {
      if (blackListLinks !== undefined) {
        return !blackListLinks.find(regex => this.router.url.match(regex));
      }
      return true;
    }
    return false;
  }
}
