import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[myflowDnD]',
})
export class DragAndDropDirective {
  @Input() private allowedExtensions: string[] = [];
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() private filesInvalidEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = 'transparent';

  @HostListener('dragover', [ '$event' ]) onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#DFEAFD';
  }

  @HostListener('dragleave', [ '$event' ]) onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';
  }

  @HostListener('drop', [ '$event' ]) onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';

    const files: File[] = Array.from(evt.dataTransfer.files);
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];

    if (files.length > 0) {
      for (const item of files) {
        const ext = item.name.split('.')[item.name.split('.').length - 1];
        if (this.allowedExtensions.length > 0) {
          if (this.allowedExtensions.lastIndexOf(ext) !== -1) {
            validFiles.push(item);
          } else {
            invalidFiles.push(item);
          }
        } else {
          validFiles.push(item);
        }
      }

      this.filesChangeEmiter.emit(validFiles);
      this.filesInvalidEmiter.emit(invalidFiles);
    }
  }
}
