
import { Location } from 'app/entity/common/location.model';

export interface Router {
  id: number;
  name: string;
  description?: string;
  location: Location;
  public: boolean;
  snat: boolean;
  publicIp?: string;
}

export interface CreateRouter {
  name: string;
  description?: string;
  locationId: number;
  public: boolean;
}

export interface UpdateRouter {
  name?: string;
  description?: string;
  public?: boolean;
}
