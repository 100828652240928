import { Injectable } from '@angular/core';

import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';


import { ApplicationToken, CreateApplicationToken, UpdateApplicationToken } from 'app/entity/infrastructure';
import { ApiService } from 'app/shared/services/api';
import { httpOrganizationAppTokens } from 'app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ApplicationTokensDataService extends GenericDataService<ApplicationToken, CreateApplicationToken, UpdateApplicationToken> {
  constructor(
    protected apiService: ApiService,
  ) {
    super(httpOrganizationAppTokens, apiService);
  }
}
