import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateElasticIp, ElasticIp } from 'app/entity/compute-networking';
import { ElasticIpDataService } from 'app/networking/store/elastic-ip/elastic-ip.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';

@Injectable()
export class ElasticIpService extends GenericService<ElasticIp, CreateElasticIp> {
  constructor(
    store: Store<EntityCache>,
    protected elasticIpDataService: ElasticIpDataService,
  ) {
    super('ElasticIp', store, elasticIpDataService);
  }

  detachElasticIp(id: number, instance: { id: number; type: string }, context?: number[]): Observable<ElasticIp> {
    const ctx = context ?? this.getContext();
    const detach: Observable<void> = instance.type === 'instance' ?
      this.elasticIpDataService.detachElasticIpFromInstance(ctx, id) :
      this.elasticIpDataService.detachElasticIpFromLoadBalancer(ctx);

    return detach.pipe(
      concatMap(() => this.getAll([], true).pipe(
        take(1),
      )),
      map((elasticIps: ElasticIp[]) => elasticIps.find((e) => e.id === id)),
    );
  }
}
