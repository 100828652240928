import { GenericService } from '../../../store/generic-store-infrastructure/generic.service';
import {
  CreatePeerEndpoint,
  CreatePeeringVirtualPrivateNetwork,
  CreateVirtualPrivateNetwork,
  UpdateVirtualPrivateNetwork,
  VirtualPrivateNetwork,
  VirtualPrivateNetworkType,
} from '../../../entity/compute-networking';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VirtualPrivateNetworkDataService } from './virtual-private-network.data';
import { EntityCache } from '../../../store/entity-cache/entity-cache';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { DummyRecord } from '../../../entity/store';
import { LocationService } from '../../../core/services/location';
import { PrivateNetworkService } from '../private-network';
import { OrderMessagesService } from '../../../shared/services/messages/order-messages.service';
import { VirtualPrivateNetworkEntities } from '../../../entity/compute-networking/virtual-private-network-entities.model';

@Injectable()
export class VirtualPrivateNetworkService
  extends GenericService<VirtualPrivateNetwork, CreateVirtualPrivateNetwork, UpdateVirtualPrivateNetwork> {
  constructor(
    store: Store<EntityCache>,
    orderMessagesService: OrderMessagesService,
    protected dataService: VirtualPrivateNetworkDataService,
    private locationService: LocationService,
    private privateNetworkService: PrivateNetworkService,
  ) {
    super('Connection', store, dataService, orderMessagesService);

    this.useAsyncDummyRecordCreation = true;
  }

  createPeering(create: CreatePeeringVirtualPrivateNetwork, context?: number[]): Observable<VirtualPrivateNetwork[]> {
    const ctx = context || this.getContext();
    return this.dataService.createPeering(create, ctx).pipe(
      switchMap(orders => combineLatest([
          this.createDummyRecordPeering(create.localEndpoint, create.remoteEndpoint),
          this.createDummyRecordPeering(create.remoteEndpoint, create.localEndpoint),
        ]).pipe(
          map(([ localData, externalData ]) => {
            const dummyRecords: DummyRecord<VirtualPrivateNetwork>[] = [
              {
                ...localData,
                orderRef: orders[0].ref,
              },
              {
                ...externalData,
                orderRef: orders[1].ref,
              },
            ];
            return dummyRecords;
          }),
          tap(dummyRecords => {
            dummyRecords.forEach(dummyRecord => {
              this.orderMessagesService.waitForRecordToCreate(
                this.entityName,
                this.getNameFromEntity(dummyRecord),
                dummyRecord.orderRef,
              ).then(result => {
                if (result) {
                  this.store.delete(+dummyRecord.id);
                }
              });
            });
          }),
        ),
      ),
      tap(vpns => this.store.addMany(ctx, vpns)),
    );
  }

  getEntities(): Observable<VirtualPrivateNetworkEntities> {
    return this.dataService.getEntities();
  }

  protected createDummyRecordAsync(create: CreateVirtualPrivateNetwork): Observable<VirtualPrivateNetwork> {
    return this.createDummyRecordVpn(create);
  }

  protected createDummyRecordVpn(create: CreateVirtualPrivateNetwork): Observable<VirtualPrivateNetwork> {
    return combineLatest([
      this.privateNetworkService.getById(create.localEndpoint.privateNetworkId).pipe(first()),
      this.getEntities().pipe(first()),
    ]).pipe(
      map(([ localNetwork, vpnEntities ]) => ({
        id: new Date().valueOf(),
        name: create.name,
        type: VirtualPrivateNetworkType.VPN,
        mtu: create.mtu,
        psk: create.psk,
        initiator: vpnEntities.initiators.find(val => val.id === create.initiatorId),
        location: localNetwork.location,
        status: { id: 3, key: 'working', name: 'Working' },
        ikePolicy: {
          authenticationAlgorithm: vpnEntities.authenticationAlgorithms.find(val => val.id === create.ikePolicy.authenticationAlgorithmId),
          encryptionAlgorithm: vpnEntities.encryptionAlgorithms.find(val => val.id === create.ikePolicy.encryptionAlgorithmId),
          diffieHellmanGroup: vpnEntities.diffieHellmanGroups.find(val => val.id === create.ikePolicy.diffieHellmanGroupId),
          ikeVersion: vpnEntities.ikeVersions.find(val => val.id === create.ikePolicy.ikeVersionId),
          lifetime: create.ikePolicy.lifetime,
        },
        ipsecPolicy: {
          // eslint-disable-next-line max-len
          authenticationAlgorithm: vpnEntities.authenticationAlgorithms.find(val => val.id === create.ipsecPolicy.authenticationAlgorithmId),
          encryptionAlgorithm: vpnEntities.encryptionAlgorithms.find(val => val.id === create.ipsecPolicy.encryptionAlgorithmId),
          diffieHellmanGroup: vpnEntities.diffieHellmanGroups.find(val => val.id === create.ipsecPolicy.diffieHellmanGroupId),
          encapsulationMode: vpnEntities.encapsulationModes.find(val => val.id === create.ipsecPolicy.encapsulationModeId),
          transformProtocol: vpnEntities.transformProtocols.find(val => val.id === create.ipsecPolicy.transformProtocolId),
          lifetime: create.ipsecPolicy.lifetime,
        },
        deadPeerDetection: {
          action: vpnEntities.deadPeerDetectionActions.find(val => val.id === create.deadPeerDetection.actionId),
          interval: create.deadPeerDetection.interval,
          timeout: create.deadPeerDetection.timeout,
        },
        localEndpoint: {
          network: localNetwork,
          router: null,
        },
        remoteEndpoint: {
          peerIp: create.remoteEndpoint.peerIp,
          cidrs: create.remoteEndpoint.cidrs,
        },
      })),
    );
  }

  private createDummyRecordPeering(
    localEndpoint: CreatePeerEndpoint,
    remoteEndpoint: CreatePeerEndpoint,
  ): Observable<VirtualPrivateNetwork> {
    return combineLatest([
      this.privateNetworkService.getById(localEndpoint.privateNetworkId).pipe(first()),
      this.privateNetworkService.getById(remoteEndpoint.privateNetworkId).pipe(first()),
    ]).pipe(
      map(([ localNetwork, externalNetwork ]) => ({
        id: new Date().valueOf(),
        name: `Peering-${localNetwork.location.name}-${localNetwork.name}`,
        type: VirtualPrivateNetworkType.PEERING,
        mtu: 1500,
        psk: '',
        initiator: { id: 2, key: 'bi-directional', name: 'Bi-Directional' },
        location: localNetwork.location,
        status: { id: 3, key: 'working', name: 'Working' },
        ikePolicy: {
          authenticationAlgorithm: { id: 2, key: 'sha256', name: 'SHA-256' },
          encryptionAlgorithm: { id: 4, key: 'aes-256', name: 'AES-256' },
          diffieHellmanGroup: { id: 2, key: 'group5', name: 'Group 5' },
          ikeVersion: { id: 2, key: 'v2', name: 'Version 2' },
          lifetime: 3600,
        },
        ipsecPolicy: {
          authenticationAlgorithm: { id: 2, key: 'sha256', name: 'SHA-256' },
          encryptionAlgorithm: { id: 4, key: 'aes-256', name: 'AES-256' },
          diffieHellmanGroup: { id: 2, key: 'group5', name: 'Group 5' },
          encapsulationMode: { id: 1, key: 'tunnel', name: 'Tunnel' },
          transformProtocol: { id: 1, key: 'esp', name: 'ESP' },
          lifetime: 3600,
        },
        deadPeerDetection: {
          action: { id: 2, key: 'hold', name: 'Hold' },
          interval: 30,
          timeout: 120,
        },
        localEndpoint: {
          network: localNetwork,
          router: null,
        },
        remoteEndpoint: {
          network: externalNetwork,
          router: null,
        },
      })),
    );
  }
}
