import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(formatted: string | number | Date | null, value: string | number | Date): string | number | Date | null {
    if (!value) {
      return formatted;
    }

    const seconds = Math.floor((new Date().getTime() - new Date(value).getTime()) / 1000);

    if (seconds < 5 * 60) {
      // less than 5 minutes ago will show as 'Just now'
      return 'Just now';
    } else if (seconds > 4 * 3600) {
      // more than 4 hours, show the formatted date
      return formatted;
    }

    const intervals = {
      hour: 3600,
      minute: 60,
    };

    for (const [ unit, unitValue ] of Object.entries(intervals)) {
      const count = Math.floor(seconds / unitValue);
      if (count) {
        return `${count} ${unit}${count > 1 ? 's' : ''} ago`;
      }
    }

    return formatted;
  }
}
