import { NgModule } from '@angular/core';

import { ColumnDirective } from 'app/shared/directives/table/column.directive';
import { CellDefDirective } from 'app/shared/directives/table/cell-def.directive';
import { ClipboardDirective } from './clipboard/clipboard.directive';
import { ColorizedRangeDirective } from './colorized-range/colorized-range.directive';
import { DragAndDropDirective } from './dnd/dnd.directive';
import { HighlightNewDirective } from './highlight-new/highlight-new.directive';
import { ModuleAccessDirective } from './module-access/module-access.directive';
import { RandomizeTableLoaderWidthDirective } from './randomize-table-loader-width/randomize-table-loader-width.directive';
import { SideBySideSelectLabelDirective } from './side-by-side-select/side-by-side-select-label.directive';
import { SideBySideSelectDisabledTooltipDirective } from './side-by-side-select/side-by-side-select-disabled-tooltip.directive';
// import { PriceDirective } from './price/price.directive';

const directives = [
  ColorizedRangeDirective,
  DragAndDropDirective,
  HighlightNewDirective,
  RandomizeTableLoaderWidthDirective,
  ClipboardDirective,
  ModuleAccessDirective,
  ColumnDirective,
  CellDefDirective,
  // PriceDirective,
  SideBySideSelectLabelDirective,
  SideBySideSelectDisabledTooltipDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {
}
