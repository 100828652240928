import { Location } from 'app/entity/common/location.model';
import { NetworkInterface } from 'app/entity/compute-networking/network-interface.model';

export interface AttachedNetwork {
  id: number;
  name: string;
  cidr: string;
  networkInterfaces: NetworkInterface[];
}

export interface Network {
  id: number;
  name: string;
  description?: string;
  cidr: string;
  location: Location;
  domainNameServers?: string[];
  allocationPoolStart?: string;
  allocationPoolEnd?: string;
  gatewayIp?: string;
  usedIps: number;
  totalIps: number;
}

export interface CreateNetwork {
  name: string;
  description?: string;
  locationId: number;
  cidr: string;
  domainNameServers: string[];
  allocationPoolStart?: string;
  allocationPoolEnd?: string;
  gatewayIp?: string;
}

export interface UpdateNetwork {
  name?: string;
  description?: string;
  domainNameServers?: string[];
  allocationPoolStart?: string;
  allocationPoolEnd?: string;
  gatewayIp?: string;
}
