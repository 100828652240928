<div class="maintenance-overlay">
  <div class="maintenance-container">
    <div class="maintenance-header">
      <img class="logo" [src]="imgBaseLogoUrl" alt="Brand">
    </div>
    <div>
      <p> {{ message }} </p>
    </div>
  </div>
</div>

