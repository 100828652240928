import { Injectable } from '@angular/core';
import { CreateGlobalImage, Image } from 'app/entity/ci-engine';
import { ApiService } from 'app/shared/services/api';
import { httpCiEngineGlobalImages } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class GlobalImageDataService extends GenericDataService<Image, CreateGlobalImage> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpCiEngineGlobalImages, apiService);
  }
}
