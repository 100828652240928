<div (mouseleave)="onMouseLeave()">
  <ng-container *ngFor="let item of list | slice : 0 : 2 ; let i = index">
    <ng-container *ngIf="card">{{ displaySelector ? item[displaySelector] : item }}</ng-container>
    <ng-container *ngIf="!card">
      <ng-container *ngIf="i !== 1">
        <ng-container *ngTemplateOutlet="cellTemplate; context: { $implicit: item }"></ng-container>
  
        <ng-container *ngIf="list.length > 1">, </ng-container>
      </ng-container>
  
      <ng-container *ngIf="i === 1">
          <span [ngClass]="truncateClass" [ngbTooltip]="tipContent" (mouseenter)="tooltip.open()" #tooltip="ngbTooltip" placement="right" triggers="click" [autoClose]="false">
            <ng-container *ngIf="!truncateTemplate">
              {{ (displaySelector ? item[displaySelector] : item).slice(0, 3) }}...
            </ng-container>
            <ng-container *ngTemplateOutlet="truncateTemplate; context: { $implicit: displaySelector ? item[displaySelector] : item }"></ng-container>
          </span>
      </ng-container>
    </ng-container>

    <ng-template #tipContent>
      <div class="customTooltipContent">
        <li *ngFor="let item of list">
          <ng-container *ngTemplateOutlet="cellTemplate; context: { $implicit: item }"></ng-container>
        </li>
      </div>
    </ng-template>
  </ng-container>
</div>