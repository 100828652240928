import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateVolume, UpdateVolume, UpgradeVolume, Volume, VolumeAttachInstance } from 'app/entity/compute-instances';

import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';
import { VolumeDataService } from 'app/compute/store/volume/volume.data';

@Injectable()
export class VolumeService extends GenericService<Volume, CreateVolume, UpdateVolume> {
  constructor(
    store: Store<EntityCache>,
    private volumeDataService: VolumeDataService,
  ) {
    super('Volume', store, volumeDataService);
  }

  upgrade(id: number, upgrade: UpgradeVolume, context?: number[]): Observable<Volume> {
    const ctx = context ?? this.getContext();
    return this.volumeDataService.upgrade(ctx, id, upgrade).pipe(
      tap(volume => this.updateOneInCache(ctx, volume)),
    );
  }

  detachInstance(id: number, attach: VolumeAttachInstance): Observable<Volume> {
    return this.volumeDataService.detach(id, attach).pipe(
      concatMap(() => this.getById(id).pipe(
        take(1),
      )),
      tap(volume => this.updateOneInCache([], { ...volume, instance: null })),
    );
  }

  attachInstance(id: number, attach: VolumeAttachInstance): Observable<Volume> {
    return this.volumeDataService.attach(id, attach).pipe(
      tap(volume => this.updateOneInCache([], volume)),
    );
  }
}
