import { Injectable } from '@angular/core';
import { AttachedNetworkInterface } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import { httpNetworkSecurityGroupInstances } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class SecurityGroupInstanceDataService extends GenericDataService<AttachedNetworkInterface> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpNetworkSecurityGroupInstances, apiService);
  }
}
