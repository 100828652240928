import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateTutorialType, TutorialType, UpdateTutorialType } from 'app/entity/admin';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { TutorialTypeDataService } from './tutorial-type.data';

@Injectable()
export class TutorialTypeService extends GenericService<TutorialType, CreateTutorialType, UpdateTutorialType> {
  constructor(
    store: Store<EntityCache>,
    dataService: TutorialTypeDataService,
  ) {
    super('AdminTutorialType', store, dataService);
  }
}
