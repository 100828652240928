import { Component } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'form-collapsable-input',
  templateUrl: './form-collapsable-input.component.html',
  styleUrls: [ './form-collapsable-input.component.scss' ],
})
export class FormCollapsableInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;

  collapsed = true;

  get isValid(): boolean {
    return this.group.controls[this.config.name].valid;
  }

  get isDirty(): boolean {
    return this.group.controls[this.config.name].dirty;
  }

  get isTouched(): boolean {
    return this.group.controls[this.config.name].touched;
  }

  get errors(): ValidationErrors {
    return this.group.controls[this.config.name].errors;
  }

  get control(): AbstractControl {
    return this.group.controls[this.config.name];
  }
}
