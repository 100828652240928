import { HttpHeaders, HttpParams } from '@angular/common/http';

export enum HttpObserveTypes {
  BODY = 'body',
  EVENTS = 'events',
  RESPONSE = 'response'
}

export enum HttpResponseTypes {
  ARRAY_BUFFER = 'arraybuffer',
  BLOB = 'blob',
  JSON = 'json',
  TEXT = 'text'
}

export interface HttpObserveOptions {
  observe?: HttpObserveTypes;
  responseType?: HttpResponseTypes;
}

export interface HttpOptions extends HttpObserveOptions {
  headers?: HttpHeaders|{[header: string]: string | string[]};
  observe?;
  params?: HttpParams | Record<string, any>;
  reportProgress?: boolean;
  responseType?;
  withCredentials?: boolean;
  body?: any|null;
}
