import { Injectable } from '@angular/core';
import { SupportCategory } from 'app/entity/support';
import { ApiService } from 'app/shared/services/api';
import { httpSupportCategories } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class CategoryDataService extends GenericDataService<SupportCategory> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpSupportCategories, apiService);
  }
}
