import { Injectable } from '@angular/core';
import { Runner } from 'app/entity/ci-engine';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { IntegrationRunnerDataService } from './integration-runner.data';

@Injectable()
export class IntegrationRunnerService extends GenericService<Runner> {
  constructor(
    store: Store<EntityCache>,
    private integrationRunnerDataService: IntegrationRunnerDataService,
  ) {
    super('IntegrationRunner', store, integrationRunnerDataService);
  }

  setOptions(page?: number, perPage?: number, status?: 'active'|'inactive', orderValue?: string, orderDir?: 'asc'|'desc'|''): void {
    this.integrationRunnerDataService.setOptions(page, perPage, status, orderValue, orderDir);
  }

  getTotalAmount(): number {
    return this.integrationRunnerDataService.getTotalAmount();
  }
}
