import { Injectable } from '@angular/core';
import { NetworkInterface } from 'app/entity/mbm-networking';
import { ApiService } from 'app/shared/services/api';
import { httpBareMetalDeviceNetworkInterfaces } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class DeviceNetworkInterfaceDataService extends GenericDataService<NetworkInterface> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpBareMetalDeviceNetworkInterfaces, apiService);
  }
}
