import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CountriesService } from 'app/core/services/countries';

import { AuthenticationService } from './core/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit {
  constructor (
    private authService: AuthenticationService,
    private titleService: Title,
    private countriesService: CountriesService,
  ) { }

  ngOnInit(): void {
    /** Fetch countries on init for all app here */
    this.countriesService.getCountries();
  }
}
