<div class="plans-container">
  <ng-container *ngFor="let plan of plans; trackBy: trackPlanByName">
    <div
      class="plan"
      [ngbTooltip]="plan.hint"
      placement="top"
      tooltipClass="tooltip-support-plan"
      [ngClass]="{'active': plan.active, 'selected': plan.value === value}"
      (click)="selectPlan(plan)"
    >
      <div class="plan-row content-centered plan-header">
        <h3 class="plan-title">{{ plan.name }}</h3>
      </div>
      <div *ngFor="let feature of plan.features; trackBy: trackFeatureByTitle" class="plan-row plan-row-feature">
        <p>{{ feature.title }}</p>
        <p [class.has-subtext]="feature.valueSubText">
          {{ feature.value }}
          <span *ngIf="feature.valueSubText" class="plan-row-feature-subtext">
            <br>{{feature.valueSubText}}
          </span>
        </p>
      </div>
      <div class="plan-row content-centered plan-footer">
        <h3 *ngIf="plan.price !== 0; else planFree" class="plan-title">
          <span *ngIf="plan.priceAsterisk">*</span>Starting at {{ plan.price | customCurrency: 'CHF' : true : 0 }}/mo
        </h3>
        <ng-template #planFree>
          <h3 class="plan-title">Free of charge</h3>
        </ng-template>
      </div>
    </div>
  </ng-container>    
</div>