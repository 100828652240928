import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { moment } from 'app/shared/utils';

@Pipe({
  name: 'dateLeft',
})
export class DateLeftPipe implements PipeTransform {
  transform(value: string | Date): string | Date {
    if (value) {
      const minutes = Math.abs(moment().diff(value, 'minutes'));

      if (minutes <= 60 * 2) {
        return `in ${minutes} minute${minutes === 1 ? '' : 's'}`;
      } else if (minutes <= (48 * 60)) {
        const hours = Math.floor(minutes / 60);
        return `in ${hours} hour${hours === 1 ? '' : 's'}`;
      }
        return new DatePipe('en-GB').transform(value, '\'on\' dd.MM.yy HH:mm');

    }
    return value;
  }
}
