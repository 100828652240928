import { Injectable } from '@angular/core';
import { CreateRouterInterface, RouterInterface } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import { httpComputedRouterInterfaces } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class RouterInterfaceDataService extends GenericDataService<RouterInterface, CreateRouterInterface> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedRouterInterfaces, apiService);
  }
}
