import { Injectable } from '@angular/core';
import { CreateTutorial, Tutorial, UpdateTutorial } from 'app/entity/admin';
import { ApiService } from 'app/shared/services/api';
import { httpAdminDashboardTutorials } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class TutorialDataService extends GenericDataService<Tutorial, CreateTutorial, UpdateTutorial> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminDashboardTutorials, apiService);
  }
}
