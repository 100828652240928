import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'myflow-table-placeholders',
  templateUrl: './table-placeholders.component.html',
  styleUrls: [ './table-placeholders.component.scss' ],
})
export class TablePlaceholdersComponent implements OnInit {
  @Input() isCard: boolean;
  @Input() instanceTable: boolean;
  @Input() columns: any[] = [];
  @Input() rows: number[];

  displayedColumns = [];

  get tableColumns (): any[] {
    return this.columns.filter((column) => !column.cardOnly);
  }

  ngOnInit(): void {
    if (!this.rows) {
      this.rows = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];
    }
    if (this.columns.length) {
      this.columns.forEach((column: any) => {
        if(!column.cardOnly) {
          this.displayedColumns.push(column.column);
        }
      });
    }
  }
}
