import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { EntityCacheService } from 'app/store/generic-store-infrastructure/entity-cache.service';
import { environment } from 'environments/environment';

import * as fromRouter from '@ngrx/router-store';

import * as fromAuth from './auth.reducer';
import * as fromLayout from './layout.reducer';
import * as fromOrganization from './organization.reducer';
import * as fromUser from './user.reducer';

export interface State {
  auth: fromAuth.State;
  user: fromUser.State;
  organization: fromOrganization.State;
  layout: fromLayout.State;
  router: fromRouter.RouterReducerState;
  entityCache?: EntityCacheService;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  user: fromUser.reducer,
  organization: fromOrganization.reducer,
  layout: fromLayout.reducer,
  router: fromRouter.routerReducer,
};

// console.log all actions
export const logger = (reducer: ActionReducer<State>): ActionReducer<State> => (state, action): State => {
  const result = reducer(state, action);
    /* eslint-disable no-console */
  console.groupCollapsed(action.type);
  console.log('prev state', state);
  console.log('action', action);
  console.log('next state', result);
  console.groupEnd();
  /* eslint-enable no-console */
  return result;
};

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [ logger ]
  : [];

/** Login Getters */
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');

export const getAuthed = createSelector(getAuthState, fromAuth.getAuthed);
export const getToken = createSelector(getAuthState, fromAuth.getToken);
export const getLoginPageError = createSelector(getAuthState, fromAuth.getLoginPageError);
export const getLoginPagePending = createSelector(getAuthState, fromAuth.getLoginPagePending);

/** User Getters */
export const getUserState = createFeatureSelector<fromUser.State>('user');

export const getUser = createSelector(getUserState, fromUser.getUser);
export const getImpersonated = createSelector(getUserState, fromUser.getImpersonated);
export const getAdmin = createSelector(getUserState, fromUser.getIsAdmin);

/** Organization Getters */
export const getOrganizationState = createFeatureSelector<fromOrganization.State>('organization');

export const getOrganization = createSelector(getOrganizationState, fromOrganization.getOrganization);
export const getOrganizationPrimaryAccount = createSelector(getOrganizationState, fromOrganization.getOrganizationPrimaryAccount);
export const getBalance = createSelector(getOrganizationState, fromOrganization.getBalance);
export const getPayments = createSelector(getOrganizationState, fromOrganization.getOrganizationPayments);

/**
 * Layout Reducers
 */
export const getLayoutState = createFeatureSelector<fromLayout.State>('layout');

export const getShowSidenav = createSelector(
  getLayoutState,
  fromLayout.getShowSidenav,
);

export const getShowTable = createSelector(
  getLayoutState,
  fromLayout.getShowTable,
);

export const getMenu = createSelector(getLayoutState, fromLayout.getMenu);

/**
 * Route
 */
export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState
>('router');

export const getSector = createSelector(getRouterState, router => {
  const root = router.state.root.firstChild;
  const coreModule = root.firstChild;
  let module = coreModule.firstChild;

  if (module.routeConfig && module.routeConfig.path[0] === ':') {
    const paramName = module.routeConfig.path.substring(1);
    const param = module.params[paramName];
    return param || '';
  }

  if (module.routeConfig && module.routeConfig.path === 'admin') {
    module = module.firstChild.firstChild;
    module = module.routeConfig && module.routeConfig.path ? module : module.firstChild;
  }

  return module.routeConfig ? module.routeConfig.path : '';
});

const searchParam = (
  router,
): any => router && router.params && router.params.id ? router.params.id : router.firstChild ? searchParam(router.firstChild) : null;

// export const getUrl = createSelector(getRouterState, router => router.state);
export const getReturnUrl = createSelector(getRouterState, router => router.state.root.queryParams.returnUrl);
export const getUrl = createSelector(getRouterState, router => router.state.url);
export const getUrlParamId = createSelector(getRouterState, router => searchParam(router.state.root));
