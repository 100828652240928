/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/naming-convention */
import { I18nPluralPipe, NgLocaleLocalization } from '@angular/common';
import { OneTimeProduct, PeriodicProduct, UsagePeriod } from 'app/entity/billing/usage-period.model';

interface AggregatedConfig {
  label: string; // The label of the element
  plural: any; // The plural method used for top-level elements (1 Package, 2 Clusters...)
  hasDetails: boolean; // If hasDetails is marked as true, when clicking the link a /{id}/details will be added to the path
  link?: string | null; // Link which will be displayed in the label
  group?: string; // Items can be groupped using the given id, specify group configuration in the defineGroupLabels variable
}

export interface AggregatedProduct {
  id?: number | null;
  key?: string | null;
  label: string;
  description: string;
  cost: number;
  deleted?: boolean;
  duration?: number | null;
  children?: AggregatedProduct[] | null;
  config?: AggregatedConfig;
}

//Defines group configuration which are automatically applied to the parent AggregatedProduct of the group
const definedGroupLabels: {[group: string]: AggregatedConfig} = {
  'group-support': {
    label: 'Support',
    plural: { '=0': '# Packages', '=1': '# Package', 'other': '# Packages' },
    hasDetails: false,
  },
};

const definedLabels: { [type: string]: AggregatedConfig } = {
  'compute-engine-vm': {
    label: 'Compute Server',
    plural: { '=0': '# Instances', '=1': '# Instance', 'other': '# Instances' },
    hasDetails: true,
    link: '/compute/instances',
  },
  'compute-engine-elastic-ip': {
    label: 'Compute Elastic IP',
    plural: { '=0': '# Elastic IPs', '=1': '# Elastic IP', 'other': '# Elastic IPs' },
    hasDetails: false,
    link: '/compute/elastic-ips',
  },
  'compute-engine-volume': {
    label: 'Compute Volume',
    plural: { '=0': '# Volumes', '=1': '# Volume', 'other': '# Volumes' },
    hasDetails: true,
    link: '/compute/volumes',
  },
  'compute-connection': {
    label: 'Compute Connection',
    plural: { '=0': '# Connections', '=1': '# Connection', 'other': '# Connection' },
    hasDetails: true,
    link: '/compute/connections',
  },
  'compute-engine-snapshot': {
    label: 'Compute Snapshot',
    plural: { '=0': '# Snapshots', '=1': '# Snapshot', 'other': '# Snapshots' },
    hasDetails: false,
    link: '/compute/volumes', // @todo need to be a scheme
  },
  'compute-kubernetes-cluster': {
    label: 'Kubernetes Cluster',
    plural: { '=0': '# Clusters', '=1': '# Cluster', 'other': '# Clusters' },
    hasDetails: true,
    link: '/compute/clusters',
  },
  'compute-kubernetes-node': {
    label: 'Kubernetes Nodes',
    plural: { '=0': '# Nodes', '=1': '# Node', 'other': '# Nodes' },
    hasDetails: false,
    link: '/compute/clusters',
  },
  'compute-load-balancer': {
    label: 'Load Balancer',
    plural: { '=0': '# Load Balancers', '=1': '# Load Balancer', 'other': '# Load Balancers' },
    hasDetails: true,
    link: '/compute/load-balancers',
  },
  'bare-metal-device': {
    label: 'Mac Bare Metal Device',
    plural: { '=0': '# Devices', '=1': '# Device', 'other': '# Devices' },
    hasDetails: true,
    link: '/macbaremetal/devices',
  },
  'bare-metal-dedicated-device': {
    label: 'Dedicated Mac Bare Metal Device',
    plural: { '=0': '# Dedicated Devices', '=1': '# Dedicated Device', 'other': '# Dedicated Devices' },
    hasDetails: true,
    link: '/macbaremetal/devices',
  },
  'bare-metal-elastic-ip': {
    label: 'Mac Bare Metal Elastic IP',
    plural: { '=0': '# Elastic IPs', '=1': '# Elastic IP', 'other': '# Elastic IPs' },
    hasDetails: false,
    link: '/macbaremetal/elastic-ips',
  },
  'license': {
    label: 'License',
    plural: { '=0': '# Licenses', '=1': '# License', 'other': '# Licenses' },
    hasDetails: false,
  },
  'ci-engine-subscription': {
    label: 'CI Engine Subscription',
    plural: { '=0': '# Subscriptions', '=1': '# Subscription', 'other': '# Subscriptions' },
    hasDetails: false,
  },
  'ci-engine-add-on': {
    label: 'CI Engine Concurrency Add-on',
    plural: { '=0': '# Add-ons', '=1': '# Add-on', 'other': '# Add-ons' },
    hasDetails: false,
  },
  'object-storage': {
    label: 'Object Storage',
    plural: { '=0': '# Stores', '=1': '# Store', 'other': '# Stores' },
    hasDetails: true,
    link: '/object-storage/instances',
  },
  'app-engine-account': {
    label: 'App Engine Account',
    plural: { '=0': '# Accounts', '=1': '# Account', 'other': '# Accounts' },
    hasDetails: true,
    link: '/app-engine/accounts',
  },
  'support-ticketing': {
    label: 'Ticketing Support Package',
    plural: { '=0': '# Packages', '=1': '# Package', 'other': '# Packages' },
    hasDetails: false,
    group: 'group-support',
  },
  'support-dev-ops': {
    label: 'DevOps Support Package',
    plural: { '=0': '# Packages', '=1': '# Package', 'other': '# Packages' },
    hasDetails: false,
    group: 'group-support',
  },
};

const subNestedLabels: { [type: string]: AggregatedConfig } = {
  'compute-engine-volume': {
    label: 'Persistent Volumes',
    plural: { '=0': '# Volumes', '=1': '# Volume', 'other': '# Volumes' },
    hasDetails: false,
  },
  'compute-engine-elastic-ip': {
    label: 'Load Balancers',
    plural: { '=0': '# Load Balancers', '=1': '# Load Balancer', 'other': '# Load Balancers' },
    hasDetails: false,
  },
  'compute-kubernetes-node': {
    label: 'Nodes',
    plural: { '=0': '# Noes', '=1': '# Node', 'other': '# Nodes' },
    hasDetails: false,
  },
};

const aggregateOneTimeProducts = (oneTimeProducts: OneTimeProduct[]): AggregatedProduct => {
  const aggregated: AggregatedProduct = {
    label: 'General fees',
    description: 'General fees',
    cost: 0,
    children: [],
    config: {
      label: 'Fee',
      plural: { '=0': '# Fees', '=1': '# Fee', 'other': '# Fees' },
      hasDetails: false,
    },
  };

  oneTimeProducts.forEach(oneTime => {
    aggregated.children.push({
      key: 'general-fees',
      label: oneTime.type,
      description: oneTime.description,
      cost: oneTime.price,
    });

    aggregated.cost += oneTime.price;
  });

  return aggregated;
};

const nestAggregatedProducts = (parent: AggregatedProduct): AggregatedProduct[] => {
  const childrenMap = new Map<string, AggregatedProduct[]>();
  const costMap = new Map<string, number>();
  const nestedProducts: AggregatedProduct[] = [];
  parent.children.forEach((value) => {
    if (childrenMap.has(value.key)) {
      const existingChildren = childrenMap.get(value.key);
      let existingCosts = costMap.get(value.key);
      existingChildren.push(value);
      existingCosts += value.cost;
      childrenMap.set(value.key, existingChildren);
      costMap.set(value.key, existingCosts);
    } else {
      childrenMap.set(value.key, [ value ]);
      costMap.set(value.key, value.cost);
    }
  });

  childrenMap.forEach((value, key) => {
    const config = subNestedLabels[key];
    if (config !== undefined) {
      nestedProducts.push({
        label: config?.label || key,
        description: new I18nPluralPipe(new NgLocaleLocalization('en')).transform(value.length, config?.plural),
        cost: costMap.get(key),
        children: value,
        config,
      });
    }
  });
  return nestedProducts;
};

const aggregateProduct = (product: PeriodicProduct): AggregatedProduct => {
  let parent: AggregatedProduct = {
    id: product.productInstance?.id,
    key: product.productInstance?.type,
    label: product.productInstance?.name || product.productInstance?.type,
    description: '',
    duration: 0,
    cost: product.totalCost,
    children: [],
    deleted: product.productInstance?.deletedAt !== null,
  };

  if (product.children && product.children.length > 0) {
    product.children.forEach(c => {
      const child = aggregateProduct(c);

      parent.children.push(child);
      parent.cost += child.cost;
    });

    parent.children.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);

    if (product.productInstance.type === 'compute-kubernetes-cluster') {
      parent.children = nestAggregatedProducts(parent);
    }
  }

  let description = product.productInstance.name;

  if (product.periods && product.periods.length > 0) {
    const lastElement = product.periods[product.periods.length - 1];

    description = lastElement.config.preset.name;
  }

  let duration = 0;

  product.periods.forEach(period => {
    duration += ((new Date(period.to)).getTime() - (new Date(period.from)).getTime()) / 60000;
  });

  parent = {
    ...parent,
    description,
    duration,
  };

  return parent;
};

const aggregateProducts = (periodicProducts: PeriodicProduct[]): AggregatedProduct[] => {
  const aggregatedProducts: AggregatedProduct[] = [];

  const removedIndexed = [];

  periodicProducts.forEach((product, index) => {
    const parentId = product.productInstance.parentId;
    if (parentId === 0) {
      return;
    }

    const parents = periodicProducts.filter(prod => prod.productInstance.id === parentId);
    if (!parents || parents.length === 0) {
      return;
    }
    const parent = parents[0];

    if (!parent.children) {
      parent.children = [];
    }
    parent.children.push(product);
    removedIndexed.push(index);
  });

  removedIndexed.reverse().forEach(index => {
    periodicProducts.splice(index, 1);
  });

  const groupedByInstanceType = periodicProducts.reduce((result, periodicProduct) => {
    const type = periodicProduct.productInstance.type;
    result.set(type, [ ...(result.get(type) ?? []), periodicProduct ]);

    return result;
  }, new Map<string, PeriodicProduct[]>());

  groupedByInstanceType.forEach((products, type) => {
    const config = definedLabels[type];

    const parent: AggregatedProduct = {
      label: config?.label || type,
      description: '',
      cost: 0,
      children: [],
      config,
    };

    products.forEach(product => {
      const child = aggregateProduct(product);

      parent.children.push(child);
      parent.cost += child.cost;
    });

    aggregatedProducts.push(parent);
  });

  const aggregatedProductsGrouped = [];

  //Iterate through the aggregated products and group them if necessary
  aggregatedProducts.forEach((product) => {
    //Check if a group is configured for this element
    if(product.config?.group) {
      //If a group is present, check if this group already exists in the group array by fetching its index
      const groupIndex = aggregatedProductsGrouped.findIndex((p) => p.groupName === product.config.group);

      //product.description = new I18nPluralPipe(new NgLocaleLocalization('en')).transform(product.children.length, product.config.plural);

      if(groupIndex !== -1) {
         //If group has already been added to the aggregatedProductsGroupped array, append product as child and increase total cost
        aggregatedProductsGrouped[groupIndex].product.children.push(...product.children);
        aggregatedProductsGrouped[groupIndex].product.cost += product.cost;
      }else {
        /* If the group is not yet present in the array, create a new AggregatedProduct with the group config,
        the cost of the group and the product as a child*/
        const groupConfig = definedGroupLabels[product.config.group];
        const parent: AggregatedProduct = {
          label: groupConfig.label,
          description: '',
          cost: product.cost,
          children: product.children,
          config: groupConfig,
        };

        aggregatedProductsGrouped.push({ groupName: product.config.group, product: parent });
      }

    }else {
      //If the element does not contain any group, simply append it with an empty key, specifying no group
      aggregatedProductsGrouped.push({ groupName: '', product });
    }
  });

  //Iterate through the grouped array and add the elements to a result array, removing their key
  const aggregatedProductsResult = [];
  aggregatedProductsGrouped.forEach((product) => {
    aggregatedProductsResult.push(product.product);
  });

  return aggregatedProductsResult;
};

export const aggregateUsagePeriod = (usagePeriod: UsagePeriod): AggregatedProduct[] => {
  const aggregatedProducts: AggregatedProduct[] = [];

  if (usagePeriod.oneTime && usagePeriod.oneTime.length > 0) {
    aggregatedProducts.push(aggregateOneTimeProducts(usagePeriod.oneTime));
  }

  const toAggreateProducts = [];

  if (usagePeriod.periodic && usagePeriod.periodic.length > 0) {
    toAggreateProducts.push(...usagePeriod.periodic);
  }

  if (usagePeriod.dynamic && usagePeriod.dynamic.length > 0) {
    toAggreateProducts.push(...usagePeriod.dynamic);
  }
  aggregatedProducts.push(...aggregateProducts(toAggreateProducts));

  return aggregatedProducts;
};
