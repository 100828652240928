import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Instance } from 'app/entity/compute-instances';
import { PrivateNetworkInstanceDataService } from 'app/networking/store/private-network-instance/private-network-instance.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class PrivateNetworkInstanceService extends GenericService<Instance> {
  constructor(
    store: Store<EntityCache>,
    dataService: PrivateNetworkInstanceDataService,
  ) {
    super('PrivateNetworkInstance', store, dataService);
  }
}
