import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeviceNetworkInterfaceDataService } from 'app/baremetal/store/device-network-interface/device-network-interface.data';
import { NetworkInterface } from 'app/entity/mbm-networking';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class DeviceNetworkInterfaceService extends GenericService<NetworkInterface> {
  constructor(
    store: Store<EntityCache>,
    deviceNetworkInterfaceDataService: DeviceNetworkInterfaceDataService,
  ) {
    super('DeviceNetworkInterface', store, deviceNetworkInterfaceDataService);
  }
}
