<div class="modal-header">
  <div #header>
    <ng-content select="[header]"></ng-content>
  </div>
  <h6 *ngIf="header.childNodes.length === 0" class="modal-title">{{ title }}</h6>

  <div class="wizard-close" (click)="close()">
    <i class="icon icon-cancel"></i>
  </div>
</div>
<div class="modal-body">
  <div #body>
    <ng-content select="[body]"></ng-content>
  </div>
  <div *ngIf="body.childNodes.length === 0">
    <div *ngIf="!fields.length" class="flex jumbotron justify-content-center">
      Loading...
    </div>
    <myflow-dynamic-form
      [config]="fields"
      (keydown.enter)="onSubmit()"
      #form
    ></myflow-dynamic-form>
  </div>
</div>
<div class="modal-footer">
  <div #footer>
    <ng-content select="[footer]"></ng-content>
  </div>
  <div *ngIf="footer.childNodes.length === 0 && fields.length">
    <button [disabled]="(loading$ | async)" type="button" class="btn btn-link link" (click)="close()">Cancel</button>

    <app-submit-btn [loading]="(loading$ | async)" (btnClick)="onSubmit()">{{ action }}</app-submit-btn>
  </div>
</div>
