import { MenuCategory } from "../components";

/* Make sure that there is an item in your module which has a key that
   corresponds to the first part of the module url, e.g. for compute there
   must be a key 'compute' (logically the first item) or else the menu will behave
   incorrectly and close randomly when an item of the module is first selected */
export const prebuildMenu: MenuCategory[] = [
  {
    // Compute
    id: 2,
    name: null,
    items: [
      {
        moduleId: 2,
        name: 'Instances',
        key: 'compute',
        link: '/compute/instances',
        listOfLinks: [ '/compute/instances', '/compute/keypairs', '/compute/volumes' ],
      },
      {
        moduleId: 7,
        name: 'Kubernetes',
        key: 'kubernetes',
        link: '/compute/clusters',
        listOfLinks: [ '/compute/clusters' ],
      },
      {
        moduleId: 5,
        name: 'Networking',
        key: 'networking',
        link: '/compute/networks',
        listOfLinks: [
          '/compute/networks',
          '/compute/elastic-ips',
          '/compute/security-groups',
          '/compute/routers',
          '/compute/load-balancers',
          '/compute/certificates',
          '/compute/connections',
        ],
      },
    ],
  },
  {
    // Mac Bare Metal
    id: 3,
    name: null,
    items: [
      {
        moduleId: 3,
        name: 'Devices',
        key: 'macbaremetal',
        link: '/macbaremetal/devices',
        listOfLinks: [ '/macbaremetal/devices' ],
      },
      {
        moduleId: 3,
        name: 'Networking',
        key: 'mbm_networking',
        link: '/macbaremetal/networks',
        listOfLinks: [ '/macbaremetal/networks', '/macbaremetal/routers', '/macbaremetal/elastic-ips', '/macbaremetal/security-groups' ],
      },
    ],
  },
  {
    // CI Engine
    id: 10,
    name: null,
    items: [
      {
        moduleId: 10,
        name: 'Subscriptions',
        key: 'ci-engine',
        link: '/ci-engine/subscriptions',
        listOfLinks: [ '/ci-engine/subscriptions' ],
        blackListLinks: [ '/ci-engine/subscriptions/.*/integrations' ],
      },
      {
        moduleId: 10,
        name: 'Integrations',
        key: 'integrations',
        link: '/ci-engine/integrations',
        listOfLinks: [ '/ci-engine/integrations', '/ci-engine/subscriptions/.*/integrations' ],
      },
    ],
  },
  {
    // Object Storage
    id: 4,
    name: null,
    items: [
      {
        moduleId: 4,
        name: 'Overview',
        key: 'object-storage',
        link: '/object-storage/instances',
        listOfLinks: [ '/object-storage/instances' ],
      },
    ],
  },
  {
    // App Engine
    id: 8,
    name: null,
    items: [
      {
        moduleId: 8,
        name: 'Overview',
        key: 'app-engine',
        link: '/app-engine/accounts',
        listOfLinks: [ '/app-engine/accounts' ],
      },
    ],
  },
];
