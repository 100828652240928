import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateTicket, CreateTicketComment, Ticket, TicketComment, UpdateTicket } from 'app/entity/tickets';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { BehaviorSubject, combineLatest, forkJoin, iif, Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { TicketDataService } from './ticket.data';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { contextTicket } from '../../../store/context/support.context';

export const ticketFilterOpenPending = [ 1, 2 ];
export const ticketFilterClosedSolved = [ 3, 4 ];

@Injectable()
export class TicketService extends GenericService<Ticket, CreateTicket, UpdateTicket> {
  private onlyMyTicketsFilter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private ticketStatusFilter$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(ticketFilterOpenPending);

  constructor(
    store: Store<EntityCache>,
    private ticketDataService: TicketDataService,
    private localStore: LocalStorageService,
  ) {
    super('Ticket', store, ticketDataService);
  }

  get filteredEntities$(): Observable<Ticket[]> {
    // Get the user id
    const xIdentity = this.localStore.getSessionObject('impersonated-user'); // for impersonate feature (multiple users)
    const userId = xIdentity ? +xIdentity.id : +this.localStore.getObject('user').id;
    return combineLatest([
      super.filteredEntities$,
      this.onlyMyTicketsFilter$,
      this.ticketStatusFilter$,
    ]).pipe(
      map(([ tickets, onlyMyTicketsFilterEnabled, ticketStatusFilter ]) => {
        if (onlyMyTicketsFilterEnabled) {
          tickets = tickets.filter(ticket => ticket.createdBy.id === userId);
        }
        tickets = tickets.filter(ticket => ticketStatusFilter.includes(ticket.status.id));
        return tickets;
      }),
    );
  }

  get onlyMyTicketsFilterEnabled$(): Observable<boolean> {
    return this.onlyMyTicketsFilter$.asObservable();
  }

  getById(id: number, context?: number[]): Observable<Ticket> {
    return super.getById(id, context).pipe(
      map(ticket => {
        if (ticket.comments) {
          ticket.comments.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          );
        }

        return ticket;
      }),
    );
  }

  setOnlyMyTicketsFilter(value: boolean): void {
    this.onlyMyTicketsFilter$.next(value);
  }

  setTicketStatusFilter(value: number[]): void {
    this.ticketStatusFilter$.next(value);
  }

  createTicket(fileList: Set<File>, data: CreateTicket): Observable<Ticket> {
    return this.createWithAttachments(fileList, data, (d) => this.create(d));
  }

  closeTicket(id: number): Observable<Ticket> {
    return this.update(id, { statusId: 3 });
  }

  /**
   * Creates a comment for a ticket
   *
   * @param ticketId The id of the ticket
   * @param fileList A set of files added as attachments to the comment
   * @param data Additional data of the comment
   */
  createComment(ticketId: number, fileList: Set<File>, data: CreateTicketComment): Observable<Ticket> {
    return this.createWithAttachments(fileList, data, (d) => this.ticketDataService.createComment(ticketId, d).pipe(
      tap(ticket => this.updateOneInCache(contextTicket(), ticket)),
    ));
  }

  /**
   * Download ticket attachment
   *
   * @arg url
   */
  fetchCommentAttachment(ticketId: number, attachmentId: number): Observable<any> {
    return this.ticketDataService.fetchCommentAttachment(ticketId, attachmentId);
  }

  upload(files: Set<File>): { [ key: string ]: { progress: Observable<number>; token: Observable<string> } } {
    return this.ticketDataService.upload(files);
  }

  addOneCommentToCache(context: number[], entity: TicketComment): Observable<Ticket> {
    const ticketId = context[0];
    return this.entities$.pipe(
      first(),
      map(tickets => tickets.find(ticket => ticket.id === ticketId)),
      tap(ticket => {
        if (!ticket.comments) {
          return;
        }

        ticket.comments.push(entity);
        this.updateOneInCache(contextTicket(), ticket);
      }),
    );
  }

  updateOneCommentInCache(context: number[], entity: TicketComment): Observable<Ticket> {
    const ticketId = context[0];
    return this.entities$.pipe(
      first(),
      map(tickets => tickets.find(ticket => ticket.id === ticketId)),
      tap(ticket => {
        if (!ticket.comments) {
          return;
        }

        const commentIndex = ticket.comments.findIndex(comment => comment.id === entity.id);
        ticket.comments[commentIndex] = entity;
        this.updateOneInCache(contextTicket(), ticket);
      }),
    );
  }

  removeOneCommentFromCache(context: number[], entity: TicketComment): Observable<Ticket> {
    const ticketId = context[0];
    return this.entities$.pipe(
      first(),
      map(tickets => tickets.find(ticket => ticket.id === ticketId)),
      tap(ticket => {
        if (!ticket.comments) {
          return;
        }

        const commentIndex = ticket.comments.findIndex(comment => comment.id === entity.id);
        delete ticket.comments[commentIndex];
        this.updateOneInCache(contextTicket(), ticket);
      }),
    );
  }

  private createWithAttachments<T extends CreateTicket|CreateTicketComment>(
    fileList: Set<File>,
    data: T,
    fn: (data: T) => Observable<Ticket>,
  ): Observable<Ticket> {
    // Before submitting the comment, upload the files
    const progress = this.upload(fileList);

    // convert the progress map into an array
    const allProgressObservables: Observable<string>[] = [];

    for (const key in progress) {
      allProgressObservables.push(progress[key].token);
    }

    return iif(
      // If there are progress observables (therefore there are attachments)
      () => allProgressObservables.length !== 0,
      // Join all the file upload progress observables and wait until files are uploaded
      forkJoin(allProgressObservables).pipe(
        tap((tokens: [string]) => {
          data.attachments = tokens; // Add the tokens of the uploaded files as the attachments in the data
        }),
        // Once files are uploaded
        switchMap(() => fn(data)),
      ),
      // If there aren't any attachments, upload the file directly
      fn(data),
    );
  }
}
