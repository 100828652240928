import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ObjectStorageCreate, ObjectStorageInstance } from 'app/entity';
import { ApiService } from 'app/shared/services/api';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

import {
  httpObjStorageRotateCredentials,
  httpObjStorageProducts,
} from 'app/shared/utils';


@Injectable({
  providedIn: 'root',
})
export class ObjectStorageDataService extends GenericDataService<ObjectStorageInstance, ObjectStorageCreate> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpObjStorageProducts, apiService);
  }

  /**
   * Rotate Object Storage Instances Credentials
   */
  rotateCredentials(id: number): Observable<ObjectStorageInstance> {
    return this.apiService.post(httpObjStorageRotateCredentials(id));
  }
}
