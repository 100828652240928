import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateLoadBalancerPoolMember, LoadBalancerPoolMember } from 'app/entity/compute-networking';
import { LoadBalancerPoolMemberDataService } from 'app/networking/store/load-balancer-pool-member/load-balancer-pool-member.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class LoadBalancerPoolMemberService extends GenericService<LoadBalancerPoolMember, CreateLoadBalancerPoolMember> {
  constructor(
    store: Store<EntityCache>,
    protected dataService: LoadBalancerPoolMemberDataService,
  ) {
    super('LoadBalancerPoolMember', store, dataService);
  }
}
