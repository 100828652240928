import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const QRCode: any;

@Component({
  selector: 'myflow-qr-code',
  template: '',
})
export class QrCodeComponent implements OnInit, OnChanges {
  @Input() data: string;
  @Input() width = 256;
  @Input() height = 256;
  @Input() colorDark = '#000000';
  @Input() colorLight = '#ffffff';

  private qrCode: any;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.qrCode = new QRCode(this.el.nativeElement, {
      text: this.data,
      width: this.width,
      height: this.height,
      colorDark: this.colorDark,
      colorLight: this.colorLight,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'data') && changes.data.currentValue) {
      this.qrCode.clear();
      this.qrCode.makeCode(changes.data.currentValue);
    }
  }
}
