<div class="sidebar" [ngClass]="{'closed': !showSidenav }">
  <div class="create-btn">
    <button class="btn btn-primary btn-block" (click)="createBtnClick.emit($event)">
      <i *ngIf="(createBtnIcon && createBtnIcon !== 'icon-plus') || (createBtnIcon && !showSidenav)" [ngClass]="createBtnIcon"></i> <span>&nbsp;{{ createBtnText }}</span>
    </button>
  </div>

  <ul class="menu">
    <ng-container *ngIf="menuList.length !== 0;else placeholder">
      <ng-content select="[custom-items-start]"></ng-content>
      <li *ngFor="let n of menuList" [class.active]="isCategoryActive(n)" class="module">
        <div class="module-title" (click)="selectCategory(n.id)">
          <span>
            {{ n.name }}
            <i class="icon-angel-down"></i>
          </span>
        </div>

        <ul class="module-submenu" [style.max-height]="calculateMaxHeightForSubmenus(n)">
          <li *ngFor="let child of n.items" [class.active]="isRouteActive(child.listOfLinks, child.blackListLinks)">
            <a class="item" [routerLink]="child.link">{{ child.name }}</a>
          </li>
        </ul>
      </li>

      <ng-content select="[custom-items-end]"></ng-content>
    </ng-container>
    <ng-template #placeholder>
      <div *ngFor="let n of [ 1, 2, 3, 4, 5, 6 ]" class="placeholder animated animated-dark animated-slow" myflowRandomizeTableLoaderWidth></div>
    </ng-template>
  </ul>

  <span class="sidebar-toggle" (click)="toggleSidebar()"></span>

  <div class="sidebar-footer">
    <div class="info">
      <ng-content select="[custom-footer]"></ng-content>
      <a routerLink="/admin/organizations" *ngIf="admin$ | async" class="admin-link">Go to Admin Area</a>
      <a [href]="documentationUrl" target="_blank">Documentation</a>
      <a routerLink="/doc">API Documentation</a>
      <a [href]="termsAndConditionsUrl" target="_blank">Terms and Conditions</a>
      <p class="version">{{ versionName }}</p>
    </div>
  </div>
</div>
