<div class="row">
  <input
    *ngFor="let item of inputControls; let i = index"
    type="text"
    class="form-control"
    (keyup)="onInput(i, $event)"
    (focus)="$event.target.select()"
    maxlength="1"
    #step
  >
</div>