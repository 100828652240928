import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValueAccessorBase } from 'app/shared/utils/value-accessor';

export interface PlanFeature {
  title: string;
  value: string;
  valueSubText?: string;
}

export interface PlanOption<T> {
  name: string;
  features: PlanFeature[];
  price: number;
  priceAsterisk?: boolean;
  value: T;
  active?: boolean;
  hint?: string;
}

@Component({
  selector: 'myflow-plan-select',
  templateUrl: './plan-select.component.html',
  styleUrls: [ './plan-select.component.scss' ],
  providers: [
    // eslint-disable-next-line no-use-before-define
    ValueAccessorBase.createProviderFor(PlanSelectComponent),
  ],
})
export class PlanSelectComponent<T> extends ValueAccessorBase<T> {
  @Input() plans: PlanOption<T>[];
  @Output() emitChange = new EventEmitter();

  change(value: T): void {
    this.value = value;
    this.emitChange.emit(value);
  }

  selectPlan(plan: PlanOption<T>): void {
    if(!plan.active) {
      this.change(plan.value);
    }
  }

  /* Track the ng-for by anything but the plan object (causes the change detection to
     always get triggered, even if no changes are made*/
  trackPlanByName(_index: number, plan: PlanOption<T>): string {
    return plan.name;
  }

  // Same thing for feature ng-for
  trackFeatureByTitle(_index: number, feature: PlanFeature): string {
    return feature.title;
  }
}
