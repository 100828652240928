import { KeyValue, Location, Status } from '../common';
import { CreateIkePolicy, VirtualPrivateNetworkIkePolicy } from './virtual-private-network-ike-policy.model';
import { CreateIpsecPolicy, VirtualPrivateNetworkIpsecPolicy } from './virtual-private-network-ipsec-policy.model';
import {
  CreateDeadPeerDetection,
  VirtualPrivateNetworkDeadPeerDetection,
} from './virtual-private-network-dead-peer-detection.model';
import {
  CreateRemoteEndpoint,
  VirtualPrivateNetworkRemoteEndpoint,
} from './virtual-private-network-remote-endpoint.model';
import { CreatePeerEndpoint, VirtualPrivateNetworkPeerEndpoint } from './virtual-private-network-peer-endpoint.model';
import { VirtualPrivateNetworkType } from './virtual-private-network.type.model';

export interface VirtualPrivateNetwork {
  id: number;
  name: string;
  type: VirtualPrivateNetworkType;
  mtu: number;
  psk: string;
  initiator: KeyValue;
  location: Location;
  status: Status;
  ikePolicy: VirtualPrivateNetworkIkePolicy;
  ipsecPolicy: VirtualPrivateNetworkIpsecPolicy;
  deadPeerDetection: VirtualPrivateNetworkDeadPeerDetection;
  localEndpoint: VirtualPrivateNetworkPeerEndpoint;
  remoteEndpoint: VirtualPrivateNetworkRemoteEndpoint|VirtualPrivateNetworkPeerEndpoint;
}

export interface CreateVirtualPrivateNetwork {
  name: string;
  psk: string;
  mtu: number;
  initiatorId: number;
  ikePolicy: CreateIkePolicy;
  ipsecPolicy: CreateIpsecPolicy;
  localEndpoint: CreatePeerEndpoint;
  remoteEndpoint: CreateRemoteEndpoint;
  deadPeerDetection: CreateDeadPeerDetection;
}

export interface CreatePeeringVirtualPrivateNetwork {
  localEndpoint: CreatePeerEndpoint;
  remoteEndpoint: CreatePeerEndpoint;
}

export interface UpdateVirtualPrivateNetwork {
  name?: string;
  psk?: string;
  mtu?: number;
  initiatorId?: number;
  localEndpoint?: CreatePeerEndpoint;
  remoteEndpoint?: CreateRemoteEndpoint;
  deadPeerDetection?: CreateDeadPeerDetection;
}
