import configs from 'app/shared/data/configs';

/**
 * Bare Metal
 * ----------------------------------------------
 */
//   - "/v4/macbaremetal/devices/{deviceId}/workflows"

// Devices
export const httpBareMetalDevices = (): string => `v${configs.version}/macbaremetal/devices`;
export const httpBareMetalDeviceRenew = (deviceId: number): string => `v${configs.version}/product-instances/${deviceId}/commitment/renew`;
export const httpBareMetalDeviceWorkflows = (deviceId: number): string => `v${configs.version}/macbaremetal/devices/${deviceId}/workflows`;
export const httpBareMetalDeviceActions = (deviceId: number): string => `v${configs.version}/macbaremetal/devices/${deviceId}/actions`;
export const httpBareMetalDeviceElasticIps = (
  deviceId: number,
): string => `v${configs.version}/macbaremetal/devices/${deviceId}/elastic-ips`;
export const httpBareMetalDeviceElasticIp = (
  deviceId: number,
  eipId: number,
): string => `v${configs.version}/macbaremetal/devices/${deviceId}/elastic-ips/${eipId}`;
export const httpBareMetalDeviceNetworkInterfaces = (
  deviceId: number,
): string => `v${configs.version}/macbaremetal/devices/${deviceId}/network-interfaces`;
export const httpBareMetalDeviceNetworkInterface = (
  deviceId: number,
  interfaceId: number,
): string => `v${configs.version}/macbaremetal/devices/${deviceId}/network-interfaces/${interfaceId}`;
export const httpBareMetalDeviceSecurityGroups = (
  deviceId: number,
): string => `v${configs.version}/macbaremetal/devices/${deviceId}/security-groups`;
export const httpBareMetalDeviceVNC = (deviceId: number): string => `v${configs.version}/macbaremetal/devices/${deviceId}/vnc`;

// Elastic IPs
export const httpBareMetalElasticIps = (): string => `v${configs.version}/macbaremetal/elastic-ips`;

// Networks
export const httpBareMetalNetworks = (): string => `v${configs.version}/macbaremetal/networks`;
export const httpBareMetalNetworkDevices = (id: number): string => `v${configs.version}/macbaremetal/networks/${id}/devices`;

// Routers
export const httpBareMetalRouters = (): string => `v${configs.version}/macbaremetal/routers`;
export const httpBareMetalRouterInterfaces = (id: number): string => `v${configs.version}/macbaremetal/routers/${id}/interfaces`;

// Security Groups
export const httpBareMetalSecurityGroups = (): string => `v${configs.version}/macbaremetal/security-groups`;
export const httpBareMetalSecurityGroupRules = (
  secGroupId: number,
): string => `v${configs.version}/macbaremetal/security-groups/${secGroupId}/rules`;

// SSE Topics
export const sseBareMetalDevices = (): string => `/v${configs.version}/macbaremetal/devices/{deviceId}`;
