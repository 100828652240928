<div class="clipboard-card" [ngClass]="{wizard}">
  <div class="clipboard-card-title">
    <p>{{ title }}</p>
    <div *ngIf="data" class="clipboard-card-title-icons">
      <i *ngIf="downloadFilename" class="icon-download-file" (click)="downloadAsFile()"></i>
      <i *ngIf="!showCopiedText; else copiedText" class="icon-clipboard" (click)="copyToClipboard()"></i>
    </div>
    <ng-template #copiedText>
      <p>copied to clipboard</p>
    </ng-template>
  </div>
  <div class="clipboard-card-content">
    <p *ngIf="data" [ngClass]="{cutOff}">{{ data }}</p>
    <ng-content *ngIf="!data" />
  </div>
</div>