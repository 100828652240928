import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ModuleSubscription } from "app/entity/infrastructure";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { OrganizationModuleSubscriptionDataService } from "./organization-module-subscription.data";

@Injectable()
export class OrganizationModuleSubscriptionService extends GenericService<ModuleSubscription> {
  constructor(
    store: Store<EntityCache>,
    private moduleSubscriptionDataService: OrganizationModuleSubscriptionDataService,
  ) {
    super('AdminOrganizationModuleSubscription', store, moduleSubscriptionDataService);
  }
}
