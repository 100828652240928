<myflow-side-panel
  [title]="!this.updating ? 'Activate Support Plan' : 'Update Support Plan'"
  [loading$]="(loading$ | async)"
  (closeEvent)="close()"
  (confirmEvent)="submitPlan()"
  [action]="!this.updating ? 'Activate': 'Update'"
>
  <form content [formGroup]="supportPlanForm" class="form-wrapper centered">
    <div class="form-group">
      <label for="presetId" required>Support Plan <span class="ng-star-inserted">*</span></label>
      <ng-select [clearable]="false" [searchable]="false" formControlName="presetId">
        <ng-container *ngFor="let plan of availablePlans">
          <ng-option [value]="plan.id">
            {{ plan.name }}
          </ng-option>
        </ng-container>
      </ng-select>
      <app-form-errors [control]="supportPlanForm.controls['presetId']" label="Price per month"></app-form-errors>
    </div>
    <div class="form-group">
      <label for="price" required>Price per month <span class="ng-star-inserted">*</span></label>
      <input type="number" placeholder="Enter price per month in CHF" class="form-control" id="price" formControlName="price">
      <app-form-errors [control]="supportPlanForm.controls['price']" label="Price per month"></app-form-errors>
    </div>
    <p class="require-message"><span class="app-color">*</span> Required fields</p>
  </form>
</myflow-side-panel>