<mat-accordion>
  <mat-expansion-panel *ngFor="let item of items" [@.disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <ng-container *ngTemplateOutlet="headerRef; context: {$implicit: item}"></ng-container>
      </mat-panel-title>
      <mat-panel-description>
        <ng-container *ngTemplateOutlet="descriptionRef; context: {$implicit: item}"></ng-container>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngTemplateOutlet="contentRef; context: {$implicit: item}"></ng-container>
  </mat-expansion-panel>
</mat-accordion>
