import configs from 'app/shared/data/configs';

/**
 * Auth
 * ----------------------------------------------
 */
export const httpLogin = (): string => `v${configs.version}/auth`;
export const httpLogout = (): string => `v${configs.version}/logout`;
export const httpSignUp = (): string => `v${configs.version}/register`;

// Activates
export const httpSignUpUserActivate = (userID: number | string): string => `v${configs.version}/users/${userID}/activate`;
export const httpSignUpOrganizationActivate = (orgId: number | string): string => `v${configs.version}/organizations/${orgId}/activate`;
export const httpSignUpOrganizationVerify = (orgId: number | string): string => `v${configs.version}/organizations/${orgId}/verify`;
