import { Injectable } from '@angular/core';
import { ProductNotification } from 'app/entity/admin';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { NotificationDataService } from './notification.data';
import { Store } from '@ngrx/store';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Observable, tap } from 'rxjs';

@Injectable()
export class NotificationService extends GenericService<ProductNotification> {
  constructor(
    store: Store<EntityCache>,
    private notificationDataService: NotificationDataService,
  ) {
    super('AdminProductNotifications', store, notificationDataService);
  }

  sendNotificationEmail(id: number): Observable<void> {
    return this.notificationDataService.sendNotificationEmail(id).pipe(
      tap(() => this.removeOneFromCache(id)),
    );
  }

  dismissNotificationEmail(id: number): Observable<void> {
    return this.notificationDataService.dismissNotificationEmail(id).pipe(
      tap(() => this.removeOneFromCache(id)),
    );
  }
}
