import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: [ './loading-page.component.scss' ],
})
export class LoadingPageComponent {
  @Input() type: string;
}
