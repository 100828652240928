import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClusterNode } from 'app/entity/compute-kubernetes';
import { ClusterNodeDataService } from 'app/kubernetes/store/cluster-node/cluster-node.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { ClusterService } from '../cluster';
import { InvokeAction } from '../../../entity/common';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { contextClusterNode } from '../../../store/context/kubernetes.context';

@Injectable()
export class ClusterNodeService extends GenericService<ClusterNode> {
  pendingActions = [
    'cordon',
    'uncordon',
    'drain',
    'rebuild',
    'force-rebuild',
  ];

  constructor(
    store: Store<EntityCache>,
    private clusterNodesDataService: ClusterNodeDataService,
    private clusterService: ClusterService,
  ) {
    super('ClusterNode', store, clusterNodesDataService);
  }

  getControlPlaneOfCluster(clusterId: number): Observable<ClusterNode> {
    return this.getAll(contextClusterNode(clusterId)).pipe(
      first(),
      map(nodes => nodes.filter(node => node.cluster.id === clusterId)),
      map(nodes => nodes.filter(node => node.roles.map(role => role.key).includes('control-plane'))),
      map(nodes => nodes.length >= 1 ? nodes[0] : null),
    );
  }

  invokeAction(id: number, action: InvokeAction, context?: number[]): Observable<ClusterNode> {
    return super.invokeAction(id, action, context).pipe(
      tap(node => {
        if (!this.pendingActions.includes(action.action)) {
          return;
        }

        this.clusterService.getById(node.cluster.id).pipe(
          first(),
        ).subscribe(cluster => {
          this.clusterService.showMessageUntilUnlocked(
            cluster,
            `Node ${node.name} gets ${action.action}ed.`,
            `Succeeded to ${action.action} node ${node.name}.`,
          ).subscribe();
        });
      }),
    );
  }
}
