import { Injectable } from '@angular/core';

export interface TableState {
  path: string;
  page: number;
  limit: number;
}

@Injectable({
  providedIn: 'root',
})
export class TableService {
  state: TableState[] = [];

  getTableState(path: string): TableState | null {
    return this.state ? this.state.find(table => table.path === path) : null;
  }

  setTableState(path: string, state: TableState): void {
    const index = this.state.findIndex(table => table.path === path);
    if (index >= 0) {
      this.state[index] = {
        ...this.state[index],
        ...state,
      };
    } else {
      this.state.push({
        path,
        ...state,
      });
    }
  }
}
