import { Injectable } from '@angular/core';
import { Order } from 'app/entity';
import { DummyRecord } from 'app/entity/store';
import { ToastPendingComponent } from 'app/shared/components';
import { CaseConverterService } from 'app/shared/services/case-converter.service';
import { SseService } from 'app/shared/services/sse/sse.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Message } from '../sse/connection';

type NamedDummyRecord = DummyRecord<{ id: number; name: string }>;

export interface MessageOptions {
  action: string;
  subject: string;
  disableCreateMessage: boolean;
}

export interface OrderCompleteEvent {
  type: string;
  targetId: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderMessagesService {
  creating: MessageOptions = { action: 'Creating', subject: 'create', disableCreateMessage: true };
  upgrading: MessageOptions = { action: 'Upgrading', subject: 'upgrade', disableCreateMessage: false };
  resizing: MessageOptions = { action: 'Resizing', subject: 'resize', disableCreateMessage: false };

  orderEventComplete$ = new Subject<OrderCompleteEvent>();

  constructor(
    private toastrService: ToastrService,
    private sseService: SseService,
  ) {
  }

  async waitForRecordToCreate(type: string, name: string, ref: string): Promise<boolean> {
    const pending = this.toastrService.warning(`<b>${type}</b>: Creating ${name}`, '', {
      toastComponent: ToastPendingComponent,
      enableHtml: true,
      disableTimeOut: true,
    });

    const resolve$ = new BehaviorSubject(null);

    const connection = await this.sseService.open([ ref ]);
    connection.on<Order>('update').pipe(
      map(this.convertKeys),
    ).subscribe(event => {
      if (event.data.status.id === 4) {
        // order failed
        this.toastrService.error(`<b>${type}</b>: Failed to create ${name}`, '', { enableHtml: true });
      }

      if (event.data.status.id === 3) {
        // order successful
        this.toastrService.success(`<b>${type}</b>: Succeeded to create ${name}`, '', { enableHtml: true });
      }

      if ([ 3, 4 ].includes(event.data.status.id)) {
        // order completed
        this.toastrService.clear(pending.toastId);
        connection.close();

        resolve$.next(true);
        resolve$.complete();
      }
    });

    return lastValueFrom(resolve$);
  }

  async subscribeToOrderEvents(
    type: string,
    data: NamedDummyRecord | string,
    ref: string,
    options: MessageOptions = this.creating,
  ): Promise<void> {
    const name = OrderMessagesService.isDummyRecord(data)
      ? (data as NamedDummyRecord).name
      : data;

    const pending = this.toastrService.warning(`<b>${type}</b>: ${options.action} ${name}`, '', {
      toastComponent: ToastPendingComponent,
      enableHtml: true,
      disableTimeOut: true,
    });

    const connection = await this.sseService.open([ ref ]);
    connection.on<Order>('update').pipe(
      map(this.convertKeys),
    ).subscribe(event => {
      if (event.data.status.id === 4) {
        // order failed
        this.toastrService.error(`<b>${type}</b>: Failed to ${options.subject} ${name}`, '', { enableHtml: true });
      }

      if (event.data.status.id === 3 && !options.disableCreateMessage) {
        // order successful
        this.toastrService.success(`<b>${type}</b>: Succeeded to ${options.subject} ${name}`, '', { enableHtml: true });
      }

      if ([ 3, 4 ].includes(event.data.status.id)) {
        // order completed
        this.toastrService.clear(pending.toastId);
        connection.close();

        if (OrderMessagesService.isDummyRecord(data)) {
          this.orderEventComplete$.next({ type, targetId: (data as NamedDummyRecord).id.toString() });
        }
      }
    });
  }

  private convertKeys(message: Message<Order>): any {
    return CaseConverterService.convertKeys(message, CaseConverterService.snakeToCamel);
  }

  private static isDummyRecord(data: unknown): boolean {
    return Object.prototype.hasOwnProperty.call(data, 'id');
  }
}
