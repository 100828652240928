import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateNetwork, Network, UpdateNetwork } from 'app/entity/compute-networking';
import { PrivateNetworkDataService } from 'app/networking/store/private-network/private-network.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class PrivateNetworkService extends GenericService<Network, CreateNetwork, UpdateNetwork> {
  constructor(
    store: Store<EntityCache>,
    dataService: PrivateNetworkDataService,
  ) {
    super('PrivateNetwork', store, dataService);
  }
}
