import configs from 'app/shared/data/configs';

/**
 * Account
 * ----------------------------------------------
 */

// Two Factor
export const httpTwoFactorStatus = (): string => `v${configs.version}/account/2fa`;
export const httpTwoFactorVerify = (): string => `v${configs.version}/account/2fa/verify`;
export const httpTwoFactorEnable = (): string => `v${configs.version}/account/2fa/enable`;
export const httpTwoFactorActivate = (): string => `v${configs.version}/account/2fa/activate`;
export const httpTwoFactorDisable = (): string => `v${configs.version}/account/2fa/disable`;
export const httpTwoFactorBackupSMS = (): string => `v${configs.version}/account/2fa/sms`;

// Password
export const httpPasswordChange = (): string => `v${configs.version}/account/password`;
export const httpPasswordReset = (): string => `v${configs.version}/account/password/reset`;
export const httpPasswordSet = (): string => `v${configs.version}/account/password/set`;

// Organizations
export const httpUserOrganizations = (): string => `v${configs.version}/account/organizations`;
