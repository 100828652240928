<div
  class="dynamic-field form-input form-group"
  [formGroup]="group">

  <form-base-label [config]="config" />
  
  <input
    *ngIf="config.isPriceString"
    appPriceString
    type="text"
    [attr.placeholder]="config.placeholder"
    [formControlName]="config.name"
    class="form-control"
    [required]="config.required"
    [id]="config.name"
  >
  <input
    *ngIf="!config.isPriceString"
    type="text"
    [attr.placeholder]="config.placeholder"
    [formControlName]="config.name"
    class="form-control"
    [required]="config.required"
    [id]="config.name"
  >

  <div *ngIf="config.hint" class="form-text text-muted">
    {{ config.hint }}
  </div>

  <app-form-errors [control]="control" [label]="config.label"></app-form-errors>
</div>
