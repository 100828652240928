import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClusterLoadBalancer } from 'app/entity';
import { ClusterLoadBalancerDataService } from 'app/kubernetes/store/cluster-load-balancer/cluster-load-balancer.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class ClusterLoadBalancerService extends GenericService<ClusterLoadBalancer> {
  constructor(
    store: Store<EntityCache>,
    clusterLoadBalancerDataService: ClusterLoadBalancerDataService,
  ) {
    super('ClusterLoadBalancer', store, clusterLoadBalancerDataService);
  }
}
