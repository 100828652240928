import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadBalancerEntityField } from 'app/entity';
import { CreateLoadBalancerPool, LoadBalancerPool, UpdateLoadBalancerPool } from 'app/entity/compute-networking';
import { LoadBalancerPoolDataService } from 'app/networking/store/load-balancer-pool/load-balancer-pool.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoadBalancerPoolService extends GenericService<LoadBalancerPool, CreateLoadBalancerPool, UpdateLoadBalancerPool> {
  constructor(
    store: Store<EntityCache>,
    private loadBalancerProolDataService: LoadBalancerPoolDataService,
  ) {
    super('LoadBalancerPool', store, loadBalancerProolDataService);
  }

  getLoadBalancerProtocols(): Observable<LoadBalancerEntityField[]> {
    return this.loadBalancerProolDataService.getLoadBalancerProtocols();
  }

  getLoadBalancerBalancingAlgorithms(): Observable<LoadBalancerEntityField[]> {
    return this.loadBalancerProolDataService.getLoadBalancerBalancingAlgorithms();
  }

  getLoadBalancerBalancingHealthTypes(): Observable<LoadBalancerEntityField[]> {
    return this.loadBalancerProolDataService.getLoadBalancerBalancingHealthTypes();
  }
}
