/* eslint-disable max-len */
import configs from 'app/shared/data/configs';

/**
 * Object Storage
 * ----------------------------------------------
 */
export const httpObjStorageProducts = (): string => `v${configs.version}/object-storage/instances`;
export const httpObjStorageProduct = (instanceId: number): string => `v${configs.version}/object-storage/instances/${instanceId}`;
export const httpObjStorageBuckets = (id: number): string => `v${configs.version}/object-storage/instances/${id}/buckets`;
export const httpObjStorageAccounts = (id: number): string => `v${configs.version}/object-storage/instances/${id}/accounts`;
export const httpObjStorageAccountCredentials = (id: number, accountId: number): string => `v${configs.version}/object-storage/instances/${id}/accounts/${accountId}/rotate-credentials`;
export const httpObjStorageRotateCredentials = (id: number): string => `v${configs.version}/object-storage/instances/${id}/rotate-credentials`;

// SSE Topics
export const sseObjectStorageBucket = (): string => `/v${configs.version}/object-storage/instances/{instanceId}/buckets/{bucketId}`;
