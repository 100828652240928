<div class="search">
  <input
    id="search"
    [ngClass]="{'active': show}"
    (keyup)="applyFilter($event)"
    name="search"
    type="text"
    placeholder="What are you looking for?"
    [(ngModel)]="searchRequest"
    #input
  >
  <button class="btn btn-primary dotted-menu" [ngClass]="{'round': show}" (click)="toggleSearch()">
    <i class="icon-search-bold" *ngIf="!show"></i>
    <i class="icon-cancel-bold" *ngIf="show"></i>
  </button>
</div>