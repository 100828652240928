import { Component, Input } from '@angular/core';
import { ValueAccessorBase } from '../../utils/value-accessor';

@Component({
  selector: 'myflow-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: [ './toggle.component.scss' ],
  // eslint-disable-next-line no-use-before-define
  providers: [ ValueAccessorBase.createProviderFor(ToggleComponent) ],
})
export class ToggleComponent extends ValueAccessorBase<boolean> {
  @Input() disabled = false;
  @Input() alwaysColored = true;
  @Input() small = false;

  actionToggle(): void {
    if (!this.disabled) {
      this.value = !this.value;
    }
  }
}
