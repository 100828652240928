import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateRouter, Router, UpdateRouter } from 'app/entity/compute-networking';
import { RouterDataService } from 'app/networking/store/router/router.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class RouterService extends GenericService<Router, CreateRouter, UpdateRouter> {
  constructor(
    store: Store<EntityCache>,
    dataService: RouterDataService,
  ) {
    super('Router', store, dataService);
  }
}
