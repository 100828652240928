<myflow-side-panel
  title="Switch Organization"
  [loading$]="loading"
  (closeEvent)="close()"
  (confirmEvent)="onSwitch()"
  action="Switch"
>
  <div content class="form-wrapper centered">
    <ul class="creator-list">
      <li *ngFor="let org of assignedOrganizations" class="creator-item" [ngClass]="{ 'text-primary': org.id === active }" (click)="switch(org)">
        <div>{{ org.name }}</div>
        <div *ngIf="org.id === active"><i class="icon-check-circle"></i></div>
      </li>
    </ul>
  </div>
</myflow-side-panel>