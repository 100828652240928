
export interface StoreEntity<T> {
  id: number;
  context: number[];
  entity: T;
}

const filterKeys = (data: Record<string, unknown>, filter: string): boolean => {
  for (const key in data) {
    const value = data[key];

    if (value && typeof value === 'object') {
      if (filterKeys(value as Record<string, unknown>, filter)) {
        return true;
      }

      continue;
    }

    if (`${value}`.match(new RegExp(`.*${filter}.*`, 'gi'))) {
      return true;
    }
  }

  return false;
};

export const filterEntity = (entity: Record<string, unknown>, filter: string): boolean => {
  if (!filter || filter === '') {
    return true;
  }

  return filterKeys(entity, filter);
};
