import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { priceValidator } from 'app/shared/utils';

/**
 * This directive makes sure that a price entered by the user matches the correct number of decimals.
 *
 * It also makes sure that the value is transmitted to the server as a json value of type string to avoid floating point issues.
 */
@Directive({
  selector: '[appPriceString]',
  // eslint-disable-next-line no-use-before-define
  providers: [ { provide: NG_VALIDATORS, useExisting: PriceDirective, multi: true } ],
})
export class PriceDirective implements Validator {
  @Input() decimals = 0;

  validate(control: AbstractControl): ValidationErrors | null {
    return priceValidator(this.decimals)(control);
  }
}
