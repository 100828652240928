<div class="modal-header">
  <h6 class="modal-title">{{ title }}</h6>
  <div class="wizard-close" (click)="close()">
    <i class="icon icon-cancel"></i>
  </div>
</div>
<div class="modal-body">
  <div>
    <span>{{ msg }}</span>
    <div *ngIf="name">{{ type }}: {{ name }}</div>
    <div *ngIf="describe" [innerHTML]="describe">{{ describe }}</div>
  </div>
</div>
<div class="modal-footer">
  <button [disabled]="isLoading" type="button" class="btn btn-link link" (click)="close()" i18n="@@btnCancel">
    {{ cancelLabel }}
  </button>

  <app-submit-btn [loading]="isLoading" [disabled]="isLoading" (btnClick)="confirm()">
    {{confirmLabel}}
  </app-submit-btn>
</div>
