
export interface SecurityGroupRule {
  id: number;
  direction: string;
  protocol?: number;
  fromPort?: number;
  toPort?: number;
  icmpType?: number;
  icmpCode?: number;
  ipRange?: string;
  remoteSecurityGroup?: {
    id: number;
    name: string;
  };
}

export interface CreateSecurityGroupRule {
  direction: string;
  protocol: number;
  fromPort?: number;
  toPort?: number;
  icmpType?: number;
  icmpCode?: number;
  ipRange?: string;
  remoteSecurityGroupId?: number;
}

export interface UpdateSecurityGroupRule {
  direction: string;
  protocol: number;
  fromPort?: number;
  toPort?: number;
  icmpType?: number;
  icmpCode?: number;
  ipRange?: string;
  remoteSecurityGroupId?: number;
}
