import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Product, ProductItems, flattenGroupedProducts, groupByProductCategory } from 'app/entity/common/product.model';
import { environment } from 'environments/environment';

import { ValueAccessorBase } from '../../utils/value-accessor';
import { ProductItem } from 'app/entity/common';

export interface ProductFilter {
  isAvailable(product: Product): boolean;
  canBeSelected?(product: Product): boolean;
  isOutOfStock?(product: Product): boolean;
}

@Component({
  selector: 'myflow-product-cards',
  templateUrl: './product-cards.component.html',
  styleUrls: [ './product-cards.component.scss' ],
  // eslint-disable-next-line no-use-before-define
  providers: [ ValueAccessorBase.createProviderFor(ProductCardsComponent) ],
})
export class ProductCardsComponent extends ValueAccessorBase<Product> implements OnInit, OnChanges {
  @ViewChild('productList', { static: true }) productListComponent: TemplateRef<unknown>;
  @ContentChild('icon') icon: TemplateRef<unknown>;

  @Input() isCluster: boolean;
  @Input() isWorker = true;
  @Input() smallTitle = false;
  @Input() reverseCategories = false;
  @Input() multiple: boolean;
  @Input() products: Product[];
  @Input() productFilter: ProductFilter;
  @Input() productItemOrder: string[];

  @Output() notify = new EventEmitter<Product>();

  categorizedProducts: Product[][];

  selectedProducts: Product[] = [];

  productMin: number;
  productMax: number;

  ngOnInit(): void {
    this.productMin = this.isWorker ? environment.cluster.products.min : 1;
    this.productMax = this.isWorker ? environment.cluster.products.max : 1;
  }

  cast(product: any): Product {
    return product;
  }

  isSelectable(product: Product): boolean {
    if (this.productFilter && this.productFilter.canBeSelected) {
      return this.productFilter.canBeSelected(product);
    }
    return true;
  }

  productTitle(product: Product): string {
    if (!this.isSelectable(product)) {
      return 'Not selectable, due to lack of resources from the chosen image.';
    } else if (this.isOutOfStock(product)) {
      return 'This product is currently not available at the selected location.';
    }
    return product.name;
  }

  isOutOfStock(product: Product): boolean {
    if (this.productFilter && this.productFilter.isOutOfStock) {
      return this.productFilter.isOutOfStock(product);
    }
    return false;
  }

  notifyMe(product: Product): void {
    this.notify.emit(product);
  }

  filterProducts(product: Product): boolean {
    return this.productFilter ? this.productFilter.isAvailable(product) : true;
  }

  selectProduct(product: Product): void {
    if (this.isSelectable(product) && !this.isOutOfStock(product) && !this.isSelected(product.id)) {
      if (this.value && this.value.entity) {
        product.entity = this.value.entity;
      }
      this.value = product;
    }
  }

  isSelected(id: number): boolean {
    return this.multiple ? this.selectedProducts.findIndex(selected => selected.id === id) > -1 : (this.value && this.value.id === id);
  }

  onCount(_id: number, increase = true): void {
    if (increase && this.value.entity < this.productMax) {
      this.value.entity++;
    } else if (!increase && this.value.entity > this.productMin) {
      this.value.entity--;
    }

    // Need to rewrite value for ngModelChanged event
    this.value = {
      ...this.value,
      entity: this.value.entity,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'products') && this.products) {
      this.regroupProducts();
    }
  }

  regroupProducts(): void {
    const grouped = this.products
      .filter(prod => this.filterProducts(prod))
      .reduce(groupByProductCategory, {});

    this.categorizedProducts = flattenGroupedProducts(grouped);

    if (this.reverseCategories) {
      this.categorizedProducts = this.categorizedProducts.reverse();
    }
  }

  getSortedProductItems(product: Product): ProductItem[] {
    if (this.productItemOrder) {
      return product.items.slice().sort((a: ProductItem, b: ProductItem) => {
        return this.productItemOrder.indexOf(a.name as ProductItems) - this.productItemOrder.indexOf(b.name as ProductItems);
      });
    }
    return product.items;
  }

  getProcessorProductItemName(): string {
    return ProductItems.PROCESSOR;
  }
}
