import { Injectable } from '@angular/core';
import { CreateElasticIp, ElasticIp } from 'app/entity/mbm-networking';
import { ApiService } from 'app/shared/services/api';
import { httpBareMetalDeviceElasticIp, httpBareMetalElasticIps } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ElasticIpDataService extends GenericDataService<ElasticIp, CreateElasticIp> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpBareMetalElasticIps, apiService);
  }

  detachElasticIp(context: number[], id: number): Observable<void> {
    return this.apiService.delete(httpBareMetalDeviceElasticIp(context[0], id));
  }
}
