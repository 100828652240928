import { Injectable } from "@angular/core";
import { ModuleSubscription } from "app/entity/infrastructure";
import { ApiService } from "app/shared/services/api";
import { httpAdminOrganizationModuleSubscriptions } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";

@Injectable({
  providedIn: 'root',
})
export class OrganizationModuleSubscriptionDataService extends GenericDataService<ModuleSubscription> {
  constructor (
    apiService: ApiService,
  ) {
    super(httpAdminOrganizationModuleSubscriptions, apiService);
  }
}
