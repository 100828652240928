import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentMethodModel } from 'app/entity/billing/payment-method.model';
import { OrganizationService } from 'app/organization/services/organization';
import { OrganizationActions } from 'app/store/actions';
import * as fromRoot from 'app/store/reducers';
import { ToastrService } from 'ngx-toastr';

import { DialogsService } from 'app/shared/services/dialogs';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'myflow-credit-card',
  templateUrl: './credit-cards.component.html',
  styleUrls: [ './credit-cards.component.scss' ],
})
export class CreditCardsComponent {
  @Input() paymentMethod: PaymentMethodModel;
  @Input() disabled: boolean;
  @Input() transparent: boolean;

  constructor(
    private organizationService: OrganizationService,
    private dialogsService: DialogsService,
    private toastrService: ToastrService,
    private store: Store<fromRoot.State>,
  ) {
  }

  delete(): void {
    this.dialogsService.confirm({
      data: 'payment method',
      action: 'remove',
      title: 'Delete payment method',
      name: `**** **** **** ${this.paymentMethod.details ? this.paymentMethod.details.last4 : '0000'}`,
    }).subscribe(data => {
      if (data) {
        lastValueFrom(
          this.organizationService.deleteOrganizationPaymentMethod(this.paymentMethod.id),
        )
        .then(() => {
          this.toastrService.success('Payment method has been deleted');
          this.store.dispatch(OrganizationActions.getOrganizationPayments());
          this.dialogsService.closeModal();
        });
      }
    });
  }
}
