import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupportPlan } from 'app/entity/support';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Plan } from './plan';
import { PlanDataService } from './plan.data';

const availability = 'Availability';
const responseTime ='Response time';
const channel = 'Channel';
const caseSeverity = 'Case severity';
const extras = 'Extras';

export const businessPlanId = 59;
export const firstPlanId = 60;
export const devopsPlanId = 61;

@Injectable()
export class PlanService extends GenericService<SupportPlan> {
  private plans: Plan[] = [
    {
      id: undefined,
      name: 'Economy',
      price: 0,
      description: `Included and good enough for non-critical environments
      in which traditional severities and response times aren't needed.`,
      items: [
        { name: availability, description: '24x7' },
        { name: responseTime, description: 'Best effort' },
        { name: channel, description: 'Ticketing System' },
      ],
    },
    {
      id: businessPlanId,
      name: 'Business',
      price: 500,
      description: 'Business Support is designed for clients with business-critical environments',
      items: [
        { name: availability, description: '24x7' },
        { name: responseTime, description: 'Starting at < 2h' },
        { name: channel, description: 'Ticketing System' },
        { name: caseSeverity, description: 'Low, Normal, Critical' },
      ],
    },
    {
      id: firstPlanId,
      name: 'First',
      price: 5000,
      description: `First Support is designed for clients that operate business-critical 24×7 environments,
      which require highest priority of cases handling and a dedicated Technical Account Manager`,
      items: [
        { name: availability, description: '24x7' },
        { name: responseTime, description: 'Starting at < 30min' },
        { name: channel, description: 'Ticketing System, Phone' },
        { name: caseSeverity, description: 'Low, Normal, Critical' },
        { name: extras, description: 'Dedicated Technical Account Manager' },
      ],
    },
  ];

  constructor(
    store: Store<EntityCache>,
    private planDataService: PlanDataService,
  ) {
    super('Plan', store, planDataService);
  }

  /**
   * Returns to display plans, only includes the following: Economy, Business and First
   * Plans which aren't active and can't be ugpraded to (e.g. Business is active -> Economy), are not included
   */
  getPlans(): Observable<Plan[]> {
    return super.entities$.pipe(
      map(entities => {
        let activePlanIndex = -1;

        // Reset the plans active state to false
        this.plans.forEach((_plan, index) => {
          this.plans[index].active = false;
        });

        // Iterate through each plan and check if a corresponding entity can be found (meaning the plan is active)
        this.plans.forEach((plan, index) => {
          entities.forEach((entity) => {
            if(entity.preset.id === plan.id) {
              this.plans[index].active = true;
              activePlanIndex = index;
            }
          });
        });

        // If no plan is active, economy is set as active
        if(activePlanIndex === -1) {
          this.plans[0].active = true;
          activePlanIndex = 0;
        }

        // Remove all plans, which can't be upgraded to and aren't active
        return this.plans.filter((_plan, index) => index >= activePlanIndex);
      }),
    );
  }

  /**
   * Checks if a plan with an id is active
   *
   * @param id The id of the plan to check
   * @returns Is the plan active (observable)
   */
  isPlanActive(id: number): Observable<boolean> {
    return super.entities$.pipe(
      map((entities) => entities.filter((entity) => entity.preset.id === id).length > 0),
    );
  }
}
