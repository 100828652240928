import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePassword',
})
export class HidePasswordPipe implements PipeTransform {
  transform(password: string): string {
    return '*'.repeat(password.length);
  }
}
