import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateSnapshot, Snapshot, UpdateSnapshot } from 'app/entity/compute-instances';

import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { VolumeSnapshotDataService } from 'app/compute/store/volume-snapshot/volume-snapshot.data';

@Injectable()
export class VolumeSnapshotService extends GenericService<Snapshot, CreateSnapshot, UpdateSnapshot> {
  constructor(
    store: Store<EntityCache>,
    private snapshotsDataService: VolumeSnapshotDataService,
  ) {
    super('Snapshot', store, snapshotsDataService);
  }

  revertVolume(id: number, data: { snapshotId: number }): Observable<void> {
    return this.snapshotsDataService.revert(id, data).pipe(
      tap(() => {
        this.getById(data.snapshotId).pipe(
          first(),
          switchMap(snapshot =>
            this.filteredEntities$.pipe(
              first(),
              map(snapshots =>
                snapshots.filter((item: Snapshot) => new Date(item.createdAt) > new Date(snapshot.createdAt)),
              ),
            ),
          ),
          tap(snapshots => {
            snapshots.forEach(snapshot => {
              this.removeOneFromCache(snapshot.id);
            });
          }),
        ).subscribe();
      }),
    );
  }
}
