import { Injectable } from '@angular/core';
import { ObjectStorageAccount } from 'app/entity';
import { ApiService } from 'app/shared/services/api';
import {
  httpObjStorageAccountCredentials,
  httpObjStorageAccounts,
} from 'app/shared/utils';

import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObjectStorageAccountsDataService extends GenericDataService<ObjectStorageAccount> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpObjStorageAccounts, apiService);
  }

  /**
   * Rotate Object Storage Instances Credentials
   */
  rotateCredentials(context: number[], id: number): Observable<ObjectStorageAccount> {
    return this.apiService.post(httpObjStorageAccountCredentials(context[0], id));
  }
}
