import { Injectable } from '@angular/core';
import { Instance } from 'app/entity/compute-instances';
import { ApiService } from 'app/shared/services/api';
import { httpComputedNetworkInstances } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class PrivateNetworkInstanceDataService extends GenericDataService<Instance> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedNetworkInstances, apiService);
  }
}
