import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnvironmentService } from 'app/app-engine/store/environment';
import { DeviceService } from 'app/baremetal/store/device';
import { InstanceService } from 'app/compute/store/instance';
import { VolumeService } from 'app/compute/store/volume';
import { VolumeSnapshotService } from 'app/compute/store/volume-snapshot/volume-snapshot.service';
import { Environment } from 'app/entity/app-engine';
import { Instance, Snapshot, Volume } from 'app/entity/compute-instances';
import { Cluster, ClusterNode } from 'app/entity/compute-kubernetes';
import {
  LoadBalancer,
  LoadBalancerPool,
  LoadBalancerPoolMember,
  VirtualPrivateNetwork,
} from 'app/entity/compute-networking';
import { Device } from 'app/entity/mbm-devices';
import { ClusterNodeService } from 'app/kubernetes/store/cluster-node';
import { ClusterService } from 'app/kubernetes/store/cluster';
import { LoadBalancerService } from 'app/networking/store/load-balancer';
import { LoadBalancerPoolService } from 'app/networking/store/load-balancer-pool';
import { LoadBalancerPoolMemberService } from 'app/networking/store/load-balancer-pool-member';
import { AppEngineEnvironmentMessages } from 'app/shared/services/messages/app-engine-environment.messages';
import { BalanceEntityHandler } from 'app/shared/services/messages/balance.handler';
import { ComputeMessages } from 'app/shared/services/messages/compute.messages';
import { EntitySseConnection, MessageHandler } from 'app/shared/services/messages/entity-connection';
import { KubernetesClusterMessages } from 'app/shared/services/messages/kubernetes-cluster.messages';
import { KubernetesNodeMessages } from 'app/shared/services/messages/kubernetes-node.messages';
import { MacBareMetalMessages } from 'app/shared/services/messages/mac-bare-metal.messages';
import { MessageEntityHandler } from 'app/shared/services/messages/handler/message.handler';
import { NgrxEntityHandler } from 'app/shared/services/messages/handler/ngrx.handler';
import { OrganizationEntityHandler } from 'app/shared/services/messages/organization.handler';
import { SnapshotMessages } from 'app/shared/services/messages/snapshot.messages';
import { Message, SseConnection } from 'app/shared/services/sse/connection';
import { LoadBalancerMessages } from './load-balancer.messages';
import { SseService } from 'app/shared/services/sse/sse.service';
import {
  sseBareMetalDevices,
  sseComputeInstances,
  sseComputeVolumes,
  sseKubernetesClusters,
  sseKubernetesNodes,
  sseLoadBalancer,
  sseLoadBalancerPool,
  sseLoadBalancerPoolMember,
  sseOrganizationBalance,
  sseAppEngineEnvironment,
  sseComputeSnapshots,
  sseOrganization,
  sseObjectStorageBucket,
  sseSupportTicket,
  sseSupportTicketComment, sseComputeConnections,
} from 'app/shared/utils';
import * as fromRoot from 'app/store/reducers';
import { ToastrService } from 'ngx-toastr';
import { NgrxCollectionHandler } from './handler/ngrx-collection.handler';
import { InstanceVolumeService } from '../../../compute/store/instance-volume';
import { ObjectStorageBucket } from '../../../entity';
import {
  ObjectStorageBucketService,
} from '../../../object-storage/services/object-storage-bucket/object-storage-bucket.service';
import { ObjectStorageBucketMessages } from './object-storage-bucket.messages';
import { TicketService } from '../../../tickets/store/tickets';
import { Ticket, TicketComment } from '../../../entity/tickets';
import { TicketCommentNgrxEntityHandler } from './handler/custom/ticket-comment.handler';
import { SupportTicketMessages } from './support-ticket.messages';
import { VirtualPrivateNetworkService } from '../../../networking/store/virtual-private-network';
import { ConnectionMessages } from './connection.messages';
import { Integration, Runner, Subscription, Image } from 'app/entity/ci-engine';
import { SubscriptionRunnerService } from 'app/ci-engine/store/subscription-runner';
import { IntegrationRunnerService } from 'app/ci-engine/store/integration-runner';
import { sseCiEngineIntegration, sseCiEngineRunner, sseCiEngineSubscription, sseCiEngineImage } from 'app/shared/utils/endpoints/ci-engine.http';
import { SubscriptionService } from 'app/ci-engine/store/subscription';
import { SubscriptionIntegrationService } from 'app/ci-engine/store/subscription-integration';
import { IntegrationService } from 'app/ci-engine/store/integration';
import { SubscriptionImageService } from 'app/ci-engine/store/subscription-image';
import { NgrxRerenderHandler } from './handler/ngrx-rerender.handler';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private readonly topics = [
    this.sseService.buildTopic(sseComputeInstances()),
    this.sseService.buildTopic(sseComputeSnapshots()),
    this.sseService.buildTopic(sseComputeVolumes()),
    this.sseService.buildTopic(sseComputeConnections()),
    this.sseService.buildTopic(sseBareMetalDevices()),
    this.sseService.buildTopic(sseOrganization()),
    this.sseService.buildTopic(sseOrganizationBalance()),
    this.sseService.buildTopic(sseKubernetesClusters()),
    this.sseService.buildTopic(sseKubernetesNodes()),
    this.sseService.buildTopic(sseLoadBalancer()),
    this.sseService.buildTopic(sseLoadBalancerPool()),
    this.sseService.buildTopic(sseLoadBalancerPoolMember()),
    this.sseService.buildTopic(sseAppEngineEnvironment()),
    this.sseService.buildTopic(sseObjectStorageBucket()),
    this.sseService.buildTopic(sseSupportTicket()),
    this.sseService.buildTopic(sseSupportTicketComment()),
    this.sseService.buildTopic(sseCiEngineSubscription()),
    this.sseService.buildTopic(sseCiEngineIntegration()),
    this.sseService.buildTopic(sseCiEngineRunner()),
    this.sseService.buildTopic(sseCiEngineImage()),
  ];

  private connection: SseConnection;

  constructor(
    private sseService: SseService,
    private store: Store<fromRoot.State>,
    private toastrService: ToastrService,
    private instanceService: InstanceService,
    private volumeService: VolumeService,
    private instanceVolumeService: InstanceVolumeService,
    private vpnService: VirtualPrivateNetworkService,
    private deviceService: DeviceService,
    private clusterService: ClusterService,
    private clusterNodeService: ClusterNodeService,
    private loadBalancerService: LoadBalancerService,
    private loadBalancerPoolService: LoadBalancerPoolService,
    private loadBalancerPoolMemberService: LoadBalancerPoolMemberService,
    private accountEnvironmentService: EnvironmentService,
    private snapshotService: VolumeSnapshotService,
    private objectStorageBucketService: ObjectStorageBucketService,
    private ticketService: TicketService,
    private subscriptionService: SubscriptionService,
    private subscriptionIntegrationService: SubscriptionIntegrationService,
    private integrationService: IntegrationService,
    private subscriptionRunnerService: SubscriptionRunnerService,
    private integrationRunnerService: IntegrationRunnerService,
    private subscriptionImageService: SubscriptionImageService,
  ) {
  }

  async connect(): Promise<void> {
    this.connection = await this.sseService.open(this.topics);

    const entityConnection = new EntitySseConnection(this.connection);
    entityConnection.setHandlerFor('compute-instance', this.createComputeInstanceHandler());
    entityConnection.setHandlerFor('compute-snapshot', this.createComputeSnapshotsHandler());
    entityConnection.setHandlerFor('compute-volume', this.createComputeVolumeHandler());
    entityConnection.setHandlerFor('compute-connection', this.createComputeConnectionHandler());
    entityConnection.setHandlerFor('balance', new BalanceEntityHandler(this.store));
    entityConnection.setHandlerFor('organization', new OrganizationEntityHandler(this.store));
    entityConnection.setHandlerFor('mac-bare-metal-device', this.createMacBareMetalDeviceHandler());
    entityConnection.setHandlerFor('kubernetes-cluster', this.createKubernetesClusterHandler());
    entityConnection.setHandlerFor('kubernetes-node', this.createKubernetesClusterNodeHandler());
    entityConnection.setHandlerFor(
      'compute-load-balancer',
      this.createLoadBalancerHandler(),
    );
    entityConnection.setHandlerFor(
      'compute-load-balancer-pool',
      new NgrxEntityHandler(this.loadBalancerPoolService, MessagesService.mapLoadBalancerPool),
    );
    entityConnection.setHandlerFor(
      'compute-load-balancer-member',
      new NgrxEntityHandler(this.loadBalancerPoolMemberService, MessagesService.mapLoadBalancerPoolMember),
    );
    entityConnection.setHandlerFor('app-engine-environment', this.createAppEngineEnvironmentsHandler());
    entityConnection.setHandlerFor('object-storage-bucket', this.createObjectStorageBucketHandler());
    entityConnection.setHandlerFor('support-ticket', this.createSupportTicketHandler());
    entityConnection.setHandlerFor('support-comment', this.createSupportTicketCommentHandler());
    entityConnection.setHandlerFor('ci-engine-subscription', this.createCiEngineSubscriptionHandler());
    entityConnection.setHandlerFor('ci-engine-integration', this.createCiEngineIntegrationHandler());
    entityConnection.setHandlerFor('ci-engine-runner', this.createCiEngineRunnerHandler());
    entityConnection.setHandlerFor('ci-engine-image', this.createCiEngineImageHandler());
  }

  close(): void {
    this.connection.close();
  }

  private createComputeInstanceHandler(): MessageHandler<Instance> {
    const base = new NgrxEntityHandler<Instance>(this.instanceService);
    const messages = new ComputeMessages(this.instanceService);

    return new MessageEntityHandler<Instance>(this.toastrService, base, messages);
  }

  private createComputeSnapshotsHandler(): MessageHandler<Snapshot> {
    const base = new NgrxEntityHandler<Snapshot>(this.snapshotService);
    const messages = new SnapshotMessages();

    return new MessageEntityHandler<Snapshot>(this.toastrService, base, messages);
  }

  private createComputeVolumeHandler(): MessageHandler<Volume> {
    return new NgrxCollectionHandler([
      new NgrxEntityHandler<Volume>(this.volumeService),
      new NgrxEntityHandler<Volume>(this.instanceVolumeService, MessagesService.mapInstanceVolume),
    ]);
  }

  private createComputeConnectionHandler(): MessageHandler<VirtualPrivateNetwork> {
    const base = new NgrxEntityHandler<VirtualPrivateNetwork>(this.vpnService);
    const messages = new ConnectionMessages();

    return new MessageEntityHandler<VirtualPrivateNetwork>(this.toastrService, base, messages);
  }

  private createMacBareMetalDeviceHandler(): MessageHandler<Device> {
    const base = new NgrxEntityHandler<Device>(this.deviceService);
    const messages = new MacBareMetalMessages();

    return new MessageEntityHandler<Device>(this.toastrService, base, messages);
  }

  private createKubernetesClusterHandler(): MessageHandler<Cluster> {
    const base = new NgrxEntityHandler<Cluster>(this.clusterService);
    const messages = new KubernetesClusterMessages();

    return new MessageEntityHandler<Cluster>(this.toastrService, base, messages);
  }

  private createKubernetesClusterNodeHandler(): MessageHandler<ClusterNode> {
    const base = new NgrxEntityHandler<ClusterNode>(this.clusterNodeService, MessagesService.mapClusterNode);
    const messages = new KubernetesNodeMessages();

    return new MessageEntityHandler<ClusterNode>(this.toastrService, base, messages);
  }

  private createLoadBalancerHandler(): MessageHandler<LoadBalancer> {
    const base = new NgrxEntityHandler<LoadBalancer>(this.loadBalancerService);
    const messages = new LoadBalancerMessages();

    return new MessageEntityHandler<LoadBalancer>(this.toastrService, base, messages);
  }

  private createAppEngineEnvironmentsHandler(): MessageHandler<Environment> {
    const base = new NgrxEntityHandler<Environment>(this.accountEnvironmentService, MessagesService.mapEnvironment);
    const messages = new AppEngineEnvironmentMessages();

    return new MessageEntityHandler<Environment>(this.toastrService, base, messages);
  }

  private createObjectStorageBucketHandler(): MessageHandler<ObjectStorageBucket> {
    const base = new NgrxEntityHandler<ObjectStorageBucket>(this.objectStorageBucketService, MessagesService.mapBucket);
    const messages = new ObjectStorageBucketMessages();

    return new MessageEntityHandler<ObjectStorageBucket>(this.toastrService, base, messages);
  }

  private createSupportTicketHandler(): MessageHandler<Ticket> {
    const base = new NgrxEntityHandler<Ticket>(this.ticketService, null);
    const messages = new SupportTicketMessages();

    return new MessageEntityHandler<Ticket>(this.toastrService, base, messages);
  }

  private createSupportTicketCommentHandler(): MessageHandler<TicketComment> {
    const base = new TicketCommentNgrxEntityHandler(this.ticketService, MessagesService.mapComment);

    return new MessageEntityHandler<TicketComment>(this.toastrService, base, null);
  }

  private createCiEngineSubscriptionHandler(): MessageHandler<Subscription> {
    return new NgrxEntityHandler<Subscription>(this.subscriptionService);
  }

  private createCiEngineIntegrationHandler(): MessageHandler<Integration> {
    return new NgrxCollectionHandler([
      new NgrxEntityHandler<Integration>(this.subscriptionIntegrationService, MessagesService.mapSubscriptionIntegration),
      new NgrxEntityHandler<Integration>(this.integrationService),
    ]);
  }

  private createCiEngineRunnerHandler(): MessageHandler<Runner> {
    return new NgrxCollectionHandler([
      new NgrxRerenderHandler<Runner>(this.subscriptionRunnerService, MessagesService.mapRunner),
      new NgrxRerenderHandler<Runner>(this.integrationRunnerService, MessagesService.mapRunner),

    ]);
  }

  private createCiEngineImageHandler(): MessageHandler<Image> {
    return new NgrxCollectionHandler([
      new NgrxEntityHandler<Image>(this.subscriptionImageService, MessagesService.mapImage),
    ]);
  }

  private static mapInstanceVolume(msg: Message<Volume>): [ number[], Volume ] {
    const volume = msg.data;
    let context = [];
    if (volume.instance) {
      context = [ volume.instance.id ];
    }

    return [
      context,
      volume,
    ];
  }

  private static mapLoadBalancerPool(msg: Message<LoadBalancerPool>): [ number[], LoadBalancerPool ] {
    const regex = /\/v4\/compute\/load-balancers\/(\d+)\/balancing-pools\/\d+$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 1),
      msg.data,
    ];
  }

  private static mapLoadBalancerPoolMember(msg: Message<LoadBalancerPoolMember>): [ number[], LoadBalancerPoolMember ] {
    const regex = /\/v4\/compute\/load-balancers\/(\d+)\/balancing-pools\/(\d+)\/members\/\d+$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 2),
      msg.data,
    ];
  }

  private static mapClusterNode(msg: Message<ClusterNode>): [ number[], ClusterNode ] {
    const regex = /\/v4\/kubernetes\/clusters\/(\d+)\/nodes\/(\d+)$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 1),
      msg.data,
    ];
  }

  private static mapEnvironment(msg: Message<Environment>): [ number[], Environment ] {
    const regex = /\/v4\/app-engine\/accounts\/(\d+)\/environments\/(\d+)$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 1),
      msg.data,
    ];
  }

  private static mapBucket(msg: Message<ObjectStorageBucket>): [ number[], ObjectStorageBucket ] {
    const regex = /\/v4\/object-storage\/instances\/(\d+)\/buckets\/(\d+)$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 1),
      msg.data,
    ];
  }

  private static mapComment(msg: Message<TicketComment>): [ number[], TicketComment ] {
    const regex = /\/v4\/organization\/support\/tickets\/(\d+)\/comments\/(\d+)$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 1),
      msg.data,
    ];
  }

  private static mapSubscriptionIntegration(msg: Message<Integration>): [ number[], Integration ] {
    const regex = /\/v4\/ci-engine\/subscription\/(\d+)\/integration\/(\d+)$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 1),
      msg.data,
    ];
  }

  private static mapRunner(msg: Message<Runner>): number {
    const regex = /\/v4\/ci-engine\/subscription\/(\d+)\/runner\/(\d+)$/;
    const parts = regex.exec(msg.ref);
    const context = parts.map(part => +part).splice(1, 1);
    return context[0];
  }

  private static mapImage(msg: Message<Image>): [ number[], Image ] {
    const regex = /\/v4\/ci-engine\/subscription\/(\d+)\/image\/(\d+)$/;
    const parts = regex.exec(msg.ref);

    return [
      parts.map(part => +part).splice(1, 1),
      msg.data,
    ];
  }
}
