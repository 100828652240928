import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogConfirmComponent } from 'app/shared/components/dialog-confirm/dialog-confirm.component';
import { DialogCriticalComponent } from 'app/shared/components/dialog-critical/dialog-critical.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';

import { ConfirmService } from '../confirm.service';
import { DialogFullComponent } from 'app/shared/components/dialog-full/dialog-full.component';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  modalReference: NgbModalRef | null;
  modalOptions: NgbModalOptions = {
    keyboard: false,
  };

  private destroyed$ = new Subject();

  constructor (
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private confirmService: ConfirmService,
  ) { }

  get confirmSpinner(): Observable<any> {
    return this.confirmService.spinnerOn
      .pipe(
        takeUntil(this.destroyed$),
      );
  }

  open(instances?: any, fullscreen = false): Observable<any> {
    const options = {
      modalDialogClass: '',
    };

    if (fullscreen) {
      options.modalDialogClass = 'fullscreen';
    }

    this.modalReference = this.modalService.open(fullscreen ? DialogFullComponent : DialogComponent, { ...this.modalOptions, ...options });

    if (instances) {
      for (const key of Object.keys(instances)) {
        this.modalReference.componentInstance[key] = instances[key];
      }
    }

    this.modalReference.result.then(() => {
      this.destroyed$.next(true);
      this.destroyed$.complete();
      this.modalReference = null;
    });

    this.modalReference.componentInstance.closing.pipe(
      takeUntil(this.destroyed$),
      tap((data: boolean) => {
        if (data) {
          this.confirm({ action: 'close' }).subscribe(data => {
            if (data) { // if we get confirm action to close the Modal Window
              this.closeModal();
            }
          });
        }
      }),
    ).subscribe();

    return this.modalReference.componentInstance.out.pipe(
      takeUntil(this.destroyed$),
    );
  }

  closeModal(reason?: string|boolean): void {
    if (reason) {
      this.toastrService.success(reason as string);
    }
    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  stopLoading(): void {
    if (this.modalReference) {
      this.modalReference.componentInstance.loading$.next(false);
    }
  }

  stopConfirmLoading(): void {
    this.confirmService.updateModalSpinner(false);
    this.stopLoading();
  }

  setFields(data: any): void {
    try {
      if (this.modalReference) {
        this.modalReference.componentInstance.fields = data;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error', e);
    }
  }

  /**
   * Opens the DialogConfirmComponent with the provided parameters.
   * To catch the confirm action use the confirmSpinner returned from the function or the confirmSpinner() function
   * @param instances All needed parameters for the DialogConfirmComponent
   * @param options Options for the modal
   * @returns confirmSpinner
   */
  confirm(instances?: any, options?: NgbModalOptions): Observable<any> {
    const modalOptions: NgbModalOptions = {
      ...this.modalOptions,
      ...options,
      windowClass: 'confirmModal',
    };
    this.modalReference = this.modalService.open(DialogConfirmComponent, modalOptions);

    if (instances) {
      for (const key of Object.keys(instances)) {
        this.modalReference.componentInstance[key] = instances[key];
      }
    }

    return this.confirmSpinner;
  }

  /**
   * Opens the DialogCriticalComponent with the provided parameters.
   * To catch the confirm action use the confirmSpinner returned from the function or the confirmSpinner() function
   * @param instances All needed parameters for the DialogCriticalComponent
   * @param options Options for the modal
   * @returns confirmSpinner
   */
  critical(instances?: any, options?: NgbModalOptions): Observable<any> {
    const modalOptions: NgbModalOptions = {
      ...this.modalOptions,
      ...options,
    };
    this.modalReference = this.modalService.open(DialogCriticalComponent, modalOptions);

    if (instances) {
      for (const key of Object.keys(instances)) {
        this.modalReference.componentInstance[key] = instances[key];
      }
    }

    return this.confirmSpinner;
  }
}
