import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'myflow-dnd',
  templateUrl: './dnd.component.html',
  styleUrls: [ './dnd.component.scss' ],
})
export class DragAndDropComponent implements OnInit {
  @ViewChild('fi') fileInput: any;

  @Input() fileList: Set<File> = new Set();
  @Input() extensions: string[] = [];
  @Input() component: string;
  @Input() multiple = true;
  @Input() progress: any[] = [];
  @Input() showFilesList = true;
  @Input() info: string;
  @Output() fileEvent = new EventEmitter<any>();

  errorMsg = '';
  messages = {
    quantityLimit: 'To many files. Maximum size for attachment 10 files',
    onlyOne: 'Only one file can be uploaded. Please try again',
    invalidFile: 'The uploaded file is invalid. Please try again with a valid file',
    invalidSize: (size: number): string => `File is too large, file size ${Math.round(size * 0.0001) / 100}MB (allowed less then 5MB)`,
  };
  invalidFiles: Set<File> = new Set();

  componentNames = [ 'keypairs-add', 'input-file' ];

  @Input() private form: UntypedFormGroup;
  @Input() private customMessages: {quantityLimit?: string; onlyOne?: string; invalidFile?: string};

  ngOnInit(): void {
    if (this.customMessages) {
      if (this.customMessages.quantityLimit) {
        this.messages.quantityLimit = this.customMessages.quantityLimit;
      }
      if (this.customMessages.onlyOne) {
        this.messages.onlyOne = this.customMessages.onlyOne;
      }
      if (this.customMessages.invalidFile) {
        this.messages.invalidFile = this.customMessages.invalidFile;
      }
    }
  }

  choose(): void {
    this.fileInput.nativeElement.click();
  }

  onFilesChange(fileList: File[]): void {
    this.errorMsg = '';
    this.invalidFiles.clear();

    if (!this.validateFiles(fileList)) {
      return;
    }

    const list: Set<File> = new Set();
    for (const key in fileList) {
      if (!isNaN(parseInt(key, 10))) {
        // files size checking
        if (fileList[key].size * 0.000001 > 5) {
          this.errorMsg = this.messages.invalidSize(fileList[key].size);
          this.invalidFiles.add(fileList[key]);
          break;
        }

        // files extensions checking
        const ext = fileList[key].name.split('.')[fileList[key].name.split('.').length - 1];
        if (this.extensions.length && this.extensions.lastIndexOf(ext) === -1) {
          this.invalidFiles.add(fileList[key]);
          this.errorMsg = this.messages.invalidFile;

          if (this.componentNames.includes(this.component)) {
            this.fileList.clear();
          }

          break;
        }

        this.fileList.add(fileList[key]);
        list.add(fileList[key]);
      }
    }

    this.emitFiles(list, fileList);
  }

  validateFiles(fileList: File[]): boolean {
    if (this.multiple && (this.fileList.size > 10 || fileList.length > 10)) {
      this.errorMsg = this.messages.quantityLimit;
      return false;
    }
    if (!this.multiple && (this.fileList.size > 1 || fileList.length > 1)) {
      this.errorMsg = this.messages.onlyOne;
      return false;
    }

    return true;
  }

  emitFiles(list: Set<File>, fileList: File[]): void {
    if (this.componentNames.includes(this.component)) {
      if (this.invalidFiles.size === 0 && fileList.length) {
        // rewriting the files list by a new file
        this.fileList = new Set(fileList);

        // getting a first file from the files list
        const fileListIterator = this.fileList.values();
        const fileVal = fileListIterator.next().value;

        // the file is rendering
        const reader = new FileReader();
        reader.readAsText(fileVal);
        reader.onload = (): void => {
          const text = reader.result;
          this.fileEvent.emit({ text, name: fileVal.name, disable: true });
        };
      }
    } else {
      this.fileEvent.emit(list);
    }
  }

  onFileInvalids(fileList: File[]): void {
    this.invalidFiles = new Set(fileList);

    if (this.invalidFiles.size) {
      this.errorMsg = this.messages.invalidFile;
      if (this.componentNames.includes(this.component)) {
        this.fileList.clear();
      }
    }
  }

  removeFile(index: number, file: File): void {
    if (this.component === 'ticket-details' || this.component === 'ticket-create') {
      const attachments = this.form.controls.attachments.value;
      if(attachments !== null) {
        attachments.splice(index, 1);
      }
      this.form.controls.attachments.setValue(attachments);
    }
    if (this.componentNames.includes(this.component)) {
      this.fileEvent.emit({ text: '', disable: false });
    }
    this.fileList.delete(file);
  }
}
