import { Injectable } from '@angular/core';
import { CreateKeyPair, KeyPair } from 'app/entity/compute-instances';

import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

import { ApiService } from 'app/shared/services/api';

import {
  httpComputeKeypairs,
} from 'app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class KeyPairDataService extends GenericDataService<KeyPair, CreateKeyPair> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputeKeypairs, apiService);
  }
}
