<div class="toggle-container">
  <ng-container *ngFor="let plan of plans$ | async">
    <div class="toggle-area"  [ngClass]="{'active': plan.active}" (mouseleave)="t.close()" (mouseenter)="t.open()" >
      <ng-template #activeSupportPlanTooltip>
        Active support plan
      </ng-template>
      <ng-template #upgradeTooltip>
        Upgrade to {{ plan.name }} Support or <a href="https://doc.flow.swiss/platform/pricing/support" target="_blank" class="tooltip-link">learn more</a> about our Plans
      </ng-template>
      <div 
        [ngbTooltip]="plan.active ? activeSupportPlanTooltip : upgradeTooltip" 
        #t="ngbTooltip" 
        [autoClose]="false" 
        triggers="manual" 
        [tooltipClass]="plan.active ? 'tooltip-support-plan-toggle-active' : 'tooltip-support-plan-toggle-large'" 
        placement="top" 
        class="toggle-item-container"
        (click)="planClicked(plan)"
        >
        <i class="upgrade-icon icon-status-arrow" *ngIf="!plan.active"></i>
        <p>{{ plan.name }}</p>
      </div>
    </div>
  </ng-container>
</div>