import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { ObjectStorageAccount } from 'app/entity';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { ObjectStorageAccountsDataService } from './object-storage-accounts.data';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ObjectStorageAccountsService extends GenericService<ObjectStorageAccount, { name: string }> {
  constructor(
    store: Store<EntityCache>,
    private objectStorageAccountsDataService: ObjectStorageAccountsDataService,
  ) {
    super('ObjectStorageAccount', store, objectStorageAccountsDataService);
  }

  /**
   * Rotate Object Storage Account Credentials
   */
  rotateCredentials(id: number, context?: number[]): Observable<ObjectStorageAccount> {
    const ctx = context ?? this.getContext();

    return this.objectStorageAccountsDataService.rotateCredentials(ctx, id)
      .pipe(
        tap(instance => this.updateOneInCache(ctx, instance)),
      );
  }
}
