import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig } from 'app/shared/components/dynamic-form/models/field-config.interface';

@Injectable({
  providedIn: 'root',
})
export class EditProfileFormService {
  userControls(disableUsername: boolean): FieldConfig[] {
    return [
      {
        name: 'username',
        label: 'Email / Username',
        value: null,
        validation: [ Validators.required, Validators.email ],
        required: true,
        disabled: disableUsername,
      },
      {
        name: 'firstname',
        label: 'First Name',
        value: null,
        validation: [ Validators.required ],
        required: true,
      },
      {
        name: 'lastname',
        label: 'Last Name',
        value: null,
        validation: [ Validators.required ],
        required: true,
      },
      {
        name: 'phoneNumber',
        value: null,
        label: 'Phone Number',
        validation: [ Validators.minLength(8) ],
      },
    ];
  }
}
