import { Status } from 'app/entity/common/status.model';

export interface LoadBalancerPoolMember {
  id: number;
  name: string;
  address: string;
  port: number;
  status: Status;
}

export interface CreateLoadBalancerPoolMember {
  name: string;
  address: string;
  port: number;
}
