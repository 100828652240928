import { Injectable } from '@angular/core';
import { ImageOs } from 'app/entity/ci-engine';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { ImageOSDataService } from './image-os.data';

@Injectable()
export class ImageOSService extends GenericService<ImageOs> {
  constructor(
    store: Store<EntityCache>,
    private imageOSDataService: ImageOSDataService,
  ) {
    super('CiEngineImageOS', store, imageOSDataService);
  }
}
