import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { toastAnimation } from 'app/shared/utils/animations';

@Component({
  selector: 'app-toast-pending',
  templateUrl: './toast-pending.component.html',
  styleUrls: [ './toast-pending.component.scss' ],
  animations: [
    toastAnimation,
  ],
  // preserveWhitespaces: false
})
export class ToastPendingComponent extends Toast {
  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event): boolean {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
