import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { UpdateUser, UpdateUserNotes } from "app/entity/admin/user.model";
import { User } from "app/entity/infrastructure";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { Observable } from "rxjs";
import { UserDataService } from "./user.data";

@Injectable()
export class UserService extends GenericService<User, null, UpdateUser> {
  constructor(
    store: Store<EntityCache>,
    private userDataService: UserDataService,
  ) {
    super('AdminUser', store, userDataService);
  }

  updateNotes(id: number, data: UpdateUserNotes): Observable<User> {
    return this.userDataService.updateNotes(id, data);
  }

  resetPassword(email: string): Observable<void> {
    return this.userDataService.resetPassword(email);
  }

  resendActivationMail(id: number): Observable<void> {
    return this.userDataService.resendActivationMail(id);
  }

  impersonate(id: number): Observable<User> {
    return this.userDataService.impersonate(id);
  }
}
