import { Pipe, PipeTransform } from '@angular/core';
import { DurationPipe } from '../duration/duration.pipe';

@Pipe({
  name: 'timeBetween',
  pure: false,
})
export class TimeBetweenPipe implements PipeTransform {
  constructor(
    private durationPipe: DurationPipe,
  ) {

  }

  transform(from: string, to: string | undefined, fallback: Date): string {
    const fromMilli = new Date(from).getTime();
    const toMilli = to ? new Date(to).getTime() : fallback.getTime();

    const seconds = (toMilli - fromMilli) / 1000;

    return this.durationPipe.transform(seconds);
  }
}
