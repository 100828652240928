import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(seconds: number): string {
    const formats = [
      {
        suffix: 'h',
        divider: 3600,
      },
      {
        suffix: 'min',
        divider: 60,
      },
      {
        suffix: 'sec',
        divider: 1,
      },
    ];

    let result = '';

    formats.forEach(element => {
      if (seconds >= element.divider) {
        const amount = Math.floor(seconds / element.divider);
        seconds -= amount * element.divider;
        result += `${amount} ${element.suffix} `;
      }
    });

    return result.trim();
  }
}
