/* eslint-disable @typescript-eslint/no-empty-function */
import { LoadBalancer } from 'app/entity/compute-networking';
import { Status } from 'app/shared/services/messages/entity-connection';
import { MessageOptions } from 'app/shared/services/messages/handler/message.handler';
import { ToastrService } from 'ngx-toastr';

export class LoadBalancerMessages implements MessageOptions<LoadBalancer> {
  createMessageCallback(toastr: ToastrService, entity: LoadBalancer): void {
    toastr.success(`<b>Load Balancer:</b> Succeeded to create ${entity.name}`, '', { enableHtml: true });
  }

  updateMessageCallback(_toastr: ToastrService, _entity: LoadBalancer): void {}

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void {}

  deleteMessageCallback(_toastr: ToastrService, _entity: LoadBalancer): void {}
}
