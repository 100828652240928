import { AbstractControl, ValidationErrors } from '@angular/forms';

export const emailValidator = (control: AbstractControl): ValidationErrors | null => {
  const emailRegex = /[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i;
  const value = control.value;

  const result = emailRegex.test(value);

  if (result || !value) {
    return null;
  }
  return {
    'emailValidator': {
      valid: false,
    },
    'email': {
      valid: false,
    },
  };
};
