import { CaseConverterService } from 'app/shared/services/case-converter.service';
import { Message, SseConnection } from 'app/shared/services/sse/connection';
import { map } from 'rxjs/operators';

export interface Action {
  id: number;
  name: string;
  command: string;
  sorting: string;
}

export interface Status {
  id: number;
  key: string;
  name: string;
  actions?: Action[];
}

export interface MessageHandler<T> {
  create(msg: Message<T>): Promise<void> | void;

  update(msg: Message<T>): Promise<void> | void;

  status?(msg: Message<Status>): Promise<void> | void;

  delete(msg: Message<T>): Promise<void> | void;
}

export class EntitySseConnection {
  private readonly handlers: Map<string, MessageHandler<any>>;

  constructor(private connection: SseConnection) {
    this.handlers = new Map<string, MessageHandler<any>>();

    connection.on('create').pipe(
      map(message => CaseConverterService.convertKeys(message, CaseConverterService.snakeToCamel)),
    ).subscribe(message => this.onCreate(message));
    connection.on('update').pipe(
      map(message => CaseConverterService.convertKeys(message, CaseConverterService.snakeToCamel)),
    ).subscribe(message => this.onUpdate(message));
    connection.on('status').pipe(
      map(message => CaseConverterService.convertKeys(message, CaseConverterService.snakeToCamel)),
    ).subscribe(message => this.onStatus(message));
    connection.on('delete').pipe(
      map(message => CaseConverterService.convertKeys(message, CaseConverterService.snakeToCamel)),
    ).subscribe(message => this.onDelete(message));
  }

  setHandlerFor(type: string, handler: MessageHandler<any>): void {
    this.handlers[type] = handler;
  }

  private onCreate(message: Message<any>): void {
    const handler = this.handlers[message.type];
    if (!handler) {
      return;
    }

    handler.create(message);
  }

  private onUpdate(message: Message<any>): void {
    const handler = this.handlers[message.type];
    if (!handler) {
      return;
    }

    handler.update(message);
  }

  private onStatus(message: Message<any>): void {
    const handler = this.handlers[message.type];
    if (!handler || !('status' in handler)) {
      return;
    }

    handler.status(message);
  }

  private onDelete(message: Message<any>): void {
    const handler = this.handlers[message.type];
    if (!handler) {
      return;
    }

    handler.delete(message);
  }
}
