import { Injectable } from '@angular/core';
import { Runner } from 'app/entity/ci-engine';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { SubscriptionRunnerDataService } from './subscription-runner.data';

@Injectable()
export class SubscriptionRunnerService extends GenericService<Runner> {
  constructor(
    store: Store<EntityCache>,
    private subscriptionRunnerDataService: SubscriptionRunnerDataService,
  ) {
    super('SubscriptionRunner', store, subscriptionRunnerDataService);
  }

  setOptions(page?: number, perPage?: number, status?: 'active'|'inactive', orderValue?: string, orderDir?: 'asc'|'desc'|''): void {
    this.subscriptionRunnerDataService.setOptions(page, perPage, status, orderValue, orderDir);
  }

  getTotalAmount(): number {
    return this.subscriptionRunnerDataService.getTotalAmount();
  }
}
