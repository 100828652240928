<div class="side-by-side-control">
  <div
    class="side-by-side-item"
    *ngFor="let item of items; let index = index"
    (click)="writeValue(item)"
    [ngClass]="{'selected': isSelected(item), 'disabled': isDisabled(item), 'no-border-right': items[index + 1] && isSelected(items[index + 1]), 'no-border-left': items[index - 1] && isSelected(items[index - 1])}"
    [ngbTooltip]="item.disabled ? tooltipTemplate : ''"
    tooltipClass="tooltip-side-by-side-item"
    placement="top"
  >
    <ng-template #defaultLabelTemplate>
      <span>{{item[bindLabel] || ''}}</span>
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
      [ngTemplateOutletContext]="{item}">
    </ng-template>
  </div>
</div>
