import { Injectable } from '@angular/core';
import { AttachElasticIp, CreateElasticIp, ElasticIp } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import {
  httpComputedInstanceElasticIP,
  httpComputedInstanceElasticIPs,
  httpElasticIP,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstanceElasticIpDataService extends GenericDataService<ElasticIp, CreateElasticIp> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedInstanceElasticIPs, apiService);
  }

  delete(_context: number[], id: number): Observable<void> {
    return this.apiService.delete(httpElasticIP(id));
  }

  attach(context: number[], data: AttachElasticIp): Observable<ElasticIp> {
    return this.apiService.post(httpComputedInstanceElasticIPs(context[0]), data);
  }

  detach(context: number[], id: number): Observable<void> {
    return this.apiService.delete(httpComputedInstanceElasticIP(context[0], id));
  }
}
