import { Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export class ValueAccessorBase<T> implements ControlValueAccessor {
  private innerValue: T;
  private changed = new Array<(value: T) => void>();
  private touched = new Array<() => void>();

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach(f => f(value));
    }
  }

  touch(): void {
    this.touched.forEach(f => f());
  }

  writeValue(value: T): void {
    this.innerValue = value;
  }

  registerOnChange(fn: ((value: T) => void)): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: (() => void)): void {
    this.touched.push(fn);
  }

  static createProviderFor(component: any): Provider {
    return { provide: NG_VALUE_ACCESSOR, useExisting: component, multi: true };
  }
}
