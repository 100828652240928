import { createAction, props } from '@ngrx/store';

export class GenericActions<T> {
  private loadAllAction;
  private addMultipleAction;
  private addAction;
  private updateAction;
  private deleteMultipleAction;
  private deleteAction;
  private deleteAllAction;

  constructor(entityName: string) {
    this.generateActions(entityName);
  }

  generateActions(entityName: string): void {
    this.loadAllAction = createAction(
      `[${entityName}] Get all ${entityName.toLowerCase()}s`,
    );
    this.addMultipleAction = createAction(
      `[${entityName}] Add multiple ${entityName.toLowerCase()}s`,
      props<{ context: number[]; entities: T[] }>(),
    );
    this.addAction = createAction(
      `[${entityName}] Add ${entityName.toLowerCase()}`,
      props<{ context: number[]; entity: T }>(),
    );
    this.updateAction = createAction(
      `[${entityName}] Update ${entityName.toLowerCase()}`,
      props<{ context: number[]; entity: T }>(),
    );
    this.deleteMultipleAction = createAction(
      `[${entityName}] Delete multiple ${entityName.toLowerCase()}`,
      props<{ ids: number[] }>(),
    );
    this.deleteAction = createAction(
      `[${entityName}] Delete ${entityName.toLowerCase()}`,
      props<{ id: number }>(),
    );
    this.deleteAllAction = createAction(
      `[${entityName}] Delete all ${entityName.toLowerCase()}s`,
    );
  }

  loadAll(): any {
    return this.loadAllAction;
  }

  addMultiple(): any {
    return this.addMultipleAction;
  }

  add(): any {
    return this.addAction;
  }

  update(): any {
    return this.updateAction;
  }

  deleteMultiple(): any {
    return this.deleteMultipleAction;
  }

  delete(): any {
    return this.deleteAction;
  }

  deleteAll(): any {
    return this.deleteAllAction;
  }
}
