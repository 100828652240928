<ng-container *ngIf="categorizedProducts?.length === 1; else tabbedProducts">
  <ng-container *ngTemplateOutlet="productCard; context: { products: categorizedProducts[0] }"></ng-container>
</ng-container>

<ng-template #productCard let-products="products">
  <div class="product-items">
    <div
      class="product-item"
      *ngFor="let p of products"
      [class.selected]="isSelected(p.id)"
      [class.disabled]="!isSelectable(p) || isOutOfStock(p)"
      [title]="productTitle(p)"
      (click)="selectProduct(p)"
    >
      <ng-container *ngIf="cast(p) as product">
        <div class="product-title">
          <p [ngClass]="{'small-title': smallTitle}">
            {{ product.productName }}
          </p>
        </div>
        <div class="product-content">
          <div class="notification-overlay" *ngIf="isOutOfStock(p)">
            <strong>Out of stock</strong>
            <button class="btn btn-primary btn-small" (click)="notifyMe(product)">Notify Me</button>
          </div>
          
          <ng-container *ngIf="!isOutOfStock(p)">
            <ng-container 
              *ngTemplateOutlet="icon; context:{product}"
            ></ng-container>
          </ng-container>

          <p class="product-property" *ngFor="let item of getSortedProductItems(product)">
            {{ item.name }}
            <span class="product-values">{{ item.amount }} {{ item.name === 'vCPU' ? '' : item.name === getProcessorProductItemName() ? 'Core' : 'GB' }}</span>
          </p>
        </div>
        <div class="product-footer" [class.active]="isCluster && isSelected(product.id)">
          <myflow-price-label [price]="product.price" place="center" type="small" *ngIf="!isCluster || (isCluster && !isSelected(product.id))"></myflow-price-label>
          <div class="counter" *ngIf="isCluster && isSelected(product.id)">
            <div class="counter-action" (click)="onCount(product.id, false)" [ngClass]="{'disabled': value?.entity === productMin }">
              <i class="icon-prod-minus"></i>
            </div>
            <div class="counter-entity">
              {{ value?.entity }}
            </div>
            <div class="counter-action" (click)="onCount(product.id)" [ngClass]="{'disabled': value?.entity === productMax }">
              <i class="icon-prod-plus"></i>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tabbedProducts>
  <ul ngbNav #tabSet="ngbNav" [activeId]="0" class="nav-tabs justify-content-center">
    <li [ngbNavItem]="i" *ngFor="let p of categorizedProducts; let i = index">
      <a ngbNavLink>{{ p[0].category }}</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="productCard; context: { products: p }"></ng-container>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="tabSet"></div>
</ng-template>
