import configs from 'app/shared/data/configs';

/**
 * Computed Engine
 * ----------------------------------------------
 */

// Instances
export const httpComputedInstances = (): string => `v${configs.version}/compute/instances`;
export const httpComputedInstanceVNC = (id: number): string => `v${configs.version}/compute/instances/${id}/vnc`;
export const httpComputedInstanceActions = (id: number): string => `v${configs.version}/compute/instances/${id}/action`;
export const httpComputedInstanceUpgrade = (id: number): string => `v${configs.version}/compute/instances/${id}/upgrade`;
export const httpComputedInstanceVolumes = (id: number): string => `v${configs.version}/compute/instances/${id}/volumes`;
export const httpComputedInstanceSecurityGroups = (
  id: number,
): string => `v${configs.version}/compute/instances/${id}/security-groups`;
export const httpComputedInstanceNetworkInterfaces = (
  id: number,
): string => `v${configs.version}/compute/instances/${id}/network-interfaces`;
export const httpComputedInstanceNetworkInterfaceSecurity = (
  id: number,
  interfaceId: number,
): string => `v${configs.version}/compute/instances/${id}/network-interfaces/${interfaceId}/security`;
export const httpComputedInstanceNetworkInterfaceSecurityGroup = (
  id: number,
  interfaceId: number,
): string => `v${configs.version}/compute/instances/${id}/network-interfaces/${interfaceId}/security-groups`;
export const httpComputedInstanceLicenses = (id: number): string => `v${configs.version}/compute/instances/${id}/licenses`;
export const httpComputedInstanceElasticIPs = (id: number): string => `v${configs.version}/compute/instances/${id}/elastic-ips`;
export const httpComputedInstanceElasticIP = (
  id: number,
  elIPid: number,
): string => `v${configs.version}/compute/instances/${id}/elastic-ips/${elIPid}`;

// Networks
export const httpComputedEnginesVPCs = (): string => `v${configs.version}/compute/networks`;
export const httpComputedEnginesVPC = (id: number): string => `v${configs.version}/compute/networks/${id}`;
export const httpComputedNetworkInstances = (id: number): string => `v${configs.version}/compute/networks/${id}/instances`;
export const httpNetworkSecurityGroups = (): string => `v${configs.version}/compute/security-groups`;
export const httpNetworkSecurityGroupRules = (
  securityGroupId: number,
): string => `v${configs.version}/compute/security-groups/${securityGroupId}/rules`;
export const httpNetworkSecurityGroupInstances = (
  securityGroupId: number,
): string => `v${configs.version}/compute/security-groups/${securityGroupId}/instances`;

// Routers
export const httpComputedRouters = (): string => `v${configs.version}/compute/routers`;
export const httpComputedRouterInterfaces = (id: number): string => `v${configs.version}/compute/routers/${id}/interfaces`;
export const httpComputedRouterInterfaceSecurity = (
  id: number,
  interfaceId: number,
): string => `v${configs.version}/compute/instances/${id}/network-interfaces/${interfaceId}/security`;
export const httpComputedRouterRoutes = (id: number): string => `v${configs.version}/compute/routers/${id}/routes`;

// Volumes
export const httpComputedVolumes = (): string => `v${configs.version}/compute/volumes`;
export const httpComputedVolumeUpgrade = (volumeId: number): string => `v${configs.version}/compute/volumes/${volumeId}/upgrade`;
export const httpComputedVolumeSnapshotRevert = (volumeId: number): string => `v${configs.version}/compute/volumes/${volumeId}/revert`;
export const httpComputedVolumeInstances = (volumeId: number): string => `v${configs.version}/compute/volumes/${volumeId}/instances`;
export const httpComputedVolumeInstance = (
  volumeId: number,
  instanceId: number,
): string => `v${configs.version}/compute/volumes/${volumeId}/instances/${instanceId}`;
export const httpComputedVolumeSnapshots = (volumeId: number): string => `v${configs.version}/compute/volumes/${volumeId}/snapshots`;

// Keypairs
export const httpComputeKeypairs = (): string => `v${configs.version}/compute/key-pairs`;

// Elastic IPs
export const httpElasticIPs = (): string => `v${configs.version}/compute/elastic-ips`;
export const httpElasticIP = (elasticIpId): string => `v${configs.version}/compute/elastic-ips/${elasticIpId}`;

// Virtual Private Network
export const httpVirtualPrivateNetworks = (): string => `v${configs.version}/compute/connections`;

// SSL Certificates
export const sslCertificates = (): string => `v${configs.version}/compute/certificates`;

// SSE Topics
export const sseComputeInstances = (): string => `/v${configs.version}/compute/instances/{instanceId}`;
export const sseComputeVolumes = (): string => `/v${configs.version}/compute/volumes/{volumeId}`;
export const sseComputeSnapshots = (): string => `/v${configs.version}/compute/snapshots/{snapshotId}`;
export const sseComputeConnections = (): string => `/v${configs.version}/compute/connections/{connectionId}`;
