import { Injectable } from '@angular/core';
import { CommitmentContract, UpdateCommitmentContract } from 'app/entity/common';
import { ApiService } from 'app/shared/services/api';
import { httpCommitments } from 'app/shared/utils/endpoints/commitment.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class CommitmentDataService extends GenericDataService<CommitmentContract, null, UpdateCommitmentContract> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpCommitments, apiService);
  }
}
