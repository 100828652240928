import { Component, Input } from '@angular/core';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'form-base-label',
  templateUrl: './form-base-label.component.html',
  styleUrls: [ './form-base-label.component.scss' ],
})
export class FormBaseLabelComponent {
  @Input() config: FieldConfig;
}
