type ArrayOrRecord = unknown[] | Record<string, unknown>;

export class CaseConverterService {
  static isSnake(data: string): boolean {
    return !!data.match(/^([a-z0-9]+([_][a-z0-9]+)*)$/g);
  }

  static snakeToCamel(snakeCase: string): string {
    return snakeCase.replace(/([_][a-z])/g, (letter) => letter.replace('_', '').toUpperCase());
  }

  static isCamel(data: string): boolean {
    return !!data.match(/^([a-z0-9]+([A-Z][a-z0-9]+)*)$/g);
  }

  static camelToSnake(camelCase: string): string {
    return camelCase.replace(/[A-Z]/g, (letter) => '_' + letter.toLowerCase());
  }

  static checkCase(data: ArrayOrRecord, caseEvaluationFunc: (data: string) => boolean): boolean {
    if (Array.isArray(data)) {
      return data.every((element: unknown) => this.checkCase(element as ArrayOrRecord, caseEvaluationFunc));
    } else if (data && typeof data === 'object') {
      for (const [ key, value ] of Object.entries(data)) {
        const isValidCase = caseEvaluationFunc(key) && this.checkCase(value as ArrayOrRecord, caseEvaluationFunc);
        if (!isValidCase) {
          return false;
        }
      }
    }

    return true;
  }

  static convertKeys(data: unknown, conversionFunc: (origin: string) => string): any {
    if (Array.isArray(data)) {
      return data.map(element => this.convertKeys(element, conversionFunc));
    } else if (CaseConverterService.isRecord(data)) {
      const newData: Record<string, unknown> = {};

      for (const [ key, value ] of Object.entries(data)) {
        newData[conversionFunc(key)] = this.convertKeys(value, conversionFunc);
      }

      return newData;
    }

    return data;
  }

  private static isRecord(data: unknown): data is Record<string, unknown> {
    return data && typeof data === 'object' && !Array.isArray(data);
  }
}
