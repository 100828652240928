import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse,
} from '@angular/common/http';
import { CaseConverterService } from 'app/shared/services/case-converter.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class CaseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestBody = request.body;
    if (typeof requestBody === 'string') {
      requestBody = JSON.parse(requestBody);
    }

    const body = this.notConvertedValue(requestBody) ?
      requestBody :
      CaseConverterService.convertKeys(requestBody, CaseConverterService.camelToSnake);

    return next.handle(request.clone({ body })).pipe(
      filter(event => event instanceof HttpResponse),
      map((response: HttpResponse<any>) => {
        const responseBody = this.notConvertedValue(response.body) ?
          response.body :
          CaseConverterService.convertKeys(response.body, CaseConverterService.snakeToCamel);
        return response.clone({ body: responseBody });
      }),
    );
  }

  private notConvertedValue(value: unknown): boolean {
    return this.isFormData(value) || this.isBlob(value) || this.isArrayBuffer(value);
  }

  private isFormData(value: unknown): value is FormData {
    return typeof FormData !== 'undefined' && value instanceof FormData;
  }

  /**
   * Safely assert whether the given value is an ArrayBuffer.
   *
   * In some execution environments ArrayBuffer is not defined.
   */
  private isArrayBuffer(value: unknown): value is ArrayBuffer {
    return typeof ArrayBuffer !== 'undefined' && value instanceof ArrayBuffer;
  }

  /**
   * Safely assert whether the given value is a Blob.
   *
   * In some execution environments Blob is not defined.
   */
  private isBlob(value: unknown): value is Blob {
    return typeof Blob !== 'undefined' && value instanceof Blob;
  }
}
