import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnvironmentDataService } from 'app/app-engine/store/environment/environment.data';
import { Environment } from 'app/entity/app-engine';
import { InvokeAction } from 'app/entity/common';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class EnvironmentService extends GenericService<Environment> {
  constructor(
    store: Store<EntityCache>,
    protected dataService: EnvironmentDataService,
  ) {
    super('AccountEnvironment', store, dataService);
  }

  invokeAction(id: number, action: InvokeAction, context?: number[]): Observable<Environment> {
    const ctx = context || this.getContext();

    return this.getById(id, ctx).pipe(
      take(1),
      tap(environment => {
        this.updateOneInCache(ctx, { ...environment, status: { id: 0, key: 'working', name: 'Working', actions: [] } });
      }),
      switchMap(() => this.dataService.invokeAction(ctx, id, action)),
      tap(entity => this.updateOneInCache(ctx, entity)),
    );
  }
}
