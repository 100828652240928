<myflow-details [item]="paymentMethod" type="simple">
  <ng-container header>
    <div class="header-left-content">
      <h2 [class.transparent]="transparent">Credit Card</h2>
    </div>
    <div class="header-right-content">
      <myflow-buttons-menu [disabled]="disabled">
        <div class="menu-item delete-menu-item" ngbDropdownItem>
          <button type="button" (click)="delete()">
            <i class="icon-delete"></i>
            <span>Delete</span>
          </button>
        </div>
      </myflow-buttons-menu>
    </div>
  </ng-container>
  <ng-container content>
    <table class="details-content-table" [class.transparent]="transparent">
      <tr>
        <td>Name on card:</td>
        <td>{{ paymentMethod?.details?.owner }}</td>
      </tr>
      <tr>
        <td>Credit card number:</td>
        <td>**** **** **** {{ paymentMethod?.details?.last4 }}</td>
      </tr>
      <tr>
        <td>Expires:</td>
        <td>{{ paymentMethod?.details?.expMonth }} / {{ paymentMethod?.details?.expYear }} </td>
      </tr>
    </table>
  </ng-container>
</myflow-details>
