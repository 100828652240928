import configs from 'app/shared/data/configs';

/**
 * Organization
 * ----------------------------------------------
 */
export const httpOrganization = (): string => `v${configs.version}/organization`;

// Users
export const httpOrganizationUsers = (): string => `v${configs.version}/organization/users`;
export const httpUserAvatar = (userId: number | string): string => `v${configs.version}/organization/users/${userId}/avatar`;
export const httpOrganizationUser = (userId: number | string): string => `v${configs.version}/organization/users/${userId}`;
export const httpOrganizationUserSendActivation = (
  userId: number | string,
): string => `v${configs.version}/organization/users/${userId}/send-activation`;

// Payments
export const httpOrganizationBalance = (): string => `v${configs.version}/organization/balance`;
export const httpOrganizationBillingHistory = (): string => `v${configs.version}/organization/billing-history`;
export const httpOrganizationPaymentDetails = (): string => `v${configs.version}/organization/payment-details`;
export const httpOrganizationPaymentMethods = (): string => `v${configs.version}/organization/payment-methods`;
export const httpOrganizationSpecificPaymentMethod = (
  paymentMethod,
): string => `v${configs.version}/organization/payment-methods/${paymentMethod}`;
export const httpOrganizationPaymentMethod = (
  paymentMethodId: number | string,
): string => `v${configs.version}/organization/payment-methods/${paymentMethodId}`;
export const httpOrganizationRecharge = (): string => `v${configs.version}/organization/recharge`;
export const httpOrganizationUsage = (): string => `v${configs.version}/organization/usage`;
export const httpOrganizationUsageHistory = (): string => `v${configs.version}/organization/usage-history`;

// Tokens
export const httpOrganizationAppTokens = (): string => `v${configs.version}/organization/applications/tokens`;
export const httpOrganizationAppToken = (tokenId: number): string => `v${configs.version}/organization/applications/tokens/${tokenId}`;

// SSE Topics
export const sseOrganization = (): string => `/v${configs.version}/organization`;
export const sseOrganizationBalance = (): string => `/v${configs.version}/organization/balance`;
