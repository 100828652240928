import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[myflowClipboard]',
})
export class ClipboardDirective implements OnInit {
  @Input() container: HTMLElement;
  @Input() cbContent: string;
  @Input() cbSuccessMsg: string;

  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) public document: Document,
  ) {}

  @HostListener('mouseleave', [ '$event.target' ])
  onLeave(): void {
    this.el.nativeElement.classList.remove('copied');
  }

  @HostListener('click', [ '$event.target' ])
  onClick(element: HTMLElement): void {
    navigator.clipboard.writeText(element.innerText).then(() => {
      this.el.nativeElement.classList.add('copied');
    });
  }

  ngOnInit(): void {
    this.el.nativeElement.classList.add('clipboard');
  }
}
