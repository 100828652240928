import { Injectable } from '@angular/core';
import { CreateOrganizationQuota, OrganizationQuota, QuotaRule } from 'app/entity/admin/quota.model';
import { ApiService } from 'app/shared/services/api';
import { httpAdminOrganizationQuotaRule, httpAdminOrganizationQuotaRules, httpAdminOrganizationQuotas } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationQuotaDataService extends GenericDataService<OrganizationQuota, CreateOrganizationQuota> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrganizationQuotas, apiService);
  }

  createQuotaRule(context: number[], quotaId: number, data: CreateOrganizationQuota): Observable<QuotaRule> {
    return this.apiService.post(httpAdminOrganizationQuotaRules(context[0], quotaId), data);
  }

  updateQuotaRule(context: number[], quotaId: number, ruleId: number, data: CreateOrganizationQuota): Observable<QuotaRule> {
    return this.apiService.patch(httpAdminOrganizationQuotaRule(context[0], quotaId, ruleId), data);
  }

  deleteQuotaRule(context: number[], quotaId: number, ruleId: number): Observable<void> {
    return this.apiService.delete(httpAdminOrganizationQuotaRule(context[0], quotaId, ruleId));
  }

}
