
export interface LoadBalancerHealthCheck {
  type: {
    id: number;
    name: string;
    key: string;
  };
  httpMethod?: string;
  httpPath?: string;
  interval: number;
  timeout: number;
  healthyThreshold: number;
  unhealthyThreshold: number;
}

export interface CreateLoadBalancerHealthCheck {
  typeId: number;
  httpMethod?: string;
  httpPath?: string;
  interval: number;
  timeout: number;
  healthyThreshold: number;
  unhealthyThreshold: number;
}
