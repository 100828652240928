import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'myflow-storage-graph',
  templateUrl: './storage-graph.component.html',
  styleUrls: [ './storage-graph.component.scss' ],
})
export class StorageGraphComponent {
  @Input() usage: number;

  get baseStorage(): number { // to Bytes
    return environment.objectStorage.baseStorage * 1000 ** 3;
  }

  get isBaseUsage(): boolean {
    return (this.baseStorage - this.usage) >= 0;
  }

  get baseUsage(): number {
    return this.isBaseUsage ? this.usage : this.baseStorage;
  }

  get extraUsage(): number {
    return this.usage - this.baseStorage;
  }

  get baseUsagePercent(): number {
    const usage = this.isBaseUsage ? this.usage : this.baseStorage;
    const storageLimit = this.isBaseUsage ? this.baseStorage : this.usage;

    return usage * 90 / storageLimit;
  }

  get extraUsagePercent(): number {
    return this.extraUsage * 90 / this.usage;
  }
}
