import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newRecordBackground',
})
export class NewRecordPipe implements PipeTransform {
  transform(bool: boolean): string {
    return bool ? '#E9E7FF' : 'transparent';
  }
}
