import { Message } from '../../sse/connection';
import { MessageHandler, Status } from '../entity-connection';
import { NgrxRerenderHandler } from './ngrx-rerender.handler';
import { NgrxEntityHandler } from './ngrx.handler';

export class NgrxCollectionHandler implements MessageHandler<any> {
  constructor(
    private handlers: NgrxEntityHandler<any>[]|NgrxRerenderHandler<any>[],
  ) {
  }

  create(msg: Message<any>): void {
    this.handlers.forEach(handler => handler.create(msg));
  }

  update(msg: Message<any>): Promise<void> {
    const promises = this.handlers.map(handler => handler.update(msg));

    return Promise.all(promises).then();
  }

  status(msg: Message<Status>): Promise<void> {
    const promises = this.handlers.map(handler => handler.status(msg));

    return Promise.all(promises).then();
  }

  delete(msg: Message<any>): void {
    this.handlers.forEach(handler => handler.delete(msg));
  }
}
