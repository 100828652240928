import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FieldConfig } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@Component({
  selector: 'form-button',
  templateUrl: './form-button.component.html',
  styleUrls: [ 'form-button.component.scss' ],
})
export class FormButtonComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
