import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SseConnection } from 'app/shared/services/sse/connection';
import { httpSseToken } from 'app/shared/utils';

import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  private hub: string;
  private tokenUrl: string;

  private connections: SseConnection[] = [];

  constructor(
    private http: HttpClient,
  ) {
    this.hub = environment.hubHost;
    this.tokenUrl = `${environment.apiHost}${httpSseToken()}`;
  }

  buildTopic(path: string): string {
    const api = new URL(environment.apiHost);
    return `https://${api.host}${path}`;
  }

  async open(topics: string[]): Promise<SseConnection> {
    const source = await this.connect(topics);

    const connection = new SseConnection(this, topics, source);
    this.connections.push(connection);
    return connection;
  }

  async connect(topics: string[]): Promise<EventSource> {
    await lastValueFrom(this.http.post(this.tokenUrl, null, { withCredentials: true }));

    const url = new URL(this.hub);
    topics.forEach(topic => url.searchParams.append('topic', topic));

    return new EventSource(url.toJSON(), { withCredentials: true });
  }

  closeAll(): void {
    if (this.connections.length) {
      this.connections.forEach(connection => connection.close());
      this.connections = [];
    }
  }
}
