import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuleHeaderService {
  backLinkUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  customMenu$: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null);
}
