import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';

import { AvatarService } from '../../services/avatar.service';

@Component({
  selector: 'myflow-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: [ './image-crop.component.scss' ],
})
export class ImageCropComponent implements OnInit {
  @Input() id: number;
  @Input() file: any;
  @Input() admin = false;
  @Output() closeImgCrop: EventEmitter<any> = new EventEmitter();

  error: string | null;
  fileName: string;
  imageFileChanged: any | null = null;
  croppedImage: Blob;

  constructor(
    private toastr: ToastrService,
    private avatarService: AvatarService,
  ) {}

  ngOnInit(): void {
    if (this.file) {
      this.fileChangeEvent(this.file);
    }
  }

  fileChangeEvent(event: any): void {
    this.error = null;
    const file = event.target ? event.target.files[0] : Array.isArray(event) ? event[0] : event;
    this.imageFileChanged = file;
    this.fileName = file.name;
    this.validateImageSize(+file.size);
  }

  // Max file size should be 2MB, and 1024x1024.
  validateImageSize(fileSize: number): any {
    if (fileSize && fileSize * 0.000001 > 2) {
      this.error = `Image is too large, file size ${Math.round(fileSize * 0.0001) / 100}MB (allowed less then 2MB)`;
      this.imageFileChanged = null;
    }
  }

  imageCropped(image: ImageCroppedEvent): void {
    this.croppedImage = base64ToFile(image.base64);
    this.error = null;
  }

  loadImageFailed(): void {
    this.error = 'Something went wrong, did you choose a valid file?';
  }

  close(): void {
    this.file = null;
    this.closeImgCrop.emit(true);
  }

  save(): void {
    if (!this.admin) {
      this.avatarService.addAvatar(this.croppedImage, this.id, this.fileName)
        .subscribe({
          next: (response: any) => {
            if (response) {
              this.toastr.success('Avatar uploaded');
              this.closeImgCrop.emit(this.croppedImage);
            }
          },
          error: error => {
            this.error = error.error.error.message.en;
          },
        });
    } else {
      this.closeImgCrop.emit(this.croppedImage);
    }
  }
}
