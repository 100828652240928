import { Injectable } from "@angular/core";
import { User } from "app/entity/infrastructure";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { OrganizationUserDataService } from "./organization-user.data";
import { Store } from "@ngrx/store";
import { Observable, tap } from "rxjs";
import { CreateUser, UpdateUser } from "app/entity/admin/user.model";

@Injectable()
export class OrganizationUserService extends GenericService<User, CreateUser, UpdateUser> {
  constructor(
    store: Store<EntityCache>,
    private userDataService: OrganizationUserDataService,
  ) {
    super('AdminOrganizationUser', store, userDataService);
  }

  assignUser(user: User, context?: number[]): Observable<void> {
    const ctx = context || this.getContext();

    return this.userDataService.assignUser(ctx, {
      user: user.id,
    }).pipe(
      tap(() => this.addOneToCache(ctx, user)),
    );
  }

  unassignUser(id: number, context?: number[]): Observable<void> {
    const ctx = context || this.getContext();

    return this.userDataService.unassignUser(ctx, id).pipe(
      tap(() => this.removeOneFromCache(id)),
    );
  }
}
