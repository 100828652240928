import { Injectable } from '@angular/core';
import { CreateSecurityGroup, SecurityGroup, UpdateSecurityGroup } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import { httpNetworkSecurityGroups } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class SecurityGroupDataService extends GenericDataService<SecurityGroup, CreateSecurityGroup, UpdateSecurityGroup> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpNetworkSecurityGroups, apiService);
  }
}
