/* eslint-disable @typescript-eslint/no-empty-function */
import { Cluster } from 'app/entity/compute-kubernetes';
import { Status } from 'app/shared/services/messages/entity-connection';
import { MessageOptions } from 'app/shared/services/messages/handler/message.handler';
import { ToastrService } from 'ngx-toastr';

export class KubernetesClusterMessages implements MessageOptions<Cluster> {
  createMessageCallback(toastr: ToastrService, entity: Cluster): void {
    toastr.success(`<b>Cluster:</b> Succeeded to create ${entity.name}`, '', { enableHtml: true });
  }

  updateMessageCallback(_toastr: ToastrService, _entity: Cluster): void {}

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void {}

  deleteMessageCallback(_toastr: ToastrService, _entity: Cluster): void {}
}
