import { Injectable } from '@angular/core';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { NodeDataService } from './node.data';
import { CreateNode, Node, UpdateNode } from 'app/entity/ci-engine/node.model';

@Injectable()
export class NodeService extends GenericService<Node, CreateNode, UpdateNode> {
  constructor(
    store: Store<EntityCache>,
    private nodeDataService: NodeDataService,
  ) {
    super('CiEngineNode', store, nodeDataService);
  }
}
