import { Injectable } from '@angular/core';
import { CreateNode, Node, UpdateNode } from 'app/entity/ci-engine/node.model';
import { ApiService } from 'app/shared/services/api';
import { httpAdminCiEngineNodes } from 'app/shared/utils/endpoints/ci-engine.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class NodeDataService extends GenericDataService<Node, CreateNode, UpdateNode> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminCiEngineNodes, apiService);
  }
}
