import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Organization } from 'app/entity/infrastructure/organization.model';

import { OrganizationService } from 'app/organization/services/organization';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { OrganizationActions } from '../actions';
import * as fromRoot from '../reducers';

@Injectable()
export class OrganizationEffects {
  org$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.getOrganization.type),
    switchMap(() => this.organizationsService.fetchOrganization()),
    tap((org: Organization) => {
      this.store.dispatch(OrganizationActions.setOrganization({ organization: org }));
    }),
  ), { dispatch: false });

  organization$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.setOrganization.type),
    map((action: any) => action.organization),
  ), { dispatch: false });

  payments$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.getOrganizationPayments.type),
    switchMap(() =>
      this.organizationsService.fetchOrganizationPaymentMethods()
        .pipe(
          map((payments: any) => OrganizationActions.getOrganizationPaymentsSuccess({ payments })),
          catchError(error => of(OrganizationActions.getOrganizationPaymentsFailure({ error }))),
        ),
    ),
  ));

  balance$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.getBalance.type),
    switchMap(() =>
      this.organizationsService.fetchBalance()
        .pipe(
          map((balance: any) => OrganizationActions.getBalanceSuccess({ balance })),
          catchError(error => of(OrganizationActions.getBalanceFailure({ error }))),
        ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationService,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }
}
