import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupportSeverity } from 'app/entity/support';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { businessPlanId, firstPlanId, PlanService } from '../plans';
import { SeverityDataServiece } from './severity.data';

@Injectable()
export class SeverityService extends GenericService<SupportSeverity> {
  constructor(
    store: Store<EntityCache>,
    private severityDataService: SeverityDataServiece,
    private planService: PlanService,
  ) {
    super('Severity', store, severityDataService);
  }

  /**
   * Returns an array of support category (as an observable)
   */
  getSeverities(): Observable<SupportSeverity[]> {
    return combineLatest([
      this.entities$,
      this.planService.isPlanActive(businessPlanId), //Check if business plan is active
      this.planService.isPlanActive(firstPlanId), //Check if first plan is active
    ]).pipe(
      map(([ entities, businessActive, firstActive ]) => {
          entities.forEach((entity, index) => {
            switch(entity.id) {
              //Severity normal and critical only allowed if business or first plan is active
              case 2:
              case 3:
                entities[index].disabled = !businessActive && !firstActive;
                break;
              default:
                entities[index].disabled = false;
                break;
            }
          });
          return entities;
        },
      ),
    );
  }
}
