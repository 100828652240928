<div
    class="dynamic-field form-input form-group"
    [formGroup]="group"
>
  <form-base-label [config]="config" />
  
  <textarea
    class="form-control"
    [formControlName]="config.name"
    [required]="config.required"
    [id]="config.name"
    [placeholder]="config.placeholder"
    onkeydown="event.stopPropagation(); /* stop return key from submitting the form as the user may want to type newlines in a textarea */"
  ></textarea>

  <div *ngIf="config.hint" class="form-text text-muted">
    {{ config.hint }}
  </div>

  <app-form-errors [control]="control" [label]="config.label"></app-form-errors>
</div>