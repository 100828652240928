import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Account, AccountSSOLink, CreateAccount, UpdateAccount } from 'app/entity/app-engine';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

import { AccountDataService } from 'app/app-engine/store/account/account.data';
import { Observable } from 'rxjs';

@Injectable()
export class AccountService extends GenericService<Account, CreateAccount, UpdateAccount> {
  constructor(
    store: Store<EntityCache>,
    private appEngineAccountsDataService: AccountDataService,
  ) {
    super('AppEngineAccounts', store, appEngineAccountsDataService);
  }

  getSsoLink(id: number): Observable<AccountSSOLink> {
    return this.appEngineAccountsDataService.getSsoLink(id);
  }
}
