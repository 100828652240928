import configs from 'app/shared/data/configs';

/**
 * Tickets System
 * ----------------------------------------------
 */
export const httpSupportTickets = (): string => `v${configs.version}/organization/support/tickets`;
export const httpSupportTicketComment =
(ticketId: number): string => `v${configs.version}/organization/support/tickets/${ticketId}/comments`;
export const httpSupportTicketsAttachment = (): string => `v${configs.version}/organization/support/tickets/attachments`;
export const httpSupportTicketAttachmentContent = (ticketId: number, attachmentId: number): string =>
`v${configs.version}/organization/support/tickets/${ticketId}/attachments/${attachmentId}`;

/**
 * Support Severities
 * ----------------------------------------------
 */
 export const httpSupportSeverities = (): string => `v${configs.version}/entities/support/severities`;

/**
 * Tickets System
 * ----------------------------------------------
 */
export const httpSupportCategories = (): string => `v${configs.version}/entities/support/categories`;

/**
 * Support Plans
 * ----------------------------------------------
 */
export const httpPlans = (): string => `v${configs.version}/organization/support/plans`;

// SSE Topics
export const sseSupportTicket = (): string => `/v${configs.version}/organization/support/tickets/{ticketId}`;
export const sseSupportTicketComment = (): string => `/v${configs.version}/organization/support/tickets/{ticketId}/comments/{commentId}`;
