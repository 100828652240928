<div class="flex ">
  <div class="location-items" [class.horizontal]="horizontal">
    <div *ngFor="let region of regions"
        class="location-item"
        [class.selected]="region.id === value?.id"
        (click)="value = region"
    >
      <div class="location-header-overlay" [ngStyle]="getRegionBackgroundStyle(region)">
        <div class="location-header">
          <h1>{{ region.name }}</h1>
        </div>
      </div>
      <div class="location-details">
        <div class="tag" *ngIf="isNew(region)">
          NEW
        </div>
        <div class="country-icon">
          <img src="/assets/img/icon-swiss.svg" alt="Swiss Icon">
        </div>
        <p>{{ region.city }}</p>
        <div class="facts">
          <p *ngFor="let fact of getRegionFacts(region)">
            {{ fact }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>