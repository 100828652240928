<myflow-side-panel
  title="Profile Picture"
  (closeEvent)="close()"
  (confirmEvent)="save()"
>
  <div content class="form-wrapper centered">
    <div myflowDnD (filesChangeEmiter)="fileChangeEvent($event)" [allowedExtensions]="['jpg', 'jpeg', 'png', 'gif']">
      <div *ngIf="error" class="alert alert-danger" (click)="file.click()">
        <p>{{ error }}</p>
        <input type="file" #file style="display: none" (change)="fileChangeEvent($event)"/>
        <button type="button" class="btn btn-primary" (click)="file.click()">Change Image</button>
      </div>
      <image-cropper 
        *ngIf="!error"
        [imageFile]="imageFileChanged" 
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()" 
        [aspectRatio]="1 / 1" 
        [resizeToWidth]="170"
        [imageQuality]="75" 
        format="jpg" 
        width="100%" 
        class="p-0"  
      />
    </div>
  </div>
</myflow-side-panel>
