import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { toastAnimation } from '../../utils';

@Component({
  selector: 'myflow-toast',
  templateUrl: './toast.component.html',
  styleUrls: [ './toast.component.scss' ],
  animations: [
    toastAnimation,
  ],
  preserveWhitespaces: false,
})
export class MyFlowToastComponent extends Toast {
  // constructor is only necessary when not using AoT
  constructor (
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}
