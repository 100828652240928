<div class="form-group">
  <label>
    Credit card
    <span class="ng-star-inserted">*</span>
  </label>
</div>

<div class="form-group">
  <label for="name">
    <i class="icon-user"></i>
  </label>
  <input type="text" [(ngModel)]="owner" placeholder="First and last name" id="name" name="name" class="form-control"
         required>
</div>

<div class="form-group" [class.has-danger]="errors.value">
  <label for="card-number">
    <i class="icon icon-creditcard icon-credit-card"></i>
  </label>
  <div id="card-number" class="form-control"></div>
  <div id="card-number-valid" class="form-control-feedback alert alert-danger"
       [hidden]="!errors.value">{{ errors.value }}</div>
</div>

<div class="form-group" [class.has-danger]="errors.expiry">
  <label for="card-date">
    <i class="icon icon-calendar"></i>
  </label>
  <div id="card-date" class="form-control"></div>
  <div id="card-date-valid" class="form-control-feedback alert alert-danger"
       [hidden]="!errors.expiry">{{ errors.expiry }}</div>
</div>

<div class="form-group" [class.has-danger]="errors.cvv">
  <label for="card-cvv">
    <i class="icon icon-cvv icon-lock"></i>
  </label>
  <div id="card-cvv" class="form-control"></div>
  <div id="card-cvv-valid" class="form-control-feedback alert alert-danger" [hidden]="!errors.cvv">{{ errors.cvv }}</div>
</div>
