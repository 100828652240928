import { AbstractControl, ValidationErrors } from '@angular/forms';

export const priceValidator =
  (decimals: number) =>
  (control: AbstractControl): ValidationErrors | null => {
    const isValid = (value: string): boolean => {
      // short circuit if the value is only digits without decimals or empty
      if (/^\d+$/.test(value) || value === '') {
        return true;
      }

      // check the remaining cases (e.g. 12.5, 42.1, .5)
      return (
        decimals > 0 && new RegExp(`\\d*\\.\\d{1,${decimals}}`).test(value)
      );
    };

    return isValid(control.value)
      ? null
      : {
          price: { decimals },
        };
  };
