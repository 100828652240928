import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromLayout from 'app/store/reducers';

@Component({
  selector: 'myflow-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: [ './table-pagination.component.scss' ],
})
export class TablePaginationComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input() rows: T[] = [];
  @Input() page = 1;
  @Input() size = 0;
  @Input() limit = 10;
  @Input() limits: number[] = [ 5, 10, 15, 25, 50, 100 ];
  @Input() dataSource: MatTableDataSource<T>;
  @Input() ngPaginator: MatPaginator;
  @Output() changedPage = new EventEmitter();
  @Output() setLimit = new EventEmitter();
  @Output() changeState = new EventEmitter();
  @Output() loaded = new EventEmitter();

  @Input() showToggle = false;
  @Input() leftToggle: string;
  @Input() rightToggle: string;
  @Output() toggled = new EventEmitter();

  @Input() showBasedOnSize = false;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  @Input() toggleState = false;

  private destroyed$ = new Subject();

  constructor(
    private store: Store<fromLayout.State>,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.dataSource) {
      this.rows = this.dataSource.data;
      this.dataSource.paginator = this.paginator;

      setTimeout(() => {
        if (this.dataSource.paginator.pageIndex !== this.page -1) {
          this.paginator.pageIndex = this.page - 1;
          this.dataSource.paginator = this.paginator;
        }
        this.loaded.emit(true);
      }, 500);
    } else {
      this.loaded.emit(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue) {
      this.rows = this.dataSource.data;
      this.dataSource.paginator = this.paginator;
    }
  }

  pageChange(event: any): void {
    if (!this.dataSource) {
      this.changedPage.emit(event);
    } else {
      this.handlePage(event);
      this.changeState.emit({ page: event });
    }
  }

  setNewLimit(target: any): void {
    if (!this.dataSource) {
      this.setLimit.emit(target);
    } else {
      this.setLimitHandle(target);
      this.changeState.emit({ limit: target });
    }
  }

  toggle(): void {
    this.toggleState = !this.toggleState;
    this.toggled.emit(this.toggleState);
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private handlePage(event: number): void {
    this.paginator.pageIndex = event - 1;

    this.dataSource.paginator = this.paginator;
  }

  private setLimitHandle(event: number): void {
    this.limit = event;
    this.paginator.pageSize = event;
    this.dataSource.paginator = this.paginator;
  }
}
