import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separateNumber',
})
export class SeperateNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return value.toLocaleString('de-CH');
    }
    return '0';
  }
}
