import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api/api.service';

import { httpUserAvatar } from 'app/shared/utils';
import { HttpObserveTypes, HttpResponseTypes } from 'app/entity';

@Injectable({
  providedIn: 'root',
})
export class ImageCropService {
  constructor(
    private apiService: ApiService,
  ) {}

  fetchAvatar(userId: number, imgUrl: string | null): Observable<any> {
    const url = !imgUrl ? httpUserAvatar(userId) : imgUrl;
    return this.apiService.get(url, {
      observe: HttpObserveTypes.RESPONSE,
      responseType: HttpResponseTypes.BLOB,
    });
  }

  addAvatar(file: Blob, userId: number, fileName?: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('avatar', file, fileName || 'avatar.png');

    return this.apiService.postFormData(httpUserAvatar(userId), formData);
  }

  deleteAvatar(userId: number): Observable<any> {
    return this.apiService.delete(httpUserAvatar(userId));
  }
}
