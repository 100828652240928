import { Injectable } from '@angular/core';
import { Account, AccountSSOLink, CreateAccount, UpdateAccount } from 'app/entity/app-engine';
import { ApiService } from 'app/shared/services/api';
import { httpAppEngineAccountManage, httpAppEngineAccounts } from 'app/shared/utils';

import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService extends GenericDataService<Account, CreateAccount, UpdateAccount> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAppEngineAccounts, apiService);
  }

  getSsoLink(id: number): Observable<AccountSSOLink> {
    return this.apiService.get(httpAppEngineAccountManage(id));
  }
}
