import { Injectable } from '@angular/core';
import { Subscription, CreateSubscription, UpdateSubscription } from 'app/entity/ci-engine';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { SubscriptionDataService } from './subscription.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { Observable, map, tap } from 'rxjs';
import { CommitmentContract } from 'app/entity/common';
import { CommitmentDataService } from 'app/core/services/commitment/commitment.data';

@Injectable()
export class SubscriptionService extends GenericService<Subscription, CreateSubscription, UpdateSubscription> {
  constructor(
    store: Store<EntityCache>,
    private subscriptionDataService: SubscriptionDataService,
    private commitmentDataService: CommitmentDataService,
  ) {
    super('Subscription', store, subscriptionDataService);
  }

  upgrade(id: number, flavor: { flavorId: number }, context?: number[]): Observable<Subscription> {
    const ctx = context || this.getContext();
    return this.subscriptionDataService.upgrade(id, flavor).pipe(
      tap(entity => this.store.update(ctx, entity)),
    );
  }

  renewSubscription(subscription: Subscription, context?: number[]): Observable<CommitmentContract> {
    const ctx = context || this.getContext();

    return this.commitmentDataService.update([], subscription.commitment.id, {
      renew: true,
    }).pipe(
      tap((commitment) => {
        // Update subscription in cache with new commitment
        subscription.commitment = commitment;
        this.updateOneInCache(ctx, subscription);
      }),
    );
  }

  disableCommitmentRenewal(subscription: Subscription, context?: number[]): Observable<Subscription> {
    const ctx = context || this.getContext();

    return this.dataService.delete(ctx, subscription.id).pipe(
      // Map expected response (void, as delete endpoints don't usually return a response) to actual response (subscription)
      map((response) => response as unknown as Subscription),
      tap((sub) => {
        // Update subscription in cache with new commitment
        subscription.commitment = sub.commitment;
        this.updateOneInCache(ctx, subscription);
      }),
    );
  }
}
