import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';

@Injectable()
export class AppConfig {
  private config: any | null = null;
  private env: any | null = null;

  constructor(
    private http: HttpClient,
  ) {}

  getConfig(key: any): any {
    return this.config[key];
  }

  getEnv(key: any): any {
    return this.env[key];
  }

  load(): Promise<any> {
    return new Promise((resolve) => {
      this.http.get('./app/config/config.json')
        .subscribe({
          next: (responseData: any) => {
            this.config = responseData;
            this.env = responseData.env;
            resolve(true);
          },
          error: (error: any): Observable<never> => {
            // eslint-disable-next-line no-console
            console.log('Configuration file "conf.json" could not be read');
            resolve(true);
            return observableThrowError(() => error.json().error || 'Server error');
          },
        });
    });
  }
}
