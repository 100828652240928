import { Injectable } from '@angular/core';
import { CreateVolume, UpdateVolume, UpgradeVolume, Volume, VolumeAttachInstance } from 'app/entity/compute-instances';

import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

import { ApiService } from 'app/shared/services/api';

import {
  httpComputedVolumeInstance,
  httpComputedVolumeInstances,
  httpComputedVolumes,
  httpComputedVolumeUpgrade,
} from 'app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class VolumeDataService extends GenericDataService<Volume, CreateVolume, UpdateVolume> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedVolumes, apiService);
  }

  upgrade(_context: number[], id: number, upgrade: UpgradeVolume): Observable<Volume> {
    return this.apiService.post(httpComputedVolumeUpgrade(id), upgrade);
  }

  detach(id: number, attach: VolumeAttachInstance): Observable<void> {
    return this.apiService.delete(httpComputedVolumeInstance(id, attach.instanceId));
  }

  attach(id: number, attach: VolumeAttachInstance): Observable<Volume> {
    return this.apiService.post(httpComputedVolumeInstances(id), attach);
  }
}
