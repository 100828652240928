<div class="modal-header">
  <div #header>
    <ng-content select="[header]"></ng-content>
  </div>
  <h6 *ngIf="header.childNodes.length === 0" class="modal-title">{{ title }}</h6>

  <div class="modal-action">
    <div *ngIf="customAction">
      <ng-container *ngTemplateOutlet="customAction"></ng-container>
    </div>
    <div class="wizard-close" (click)="close()">
      <i class="icon icon-cancel"></i>
    </div>
  </div>
</div>
<div class="modal-body" [ngClass]="{ 'p-0': customContent }">
  <div *ngIf="loading$ | async" class="loading-content">
    Loading...
  </div>

  <ng-container *ngIf="(loading$ | async) === false">
    <div #body>
      <ng-content select="[body]"></ng-content>
    </div>
    <div *ngIf="customContent">
      <ng-container *ngTemplateOutlet="customContent"></ng-container>
    </div>
    <div *ngIf="body.childNodes.length === 0 && !customContent">
      <div *ngIf="!fields.length" class="flex jumbotron justify-content-center">
        Loading...
      </div>
      <myflow-dynamic-form
        [config]="fields"
        (keydown.enter)="onSubmit()"
        #form
      ></myflow-dynamic-form>
    </div>
  </ng-container>
</div>