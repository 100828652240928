import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, delay, of, takeUntil } from 'rxjs';
import { DownloadService } from 'app/shared/services/download.service';

@Component({
  selector: 'myflow-clipboard-card',
  templateUrl: './clipboard-card.component.html',
  styleUrls: [ './clipboard-card.component.scss' ],
})
export class ClipboardCardComponent implements OnDestroy {
  @Input() data?: string;
  @Input() title: string;
  @Input() wizard: boolean;
  @Input() downloadFilename?: string;
  @Input() cutOff: boolean;

  showCopiedText = false;

  protected destroyed$ = new Subject<boolean>();

  constructor(
    private downloadService: DownloadService,
  ) { }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.data);
    this.showCopiedText = true;

    of(true).pipe(
      takeUntil(this.destroyed$),
      delay(3000),
    ).subscribe(() => this.showCopiedText = false);
  }

  downloadAsFile(): void {
    // Map filename name to be more readable in the file name (e.g. My Filename -> my_filename)
    const fileName = this.downloadFilename.toLowerCase().replaceAll(' ', '_');
    this.downloadService.downloadFile(fileName, this.data);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
