import { Network } from 'app/entity/compute-networking/network.model';

export interface RouterInterface {
  id: number;
  privateIp: string;
  network: Network;
}

export interface CreateRouterInterface {
  networkId: number;
  privateIp?: string;
}
