import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ObjectStorageBucket } from 'app/entity';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { ObjectStorageBucketDataService } from './object-storage-bucket.data';

@Injectable()
export class ObjectStorageBucketService extends GenericService<ObjectStorageBucket> {
  constructor(
    store: Store<EntityCache>,
    dataService: ObjectStorageBucketDataService,
  ) {
    super('ObjectStorageBucket', store, dataService);
  }
}
