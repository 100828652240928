import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  private spinner$: Subject<any>;

  get spinnerOn(): Observable<any> {
    this.spinner$ = new Subject<any>();
    return this.spinner$.asObservable();
  }

  updateModalSpinner(data: unknown): void {
    if (this.spinner$) {
      this.spinner$.next(data);
      this.spinner$.complete();
    }
  }
}

// service was created as a helper
// for confirm modal window to inform
// when action was completed (to show spinner and close window)
