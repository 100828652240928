import { Injectable } from '@angular/core';
import { CreateDevice, Device, UpdateDevice } from 'app/entity/mbm-devices';
import { ApiService } from 'app/shared/services/api';
import { httpBareMetalNetworkDevices } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class PrivateNetworkDeviceDataService extends GenericDataService<Device, CreateDevice, UpdateDevice> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpBareMetalNetworkDevices, apiService);
  }
}
