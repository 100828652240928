import configs from 'app/shared/data/configs';

/**
 * App Engine Endpoints
 * ----------------------------------------------
 */
export const httpAppEngineAccounts = (): string => `v${configs.version}/app-engine/accounts`;
export const httpAppEngineAccountManage = (id: number): string => `v${configs.version}/app-engine/accounts/${id}/manage`;
export const httpAppEngineAccountEnvironments = (id: number): string => `v${configs.version}/app-engine/accounts/${id}/environments`;

// SSE Topics
export const sseAppEngineEnvironment = (): string => `/v${configs.version}/app-engine/accounts/{accountId}/environments/{environmentId}`;
