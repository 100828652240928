import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

import { environment } from 'environments/environment';
import { map, switchMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromLayout from 'app/store/reducers';

import { ActiveOrganizationService } from '../services/active-organization.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleGuard {
  constructor (
    private router: Router,
    private store: Store<fromLayout.State>,
    private activeOrganizationService: ActiveOrganizationService,
  ) {}

  canActivate (
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const data = next.data;

    if (data.module) {
      return this.getModulesState()
        .pipe(
          switchMap((modules: any) => {
            this.activeOrganizationService.rebuildLayout(modules, next);
            return of(!data.app || (data.app === environment.brand));
          }),
      );
    }

    return (!data.app || (data.app === environment.brand));
  }

  getModulesState(): Observable<any> {
    return this.store.pipe(
      select(fromLayout.getOrganization),
      map(org => org.registeredModules),
    );
  }
}
