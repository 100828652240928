import { Injectable } from '@angular/core';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { GlobalImageDataService } from './global-image.data';
import { CreateGlobalImage, Image } from 'app/entity/ci-engine';

@Injectable()
export class GlobalImageService extends GenericService<Image, CreateGlobalImage> {
  constructor(
    store: Store<EntityCache>,
    private globalImageDataService: GlobalImageDataService,
  ) {
    super('CiEngineGlobalImage', store, globalImageDataService);
  }
}
