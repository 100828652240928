<div *ngIf="price !== 0 || showAlways; else included" ngbDropdown #balanceDrop="ngbDropdown" placement="bottom-right"
     (mouseleave)="balanceDrop.close()">
  <div ngbDropdownToggle class="price" [ngClass]="[type, place, getDropdownClass()]" (mouseenter)="balanceDrop.open()">
    <p class="costs-hour">{{ price | perHour | customCurrency : 'CHF' : true : decSize }}/hr</p>
    <p class="costs-month" *ngIf="type !== 'simple'">{{ price | customCurrency: 'CHF' : true }}/mo</p>
  </div>

  <div ngbDropdownMenu class="dropdown-menu" (mouseenter)="balanceDrop.open()">
    <ng-container *ngIf="showDropdown">
      <ng-container *ngIf="customItems">
        <div class="menu-item" ngbDropdownItem *ngFor="let item of customItems">
          <div class="label">{{ item.label }}</div>
          <div class="text-right">
            {{ item.price | perHour | customCurrency : 'CHF' : true : decSize }}/hr
          </div>
        </div>
      </ng-container>
      <div class="menu-item" ngbDropdownItem [ngClass]="{'devider': customItems}">
        <div class="label">{{ customItems ? 'Total per day' : 'Day' }}</div>
        <div class="text-right">
          {{ price | perDay | customCurrency : 'CHF' : true }}
        </div>
      </div>
      <div class="menu-item" ngbDropdownItem>
        <div class="label">{{ customItems ? 'Total per week' : 'Week' }}</div>
        <div class="text-right">
          {{ price | perWeek | customCurrency : 'CHF' : true }}
        </div>
      </div>
      <div class="menu-item" ngbDropdownItem>
        <div class="label">{{ customItems ? 'Total per month (730hr)' : 'Month (730hr)' }}</div>
        <div class="text-right">
          {{ price | customCurrency : 'CHF' : true }}
        </div>
      </div>
    </ng-container>
  </div>
</div>


<ng-template #included>
  <div class="price included">Included</div>
</ng-template>

