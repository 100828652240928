import { Injectable } from '@angular/core';
import {
  Cluster,
  CreateCluster,
  UpdateCluster,
  UpdateClusterFlavor,
  UpgradeCluster,
  Version,
} from 'app/entity/compute-kubernetes';
import { KubeConfig } from 'app/entity/compute-kubernetes/kube-config.model';
import { KubernetesClusterConfiguration } from 'app/entity';
import { ApiService } from 'app/shared/services/api';
import {
  httpKubernetesClusterConfigurationHistory,
  httpKubernetesClusterFlavor,
  httpListKubernetesVersions,
} from 'app/shared/utils';
import {
  httpKubernetesClusterAction, httpKubernetesClusterConfiguration,
  httpKubernetesClusters,
  httpKubernetesClusterUpgrade,
  httpKubernetesKubeConfig,
} from 'app/shared/utils/endpoints/kubernetes.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClusterDataService extends GenericDataService<Cluster, CreateCluster, UpdateCluster> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpKubernetesClusters, apiService);
  }

  /** @deprecated use the flavor change endpoint */
  upgrade(id: number, data: UpgradeCluster): Observable<Cluster> {
    return this.apiService.post(httpKubernetesClusterUpgrade(id), data);
  }

  changeFlavor(id: number, data: UpdateClusterFlavor): Observable<Cluster> {
    return this.apiService.patch(httpKubernetesClusterFlavor(id), data);
  }

  revertAction(id: number): Observable<void> {
    return this.apiService.post(httpKubernetesClusterAction(id), {
      action: 'revert',
    });
  }

  getKubeConfig(id: number): Observable<KubeConfig> {
    return this.apiService.get(httpKubernetesKubeConfig(id));
  }

  updateConfiguration(id: number, data: KubernetesClusterConfiguration): Observable<KubernetesClusterConfiguration> {
    return this.apiService.put(httpKubernetesClusterConfiguration(id), data);
  }

  getConfiguration(id: number): Observable<KubernetesClusterConfiguration> {
    return this.apiService.get(httpKubernetesClusterConfiguration(id));
  }

  getConfigurationHistory(id: number): Observable<KubernetesClusterConfiguration> {
    return this.apiService.get(httpKubernetesClusterConfigurationHistory(id));
  }

  getKubernetesVersionList(): Observable<Version[]> {
    return this.apiService.get(httpListKubernetesVersions(), this.optionsPaginationDeactivated);
  }
}
