import configs from 'app/shared/data/configs';

export const httpApiDocumentation = (ver): string => `doc/v${ver}.json`;
export const httpDeploymentMetrics = (): string => `v${configs.version}/deployment-metrics`;
export const httpSseToken = (): string => `v${configs.version}/sse`;

// Products
export const httpProductsListByType = (typeKey: string | number): string => `v${configs.version}/products/${typeKey}`;
export const httpRegisterProductNotification = (productId: number | string): string => `v${configs.version}/products/${productId}/notify`;

// Switching
export const httpContextSwitcher = (): string => `v${configs.version}/switch-context`;
