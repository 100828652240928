import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { SearchService } from 'app/shared/services/search';
import { first, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromLayout from 'app/store/reducers';

@Component({
  selector: 'myflow-search-btn',
  templateUrl: './search-btn.component.html',
  styleUrls: [ './search-btn.component.scss' ],
})
export class SearchBtnComponent implements OnInit {
  @Output() onsearch = new EventEmitter();
  @ViewChild('input') input: ElementRef;

  show: boolean;
  searchRequest: string;

  constructor(
    private searchService: SearchService,
    private store: Store<fromLayout.State>,
  ) { }

  ngOnInit(): void {
    this.checkSearchSector();

    this.searchRequest = this.searchService.checkSearchRequest();

    if (this.searchRequest) {
      this.show = true;
      this.onsearch.emit(this.searchRequest);
    }
  }

  applyFilter(event: Event = null): void {
    const value = event ? (event.target as HTMLInputElement).value : '';

    this.searchService.setSearchRequest(value);
    this.onsearch.emit(value);
  }

  toggleSearch(): void {
    this.input.nativeElement.focus();
    this.show = !this.show;
    this.cleanSearch();
  }

  private cleanSearch(): void {
    this.searchService.cleanSearchRequest();
    this.searchRequest = '';
    this.applyFilter();
  }

  private checkSearchSector(): void {
    this.store.select(fromLayout.getSector)
      .pipe(
        first(),
        tap(sector => {
          if (!!this.searchService.searchSector && this.searchService.searchSector !== sector) {
            this.cleanSearch();
          }

          this.searchService.searchSector = sector;
        }),
      ).subscribe();
  }
}
