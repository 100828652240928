import { Injectable } from "@angular/core";
import { ProductNotification } from "app/entity/admin";
import { ApiService } from "app/shared/services/api";
import { httpAdminProductNotification, httpAdminProductNotificationNotify, httpAdminProductNotifications } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class NotificationDataService extends GenericDataService<ProductNotification> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminProductNotifications, apiService);
  }

  sendNotificationEmail(id: number): Observable<void> {
    return this.apiService.post(httpAdminProductNotificationNotify(id));
  }

  dismissNotificationEmail(id: number): Observable<void> {
    return this.apiService.delete(httpAdminProductNotification(id));
  }
}
