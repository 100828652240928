import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Volume } from 'app/entity/compute-instances';
import { ClusterVolumeDataService } from 'app/kubernetes/store/cluster-volume/cluster-volume.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { ClusterVolumeTableModel } from '../../models';

@Injectable()
export class ClusterVolumeService extends GenericService<Volume, Volume, Volume, ClusterVolumeTableModel> {
  constructor(
    store: Store<EntityCache>,
    protected dataService: ClusterVolumeDataService,
  ) {
    super('ClusterVolume', store, dataService);
  }

  forceDetachVolume(clusterId: number, volumeId: number, nodeId: number): Observable<void> {
    return this.dataService.forceDetachVolume(clusterId, volumeId, nodeId).pipe(
      tap(() => {
        this.getById(volumeId, this.getContext()).pipe(
          first(),
          tap(volume => {
            this.updateOneInCache(this.getContext(), { ...volume, status: { id: 1, name: 'Available', key: 'available' }, instance: null });
          }),
        ).subscribe();
      }),
    );
  }

  forceDeleteVolume(clusterId: number, volumeId: number): Observable<void> {
    return this.dataService.forceDeleteVolume(clusterId, volumeId).pipe(
      tap(() => {
        this.removeOneFromCache(volumeId);
      }),
    );
  }

  forceDeleteSnapshot(clusterId: number, volumeId: number, snapshotId: number): Observable<void> {
    return this.dataService.forceDeleteSnapshot(clusterId, volumeId, snapshotId).pipe(
      tap(() => {
        this.getById(volumeId, this.getContextSnapshot()).pipe(
          first(),
          tap(volume => {
            volume.snapshotDetails = volume.snapshotDetails.filter(snapshot => snapshot.id !== snapshotId);
            volume.snapshots--;
            this.updateOneInCache(this.getContextSnapshot(), volume);
          }),
        ).subscribe();
      }),
    );
  }

  protected mapEntity(entity: Volume): ClusterVolumeTableModel {
    return {
      id: entity.id,
      name: entity.name,
      size: `${entity.size}GB`,
      node: entity.instance ? {
        id: entity.instance.id,
        name: entity.instance.name,
      } : null,
      snapshotCount: entity.snapshots,
      snapshots: entity.snapshotDetails.map(snapshot => ({
        id: snapshot.id,
        name: snapshot.name,
        size: `${snapshot.size}GB`,
        createdAt: snapshot.createdAt.toString(),
        statusKey: snapshot.status.key,
        statusName: snapshot.status.name,
      })),
      statusKey: entity.status.key,
      statusName: entity.status.name,
    };
  }
}
