import { Injectable } from '@angular/core';
import { CreateIntegration, Integration, UpdateIntegration } from 'app/entity/ci-engine';
import { ApiService } from 'app/shared/services/api';
import { httpCiEngineSubscriptionIntegrations } from 'app/shared/utils/endpoints/ci-engine.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionIntegrationDataService extends GenericDataService<Integration, CreateIntegration, UpdateIntegration> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpCiEngineSubscriptionIntegrations, apiService);
  }
}
