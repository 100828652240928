
<div class="dynamic-field form-group" [formGroup]="group">

  <form-base-label [config]="config" />

  <div class="form-control">
    <mat-chip-grid #chipGrid multiple [formControlName]="config.name">
      <mat-chip-row
        *ngFor="let chip of currentChips"
        (removed)="removeChip(chip)"
      >
        {{chip}}
      </mat-chip-row>
      <input
        #chipInput
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addChip($event)"
      />
    </mat-chip-grid>
  </div>

  <app-form-errors [control]="control" [label]="config.label"></app-form-errors>

</div>
