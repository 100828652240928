import { Status } from 'app/entity/common/status.model';
import { LoadBalancerPoolMember } from 'app/entity/compute-networking/load-balancer-pool-member.model';
import { CreateLoadBalancerHealthCheck, LoadBalancerHealthCheck } from 'app/entity/compute-networking/load-balancer-health-check.model';

export interface LoadBalancerPool {
  id: number;
  name: string;
  status: Status;
  entryProtocol: {
    id: number;
    name: string;
    key: string;
  };
  targetProtocol: {
    id: number;
    name: string;
    key: string;
  };
  certificate?: {
    id: number;
    name: string;
    type: string;
    location: Location;
  };
  entryPort: number;
  algorithm: {
    id: number;
    name: string;
    key: string;
  };
  stickySession: boolean;
  healthCheck: LoadBalancerHealthCheck;
  members?: LoadBalancerPoolMember[];
}

export interface CreateLoadBalancerPool {
  entryProtocolId: number;
  targetProtocolId: number;
  certificateId: number;
  entryPort: number;
  balancingAlgorithmId: number;
  stickySession?: boolean;
  members?: {
    name: string;
    address: string;
    port: number;
  }[];
  healthCheck: CreateLoadBalancerHealthCheck;
}

export interface UpdateLoadBalancerPool {
  certificateId?: number;
  balancingAlgorithmId?: number;
  stickySession?: boolean;
  healthCheck?: CreateLoadBalancerHealthCheck;
}
