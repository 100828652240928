import { Location } from 'app/entity/common';

export interface ObjectStorage {
  id: number;
  name: string;
  location: Location;
}

export interface CreateObjectStorage {
  locationId: number;
}
