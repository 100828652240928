/* eslint-disable @typescript-eslint/no-empty-function */
import { Environment } from 'app/entity/app-engine';
import { Status } from 'app/shared/services/messages/entity-connection';
import { MessageOptions } from 'app/shared/services/messages/handler/message.handler';
import { ToastrService } from 'ngx-toastr';

export class AppEngineEnvironmentMessages implements MessageOptions<Environment> {
  createMessageCallback(_toastr: ToastrService, _entity: Environment): void {}

  updateMessageCallback(_toastr: ToastrService, _entity: Environment): void {}

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void {}

  deleteMessageCallback(_toastr: ToastrService, _entity: Environment): void {}
}
