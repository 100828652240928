import { KeyValue } from '../common';

export interface VirtualPrivateNetworkDeadPeerDetection {
  action: KeyValue;
  interval: number;
  timeout: number;
}

export interface CreateDeadPeerDetection {
  actionId: number;
  interval: number;
  timeout: number;
}
