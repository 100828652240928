<div class="details-buttons" ngbDropdown [open]="open" >
  <div class="button-notification-container" [ngbTooltip]="notificationTooltip" placement="left" tooltipClass="large-tooltip">
    <button class="btn btn-primary dotted-menu" ngbDropdownToggle [class.disabled]="disabled" [disabled]="disabled || loading">
      <myflow-loading-spinner [@zoomInOut]="loading ? 'in' : 'out'" />
      <i  [@zoomInOut]="!loading ? 'in' : 'out'" class="icon-three-dots"></i>
    </button>
    <div class="notification-container" *ngIf="hasNotifications">
      <myflow-notification-icon [notificationCount]="notificationCount" [size]="18" [type]="notificationType"></myflow-notification-icon>
    </div>
  </div>
  <div
      class="dropdown-menu entities-menu"
      aria-labelledby="menu"
      [class]="{'automatic-open-styles' : open}"
      ngbDropdownMenu
  >
    <ng-content></ng-content>
  </div>
</div>
