import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { ValueAccessorBase } from '../../utils/value-accessor';

@Component({
  selector: 'myflow-auto-recharge-edit',
  templateUrl: './auto-recharge.component.html',
  styleUrls: [ './auto-recharge.component.scss' ],
  // eslint-disable-next-line no-use-before-define
  providers: [ ValueAccessorBase.createProviderFor(AutoRechargeComponent) ],
})
export class AutoRechargeComponent extends ValueAccessorBase<number> {
  @Input() maxValue = 1000;
  @Input() step = 10;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  getProgressStyle(): SafeStyle {
    const prog = this.value / this.maxValue;
    const val = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${prog}, transparent), color-stop(${prog}, rgb(158, 158, 158))`;
    return this.sanitizer.bypassSecurityTrustStyle(val);
  }
}
