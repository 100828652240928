import { Injectable } from "@angular/core";
import { UpdateProductInstance } from "app/entity/admin";
import { ProductInstance } from "app/entity/common";
import { ApiService } from "app/shared/services/api";
import { httpAdminOrganizationProductInstances } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationProductInstanceDataService extends GenericDataService<ProductInstance, null, UpdateProductInstance> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrganizationProductInstances, apiService);
  }

  getAllDeleted(context: number[]): Observable<ProductInstance[]> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.apiService.get(this.resolveUrl(context), { params: { 'no_filter': 1, 'active': 0 } });
  }
}
