<mat-paginator #paginator class="d-none" [pageSize]="limit" [pageSizeOptions]="limits" [pageIndex]="page - 1"></mat-paginator>

<div class="entities-table-footer" *ngIf="(dataSource && dataSource.data.length !== 0) || rows.length !== 0 || (showBasedOnSize && size !== 0)">
  <ngb-pagination
    [collectionSize]="size"
    (pageChange)="pageChange($event)"
    [pageSize]="limit"
    [maxSize]="15"
    [(page)]="page"
    aria-label="Default pagination"
  >
    <ng-template ngbPaginationPrevious>
      <i class="icon-angel-left"></i>
    </ng-template>
    <ng-template ngbPaginationNext>
      <i class="icon-angel-right"></i>
    </ng-template>
  </ngb-pagination>

  <div class="entries-wrapper">
    <div class="entries-toggle" *ngIf="showToggle">
      <span [class.bold]="!toggleState">{{leftToggle}}</span>
      <div
        class="toggle toggle-small"
        (click)="toggle()"
        [class.toggled]="toggleState"
      >
        <span class="toggle-indicator"></span>
      </div>
      <span [class.bold]="toggleState">{{rightToggle}}</span>
    </div>

    <p>Entries per Page</p>

    <select [(ngModel)]="limit" (ngModelChange)="setNewLimit($event)">
      <option *ngFor="let entry of limits" [ngValue]="entry">{{entry}}</option>
    </select>
    <i class="icon-angel-down"></i>
  </div>
</div>
