import { Component } from '@angular/core';

import { ValueAccessorBase } from '../../utils/value-accessor';

@Component({
  selector: 'myflow-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
  providers: [
    // eslint-disable-next-line no-use-before-define
    ValueAccessorBase.createProviderFor(CheckboxComponent),
  ],
})
export class CheckboxComponent extends ValueAccessorBase<boolean> {
  toggle(): void {
    this.value = !this.value;
  }
}
