
<div [ngClass]="['ovg-card', 'loading-card', smallCard ? '' : 'big-card']" *ngIf="loading">
  <div class="animated" myflowRandomizeTableLoaderWidth></div>
</div>

<div class="ovg-card" *ngIf="!loading" [ngClass]="[darkAppearance ? 'dark' : '']">
  <div class="ovg-card-content">
    <div class="title-container" *ngIf="title !== null">
      <span class="title">
        {{header}}
        <span class="title-status" *ngIf="status">(<span [ngClass]="statusColor">{{status}}</span>)</span>
      </span>
    </div>
    <span class="value" *ngIf="value !== null" [ngClass]="color">{{value}}</span>
    <ng-content select="[card-content]"></ng-content>
  </div>
  <ng-content select="[small-card-icon]"></ng-content>
  <div class="floating-button-menu">
    <ng-content select="[card-button-menu]"></ng-content>
  </div>
</div>
