import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CaseInterceptor } from 'app/core/interceptors/case.interceptor';
import { ToastrModule } from 'ngx-toastr';

/** Interceptors */
import { AuthInterceptor } from 'app/core/interceptors';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MyFlowToastComponent } from './shared/components';
import { SharedModule } from './shared/shared.module';

import { AppStoreModule } from './store/store.module';

@NgModule({
    declarations: [
        AppComponent,
        MaintenanceComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        NgbModule,
        NgSelectModule,
        ToastrModule.forRoot({
            toastComponent: MyFlowToastComponent,
            positionClass: 'toast-bottom-right',
            newestOnTop: false,
            preventDuplicates: true,
            timeOut: 3000,
            easeTime: 1000,
        }),
        AppStoreModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CaseInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        Title,
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule { }
