import { environment } from 'environments/environment';

const regexp = /(v3\/auth)/i ;
const getListofCountries = /(v2\/entities\/countries)/i ;
const registerOrganization = /(v2\/register)/i ;
const img = /(\/assets)/i;
const resetEmail = /(\/password\/(re)?set)/i;
const activate = /(\/[0-9]+)(\/activate)/i;
const verify = /(\/[0-9]+)(\/verify)/i;

const apiHostUrl = new URL(environment.apiHost);

export const jwtConfig = {
  config: {
    blacklistedRoutes: [
      regexp,
      getListofCountries,
      registerOrganization,
      img,
      resetEmail,
      activate,
      verify,
    ],
    whitelistedDomains: [
      apiHostUrl.host,
    ],
    impersonateHeaderName: 'X-SWITCH-USER',
  },
};
