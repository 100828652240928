import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myflow-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: [ './month-picker.component.scss' ],
})
export class MonthPickerComponent implements OnInit {
  @ViewChild(NgbPopover) popup: NgbPopover;

  @Output() closed = new EventEmitter<{
    data: {
      startDate: Date;
      endDate: Date;
    }|false;
  }>();

  displayedYears: number[];
  displayedMonths: string[] = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep',
    'Okt', 'Nov', 'Dez',
  ];

  currentStage = 0; // first date / second date
  currentState = 0; // year selection / month selection

  selectedStartYear: number;
  selectedStartMonth: number;
  selectedEndYear: number;
  selectedEndMonth: number;
  hoveringYear: number;
  hoveringMonth: number;

  currentYear: number;
  currentMonth: number;

  pageIndex: number;

  private readonly yearsPerPage = 24;
  private readonly minPageIndex = 82; // from 1992

  get startYearSelection(): number {
    return this.selectedStartYear ?? this.hoveringYear;
  }
  get startMonthSelection(): number {
    return this.selectedStartMonth ?? this.hoveringMonth;
  }
  get endYearSelection(): number {
    return this.selectedEndYear ?? this.hoveringYear;
  }
  get endMonthSelection(): number {
    return this.selectedEndMonth ?? this.hoveringMonth;
  }
  get selectedYear(): number {
    return this.currentStage === 0 ? this.selectedStartYear : this.selectedEndYear;
  }

  ngOnInit(): void {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.currentMonth = currentDate.getMonth();

    const firstPageIndex = this.getIndexForYear(this.currentYear);
    this.renderYearPage(firstPageIndex);
  }

  selectYear(year: number): void {
    if (this.currentStage === 0) {
      this.selectedStartYear = year;
    } else {
      this.selectedEndYear = year;
    }

    this.currentState = 1;
  }

  selectMonth(month: number): void {
    if (this.currentStage === 0) {
      this.selectedStartMonth = month;

      this.currentStage = 1;
      this.currentState = 0;
    } else {
      this.selectedEndMonth = month;
      this.submit();
    }
  }

  onHoverYear(year: number): void {
    this.hoveringYear = year;
  }

  offHoverYear(): void {
    this.hoveringYear = null;
  }

  onHoverMonth(month: number): void {
    this.hoveringMonth = month;
  }

  offHoverMonth(): void {
    this.hoveringMonth = null;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  computeYearStyling(year: number): string[] {
    const styles = [];

    const startYear = this.startYearSelection;
    if (startYear) {
      const endYear = this.endYearSelection;

      const isStart = startYear === year;
      const isEnd = endYear === year;
      const futureSelection = endYear && endYear > startYear;
      const pastSelection = endYear && endYear < startYear;

      if (isStart || isEnd) {
        // is selected or being hovered over
        styles.push('selected');

        if (futureSelection) {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          styles.push(isStart ? 'open-right' : 'open-left');
        } else if (pastSelection) {
          styles.push(isStart ? 'open-left' : 'open-right');
        }
      } else if (endYear && ((startYear < year && endYear > year) || (startYear > year && endYear < year))) {
        // is neither selected nor being hovered over but is in between start and end
        styles.push('marked');
      }
    }

    return styles;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  computeMonthStyling(month: number): string[] {
    const styles = [];

    const startMonth = this.startMonthSelection;
    const endMonth = this.endMonthSelection;
    const isStart = startMonth === month;
    const isEnd = endMonth === month;

    if (this.currentStage === 0) {
      if (isStart) {
        styles.push('selected');
      }
    } else {
      const startYear = this.startYearSelection;
      const selectedYear = this.selectedYear;
      const thisYear = startYear === selectedYear;
      const previousYear = startYear < selectedYear;
      const nextYear = startYear > selectedYear;

      const futureSelection = endMonth && (
        (thisYear && endMonth > startMonth) ||
        previousYear
      );
      const pastSelection = endMonth && (
        (thisYear && endMonth < startMonth) ||
        nextYear
      );

      if ((isStart && thisYear) || isEnd) {
        styles.push('selected');

        if (futureSelection) {
          styles.push((isStart && thisYear) ? 'open-right' : 'open-left');
        } else if (pastSelection) {
          styles.push((isStart && thisYear) ? 'open-left' : 'open-right');
        }
      } else if (endMonth && (
        (thisYear && (
          (startMonth < month && endMonth > month) ||
          (startMonth > month && endMonth < month)
        )) ||
        (previousYear && endMonth > month) ||
        (nextYear && endMonth < month)
      )) {
        styles.push('marked');
      }
    }

    return styles;
  }

  changePage(change: number): void {
    this.renderYearPage(this.pageIndex + change);
  }

  changeYear(change: number): void {
    let newYear;

    if (this.currentStage === 0) {
      newYear = this.selectedStartYear += change;
    } else {
      newYear = this.selectedEndYear += change;
    }

    this.renderYearPage(this.getIndexForYear(newYear));
  }

  showYearView(): void {
    const selectedYear = this.selectedYear;

    if (this.currentStage === 0) {
      this.selectedStartYear = null;
    } else {
      this.selectedEndYear = null;
    }

    this.renderYearPage(this.getIndexForYear(selectedYear));
    this.currentState = 0;
  }

  open(): void {
    this.currentStage = 0;
    this.currentState = 0;

    this.selectedStartYear = null;
    this.selectedStartMonth = null;
    this.selectedEndYear = null;
    this.selectedEndMonth = null;

    this.hoveringYear = null;
    this.hoveringMonth = null;

    this.popup.open();
  }

  submit(): void {
    const startDate = new Date(this.selectedStartYear, this.selectedStartMonth, 1, 0, 0, 0, 0);
    const endDate = new Date(this.selectedEndYear, this.selectedEndMonth + 1, 0, 0, 0, 0, 0);

    this.closed.emit({
      data: {
        startDate,
        endDate,
      },
    });

    this.close();
  }

  close(): void {
    this.popup.close();
  }

  private renderYearPage(pageIndex: number): void {
    if (pageIndex < this.minPageIndex) {
      return;
    }

    const firstDisplayedYear = this.yearsPerPage * (pageIndex + 1);

    const years = [];
    for (let i = 0; i < this.yearsPerPage; i++) {
      years.push(firstDisplayedYear + i);
    }
    this.displayedYears = years;
    this.pageIndex = pageIndex;
  }

  private getIndexForYear(year: number): number {
    return Math.floor(year / this.yearsPerPage) - 1;
  }
}
