import { KeyValue } from '../common';

export interface VirtualPrivateNetworkIkePolicy {
  authenticationAlgorithm: KeyValue;
  encryptionAlgorithm: KeyValue;
  diffieHellmanGroup: KeyValue;
  ikeVersion: KeyValue;
  lifetime: number;
}

export interface CreateIkePolicy {
  authenticationAlgorithmId: number;
  encryptionAlgorithmId: number;
  diffieHellmanGroupId: number;
  ikeVersionId: number;
  lifetime: number;
}
