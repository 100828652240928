// @todo place to CORE Module
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { ApiService } from '../shared/services/api/api.service';

@Component({
  selector: 'myflow-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: [ './maintenance.component.scss' ],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  imgBaseLogoUrl = `../../assets/img/${environment.brand}/logo.svg`;
  message = `
    We are currently improving our services and the overall system.
    The portal will be up and running again in no time. Please be patient and try again in a few minutes.
  `;
  interval: number;

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.apiService.error) {
      this.message = this.apiService.error;
    }

    this.interval = setInterval(() => {
      this.router.navigate([ '/dashboard' ]);
    }, 60000) as unknown as number;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
