import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'perWeek',
})
export class PerWeekPipe implements PipeTransform {
  transform(value: number): string {
    return (7 * 24 * value / 730)
      .toFixed(4)
      .toString();
  }
}
