import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { User } from 'app/entity/infrastructure';

import { FieldConfig, OptionsSelectConfig } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@Component({
  selector: 'form-select',
  styleUrls: [ 'form-select.component.scss' ],
  templateUrl: 'form-select.component.html',
})
export class FormSelectComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;

  options: OptionsSelectConfig;

  get isValid(): boolean {
    return this.group.controls[this.config.name].valid;
  }

  get isDirty(): boolean {
    return this.group.controls[this.config.name].dirty;
  }

  get isTouched(): boolean {
    return this.group.controls[this.config.name].touched;
  }

  get errors(): ValidationErrors {
    return this.group.controls[this.config.name];
  }

  get control(): AbstractControl {
    return this.group.controls[this.config.name];
  }

  ngOnInit(): void {
    this.options = {
      items: null,
      multiple: false,
      bindLabel: 'value',
      bindValue: 'id',
      ...this.config.options,
    };
  }

  customSearchFn(term: string, item: User): any {
    term = term.toLocaleLowerCase();
    return item.username.toLocaleLowerCase().indexOf(term) > -1 ||
      item.firstname.toLocaleLowerCase().indexOf(term) > -1 ||
      item.lastname.toLocaleLowerCase().indexOf(term) > -1;
  }
}
