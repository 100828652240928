import { Injectable } from '@angular/core';
import { GlobalQuota, UpdateGlobalQuotaLimits } from 'app/entity/admin/quota.model';
import { ApiService } from 'app/shared/services/api';
import { httpAdminQuotas } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class QuotaDataService extends GenericDataService<GlobalQuota, null, UpdateGlobalQuotaLimits> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminQuotas, apiService);
  }
}
