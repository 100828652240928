import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: [ './info-tooltip.component.scss' ],
})
export class InfoTooltipComponent {
  @Input() message: string;
  @Input() iconPrimary = false;
}
