<div class="range-wrapper">
  <div class="row mb-3">
    <div class="col-sm">
      <strong>Auto-Recharge Amount</strong>
    </div>
    <div class="col-sm text-right">
      <strong class="text-danger" *ngIf="amount === 0">
        Disabled
      </strong>
    </div>
  </div>
  <div class="row text-muted">
    <div class="col-sm">
      <p>Set the amount in CHF you want to automatically recharge when your credit goes below 5.00 CHF in the slider below.</p>
      <p>Put to zero if you want to disable the auto recharge functionality.</p>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm">
      <input type="range"
        [(ngModel)]="amount"
        (ngModelChange)="onChange($event)"
        name="amount"
        [attr.max]="max"
        [max]="max"
        [attr.min]="min"
        [min]="min"
        [step]="step"
        id="range-input"
        myflowColorizedRange
      />
    </div>
  </div>

  <div class="row order" [@slideInOut]="(amount >= minShow) ? 'in' : 'out'">
    <div class="col"> 
      <div class="row">
        <div class="col-sm">Net subtotal</div>
        <div class="col-sm text-right">
          {{ amount | customCurrency : 'CHF' }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          {{ vat.toFixed(2) }}% VAT <span *ngIf="vat">({{ vatLabel }})</span>
        </div>
        <div class="col-sm text-right">
          {{ (amount * vat / 100) | customCurrency : 'CHF' }}
        </div>
      </div>
      <div class="dropdown-divider"></div>
      <div class="row total">
        <div class="col-sm">Order total</div>
        <div class="col-sm text-right order-total">
          {{ ((amount * vat / 100) + amount) | customCurrency : 'CHF' }}
        </div>
      </div>
    </div>
  </div>

</div>
