import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InstanceSecurityGroupDataService } from 'app/compute/store/instance-security-group/instance-security-group.data';
import {
  AttachSecurityGroups,
  CreateSecurityGroup,
  NetworkInterface,
  SecurityGroup,
  UpdateSecurityGroup,
} from 'app/entity/compute-networking';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class InstanceSecurityGroupService extends GenericService<SecurityGroup, CreateSecurityGroup, UpdateSecurityGroup> {
  constructor(
    store: Store<EntityCache>,
    private instanceSecurityGroupDataService: InstanceSecurityGroupDataService,
  ) {
    super('InstanceSecurityGroup', store, instanceSecurityGroupDataService);
  }

  attachToNetworkInterface(id: number, data: AttachSecurityGroups, context?: number[]): Observable<NetworkInterface> {
    const ctx = context ?? this.getContext();
    return this.instanceSecurityGroupDataService.attachToNetworkInterface(ctx, id, data);
  }
}
