import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'perDay',
})
export class PerDayPipe implements PipeTransform {
  transform(value: number): string {
    return (24 * value / 730)
      .toFixed(4)
      .toString();
  }
}
