import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';

import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ColumnDirective } from 'app/shared/directives/table/column.directive';
import { TableService, TableState } from 'app/shared/services/table';
import { showPlates, showTable } from 'app/store/actions/layout.actions';
import * as fromRoot from 'app/store/reducers';
import { getShowTable } from 'app/store/reducers';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DummyRecord } from '../../../entity/store';

@Component({
  selector: 'myflow-ngrx-table',
  templateUrl: './table-ngrx.component.html',
  styleUrls: [ './table-ngrx.component.scss' ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableNgrxComponent<T> implements OnInit, OnDestroy, AfterViewInit {
  @Input() items: Observable<T[]>;
  @Input() loaded: boolean;
  @Input() noDummy: boolean;
  @Input() length: number;

  @Input() sortActiveColumn: string;
  @Input() sortActiveDirection: 'asc' | 'desc';
  @Input() sortDisabled: boolean;

  //Custom Toggle configuration
  @Input() customToggle: boolean;
  @Input() leftToggleLabel: string;
  @Input() rightToggleLabel: string;
  @Input() customToggleState: boolean;
  @Input() handlePagination = true;
  @Output() customToggleEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() pagination: EventEmitter<any> = new EventEmitter();

  //Card view and pagination
  @Input() instanceTable: boolean;
  @Input() cardView = true;
  @Input() disablePagination = false;
  @Input() expandable = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('collapseRef') collapse: TemplateRef<any>;

  @Input() isItemExpandable: (item: T) => boolean = () => true;

  //Sorting manually
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() sortData: (items: any[], sort: MatSort) => any[];

  /* eslint-disable @typescript-eslint/member-ordering */

  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ViewChild(MatSort) sort: MatSort;
  @ContentChildren(ColumnDirective) columns: QueryList<ColumnDirective>;

  displayedColumns: string[] = [];

  dataSource = new MatTableDataSource([]);
  limit = 10;
  page = 1;
  empty = false;
  paginationLoaded = false;

  tableView: boolean;

  titleColumn: ColumnDirective;
  headerColumns: ColumnDirective[];
  headerMenuColumns: ColumnDirective[];
  contentColumns: ColumnDirective[];
  expandedElement: unknown | null;

  private readonly url: string;
  private destroyed$ = new Subject();

  constructor(
    private store: Store<fromRoot.State>,
    private tableService: TableService,
    private router: Router,
  ) {
    this.store.select(getShowTable)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(table => this.tableView = table);

    this.url = this.router.url;

    const newState = this.tableService.getTableState(this.url);
    if (newState) {
      this.limit = newState.limit || this.limit;
      this.page = newState.page || this.page;
    }

    this.applySortingDataAccessor();
  }

  onCustomToggleEvent(newState: boolean): void {
    this.customToggleEvent.emit(newState);
  }

  /* eslint-enable @typescript-eslint/member-ordering */

  applySortingDataAccessor(): void {
    this.dataSource.sortingDataAccessor = (data, property): string | number => {
      const column = this.columns.find(col => col.column === property);
      if (column && column.sortFunction) {
        return column.sortFunction(data);
      }

      let val = data[property];
      if (!val) {
        return null;
      }

      if (typeof val !== 'object') {
        return val;
      }

      if (Array.isArray(val) && val.length > 0) {
        val = val[0];
      }

      if (Object.prototype.hasOwnProperty.call(val, 'name')) {
        return val.name;
      }

      if (Object.prototype.hasOwnProperty.call(val, 'id')) {
        return val.id;
      }

      return val;
    };
  }

  ngOnInit(): void {
    if (this.sortData) {
      this.dataSource.sortData = this.sortData;
    }
    if (this.disablePagination) {
      this.paginationLoaded = true;
    }
    this.empty = true;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.items.pipe(takeUntil(this.destroyed$)).subscribe(items => {
      this.empty = !items || items.length === 0;
      this.dataSource.data = items;
    });

    setTimeout(() => {
      this.displayedColumns = this.columns.filter(column => !column.cardOnly).map(cell => cell.column);

      this.titleColumn = this.columns.find(cell => cell.cardTitle);
      this.headerColumns = this.columns.filter(cell => cell.cardHeader);
      this.headerMenuColumns = this.columns.filter(cell => cell.cardHeaderMenu);
      this.contentColumns = this.columns.filter(cell => (!cell.cardHeader && !cell.cardHeaderMenu && !cell.cardTitle));
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  switchView(cards: boolean): void {
    if (cards) {
      this.store.dispatch(showPlates());
    } else {
      this.store.dispatch(showTable());
    }
  }

  updateTableState(newState: TableState): void {
    this.tableService.setTableState(this.url, newState);
  }

  updatePaginationLoaded(newState: any): void {
    this.paginationLoaded = newState;
  }

  updateLimitState(newState: any): void {
    if (!this.handlePagination) {
      this.pagination.emit({ limit: newState });
    }
  }

  updatePageState(newState: any): void {
    if (!this.handlePagination) {
      this.pagination.emit({ page: newState });
    }
  }

  isDummyRecord(item: any): boolean {
    return !!(item as DummyRecord<any>)?.orderRef && !this.noDummy;
  }

  expandElement(element: T): void {
    if (!this.isItemExpandable(element)) {
      return;
    }

    this.expandedElement = this.expandedElement === element ? null : element;
  }
}
