import { Injectable } from '@angular/core';
import { CreateIntegration, Integration, UpdateIntegration } from 'app/entity/ci-engine';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { Store } from '@ngrx/store';
import { IntegrationDataService } from './integration.data';

@Injectable()
export class IntegrationService extends GenericService<Integration, CreateIntegration, UpdateIntegration> {
  constructor(
    store: Store<EntityCache>,
    private integrationDataService: IntegrationDataService,
  ) {
    super('Integration', store, integrationDataService);
  }
}
