import { ContentChild, Directive, Input } from '@angular/core';
import { CellDefDirective } from 'app/shared/directives/table/cell-def.directive';

@Directive({
  selector: '[myflowColumn]',
})
export class ColumnDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('columnName') column: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('columnTitle') title: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('columnWidth') width: string | string[];

  @Input() sortable: boolean;
  @Input() sortFunction: ((data: any) => string | number);

  @Input() cardTitle: boolean;
  @Input() cardHeader: boolean;
  @Input() cardHeaderMenu: boolean;
  @Input() cardOnly: boolean;

  @ContentChild(CellDefDirective) cellDef: CellDefDirective;
}
