import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-os-icon',
  template: `<img class="icon" [src]="getOsIconURL()" *ngIf="osName" [alt]="osName">`,
})
export class OsIconComponent {
  @Input() osName: string;

  getOsIconURL(): string {
    switch (this.osName) {
      case 'Windows Server':
        return `assets/img/os/windows_server.svg`;
      case 'Debian':
        return `assets/img/os/debian.svg`;
      case 'CentOS':
        return `assets/img/os/centOS.svg`;
      case 'Arch Linux':
        return `assets/img/os/arch_linux.svg`;
      case 'Fedora CoreOS':
        return `assets/img/os/coreOS.svg`;
      case 'RancherOS':
        return `assets/img/os/rancherOS.svg`;
      case 'Fedora':
        return `assets/img/os/fedora_atomic.svg`;
      case 'macOS':
        return `assets/img/os/macmini.svg`;
      case 'macOSm1shiny':
        return `assets/img/os/macmini-m1-shiny.svg`;
      case 'macOSm1':
        return `assets/img/os/macmini-m1.svg`;
      case 'macOSm1studioshiny':
        return `assets/img/os/mac-studio-m1-shiny.svg`;
      case 'macOSm1studio':
        return `assets/img/os/mac-studio-m1.svg`;
      case 'macOSm2shiny':
        return `assets/img/os/macmini-m2-shiny.svg`;
      case 'macOSm2':
        return `assets/img/os/macmini-m2.svg`;
      case 'macOSm2proshiny':
        return `assets/img/os/macmini-m2-pro-shiny.svg`;
      case 'macOSm2pro':
        return `assets/img/os/macmini-m2-pro.svg`;
      case 'VyOS':
        return `assets/img/os/vyOS.svg`;
      case 'FortiGate':
        return `assets/img/os/forti_gate.svg`;
      case 'Flatcar':
        return `assets/img/os/flatcar-logo.svg`;
      case 'BSD':
        return `assets/img/os/freebsd-logo.svg`;
      case 'RHEL derivatives':
        return `assets/img/os/RHEL.svg`;
      case 'Kubernetes':
        return 'assets/img/os/kubernetes_logo.svg';
      case 'Ubuntu':
      default:
        return `assets/img/os/ubuntu.svg`;
    }
  }
}
