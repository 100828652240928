import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: [ './form-errors.component.scss' ],
})
export class FormErrorsComponent {
  @Input() control: any;
  @Input() label: string;
  @Input() pattern: string;

  constructor() {
    this.label = this.label ? this.label : 'This field';
    this.pattern = this.pattern ? this.pattern : `${this.label} does not match the pattern.`;
  }
}
