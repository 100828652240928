<section class="details" *ngIf="item; else loading">
  <div #header class="details-header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div
    #content
    class="details-content {{type}}"
  >
    <ng-content select="[content]"></ng-content>
  </div>
</section>

<ng-template #loading>
  <myflow-loading-page class="mt-5" [type]="type" *ngIf="type !== 'simple'"></myflow-loading-page>
</ng-template>
