import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';

/** Store */
import { metaReducers, reducers } from './reducers';

import { AuthEffects } from './effects/auth.effects';
import { OrganizationEffects } from './effects/organization.effects';
import { UserEffects } from './effects/user.effects';
import { EntityStoreModule } from './entity-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'MYFlow',

      // In a production build you would want to disable the Store Devtools
      // logOnly: environment.production
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://ngrx.io/guide/effects#registering-root-effects
     */
    EffectsModule.forRoot([ AuthEffects, UserEffects, OrganizationEffects ]),

    EntityStoreModule,
  ],
})
export class AppStoreModule {}
