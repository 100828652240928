import { LoadBalancerPool } from 'app/entity/compute-networking/load-balancer-pool.model';
import { Status } from 'app/entity/common/status.model';
import { Location } from 'app/entity/common/location.model';
import { Product } from 'app/entity/common/product.model';
import { AttachedNetwork } from 'app/entity/compute-networking/network.model';

export interface LoadBalancer {
  id: number;
  name: string;
  location: Location;
  product: Product;
  status: Status;
  balancingPools: LoadBalancerPool[];
  networks: AttachedNetwork[];
}

export interface CreateLoadBalancer {
  name: string;
  locationId: number;
  attachExternalIp: boolean;
  networkId: number;
  privateIp?: number;
}

export interface UpdateLoadBalancer {
  name: string;
}
