import { Location } from 'app/entity/common';
import { ProductItem } from 'app/entity/common/product-item.model';
import { UsageCycle } from 'app/entity/usage-cycle';

export interface Availability {
  location: Location;
  available: number;
}

export interface DeploymentFee {
  location: Location;
  price: number;
  freeDeployments: number;
}

export interface Commitment {
  startDate: string;
  endDate: string;
  renew: boolean;
}

export interface Product {
  id: number;
  name: string;
  productName: string;
  type: {
    id: number;
    key: string;
    name: string;
  } | string;
  visibility: string;
  usageCycle: UsageCycle;
  items: ProductItem[];
  price?: number;
  availability: Availability[];
  category: string | null;
  deploymentFees: DeploymentFee[];
  entity?: number;
  children?: Product[];
  commitment: Commitment | null;
}

export enum ProductItems {
  PROCESSOR = 'Processor',
  MEMORY = 'Memory',
  STORAGE = 'Storage',
}

export interface BriefProduct {
  id: number;
  name: string;
  type: string;
}

export const isProductCurrentlyAvailableAt = (product: Product, location: Location): boolean => {
  for (const availability of product.availability) {
    if (availability.location.id === location.id) {
      return availability.available !== 0;
    }
  }
  return false;
};

export const isProductUsuallyAvailableAt = (product: Product, location: Location): boolean =>
  !!product.availability.find(availability => availability.location.id === location.id);

export const isM1MacMini = (product: Product): boolean => /^macmini[.]m1[.].*$/.test(product.productName);
export const isM1MacStudio = (product: Product): boolean => /^(d.)?studio[.]m1-max[.].*$/.test(product.productName);
export const isM2MacMini = (product: Product): boolean => /^(d.)?macmini[.]m2[.].*$/.test(product.productName);
export const isM2MacMiniPro = (product: Product): boolean => /^(d.)?macmini[.]m2-pro[.].*$/.test(product.productName);

export const extractMacDeviceType = (product: Product, shiny = false): string => {
    if (isM1MacMini(product)) {
      return 'macOSm1' + (shiny ? 'shiny' : '');
    }
    if (isM1MacStudio(product)) {
      return 'macOSm1studio' + (shiny ? 'shiny' : '');
    }
    if (isM2MacMini(product)) {
      return 'macOSm2' + (shiny ? 'shiny' : '');
    }
    if (isM2MacMiniPro(product)) {
      return 'macOSm2pro' + (shiny ? 'shiny' : '');
    }
    return 'macOS';
};


export interface GroupedProducts {
  [key: string]: Product[];
}

export const groupByProductCategory = (products: GroupedProducts, product: Product): GroupedProducts => {
  const type = product.category;

  if (!products[type]) {
    products[type] = [];
  }

  products[type].push(product);

  return products;
};

export const flattenGroupedProducts = (grouped: GroupedProducts): Product[][] => {
  const result = [];

  for (const key of Object.keys(grouped)) {
    result.push(grouped[key]);
  }

  return result;
};
