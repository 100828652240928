import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ApplicationToken, CreateApplicationToken, UpdateApplicationToken } from 'app/entity/infrastructure/application-token.model';
import { ApplicationTokensDataService } from 'app/organization/store/application-token/application-token.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ApplicationTokensService extends GenericService<ApplicationToken, CreateApplicationToken, UpdateApplicationToken> {
  constructor(
    store: Store<EntityCache>,
    applicationTokenDataService: ApplicationTokensDataService,
  ) {
    super('ApplicationToken', store, applicationTokenDataService);
  }

  create(create: CreateApplicationToken, context?: number[]): Observable<ApplicationToken> {
    const ctx = context || this.getContext();
    return this.dataService.create(ctx, create).pipe(
      map((entity: ApplicationToken) => {
        entity.tokenToShow = entity.token;
        entity.token = entity.token.substring(0, 4);

        return entity as ApplicationToken;
      }),
      tap(entity => this.store.addOne(ctx, entity)),
    );
  }
}
