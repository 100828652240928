import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateReleaseNote, ReleaseNote, UpdateReleaseNote } from 'app/entity/admin';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { ReleaseNoteDataService } from './release-note.data';

@Injectable()
export class ReleaseNoteService extends GenericService<ReleaseNote, CreateReleaseNote, UpdateReleaseNote> {
  constructor(
    store: Store<EntityCache>,
    dataService: ReleaseNoteDataService,
  ) {
    super('AdminReleaseNote', store, dataService);
  }
}
