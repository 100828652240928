import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { autoPlayVideo } from 'app/shared/utils/video-autoplay';

@Component({
  selector: 'myflow-table-video-jumbotron',
  templateUrl: './table-video-jumbotron.component.html',
  styleUrls: [ './table-video-jumbotron.component.scss' ],
})
export class TableVideoJumbotronComponent implements AfterViewInit {
  @ViewChild('video') video: ElementRef;

  @Input() entity?: string;
  @Input() description?: string;
  @Input() action: string;
  @Input() product: 'compute' | 'kubernetes' | 'app-engine' | 'mac-bare-metal' | 'ci-engine';

  @Output() actionClick = new EventEmitter();

  async ngAfterViewInit(): Promise<void> {
    if (this.video) {
      autoPlayVideo(this.video);
    }
  }
}
