import { Injectable } from '@angular/core';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

import { ApiService } from 'app/shared/services/api';

import {
  httpComputedVolumeSnapshotRevert,
  httpComputedVolumeSnapshots,
} from 'app/shared/utils';
import { CreateSnapshot, Snapshot, UpdateSnapshot } from 'app/entity/compute-instances';

@Injectable({
  providedIn: 'root',
})
export class VolumeSnapshotDataService extends GenericDataService<Snapshot, CreateSnapshot, UpdateSnapshot> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedVolumeSnapshots, apiService);
  }

  revert(id: number, data: { snapshotId: number }): Observable<void> {
    return this.apiService.post(httpComputedVolumeSnapshotRevert(id), data);
  }
}
