
export interface VirtualPrivateNetworkRemoteEndpoint {
  peerIp: string;
  cidrs: string[];
}

export interface CreateRemoteEndpoint {
  peerIp: string;
  cidrs: string[];
}
