
export interface Order {
  id: number;
  status: {
    id: number;
    name: string;
  };
  productInstance: {
    id: number;
    productName: string;
  };
}
