import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateLoadBalancer, LoadBalancer, LoadBalancerStatistics, UpdateLoadBalancer } from 'app/entity/compute-networking';
import { LoadBalancerDataService } from 'app/networking/store/load-balancer/load-balancer.data';
import { LocationService } from 'app/core/services/location';
import { OrderMessagesService } from 'app/shared/services/messages/order-messages.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoadBalancerService extends GenericService<LoadBalancer, CreateLoadBalancer, UpdateLoadBalancer> {
  constructor(
    store: Store<EntityCache>,
    orderMessagesService: OrderMessagesService,
    protected dataService: LoadBalancerDataService,
    private locationService: LocationService,
  ) {
    super('LoadBalancer', store, dataService, orderMessagesService);
  }

  getStatistics(id: number): Observable<LoadBalancerStatistics> {
    return this.dataService.getStatistics(id);
  }

  attachElasticIP(id: number, eipId: number, context?: number[]): Observable<LoadBalancer> {
    const ctx = context || this.getContext();
    return this.dataService.attach(id, eipId).pipe(
      tap(loadBalancer => this.store.update(ctx, loadBalancer)),
    );
  }

  detachElasticIP(id: number): Observable<void> {
    return this.dataService.detach(id);
  }

  createDummyRecord(create: CreateLoadBalancer): LoadBalancer {
    const location = this.locationService.moduleLocations(5).find(l => l.id === create.locationId);

    return {
      id: new Date().valueOf(),
      name: create.name,
      status: {
        id: 3,
        name: 'Creating',
        key: 'creating',
        actions: [],
      },
      location: {
        id: location.id,
        key: location.key,
        name: location.name,
      },
      product: null,
      networks: [],
      balancingPools: [],
    };
  }
}
