import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-fancy-separator',
  templateUrl: './fancy-separator.component.html',
  styleUrls: [ './fancy-separator.component.scss' ],
})
export class FancySeparatorComponent {
  @Input() iconUrl: string;
}
