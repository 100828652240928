import { Injectable } from '@angular/core';
import { LoadBalancerEntityField } from 'app/entity';
import { CreateLoadBalancerPool, LoadBalancerPool, UpdateLoadBalancerPool } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import {
  httpLoadBalancerBalancingAlgorithms,
  httpLoadBalancerBalancingHealthTypes,
  httpLoadBalancerPools,
  httpLoadBalancerProtocols,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadBalancerPoolDataService extends GenericDataService<LoadBalancerPool, CreateLoadBalancerPool, UpdateLoadBalancerPool> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpLoadBalancerPools, apiService);
  }

  getLoadBalancerProtocols(): Observable<LoadBalancerEntityField[]> {
    return this.apiService.get(httpLoadBalancerProtocols());
  }

  getLoadBalancerBalancingAlgorithms(): Observable<LoadBalancerEntityField[]> {
    return this.apiService.get(httpLoadBalancerBalancingAlgorithms());
  }

  getLoadBalancerBalancingHealthTypes(): Observable<LoadBalancerEntityField[]> {
    return this.apiService.get(httpLoadBalancerBalancingHealthTypes());
  }
}
