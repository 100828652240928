<div
  class="dynamic-field form-input form-group"
  [formGroup]="group"
>
  <ng-container *ngIf="collapsed">
    <span class="link" (click)="collapsed = false">{{config.collapsable.placeholderLabel}}</span>
  </ng-container>

  <ng-container *ngIf="!collapsed">
    <form-base-label [config]="config" />

    <input
      type="text"
      [attr.placeholder]="config.placeholder"
      [formControlName]="config.name"
      class="form-control"
      [required]="config.required"
      [id]="config.name"
    >

    <div *ngIf="config.hint" class="form-text text-muted">
      {{ config.hint }}
    </div>

    <app-form-errors [control]="control" [label]="config.label" pattern="code must match the pattern XXX-XXX-XXX"></app-form-errors>
  </ng-container>
</div>
