import { MessageOptions } from './handler/message.handler';
import { ToastrService } from 'ngx-toastr';
import { Status } from './entity-connection';
import { Ticket } from '../../../entity/tickets';

export class SupportTicketMessages implements MessageOptions<Ticket> {
  createMessageCallback(_toastr: ToastrService, _entity: Ticket): void | Promise<void> {
    return undefined;
  }

  deleteMessageCallback(_toastr: ToastrService, _entity: Ticket): void | Promise<void> {
    return undefined;
  }

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void | Promise<void> {
    return undefined;
  }

  updateMessageCallback(_toastr: ToastrService, _entity: Ticket): void | Promise<void> {
    return undefined;
  }
}
