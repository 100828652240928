import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeviceSecurityGroupDataService } from 'app/baremetal/store/device-security-group/device-security-group.data';
import { AttachedSecurityGroup } from 'app/entity/mbm-networking';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

@Injectable()
export class DeviceSecurityGroupService extends GenericService<AttachedSecurityGroup> {
  constructor(
    store: Store<EntityCache>,
    private deviceSecurityGroupDataService: DeviceSecurityGroupDataService,
  ) {
    super('DeviceSecurityGroup', store, deviceSecurityGroupDataService);
  }

  attachSecurityGroup(id: number, networkInterfaceId: number, context?: number[]): Observable<AttachedSecurityGroup> {
    const ctx = context ?? this.getContext();

    return this.deviceSecurityGroupDataService.attachSecurityGroup(ctx, networkInterfaceId, id).pipe(
      map(networkInterface => ({
        id: networkInterface.securityGroup.id,
        name: networkInterface.securityGroup.name,
        networkInterfaces: [ networkInterface ],
      })),
      tap(attachedSecurityGroup => this.addOneToCache(ctx, attachedSecurityGroup)),
      concatMap(attachedSecurityGroup => this.store.loadAll().pipe(
        tap(securityGroups => {
          securityGroups.forEach(securityGroup => {
            if (securityGroup.id === attachedSecurityGroup.id) {
              return;
            }
            this.removeOneFromCache(securityGroup.id);
          });
        }),
        map(() => attachedSecurityGroup),
      )),
    );
  }
}
