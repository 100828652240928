import { Injectable } from '@angular/core';
import { Volume } from 'app/entity/compute-instances';
import { ApiService } from 'app/shared/services/api';
import {
  httpKubernetesClusterVolume, httpKubernetesClusterVolumeNode,
  httpKubernetesClusterVolumes,
  httpKubernetesClusterVolumeSnapshot,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClusterVolumeDataService extends GenericDataService<Volume> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpKubernetesClusterVolumes, apiService);
  }

  forceDetachVolume(clusterId: number, volumeId: number, nodeId: number): Observable<void> {
    return this.apiService.delete(httpKubernetesClusterVolumeNode(clusterId, volumeId, nodeId));
  }

  forceDeleteVolume(clusterId: number, volumeId: number): Observable<void> {
    return this.apiService.delete(httpKubernetesClusterVolume(clusterId, volumeId));
  }

  forceDeleteSnapshot(clusterId: number, volumeId: number, snapshotId: number): Observable<void> {
    return this.apiService.delete(httpKubernetesClusterVolumeSnapshot(clusterId, volumeId, snapshotId));
  }
}
