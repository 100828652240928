import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Subscription, SubscriptionFlavor } from 'app/entity/ci-engine';
import { SubscriptionFlavorDataService } from './subscription-flavor.data';
import { Observable, map } from 'rxjs';

@Injectable()
export class SubscriptionFlavorService extends GenericService<SubscriptionFlavor> {
  EssentialFlavorId = 1;
  EnterpriseFlavorId = 2;

  // Shows which flavors are available per already selected flavor
  availableFlavorIds = new Map<number, number[]>([
    [ 1, [ 2 ] ],
    [ 2, [] ],
  ]);

  constructor(
    store: Store<EntityCache>,
    private subscriptionFlavorDataService: SubscriptionFlavorDataService,
  ) {
    super('SubscriptionFlavor', store, subscriptionFlavorDataService);
  }

  /**
   * Returns the available flavors for a subscription (including the active flavor)
   */
  getAvailableFlavors(subscription: Subscription): Observable<SubscriptionFlavor[]> {
    return super.entities$.pipe(
      map(flavors => {
        // First find the active flavor using the product
        const activeFlavor = flavors.find(
          (flavor) => flavor.product.id === subscription.product.id,
        );
        if (!activeFlavor) {
          return [];
        }
        const activeFlavorId = activeFlavor.id;

        // Find the available flavors based on the id
        const availableFlavors = this.availableFlavorIds.get(activeFlavorId);

        return flavors.filter(
          (flavor) => availableFlavors.includes(flavor.id) || flavor.id === activeFlavorId,
        );
      }),
    );
  }

  subscriptionUpgradeAvailable(subscription: Subscription): Observable<boolean> {
    return super.entities$.pipe(
      map(flavors => {
        // Find the flavor based on the subscription product
        const subscriptionFlavor = flavors.find(
          (flavor) => flavor.product.id === subscription.product.id,
        );
        if (!subscriptionFlavor) {
          return false;
        }
        const subscriptionFlavorId = subscriptionFlavor.id;

        // Check if any flavor upgrades are available
        return this.availableFlavorIds.get(subscriptionFlavorId).length !== 0;
      }),
    );
  }
}
