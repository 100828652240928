import { createAction, props, union } from '@ngrx/store';
import { Organization } from 'app/entity/infrastructure/organization.model';
import { PaymentMethodModel } from 'app/entity/billing/payment-method.model';

export const getOrganization = createAction(
  '[Organization] Get Organization',
);

export const setOrganization = createAction(
  '[Organization] Set Default Organization',
  props<{ organization: Organization }>(),
);

// Actions For Payments
export const getOrganizationPayments = createAction('[Organization] Get Payment Methods');
export const getOrganizationPaymentsSuccess = createAction(
  '[Organization] Get Payment Methods Successful',
  props<{ payments: PaymentMethodModel[] }>(),
);
export const getOrganizationPaymentsFailure = createAction(
  '[Organization] Get Payment Methods Failure',
  props<{ error: any }>(),
);

// Actions For Balance
export const getBalance = createAction(
  '[Organization] Get Balance',
);
export const getBalanceSuccess = createAction(
  '[Organization] Get Balance Success',
  props<{ balance: any }>(),
);
export const getBalanceFailure = createAction(
  '[Organization] Get Balance Failure',
  props<{ error: any }>(),
);

const all = union({
  getOrganization,
  setOrganization,
  getOrganizationPayments,
  getOrganizationPaymentsSuccess,
  getOrganizationPaymentsFailure,
  getBalance,
  getBalanceSuccess,
  getBalanceFailure,
});
export type OrganizationActionsUnion = typeof all;
