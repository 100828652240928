<div class="info-panel" class="info-panel-style-{{type}}">
  <span class="bold">
    <i
      class="icon-information-circle"
      *ngIf="!noIcon"
      [ngbTooltip]="tooltip"
      placement="bottom"  
    ></i>
    <ng-content select="[title]" />
  </span>
  <span>
    <ng-content />
  </span>
</div>