import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject } from 'rxjs';
import { DynamicFormComponent } from '../dynamic-form/containers/dynamic-form/dynamic-form.component';

@Component({
  selector: 'myflow-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: [ './dialog.component.scss' ],
})
export class DialogComponent {
  @Input() data: any;
  @Input() fields: any;
  @Input() action = 'Save';
  @Input() title = 'Add';
  @Input() loading$ = new BehaviorSubject(false);
  @Output() out = new EventEmitter();
  @Output() closing = new EventEmitter();

  @ViewChild('form') form: DynamicFormComponent;

  constructor (
    public activeModal: NgbActiveModal,
  ) { }

  onSubmit(): void {
    /** Hardcoded disabled field value (WC3 standard: disabled never be valid) */
    if (this.form.valid || (this.form.controls[0].label !== 'Product' && this.form.controls.length === 1)) {
      this.loading$.next(true);
      this.out.emit(this.form.value);
    } else {
      this.out.emit(false);
    }
  }

  close(): void {
    let result = false;

    if (this.form && this.form.form) {
      result = this.form.form.dirty;
    }

    if (result || !this.form) {
      this.closing.emit(true);
    } else {
      this.activeModal.close(result);
    }
  }
}
