import { Injectable } from '@angular/core';
import {
  AttachSecurityGroups,
  CreateSecurityGroup,
  NetworkInterface,
  SecurityGroup,
  UpdateSecurityGroup,
} from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import { httpComputedInstanceNetworkInterfaceSecurityGroup, httpComputedInstanceSecurityGroups } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstanceSecurityGroupDataService extends GenericDataService<SecurityGroup, CreateSecurityGroup, UpdateSecurityGroup> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedInstanceSecurityGroups, apiService);
  }

  attachToNetworkInterface(context: number[], networkInterfaceId: number, data: AttachSecurityGroups): Observable<NetworkInterface> {
    return this.apiService.patch(httpComputedInstanceNetworkInterfaceSecurityGroup(context[0], networkInterfaceId), data);
  }
}
