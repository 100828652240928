import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { AuthActions } from 'app/store/actions';
import * as fromAuth from 'app/store/reducers';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { jwtConfig } from '../configs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  whitelistedDomains: (string | RegExp)[];
  blacklistedRoutes: (string | RegExp)[];
  headerName: string;

  private token: string;
  private isAuthed: boolean;

  constructor (
    private localStorageService: LocalStorageService,
    private store: Store<fromAuth.State>,
  ) {
    this.whitelistedDomains = jwtConfig.config.whitelistedDomains || [];
    this.blacklistedRoutes = jwtConfig.config.blacklistedRoutes || [];
    this.headerName = 'Authorization';

    this.store.select(fromAuth.getToken)
      .pipe(
        map(token => this.token = token),
        switchMap(() => this.store.select(fromAuth.getAuthed)),
        map(authed => this.isAuthed = authed),
      ).subscribe();
  }

  isBlacklistedRoute(request: HttpRequest<any>): boolean {
    const url = request.url;
    return (
      this.blacklistedRoutes.findIndex(
        route =>
          typeof route === 'string' ? route === url : route instanceof RegExp ? route.test(url) : false,
      ) > -1
    );
  }

  isWhitelistedDomain(request: HttpRequest<any>): boolean {
    const requestUrl = new URL(request.url);

    return (
      this.whitelistedDomains.findIndex(
        domain =>
          typeof domain === 'string'
            ? domain === requestUrl.host
            : domain instanceof RegExp ? domain.test(requestUrl.host) : false,
      ) > -1
    );
  }

  handleInterception(authToken: string, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (authToken && this.isWhitelistedDomain(req) && !this.isBlacklistedRoute(req)) {
      req = req.clone({ setHeaders: { [this.headerName]: `Bearer ${authToken}` } });
    } else if (!authToken || this.isBlacklistedRoute(req)) {
      req = req.clone();
    }

    if (!req.headers.has('Content-Type') && !this.isFormData(req.body)) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!event.headers.has('x-version')) {
            return;
          }

          const serverVersion = event.headers.get('x-version');
          this.updateVersion(serverVersion);
        }
      }),
    );
  }

  updateVersion(serverVersion: string): void {
    const version = this.localStorageService.get('x-version');

    if (!version) {
      this.localStorageService.set('x-version', serverVersion);
    }
    if (version && version !== serverVersion) {
      this.localStorageService.set('x-version', serverVersion);
      setTimeout(() => window.location.reload(), 100);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBlacklistedRoute(req)) {
      return next.handle(req);
    } {
      // Get the auth token from the service.
      const authToken = this.token;

      if (authToken) {
        const now = new Date().getTime();
        const validUntil = this.localStorageService.get('token_valid');

        if (now >= +validUntil) {
          // Token is no longer valid -> logout
          // TODO freeze screen instead of logging out
          this.store.dispatch(AuthActions.logout(null));
          return of();
        }

        // Token is still valid -> renew valid time
        this.localStorageService.set('token_valid', (now + ((60 * 60 * 1000) + (59 * 60 * 1000))).toString()); // 1h and 59m
      }

      // const impersonateUserName = this.auth.imporsonate;
      return this.handleInterception(authToken, req, next);
    }
  }

  isFormData(value: unknown): value is FormData {
    return typeof FormData !== typeof undefined && value instanceof FormData;
  }
}
