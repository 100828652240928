/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OrganizationService } from 'app/organization/services/organization';

import { CreditCard } from 'app/entity/billing/credit-card.model';

import { SidePopupService } from '../../services/side-popup.service';
import { StripeService } from '../../services/stripe.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'myflow-credit-card-edit',
  templateUrl: './credit-cards-edit.component.html',
  styleUrls: [ './credit-cards-edit.component.scss' ],
})
export class CreditCardsEditComponent implements OnChanges {
  @Input() active: boolean;
  @Output() cardVerified = new EventEmitter();

  owner: string;

  card: CreditCard = {
    owner: '',
    ending: null,
    expires: null,
    name: null,
    valid: null,
  };

  errors = {
    name: null,
    value: null,
    expiry: null,
    cvv: null,
  };

  private stripeCardElement: any;

  constructor(
    private stripeService: StripeService,
    private sidePopup: SidePopupService,
    private organizationService: OrganizationService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active.currentValue) {
      this.init();
    }
  }

  init(): void {
    const locale = 'auto'; // TODO: for translation

    // Transform font to base64 data url to give it to stripe
    this.sidePopup.loading = true;
    fetch('/assets/fonts/avenir-next/AvenirNextLTPro-Thin.ttf')
      .then((response) => response.blob())
      .then((blob: Blob) => new Promise(((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (): void => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })))
      .then(base64 => {
        // Pass the base64 data url to stripe in for for stripe to use the font
        const elements = this.stripeService.stripe.elements({
          fonts: [
            {
              family: 'Avenir Next New W01 Regular',
              weight: 400,
              src: `url("${base64}") format("truetype")`,
              unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
            },
          ],
          locale,
        });

        // Style the input elements in stripe
        const style = {
          base: {
            color: '#000',
            fontWeight: 400,
            fontFamily: '"Avenir Next New W01 Regular", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            fontSize: '14px',
            '::placeholder': {
              color: '#6F6F6F',
            },
          },
        };

        const errorHandlerFor = (selector: any) => ({ error }): void => {
            this.sidePopup.getCurrent().updated.next(true);
            this.errors[selector] = error ? error.message : null;
          };

        // Create the custom stripe elements using the style as defined previously
        this.stripeCardElement = elements.create('cardNumber', {
          style,
          placeholder: 'Enter Credit Card Number',
        });
        this.stripeCardElement.mount('#card-number');
        this.stripeCardElement.addEventListener('change', errorHandlerFor('number'));

        const cardDate = elements.create('cardExpiry', {
          style,
          placeholder: 'MM/YY',
        });
        cardDate.mount('#card-date');
        cardDate.addEventListener('change', errorHandlerFor('expiry'));

        const cardCVV = elements.create('cardCvc', {
          style,
          placeholder: 'CVV',
        });
        cardCVV.mount('#card-cvv');
        cardCVV.addEventListener('change', errorHandlerFor('cvv'));

        this.sidePopup.loading = false;
      });
  }

  async submit(): Promise<void> {
    this.sidePopup.loading = true;

    lastValueFrom(
      this.organizationService.createOrganizationPaymentMethod('credit_card'),
    ).then(response => {
      this.stripeService.stripe.confirmCardSetup(response.clientSecret, {
        'payment_method': {
          card: this.stripeCardElement,
          'billing_details': {
            name: this.owner,
          },
        },
      }).then(result => {
        this.cardVerified.emit(result);
        this.sidePopup.loading = false;
      });
    });
  }
}
