<div
class="dynamic-field form-input form-group"
[formGroup]="group"
>
<input
  class="form-check-inline form-check-input"
  type="checkbox"
  [formControlName]="config.name"
  [required]="config.required"
  [id]="config.name"
>

<label class="form-check-label" [for]="config.name">
  <span class="text-body" [innerHTML]="config.label"></span> <span *ngIf="config.required">*</span>
</label>

<app-form-errors [control]="control" [label]="config.errorLabel || config.label"></app-form-errors>
</div>