<ng-container *ngIf="!isCard">
  <mat-table class="mat-elevation-z8 placeholders" [ngClass]="{'instances': instanceTable}" [dataSource]="rows">
    <ng-container *ngFor="let column of tableColumns" matColumnDef="{{column.column}}">
      <mat-header-cell *matHeaderCellDef [ngClass]="column.width">{{ column.title }}</mat-header-cell>
        <mat-cell *matCellDef="let item" [ngClass]="column.width">
          <div class="animated" myflowRandomizeTableLoaderWidth></div>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</ng-container>

<ng-container *ngIf="isCard">
  <div class="instance-plates">
    <div *ngFor="let item of rows">
      <div class="instance-plates-wrapper placeholders">
        <div class="instance-plate">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>
                <div class="animated" myflowRandomizeTableLoaderWidth></div>
              </mat-card-title>
              <div class="plate-header-content">
                <div class="animated os-placeholder"></div>
              </div>
              <div class="plate-header-menu">
                <div class="animated" style="width: 100%"></div>
              </div>
            </mat-card-header>
            <mat-card-content>
              <div class="plate-content-item">
                <div class="content-item-title"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
                <div class="content-item-text"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
              </div>

              <div class="plate-content-item">
                <div class="content-item-title"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
                <div class="content-item-text"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
              </div>

              <div class="plate-content-item">
                <div class="content-item-title"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
                <div class="content-item-text"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
              </div>

              <div class="plate-content-item">
                <div class="content-item-title"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
                <div class="content-item-text"><div class="animated" myflowRandomizeTableLoaderWidth></div></div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
