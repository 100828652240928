import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AttachedNetworkInterface } from 'app/entity/compute-networking';
import { SecurityGroupInstanceDataService } from 'app/networking/store/security-group-instance/security-group-instance.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class SecurityGroupInstanceService extends GenericService<AttachedNetworkInterface> {
  constructor(
    store: Store<EntityCache>,
    dataService: SecurityGroupInstanceDataService,
  ) {
    super('SecurityGroupInstance', store, dataService);
  }
}
