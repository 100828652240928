import { Injectable } from '@angular/core';
import { DeploymentMetrics, VNC } from 'app/entity';

import { CreateDevice, Device, UpdateDevice } from 'app/entity/mbm-devices';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

import { ApiService } from 'app/shared/services/api';

import {
  httpBareMetalDeviceRenew,
  httpBareMetalDevices,
  httpBareMetalDeviceVNC,
  httpDeploymentMetrics,
  httpRegisterProductNotification,
} from 'app/shared/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceDataService extends GenericDataService<Device, CreateDevice, UpdateDevice> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpBareMetalDevices, apiService);
  }

  getVnc(_context: number[], id: number): Observable<VNC> {
    return this.apiService.get(httpBareMetalDeviceVNC(id));
  }

  deleteWithElasticIps(context: number[], id: number, params: { 'delete_elastic_ip': boolean }): Observable<Device> {
    return this.apiService.delete(this.appendId(this.resolveUrl(context), id), { params });
  }

  cancelDeletion(_context: number[], id: number): Observable<Device> {
    return this.apiService.post(httpBareMetalDeviceRenew(id));
  }

  getDeploymentMetrics(): Observable<DeploymentMetrics[]> {
    return this.apiService.get(httpDeploymentMetrics(), this.optionsPaginationDeactivated);
  }

  registerForProductNotification(productId: number, payload: { locationId: number }): Observable<void> {
    return this.apiService.post(httpRegisterProductNotification(productId), payload);
  }

  protected appendAction(url: string): string {
    return `${url}/actions`;
  }
}
