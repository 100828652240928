import { Injectable } from '@angular/core';
import { CreateReleaseNote, ReleaseNote, UpdateReleaseNote } from 'app/entity/admin';
import { ApiService } from 'app/shared/services/api';
import { httpAdminDashboardReleaseNotes } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNoteDataService extends GenericDataService<ReleaseNote, CreateReleaseNote, UpdateReleaseNote> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminDashboardReleaseNotes, apiService);
  }
}
