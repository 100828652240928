<div class="container" [style.grid-template-columns]="gridColumns">
  <ng-container *ngFor="let option of cardOptions; trackBy: trackOptionByName">
    <div
      class="distribution"
      [class.selected]="isOptionActive(option)"
      [class.disabled]="disabledOption(option) || option.comingSoon"
      [class.hide-options]="option.hideOptions"
      (click)="selectOption(option);"
      [ngbTooltip]="disabledOption(option) ? disabledOption(option) : option.selectionHint"
      triggers="manual"
      [autoClose]="false"
      #t="ngbTooltip"
      (mouseenter)="onMouseEnter(t)"
      (mouseleave)="onMouseLeave(option, t)"
      tooltipClass="image-tooltip"
      placement="top"
    >
      <div class="image-description">
        <ng-container
          [ngTemplateOutlet]="icon"
          [ngTemplateOutletContext]="{ data: option }"
        ></ng-container>
        <p class="image-name">{{ option.name }}</p>
        <img *ngIf="option.comingSoon" class="coming-soon" src="assets/img/coming-soon-badge.svg" alt="Coming soon badge">
      </div>
      <div class="image-select" *ngIf="!option.hideOptions">
        <ng-select
          *ngIf="option.subOptions && option.subOptions.length > 1"
          [(ngModel)]="option.currentValue"
          [clearable]="false"
          [searchable]="false"
          class="version-list"
        >
          <ng-container *ngFor="let subOption of option.subOptions">
            <ng-option
              [value]="subOption.value"
              class="active"
            >
              {{ subOption.name }}
            </ng-option>
          </ng-container>
        </ng-select>

        <span *ngIf="option.subOptions.length === 1" class="version-single">{{ option.subOptions[0].name }}</span>
      </div>
    </div>
  </ng-container>
</div>