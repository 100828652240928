import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InstanceLicenseDataService } from 'app/compute/store/instance-license/instance-license.data';
import { License } from 'app/entity/compute-instances';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class InstanceLicenseService extends GenericService<License> {
  constructor(
    store: Store<EntityCache>,
    dataService: InstanceLicenseDataService,
  ) {
    super('InstanceLicense', store, dataService);
  }
}
