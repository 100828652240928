import { Action } from '@ngrx/store';

import { Module } from 'app/entity/infrastructure/module.model';

import { LayoutActions } from '../actions';

export interface State {
  showSidenav: boolean;
  showTable: boolean;
  menu: Module[];
}

export const initialState: State = {
  showSidenav: true,
  showTable: true,
  menu: [],
};

export const reducer = (state: State = initialState, action: Action): State => {
  const specificAction = action as LayoutActions.LayoutActionsUnion;

  switch (specificAction.type) {
    case LayoutActions.closeSidenav.type:
      return {
        ...state,
        showSidenav: false,
      };

    case LayoutActions.openSidenav.type:
      return {
        ...state,
        showSidenav: true,
      };

    case LayoutActions.showTable.type:
      return {
        ...state,
        showTable: true,
      };

    case LayoutActions.showPlates.type:
      return {
        ...state,
        showTable: false,
      };

    case LayoutActions.setMenu.type:
      return {
        ...state,
        menu: specificAction.menu,
      };

    default:
      return state;
  }
};

export const getShowSidenav = (state: State): boolean => state.showSidenav;
export const getShowTable = (state: State): boolean => state.showTable;
export const getMenu = (state: State): Module[] => state.menu;
