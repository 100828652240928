import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreateSecurityGroup, SecurityGroup, UpdateSecurityGroup } from 'app/entity/compute-networking';
import { SecurityGroupDataService } from 'app/networking/store/security-group/security-group.data';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable()
export class SecurityGroupService extends GenericService<SecurityGroup, CreateSecurityGroup, UpdateSecurityGroup> {
  constructor(
    store: Store<EntityCache>,
    dataService: SecurityGroupDataService,
  ) {
    super('SecurityGroup', store, dataService);
  }
}
