<ng-container *ngIf="component === 'ticket-create'">
  <div
    class="dropzone"
    myflowDnD
    (filesChangeEmiter)="onFilesChange($event)"
    (filesInvalidEmiter)="onFileInvalids($event)"
    [allowedExtensions]="extensions"
  >
    <div class="filezone-wrapper text-wrapper">
      <div class="centered">
        <a (click)="choose()" class="dnd-link" title="Add file">Add</a> or drop files here
      </div>
      <input #fi type="file" name="public-key" id="file" (change)="onFilesChange($event.target.files)" hidden multiple/>
    </div>
  </div>
  <div>
    <p class="dropzone-info" *ngIf="info">
      {{ info }}
    </p>
  </div>
</ng-container>
<ng-container *ngIf="component !== 'ticket-create'">
  <input #fi type="file" name="public-key" id="file" (change)="onFilesChange($event.target.files)" hidden multiple/>
</ng-container>

<div class="dropzone-list">
  <ul class="valid" *ngIf="showFilesList">
    <li *ngFor="let file of fileList; let i = index">
      <div class="file-list-name">
        <i class="icon icon-file-text-image"></i>
        {{ file.name }}
      </div>
      <i
        class="icon dnd-link icon-trash"
        title="Delete"
        (click)="removeFile(i, file)">
      </i>
    </li>
  </ul>
  <ul class="invalid">
    <li *ngIf="errorMsg">{{ errorMsg }}</li>
  </ul>
</div>
