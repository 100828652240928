import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { Observable, Subject, of } from 'rxjs';
import { repeat, take, takeUntil, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromLayout from 'app/store/reducers';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TableService } from 'app/shared/services/table';
import { HttpObserveTypes } from 'app/entity';

@Component({
  selector: 'myflow-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: [ './table-card.component.scss' ],
})
export class TableCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() title = 'Items';
  @Input() displayedColumns = [];

  @ViewChild('cardsSection') cardsSection: ElementRef;
  showCards = false;

  limits: number[] = [ 5, 10, 15, 25, 50, 100 ];
  size = 0;
  page = 1;
  limit = 10;
  query = '';
  orderStatus = '';
  rows: any[] = [];
  dataSource = new MatTableDataSource([]);
  isLoading = false;
  orderColumn = '';
  orderDir = 'asc';
  newItemId: number;
  customFilter: any = null;
  cardsList: Observable<any[]> = of([]);

  loadingItems: number[] = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]; // for 'table loading indicator'
  showTables$ = this.store.select(fromLayout.getShowTable);

  url = '';
  protected destroyed$ = new Subject();

  constructor(
    public store: Store<any>,
    public tableService: TableService,
    public router?: Router,
    public changeDetector?: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.load();
    this.cardsList = this.dataSource.connect();

    if (this.router) {
      this.url = this.router.url;
      const state = this.tableService.getTableState(this.url);

      if (state) {
        this.limit = state.limit || this.limit;
        this.page = state.page || this.page;
      }
    }
  }

  ngAfterViewInit(): void {
    if (typeof this.cardsSection !== 'undefined') {
      this.showTables$.pipe(
        takeUntil(this.destroyed$),
        tap(showTable => {
          this.showCards = !showTable;
          if (this.changeDetector) {
            this.changeDetector.detectChanges();
          }
        }),
      ).subscribe();

      setTimeout(() => {
        this.showCards = this.showCards &&
          this.cardsSection &&
          this.cardsSection.nativeElement &&
          this.cardsSection.nativeElement.childNodes &&
          this.cardsSection.nativeElement.childNodes.length > 2;
      });
    }
  }

  setLimit(limit: number): void {
    this.limit = limit;
    this.getLoadingItems();
    this.load();
  }

  getLoadingItems(): any[] {
    const loadingItem: number[] = [];
    for (let i = 0; i < this.limit; i++) { // for table loading indicator TODO
      loadingItem.push(i);
    }
    this.loadingItems = [ ...loadingItem ];
    return this.loadingItems;
  }

  load(event?: any): void {
    this.isLoading = true;
    if (event) {
      this.page = event;
    }

    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('per_page', `${this.limit}`);

    if (this.query && this.query.trim()) {
      params = params.append('q', this.query.trim());
    }
    if (this.orderColumn || this.orderDir) {
      params = params.append('order_by', this.orderColumn);
      params = params.append('order_dir', this.orderDir);
    }

    if (this.orderStatus) {
      params = params.append('status', this.orderStatus);
    }

    if (this.customFilter) {
      params = params.append(this.customFilter.param, this.customFilter.value);
    }

    this.getRows({ params, observe: HttpObserveTypes.RESPONSE })
      .pipe(
        take(1),
        repeat(1),
        takeUntil(this.destroyed$),
        tap((response) => {
          if (response.items) {
            this.rows = response.items;
            this.dataSource.data = response.items;
            this.size = response.size;
            this.query = response.query;
          } else if (this.queryMatches(response.headers)) {
            this.rows = response.body;
            this.dataSource.data = response.body;
            this.readPaginationParams(response.headers);
          }
          this.isLoading = false;
        }),
        tap(() => {
          if (this.changeDetector) {
            this.changeDetector.detectChanges();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  readPaginationParams(headers: any): void {
    this.size = headers.get('x-pagination-total-count');
    this.limit = headers.get('x-pagination-limit');
  }

  getRowIndexById(id: number): number | undefined {
    for (const idx in this.rows) {
      if (this.rows[idx].id === id) {
        return +idx;
      }
    }

    return -1;
  }

  replaceRow(row: any): void {
    for (const idx in this.rows) {
      if (this.rows[idx].id === row.id) {
        this.rows[idx] = row;
      }
    }
  }

  getRows(_params: { params: HttpParams; observe: HttpObserveTypes }): Observable<any> {
    return new Observable<any>();
  }

  queryMatches(headers: any): boolean {
    if (headers && headers.has('x-search-query')) {
      return this.query.trim() === headers.get('x-search-query')[0];
    }
    return this.query === '' || this.query.length < 2;
  }
}
