import { Injectable } from '@angular/core';
import { SupportPlan } from 'app/entity/support';
import { ApiService } from 'app/shared/services/api';
import {
  httpPlans,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class PlanDataService extends GenericDataService<SupportPlan> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpPlans, apiService);
  }
}
