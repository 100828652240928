import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { contextOrganization } from 'app/admin/store/context/organization-quota.context';
import { OrganizationSupportPlanService } from 'app/admin/store/organization-support-plan';
import { SupportPlan } from 'app/entity/admin';
import { ProductInstance } from 'app/entity/common';
import { SupportPlanPreset } from 'app/entity/support';
import { SidePopupService } from 'app/shared/services/side-popup.service';
import { businessPlanId, firstPlanId } from 'app/tickets/store/plans';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'myflow-edit-support-plan',
  templateUrl: './edit-support-plan.component.html',
  styleUrls: [ './edit-support-plan.component.scss' ],
})
export class EditSupportPlanComponent implements OnInit {
  @Input() plan$: Observable<ProductInstance>;
  @Input() orgId: number;
  @Output() closePopup: EventEmitter<{ close: boolean; update: boolean }> = new EventEmitter();

  updating = false;
  availablePlans: SupportPlanPreset[];
  editingInstance: ProductInstance;

  supportPlanForm = new UntypedFormGroup({
    presetId: new UntypedFormControl(businessPlanId, [ Validators.required ]),
    price: new UntypedFormControl('', [ Validators.required, Validators.min(0) ]),
  });

  constructor(
    private sidePopup: SidePopupService,
    private supportPlanService: OrganizationSupportPlanService,
    private toastrService: ToastrService,
  ) { }

  get loading$(): Observable<boolean> {
    return this.sidePopup.loading$;
  }

  ngOnInit(): void {
    this.plan$.subscribe((instance) => {
      this.availablePlans = [
        { id: businessPlanId, name: 'Business Support' },
        { id: firstPlanId, name: 'First Support' },
        { id: 61, name: 'DevOps Support' },
      ];
      if (instance !== null) {
        this.updating = true;
        this.editingInstance = instance;

        //Set the value of the form to the editing instance
        this.supportPlanForm.setValue({
          presetId: instance.product.id,
          price: instance.product.price,
        });
        const type = instance.product.type;
        if (typeof (type) !== 'string') {
          if (type.key === 'support-dev-ops') {
            //If we are editing a devops plan, disable Business and First Support options
            this.availablePlans.splice(0, 2);
            this.supportPlanForm.controls.presetId.disable();
          } else if (type.key === 'support-ticketing') {
            //If we are editing a ticketing support plan, only show Business and First Support options
            this.availablePlans.splice(2, 1);
          }
        }
      }
    },
    );
  }

  close(): void {
    this.closePopup.emit({ close: true, update: false });
  }

  submitPlan(): void {
    this.supportPlanForm.markAllAsTouched();
    if (this.supportPlanForm.valid) {
      this.sidePopup.loading = true;

      let call: Observable<SupportPlan>;
      if (!this.updating) {
        call = this.supportPlanService.activatePlan(this.supportPlanForm.value, contextOrganization(this.orgId));
      } else {
        call = this.supportPlanService.updatePlan(this.editingInstance.id, this.supportPlanForm.value, contextOrganization(this.orgId));
      }

      call.subscribe({
        next: (updatedPlan: { presetId: number }) => {
          this.sidePopup.loading = false;
          //Fetch plan name of the selected plan
          const planName = this.availablePlans.find((plan) => plan.id === updatedPlan.presetId).name;

          //Show success message
          const text = `${planName} plan successfully ${this.updating ? 'updated' : 'activated'}.`;
          this.toastrService.success(text);

          this.closePopup.emit({ close: true, update: true });
          this.sidePopup.close();
        }, error: () => {
          this.sidePopup.loading = false;
        },
      });
    }
  }
}
