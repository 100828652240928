import { Injectable } from "@angular/core";
import { Cluster, UpdateClusterFlavor } from "app/entity/compute-kubernetes";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { OrganizationClusterDataService } from "./organization-cluster.data";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { Store } from "@ngrx/store";
import { ClusterPrivateKey, ClusterResetStatusMessage } from "app/entity/admin";
import { Observable, tap } from "rxjs";

@Injectable()
export class OrganizationClusterService extends GenericService<Cluster> {
  constructor(
    store: Store<EntityCache>,
    private clusterDataService: OrganizationClusterDataService,
  ) {
    super('AdminOrganizationCluster', store, clusterDataService);
  }

  getPrivateKey(id: number, context?: number[]): Observable<ClusterPrivateKey> {
    const ctx = context || this.getContext();

    return this.clusterDataService.getPrivateKey(ctx, id);
  }

  upgradeFlavor(id: number, upgrade: UpdateClusterFlavor, context?: number[]): Observable<Cluster> {
    const ctx = context || this.getContext();

    return this.clusterDataService.upgradeFlavor(ctx, id, upgrade).pipe(
      tap(cluster => this.updateOneInCache(ctx, cluster)),
    );
  }

  cancelAction(id: number, context?: number[]): Observable<void> {
    const ctx = context || this.getContext();

    return this.clusterDataService.cancelAction(ctx, id);
  }

  resetStatus(id: number, context?: number[]): Observable<ClusterResetStatusMessage> {
    const ctx = context || this.getContext();

    return this.clusterDataService.resetStatus(ctx, id);
  }
}
