import { MessageHandler } from '../../entity-connection';
import { Ticket, TicketComment } from '../../../../../entity/tickets';
import { Message } from '../../../sse/connection';
import { TicketService } from '../../../../../tickets/store/tickets';
import { first, map } from 'rxjs/operators';
import { lastValueFrom, Observable } from 'rxjs';

export class TicketCommentNgrxEntityHandler implements MessageHandler<TicketComment> {
  constructor(
    private service: TicketService,
    private entityMapper: (msg: Message<TicketComment>) => [ number[], TicketComment ],
  ) {
  }

  async create(msg: Message<TicketComment>): Promise<void> {
    return this.processComment(
      msg,
      (context: number[], entity: TicketComment): Observable<Ticket> => this.service.addOneCommentToCache(context, entity),
    );
  }

  async update(msg: Message<TicketComment>): Promise<void> {
    return this.processComment(
      msg,
      (context: number[], entity: TicketComment): Observable<Ticket> => this.service.updateOneCommentInCache(context, entity),
    );
  }

  async delete(msg: Message<TicketComment>): Promise<void> {
    return this.processComment(
      msg,
      (context: number[], entity: TicketComment): Observable<Ticket> => this.service.removeOneCommentFromCache(context, entity),
    );
  }

  private async processComment(
    msg: Message<TicketComment>,
    fn: (context: number[], entity: TicketComment) => Observable<Ticket>,
  ): Promise<void> {
    const entityMapping = this.entityMapper(msg);
    const context = entityMapping[0];
    const entity = entityMapping[1];

    return lastValueFrom(
      fn(context, entity).pipe(
        first(),
        map(() => null),
      ),
    );
  }
}
