import { Injectable } from "@angular/core";
import { UpdateUser, UpdateUserNotes } from "app/entity/admin/user.model";
import { User } from "app/entity/infrastructure";
import { ApiService } from "app/shared/services/api";
import { httpAdminImpersonate, httpAdminUserActivation, httpAdminUserNotes, httpAdminUsers, httpPasswordReset } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends GenericDataService<User, null, UpdateUser> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminUsers, apiService);
  }

  updateNotes(id: number, data: UpdateUserNotes): Observable<User> {
    return this.apiService.patch(httpAdminUserNotes(id), data);
  }

  resetPassword(email: string): Observable<void> {
    return this.apiService.post(httpPasswordReset(), { email });
  }

  resendActivationMail(id: number): Observable<void> {
    return this.apiService.post(httpAdminUserActivation(id));
  }

  impersonate(id: number): Observable<User> {
    return this.apiService.post(httpAdminImpersonate(), { userId: id });
  }
}
