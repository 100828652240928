import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LocationService } from 'app/core/services/location';

@Directive({
  selector: '[myflowModuleAccess]',
})
export class ModuleAccessDirective implements OnInit {
  @Input() module: number;

  constructor(
    private el: ElementRef,
    private locationService: LocationService,
  ) { }

  ngOnInit(): void {
    if (this.locationService.modules.length && !this.locationService.modules.find(m => m.id === this.module)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
