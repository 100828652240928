<myflow-side-panel
  title="Upgrade your Support Plan"
  [loading$]="(loading$ | async)"
  [disabled$]="selectedPlan === undefined"
  (closeEvent)="close()"
  (confirmEvent)="requestSelectedPlan()"
  action="Request"
>
  <div content class="form-wrapper">
    <div class="support-header">
      <h2 class="support-header-title">Upgrade Support Plan</h2>
      <p class="support-header-description">
        Choose a support plan to enhance your support experience. Before you can take advantage of the new support plan, our sales team will review your request and contact you. 
        Learn more about our <a href="https://doc.flow.swiss/platform/pricing/support" target="_blank">Support Plans</a>.
      </p>
    </div>
    <myflow-plan-select [plans]="plans$ | async" [(ngModel)]="selectedPlan" />
  </div>
</myflow-side-panel>
