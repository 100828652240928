<div
  class="graph-container"
  (mouseenter)="baseTooltip.open();  additionalUsage.open();"
  (mouseleave)="baseTooltip.close(); additionalUsage.close();"
>
  <div
    class="usage-container"
    [ngClass]="{ 'primary': isBaseUsage }"
    [style.width.%]="baseUsagePercent"
    placement="top"
    [ngbTooltip]="baseUsage | bytes"
    [tooltipClass]="isBaseUsage ? 'primary' : 'primary-dark'"
    triggers="manual"
    #baseTooltip="ngbTooltip"
  ></div>

  <div
    [hidden]="isBaseUsage"
    class="additional-container"
    [style.width.%]="extraUsagePercent"
    placement="top"
    ngbTooltip="{{ extraUsage | bytes }}"
    tooltipClass="primary"
    triggers="manual"
    #additionalUsage="ngbTooltip"
  ></div>
</div>