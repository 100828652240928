import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InstanceVolumeDataService } from 'app/compute/store/instance-volume/instance-volume.data';
import { CreateVolume, UpdateVolume, UpgradeVolume, Volume } from 'app/entity/compute-instances';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class InstanceVolumeService extends GenericService<Volume, CreateVolume, UpdateVolume> {
  constructor(
    store: Store<EntityCache>,
    private volumeDataService: InstanceVolumeDataService,
  ) {
    super('InstanceVolume', store, volumeDataService);
  }

  upgrade(id: number, upgrade: UpgradeVolume, context?: number[]): Observable<Volume> {
    const ctx = context ?? this.getContext();
    return this.volumeDataService.upgrade(ctx, id, upgrade).pipe(
      tap(volume => this.updateOneInCache(ctx, volume)),
    );
  }

  attach(id: number, data: { instanceId: number }): Observable<Volume> {
    return this.volumeDataService.attach(id, data).pipe(
      tap(volume => this.addOneToCache([ data.instanceId ], volume)),
    );
  }

  detach(id: number, context?: number[]): Observable<boolean> {
    const ctx = context ?? this.getContext();
    return this.volumeDataService.detach(ctx, id).pipe(
      tap(() => this.removeOneFromCache(id)),
    );
  }
}
