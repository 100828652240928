import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeploymentMetrics, VNC } from 'app/entity';
import { InvokeAction } from 'app/entity/common';
import { CreateDevice, Device, UpdateDevice } from 'app/entity/mbm-devices';
import { LocationService } from 'app/core/services/location';

import { OrderMessagesService } from 'app/shared/services/messages/order-messages.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { DeviceDataService } from 'app/baremetal/store/device/device.data';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class DeviceService extends GenericService<Device, CreateDevice, UpdateDevice> {
  constructor(
    store: Store<EntityCache>,
    orderMessagesService: OrderMessagesService,
    private devicesDataService: DeviceDataService,
    private locationService: LocationService,
  ) {
    super('Device', store, devicesDataService, orderMessagesService);
  }

  invokeAction(id: number, action: InvokeAction, context?: number[]): Observable<Device> {
    const ctx = context || this.getContext();

    return this.getById(id, ctx).pipe(
      take(1),
      tap(device => {
        this.updateOneInCache(ctx, { ...device, status: { id: 0, key: 'working', name: 'Working', actions: [] } });
      }),
      switchMap(() => this.dataService.invokeAction(ctx, id, action)),
      tap(entity => this.updateOneInCache(ctx, entity)),
    );
  }

  getVnc(id: number, context?: number[]): Observable<VNC> {
    const ctx = context || this.getContext();
    return this.devicesDataService.getVnc(ctx, id);
  }

  deleteWithElasticIps(id: number, params: { 'delete_elastic_ip': boolean }, context?: number[]): Observable<Device> {
    const ctx = context || this.getContext();
    return this.devicesDataService.deleteWithElasticIps(ctx, id, params).pipe(
      tap(device => this.updateOneInCache(ctx, device)),
    );
  }

  cancelDeletion(id: number, context?: number[]): Observable<Device> {
    const ctx = context || this.getContext();
    return this.devicesDataService.cancelDeletion(ctx, id).pipe(
      tap(device => this.updateOneInCache(ctx, device)),
    );
  }

  getDeploymentMetrics(): Observable<DeploymentMetrics[]> {
    return this.devicesDataService.getDeploymentMetrics();
  }

  registerForProductNotification(productId: number, payload: { locationId: number }): Observable<void> {
    return this.devicesDataService.registerForProductNotification(productId, payload);
  }

  protected createDummyRecord(create: CreateDevice): Device {
    const location = this.locationService.moduleLocations(3).find(l => l.id === create.locationId);

    return {
      id: new Date().valueOf(),
      name: create.name,
      status: {
        id: 2,
        name: 'Powered Off',
        key: 'powered-off',
        actions: [],
      },
      location: {
        id: location.id,
        name: location.name,
        key: location.key,
      },
      product: {
        id: 0,
        name: '',
        productName: 'macmini',
        type: {
          id: 5,
          name: 'Mac Bare Metal Device',
          key: 'bare-metal-device',
        },
        visibility: 'public',
        items: [],
        usageCycle: null,
        children: [],
        availability: [],
        category: null,
        deploymentFees: [],
        commitment: null,
      },
      network: null,
      operatingSystem: {
        os: 'macOS',
        name: 'macOS',
        version: '',
      },
      networkInterfaces: [],
      metalControl: 'Connected',
      metalControlTools: 'Running',
    };
  }
}
