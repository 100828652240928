import { Injectable } from '@angular/core';
import { CreateNetwork, Network, UpdateNetwork } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import { httpComputedEnginesVPCs } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class PrivateNetworkDataService extends GenericDataService<Network, CreateNetwork, UpdateNetwork> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedEnginesVPCs, apiService);
  }
}
