import { Injectable } from '@angular/core';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';

@Injectable({
  providedIn: 'root',
})
export class EntityCacheService {
  private services: GenericService<any>[] = [];

  registerService(service: GenericService<any>): void {
    this.services.push(service);
  }

  registerServices(services: GenericService<any>[]): void {
    this.services.push(...services);
  }

  cleanUpCache(): void {
    for (const service of this.services) {
      service.cleanUpCache();
    }
  }
}
