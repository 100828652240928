import { Component, Input } from '@angular/core';
import { ValueAccessorBase } from '../../utils/value-accessor';

interface RechargeAmount {
  amount: number;
  cashbackPercent: number;
}

@Component({
  selector: 'myflow-amount-select',
  templateUrl: './amount-select.component.html',
  styleUrls: [ './amount-select.component.scss' ],
  // eslint-disable-next-line no-use-before-define
  providers: [ ValueAccessorBase.createProviderFor(AmountSelectComponent) ],
})
export class AmountSelectComponent extends ValueAccessorBase<number> {
  @Input() predefinedAmounts: RechargeAmount[];
  @Input() description: string;
  @Input() type: string; // 0 - manual, 1 - auto, 2 - no

  private dropdownVal: number;

  get dropdownValue(): number {
    const amounts = this.predefinedAmounts.map<number>((val) => val.amount);
    return this.value && !amounts.includes(+this.value) ? -1 : this.dropdownVal;
  }

  set dropdownValue(value: number) {
    this.dropdownVal = +value;

    if (value !== -1) {
      this.value = +value;
    }
  }
}
