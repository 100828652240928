import { Injectable } from '@angular/core';
import { AttachNetworkInterface, NetworkInterface } from 'app/entity/compute-networking';
import { ApiService } from 'app/shared/services/api';
import {
  httpComputedInstanceNetworkInterfaces,
  httpComputedInstanceNetworkInterfaceSecurity,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstanceNetworkInterfaceDataService extends GenericDataService<NetworkInterface, AttachNetworkInterface> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedInstanceNetworkInterfaces, apiService);
  }

  updateSecurity(context: number[], id: number, data: { security: boolean }): Observable<NetworkInterface> {
    return this.apiService.patch(httpComputedInstanceNetworkInterfaceSecurity(context[0], id), data);
  }
}
