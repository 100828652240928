import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ProductInstance } from "app/entity/common";
import { EntityCache } from "app/store/entity-cache/entity-cache";
import { GenericService } from "app/store/generic-store-infrastructure/generic.service";
import { OrganizationProductInstanceDataService } from "./organization-product-instance.data";
import { UpdateProductInstance } from "app/entity/admin";
import { Observable } from "rxjs";

@Injectable()
export class OrganizationProductInstanceService extends GenericService<ProductInstance, null, UpdateProductInstance> {
  constructor(
    store: Store<EntityCache>,
    private productInstanceDataService: OrganizationProductInstanceDataService,
  ) {
    super('AdminOrganizationProductInstance', store, productInstanceDataService);
  }

  getAllDeleted(context?: number[]): Observable<ProductInstance[]> {
    const ctx = context || this.getContext();

    return this.productInstanceDataService.getAllDeleted(ctx);
  }
}
