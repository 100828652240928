import { Injectable } from '@angular/core';
import { CreateSecurityGroupRule, SecurityGroupRule, UpdateSecurityGroupRule } from 'app/entity/mbm-networking';
import { ApiService } from 'app/shared/services/api';
import { httpBareMetalSecurityGroupRules } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class SecurityGroupRuleDataService extends GenericDataService<SecurityGroupRule, CreateSecurityGroupRule, UpdateSecurityGroupRule> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpBareMetalSecurityGroupRules, apiService);
  }
}
