import { Injectable } from '@angular/core';
import { CreateVolume, UpdateVolume, UpgradeVolume, Volume } from 'app/entity/compute-instances';
import { Order } from 'app/entity/store';
import { ApiService } from 'app/shared/services/api';
import {
  httpComputedInstanceVolumes,
  httpComputedVolumeInstance,
  httpComputedVolumeInstances, httpComputedVolumes,
  httpComputedVolumeUpgrade,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstanceVolumeDataService extends GenericDataService<Volume, CreateVolume, UpdateVolume> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedInstanceVolumes, apiService);
  }

  create(_context: number[], create: CreateVolume): Observable<Order | Volume> {
    return this.apiService.post(httpComputedVolumes(), create);
  }

  upgrade(_context: number[], id: number, upgrade: UpgradeVolume): Observable<Volume> {
    return this.apiService.post(httpComputedVolumeUpgrade(id), upgrade);
  }

  attach(id: number, data: { instanceId: number }): Observable<Volume> {
    return this.apiService.post(httpComputedVolumeInstances(id), data);
  }

  detach(context: number[], id: number): Observable<boolean> {
    return this.apiService.delete(httpComputedVolumeInstance(id, context[0]));
  }
}
