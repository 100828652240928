<div
  class="dynamic-field form-input form-group"
  [formGroup]="group">

  <form-base-label [config]="config" />

  <div class="password-input-container">
    <input
      [type]="revealPassword ? 'text' : 'password'"
      [attr.placeholder]="config.placeholder"
      [formControlName]="config.name"
      class="form-control"
      [required]="config.required"
      [id]="config.name"
    >
    <span class="secret-toggle" (click)="togglePassword()"><i class="{{ revealPassword ? 'icon-eye-on' : 'icon-eye-off' }}"></i></span>
  </div>

  <div *ngIf="config.hint" class="form-text text-muted">
    {{ config.hint }}
  </div>

  <app-form-errors [control]="control" [label]="config.label"></app-form-errors>
</div>
