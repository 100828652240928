import { Injectable } from "@angular/core";
import { ClusterPrivateKey, ClusterResetStatusMessage } from "app/entity/admin";
import { Cluster, UpdateClusterFlavor } from "app/entity/compute-kubernetes";
import { ApiService } from "app/shared/services/api";
import { httpAdminOrganizationClusterCancelAction, httpAdminOrganizationClusterFlavor, httpAdminOrganizationClusterPrivateKey, httpAdminOrganizationClusterResetStatus, httpAdminOrganizationClusters } from "app/shared/utils";
import { GenericDataService } from "app/store/generic-store-infrastructure/generic.data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationClusterDataService extends GenericDataService<Cluster> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrganizationClusters, apiService);
  }

  getPrivateKey(context: number[], id: number): Observable<ClusterPrivateKey> {
    return this.apiService.get(httpAdminOrganizationClusterPrivateKey(context[0], id));
  }

  upgradeFlavor(context: number[], id: number, upgrade: UpdateClusterFlavor): Observable<Cluster> {
    return this.apiService.patch(httpAdminOrganizationClusterFlavor(context[0], id), upgrade);
  }

  cancelAction(context: number[], id: number): Observable<void> {
    return this.apiService.post(httpAdminOrganizationClusterCancelAction(context[0], id));
  }

  resetStatus(context: number[], id: number): Observable<ClusterResetStatusMessage> {
    return this.apiService.post(httpAdminOrganizationClusterResetStatus(context[0], id));
  }
}
